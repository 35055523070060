import React from 'react';
import Proptypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Expander,
  ExpanderTitleButton,
  ExpanderContent
} from 'suomifi-ui-components';
import List from '../components/List';
import { DataProtectionPreview } from './DataProtectionPreview';
import { formatDate } from '../../../utils';

export const eAuthorizationPreview = ({
  formData,
  i18n,
  defaultOpen = true
}) => (
  <React.Fragment>
    <Expander defaultOpen={defaultOpen}>
      <ExpanderTitleButton>
        <FormattedMessage id="intermediary.serviceInfo.formTitle" />
      </ExpanderTitleButton>
      <ExpanderContent>
        {formData.serviceData?.services?.map((service, i) => (
          <React.Fragment key={`service-${service.name || i}`}>
            {i > 0 ? <hr className="my-4" /> : null}
            <List>
              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.serviceName'
                )}
                value={service.serviceName}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.serviceNameSv'
                )}
                value={service.serviceNameSv}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.serviceNameEn'
                )}
                value={service.serviceNameEn}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.serviceProviderName'
                )}
                value={service.serviceProviderName}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.serviceProviderNameSv'
                )}
                value={service.serviceProviderNameSv}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.serviceProviderNameEn'
                )}
                value={service.serviceProviderNameEn}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.serviceUrl'
                )}
                value={service.serviceUrl}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.usageDescription'
                )}
                value={service.usageDescription}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.serviceUsers'
                )}
                value={service.serviceUsers}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.actingOnBehalf'
                )}
                value={service.mattersWhenActingBehalf}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.actingOnBehalf'
                )}
                value={service.actingOnBehalf}
              />

              <List.SubItem
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.actingOnBehalfMinor'
                )}
                visible={service.actingOnBehalf}
                value={service.actingOnBehalfMinor}
              />
              <List.SubItem
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.actingOnBehalfAdult'
                )}
                visible={service.actingOnBehalf}
                value={service.actingOnBehalfAdult}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.actingOnBehalfOrganization'
                )}
                value={service.actingOnBehalfOrganization}
              />
              <List.SubItem
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.registerBased'
                )}
                visible={service.actingOnBehalfOrganization}
                value={service.registerBased}
              />
              <List.SubItem
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.authorizationBased'
                )}
                visible={service.actingOnBehalfOrganization}
                value={service.authorizationBased}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.actingOnBehalfForeignPersonOrCompany'
                )}
                value={service.actingOnBehalfForeignPersonOrCompany}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.onlineOnly'
                )}
                value={service.onlineOnly}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.actingOnBehalfOther'
                )}
                value={service.actingOnBehalfOther}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.listedWithOwnUI'
                )}
                options={{
                  ownUI: i18n('intermediary.eAuthorizationServiceInfo.ownUI'),
                  DVVUI: i18n('intermediary.eAuthorizationServiceInfo.DVVUI')
                }}
                value={service.listedWithOwnUI}
              />

              {service?.serviceUsageAttachment && (
                <List.Item
                  title={i18n('intermediary.preview.attachments')}
                  value={service.serviceUsageAttachment
                    .map(a => a.originalFileName)
                    .join(', ')}
                />
              )}

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.useTimetable'
                )}
                value={formatDate(service.implementationDate)}
              />

              <List.Item
                title={i18n(
                  'intermediary.eAuthorizationServiceInfo.supportEstimation'
                )}
                value={service.supportEstimation}
              />
            </List>
          </React.Fragment>
        ))}
      </ExpanderContent>
    </Expander>

    {/* Tietojen suojaus esikatselu tähän */}
    <DataProtectionPreview
      i18n={i18n}
      defaultOpen={defaultOpen}
      formData={formData}
    />
  </React.Fragment>
);
eAuthorizationPreview.propTypes = {
  defaultOpen: Proptypes.bool,
  i18n: Proptypes.func,
  formData: Proptypes.object
};
