import isEmpty from 'lodash/isEmpty';
import validator from 'validator';
import moment from 'moment';

/**
 * Functional utility for validator scoping
 */
export const execValidate = (values, ...validators) =>
  validators
    .map(([name, validator]) => [name, validator(values[name] || {})])
    .reduce((o, [k, v]) => (isEmpty(v) ? o : { ...o, [k]: v }), {});

export const mandatoryFieldKey = 'general.form.validation.mandatory';
export const emailFieldKey = 'general.form.validation.invalid_email';
export const ipFieldKey = 'general.form.validation.invalid_ip';
export const fqdnFieldKey = 'general.form.validation.invalid_fqdn';
export const isoDateFieldKey = 'general.form.validation.invalid_isodate';
export const isoDateNotPastFieldKey =
  'general.form.validation.not_past_isodate';
export const passwordFieldKey = 'general.form.validation.weakpassword ';
export const businessIdFieldKey = 'general.form.validation.invalid_business_id';
export const invalidCharactersFieldKey =
  'general.form.validation.invalid_characters';
export const invalidBusinessIdFieldKey =
  'general.form.validation.business_id_not_valid';

/**
 * Password must contain minimum 10 letters with at least one small and capital letter, one number and one specialmark.
 * Use validPassword() for redux-form validation.
 */
export const validatePassword = password => {
  const passReg =
    '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\\da-zA-Z])\\S{20,100}$';
  return new RegExp(passReg).test(password);
};

export const validateBusinessId = value => {
  const failReg = '^[0-9]{6}[-+A][0-9]{3}[0-9ABCDEFHJKLMNPRSTUVWXY]$';
  return new RegExp(failReg, 'i').test(value?.trim());
};

export const validateInvalidCharacters = value => {
  const failReg = '[<>/\\/]';
  return new RegExp(failReg, 'i').test(value?.trim());
};

export const validateBusinessIdAndInvalidCharacters = value => {
  if (validateBusinessId(value)) {
    return businessIdFieldKey;
  } else if (validateInvalidCharacters(value)) {
    return invalidCharactersFieldKey;
  } else {
    return null;
  }
};

/**
 * Handle mandatory fields with default error key
 * @param names
 * @returns {*}
 */
export const mandatories = (values, ...names) =>
  names
    .filter(n => !values[n] || values[n].trim().length === 0)
    .reduce((o, v) => ({ ...o, [v]: mandatoryFieldKey }), {});

export const mandatoryObject = (values, ...names) =>
  names
    .filter(n => !values[n] || Object.keys(values[n]).length === 0)
    .reduce((o, v) => ({ ...o, [v]: mandatoryFieldKey }), {});

export const mandatoryIntegers = (values, ...names) =>
  names
    .filter(
      n => values[n] === null || values[n] === undefined || isNaN(values[n])
    )
    .reduce((o, v) => ({ ...o, [v]: mandatoryFieldKey }), {});

export const mandatoryTrues = (values, ...names) =>
  names
    .filter(n => values[n] !== 'yes' && values[n] !== true)
    .reduce((o, v) => ({ ...o, [v]: mandatoryFieldKey }), {});

export const mandatoryBooleans = (values, ...names) =>
  names
    .filter(n => values[n] !== true && values[n] !== false)
    .reduce((o, v) => ({ ...o, [v]: mandatoryFieldKey }), {});

export const emails = (values, ...names) =>
  names
    .filter(n => !values[n] || !validator.isEmail(values[n]))
    .reduce((o, v) => ({ ...o, [v]: emailFieldKey }), {});

export const ips = (values, ...names) =>
  names
    .filter(n => !values[n] || !validator.isIP(values[n]))
    .reduce((o, v) => ({ ...o, [v]: ipFieldKey }), {});

export const fqdns = (values, ...names) =>
  names
    .filter(n => !values[n] || !validator.isFQDN(values[n]))
    .reduce((o, v) => ({ ...o, [v]: fqdnFieldKey }), {});

export const mandatoriesArray = (values, names) =>
  names
    .filter(n => !values[n])
    .reduce((o, v) => ({ ...o, [v]: mandatoryFieldKey }), {});

export const mandatoriesNonEmptyArray = (values, names) =>
  names
    .filter(n => !values[n])
    .reduce((o, v) => ({ ...o, [v]: mandatoryFieldKey }), {});

export const isoDates = (values, ...names) =>
  names
    .filter(n => !values[n] || !validator.isISO8601(values[n]))
    .reduce((o, v) => ({ ...o, [v]: isoDateFieldKey }), {});

export const isoDatesBetween = (values, ...rules) =>
  rules
    .filter(({ name, min, max }) => {
      const momentValue = moment(values[name]);
      return (
        !values[name] ||
        !validator.isISO8601(values[name]) ||
        !momentValue.isValid() ||
        (min && momentValue.isBefore(min)) ||
        (max && momentValue.isAfter(max))
      );
    })
    .reduce((o, v) => ({ ...o, [v.name]: v.messageKey }), {});

export const isoDatesNotPast = (values, ...names) =>
  isoDatesBetween(
    values,
    ...names.map(n => ({
      name: n,
      min: moment().startOf('day'),
      messageKey: isoDateNotPastFieldKey
    }))
  );

export const validPassword = (values, ...names) =>
  names
    .filter(n => !values[n] || !validatePassword(values[n]))
    .reduce((o, v) => ({ ...o, [v]: passwordFieldKey }), {});

export const validBusinessId = (values, ...names) =>
  names
    .filter(n => !values[n] || !validateBusinessId(values[n]))
    .reduce((o, v) => ({ ...o, [v]: businessIdFieldKey }), {});

export const isValidCompanyId = companyId => {
  // Some old company IDs have only 6 digits. They should be prefixed with 0.
  if (/^[0-9]{6}-[0-9]{1}/.test(companyId)) {
    companyId = '0' + companyId;
  }

  // Ensure that the company ID is entered in the correct format.
  if (!/^[0-9]{7}-[0-9]{1}/.test(companyId)) {
    return false;
  }

  const parts = companyId.split('-');
  const id = parts[0];
  const checksum = parseInt(parts[1], 10);

  let totalCount = 0;
  const multipliers = [7, 9, 10, 5, 8, 4, 2];
  multipliers.forEach((multiplier, key) => {
    totalCount += multiplier * parseInt(id[key], 10);
  });

  const remainder = totalCount % 11;

  // Remainder 1 is not valid.
  if (remainder === 1) {
    return false;
  }

  // Remainder 0 leads to checksum 0.
  if (remainder === 0) {
    return checksum === remainder;
  }

  // If remainder is not 0, the checksum should be 11 deducted by the remainder.
  const res = checksum === 11 - remainder;
  return res;
};

export const validateBusinessIdWithMessage = value => {
  if (validateInvalidCharacters(value)) {
    return invalidCharactersFieldKey;
  } else if (!isValidCompanyId(value)) {
    return invalidBusinessIdFieldKey;
  } else {
    return '';
  }
};
