import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, InlineAlert } from 'suomifi-ui-components';
import { Link } from 'react-router';
import Preview from '../../IntermediaryForms/components/Preview';
import styles from '../styles/ApplicationView.scss';
import { formStates } from '../../IntermediaryForms/constants';
import AsyncLoader from 'components/AsyncLoader';
import { loadResource } from 'utils/http';
import { printingUrl } from '../utils/applicationView';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { ApplicationDetails } from '../components/ApplicationDetails';
import { DecisionDetails } from '../components/DecisionDetails';

export const ApplicationView = (
  { application, locale, setOverlay, unsetOverlay },
  { intl }
) => {
  const i18n = id => intl.formatMessage({ id });

  const decisionId = application?.proposalDecisionGuid || null;
  const hasDecision = application?.decisionData != null;

  const serviceType = application?.serviceType;

  const loadFile = url => {
    setOverlay({
      component: (
        <AsyncLoader
          params={{
            url: url,
            type: 'application/pdf'
          }}
          init={loadResource}
          close={unsetOverlay}
        />
      )
    });
  };

  if (!application) {
    return (
      <div className={styles.container}>
        <InlineAlert status="error" labelText="Virhe">
          {i18n('intermediary.error.application-not-found')}
        </InlineAlert>
      </div>
    );
  }

  return (
    <>
      <div className={styles.breadCrumb}>
        <Breadcrumbs i18n={i18n} locale={locale} />
      </div>
      <div className={styles.container}>
        <div className={styles.flexContainer}>
          <div className={styles.flexItemLeft}>
            <Heading variant="h1">
              {i18n('intermediary.application.license')}
            </Heading>
            <Preview
              i18n={i18n}
              values={application}
              serviceType={serviceType}
            />
          </div>
          <div className={styles.flexItemRight}>
            {application.formState === formStates.DRAFT && (
              <Link
                href={`/fi/valitoimija/${application.serviceType}/${application.proposalGuid}`}
              >
                <Button>{i18n('intermediary.application.continue')}</Button>
              </Link>
            )}

            {application?.journalNumber ? (
              <Button
                onClick={() =>
                  loadFile(printingUrl + application?.proposalGuid)
                }
              >
                {i18n('forms.detailsView.loadForm')}
              </Button>
            ) : null}

            {decisionId && (
              <Button
                onClick={() => loadFile(printingUrl + decisionId)}
                className="ml-2"
              >
                {i18n('forms.detailsView.loadDecision')}
              </Button>
            )}
            <ApplicationDetails i18n={i18n} application={application} />
            {hasDecision && (
              <DecisionDetails i18n={i18n} application={application} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ApplicationView.contextTypes = {
  intl: PropTypes.object.isRequired
};

ApplicationView.propTypes = {
  application: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  setOverlay: PropTypes.func.isRequired,
  unsetOverlay: PropTypes.func.isRequired
};
