import React from 'react';
import { FormattedMessage } from 'react-intl';
import { requireAuthAndHasSema } from '../../modules/auth/utils';

export default function(store) {
  return {
    path: 'dashboard',
    name: <FormattedMessage id="dashboard_title" />,
    onEnter: requireAuthAndHasSema(store.getState().auth),
    async getComponents(nextState, next) {
      const initRoute = (
        await import(
          /* webpackChunkName: "dashboard" */
          './route'
        )
      ).default;
      initRoute(store, next);
    }
  };
}
