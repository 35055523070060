import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { browserHistory, Link } from 'react-router';
import { Field, formValueSelector } from 'redux-form';
import { pure } from 'recompose';
import Dropzone from 'react-dropzone';
import cx from 'classnames';
import TranslatableHelmet from '../../../components/TranslatableHelmet';
import {
  Button,
  ModalActions,
  ModalContent,
  ModalTitle,
  PageHeader,
  RadioButton
} from 'sema-ui-components';
import { Heading } from 'components/Heading';
import Loader from 'components/Loader';
import { Table } from 'components/Table';
import { toStore } from 'components/CalendarInput/CalendarInput';
import connectForm from 'utils/connectForm';
import {
  renderInfobox,
  renderCalendarInput,
  renderCheckbox,
  renderRadioGroup,
  renderTextField,
  renderXMLField
} from 'utils/fieldWrappers';
import {
  showEnvironmentCreateFail,
  showEnvironmentCreateSuccess
} from './Notifications';
import validate from './ValidateXMLForm';
import styles from './RegisterEnvironmentView.scss';
import { MSG_LEVEL_WARN } from 'constants/index';

import { renderDate } from '../utils';

class RegisterEnvironmentView extends React.Component {
  static propTypes = {
    change: PropTypes.func,
    currentProvider: PropTypes.object,
    identificationDecisions: PropTypes.array,
    formValues: PropTypes.object,
    handleSubmit: PropTypes.func,
    isFetching: PropTypes.bool,
    notifSend: PropTypes.func.isRequired,
    params: PropTypes.any,
    postProviderXML: PropTypes.func,
    routes: PropTypes.any,
    sahaGuid: PropTypes.string.isRequired,
    valid: PropTypes.bool
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  loc = 'identification_admin.environment.register';
  getLang = () => this.props.params.lang;
  i18n = local => this.context.intl.formatMessage({ id: local });

  replaceServiceRoute = routes =>
    routes.map(r => {
      if (r.name === 'SERVICE_NAME') {
        const newRoute = { ...r };
        newRoute.name = this.props.currentProvider[
          `name${this.getLang().toUpperCase()}`
        ];
        return newRoute;
      }
      return r;
    });

  doHandleSubmit = values => {
    const getLevelOfPermit = (decisions, decisionId) => {
      const decision = decisions.find(decision => decision.id === decisionId);
      return (
        decision &&
        decision.formData &&
        decision.formData.clientServiceInformation &&
        decision.formData.clientServiceInformation.extentOfPersonalInformation
      );
    };

    const levelOfPermit =
      values.targetEnv !== 'production' || values.noDecision
        ? null
        : getLevelOfPermit(
            this.props.identificationDecisions,
            values.decisionId
          );

    this.props
      .postProviderXML(
        this.props.currentProvider.id,
        this.props.sahaGuid,
        values.targetEnv,
        values.ownEnv,
        this.props.currentProvider.nameFI,
        values.xml,
        values.decisionId,
        levelOfPermit,
        values.noDecision ? values.journalNumber : null,
        values.noDecision ? values.approvedDate : null
      )
      .then(result => {
        // handle errors that are not indicated by http error code
        if (result && result.error) {
          const additionalInfoMessage =
            result.errorMessages &&
            result.errorMessages.find(emsg => !!emsg.errorCode);
          showEnvironmentCreateFail(
            this.props.notifSend,
            this.i18n,
            additionalInfoMessage && additionalInfoMessage.errorCode
          );
        } else {
          if (values.decisionId) {
            // When decision exists, semaBE returns warnings if the permit level is exceeded
            const parseLevelOfPermitWarnings = result => {
              const warnings = result.filter(
                message =>
                  message.level === MSG_LEVEL_WARN &&
                  message.message.includes('exceeds data permit level')
              );
              return warnings;
            };
            const levelOfPermitWarnings = parseLevelOfPermitWarnings(result);
            const showMessages = messages => {
              return messages.map((message, i) => {
                return (
                  <div key={i}>
                    {Object.keys(message).map((k, index) => (
                      <span key={index}>
                        {' '}
                        {k} : {message[k]}{' '}
                      </span>
                    ))}
                  </div>
                );
              });
            };

            if (levelOfPermitWarnings.length > 0) {
              // show all the messages from SemaBE when permit level is exceeded
              this.props.openModal({
                content: (
                  <Fragment>
                    <ModalTitle
                      title={this.i18n(`${this.loc}.permit_exceeded_title`)}
                    />
                    <ModalContent>
                      <code className={styles.warningMessages}>
                        {showMessages(result)}
                      </code>
                      <p>{this.i18n(`${this.loc}.permit_exceeded_guide`)}</p>
                    </ModalContent>
                    <ModalActions>
                      <Button
                        onClick={() => {
                          this.props.closeModal();
                        }}
                        data-test={`button-in-modal-continue`}
                      >
                        {this.i18n('general.form.continue')}
                      </Button>
                    </ModalActions>
                  </Fragment>
                )
              });
            }
          }
          this.environmentCreated();
        }
      });
  };

  flushNoDecisionInputs = () => {
    this.props.change('noDecision', false);
    this.props.change('approvedDate', null);
    this.props.change('journalNumber', null);
  };

  onDrop = (files, rejectedFiles) => {
    Object.keys(files).forEach(key => {
      const reader = new FileReader();

      reader.onload = (file => e => {
        this.props.change('xml', e.target.result);
      })(files[key]);

      reader.readAsText(files[key]);
    });
  };

  environmentCreated = () => {
    showEnvironmentCreateSuccess(this.props.notifSend, this.i18n);
    browserHistory.push(
      `/${this.context.intl.locale}/tunnistus/${encodeURIComponent(
        this.props.currentProvider.nameFI
      )}`
    );
  };

  render() {
    const {
      formValues,
      isFetching,
      routes,
      params,
      valid,
      handleSubmit
    } = this.props;

    const isProductionSelected =
      formValues.targetEnv === 'production' &&
      formValues.ownEnv === 'production';

    return (
      <div>
        <TranslatableHelmet
          locale={params.lang}
          subTitle={this.i18n(`${this.loc}.title`)}
        />
        <PageHeader
          title={
            <span>
              <FormattedMessage id={`${this.loc}.title`} />
            </span>
          }
          routes={this.replaceServiceRoute(routes)}
          params={params}
        />
        {!isFetching ? (
          <div className={styles.base}>
            <form onSubmit={handleSubmit(this.doHandleSubmit)}>
              <div className="row">
                <div className={cx('ml-xl', 'mt-l', 'col-22')}>
                  <Heading.h2>
                    <FormattedMessage id={`${this.loc}.env_title`} />
                  </Heading.h2>
                  <label
                    className={styles.inputLabel}
                    htmlFor="targetEnvInfobox"
                  >
                    <FormattedMessage id={`${this.loc}.target_env`} />
                    <span className={styles.mandatoryStar}>*</span>
                  </label>
                  <Field
                    className={styles.infoBox}
                    name="targetEnvInfobox"
                    component={renderInfobox}
                    data-test={`button-targetEnvInfobox`}
                  >
                    <label className={styles.inputLabel} htmlFor="targetEnv">
                      <FormattedMessage id={`${this.loc}.info_title`} />
                    </label>
                    <p>
                      <b>
                        <FormattedMessage
                          id={`${this.loc}.target_env_info1_1word`}
                        />
                      </b>
                      <FormattedMessage id={`${this.loc}.target_env_info1`} />
                    </p>
                    <p>
                      <b>
                        <FormattedMessage
                          id={`${this.loc}.target_env_info2_1word`}
                        />
                      </b>
                      <FormattedMessage id={`${this.loc}.target_env_info2`} />
                    </p>
                  </Field>
                  <Field
                    name="targetEnv"
                    id="targetEnv"
                    small
                    component={renderRadioGroup}
                    data-test={`radioButtonGroup-targetEnv`}
                  >
                    <RadioButton
                      label={this.i18n(`${this.loc}.target_customer_test`)}
                      value="testing"
                      onChange={() => {
                        this.props.change('ownEnv', 'testing');
                        this.props.change('decisionId', null);
                        this.props.change('noDecision', false);
                        this.flushNoDecisionInputs();
                      }}
                      data-test={`radioButton-targetEnv-testing`}
                    />
                    <RadioButton
                      label={this.i18n(`${this.loc}.target_production`)}
                      value="production"
                      onChange={() => this.props.change('ownEnv', 'production')}
                      data-test={`radioButton-targetEnv-production`}
                    />
                  </Field>
                  <label htmlFor="ownEnvInfobox" className={styles.inputLabel}>
                    <FormattedMessage id={`${this.loc}.own_env`} />
                    <span className={styles.mandatoryStar}>*</span>
                  </label>
                  <Field
                    className={styles.infoBox}
                    name="ownEnvInfobox"
                    component={renderInfobox}
                    data-test={`button-ownEnvInfobox`}
                  >
                    <Fragment>
                      <label className={styles.inputLabel} htmlFor="ownEnv">
                        <FormattedMessage id={`${this.loc}.info_title`} />
                      </label>
                      <p>
                        <FormattedMessage id={`${this.loc}.own_env_info`} />
                      </p>
                    </Fragment>
                  </Field>
                  <Field name="ownEnv" small component={renderRadioGroup}>
                    <RadioButton
                      label={this.i18n(`${this.loc}.target_own_dev`)}
                      value="development"
                      disabled={formValues.targetEnv === 'production'}
                      data-test={`radioButton-ownEnv-development`}
                    />
                    <RadioButton
                      label={this.i18n(`${this.loc}.target_own_test`)}
                      value="testing"
                      disabled={formValues.targetEnv === 'production'}
                      data-test={`radioButton-ownEnv-testing`}
                    />
                    <RadioButton
                      label={this.i18n(`${this.loc}.target_own_qa`)}
                      value="qualityAssurance"
                      disabled={formValues.targetEnv === 'production'}
                      data-test={`radioButton-ownEnv-qualityAssurance`}
                    />
                    <RadioButton
                      label={this.i18n(`${this.loc}.target_own_prod`)}
                      value="production"
                      disabled={formValues.targetEnv === 'testing'}
                      data-test={`radioButton-ownEnv-production`}
                    />
                  </Field>
                </div>
              </div>
              <div className={cx('row')}>
                <div className={cx('ml-xl', 'mr-xl', 'mt-l', 'col-22')}>
                  <hr className={styles.separator} />
                </div>
              </div>
              <div className="row">
                <div
                  className={cx('ml-xl', 'mt-l', 'col-22', {
                    [styles.disabledSection]:
                      !isProductionSelected || formValues.noDecision
                  })}
                >
                  <Heading.h2>
                    {this.i18n(`${this.loc}.select_application_title`)}
                  </Heading.h2>
                  <p>{this.i18n(`${this.loc}.select_application_text`)}</p>
                  <Table
                    className="decisionTable"
                    data={
                      this.props.identificationDecisions
                        ? this.props.identificationDecisions.map(
                            ({ id, caseId, createdDate, formData }) => ({
                              decisionId: (
                                <Field
                                  name="decisionId"
                                  data-test={`radiobutton-decisionId-${id}`}
                                  component={renderRadioGroup}
                                  onChange={() => this.flushNoDecisionInputs()}
                                >
                                  <RadioButton
                                    label={
                                      formData.clientServiceInformation
                                        .services[0].serviceName
                                    }
                                    key={id}
                                    disabled={
                                      !isProductionSelected ||
                                      this.props.formValues.noDecision
                                    }
                                    small
                                    value={id}
                                  />
                                </Field>
                              ),
                              decisionAt: createdDate
                                ? renderDate(new Date(createdDate), true)
                                : '-',
                              serviceName:
                                formData.clientServiceInformation.services[0]
                                  .serviceName,
                              levelOfPermit: (
                                <FormattedMessage
                                  id={`${this.loc}.level_of_permit.${formData.clientServiceInformation.extentOfPersonalInformation}`}
                                />
                              ),
                              applicationLink: (
                                <Link
                                  to={`/${this.getLang()}/omat-hakemukset/hakemus/${caseId}/tiedot`}
                                >
                                  <FormattedMessage
                                    id={`${this.loc}.link_to_application`}
                                  />
                                </Link>
                              )
                            })
                          )
                        : []
                    }
                    columns={[
                      {
                        header: this.i18n(`${this.loc}.service_name_column`),
                        property: 'decisionId'
                      },
                      {
                        header: this.i18n(`${this.loc}.level_of_permit_column`),
                        property: 'levelOfPermit'
                      },
                      {
                        header: this.i18n(`${this.loc}.decision_date_column`),
                        property: 'decisionAt'
                      },
                      {
                        header: this.i18n(`${this.loc}.additional_info_column`),
                        property: 'applicationLink'
                      }
                    ]}
                    rowKey="decisionId"
                  />
                </div>
              </div>
              <div className={cx('row')}>
                <div
                  className={cx('ml-xl', 'mt-l', 'col-1', {
                    [styles.disabledSection]:
                      !isProductionSelected || formValues.decisionId
                  })}
                >
                  <Field
                    className="noDecisionCheckbox"
                    name="noDecision"
                    data-test={`checkbox-noDecision`}
                    onChange={() => {
                      this.props.change('decisionId', null);
                    }}
                    component={renderCheckbox}
                    disabled={!isProductionSelected}
                  />
                </div>
                <div
                  className={cx('mt-l', 'col-21', {
                    [styles.disabledSection]:
                      !isProductionSelected || formValues.decisionId
                  })}
                >
                  <p>
                    <FormattedMessage id={`${this.loc}.no_decision_text`} />
                  </p>
                  <div
                    className={cx('row', 'mt-l', 'col-21', {
                      [styles.disabledSection]:
                        !isProductionSelected ||
                        !formValues.noDecision ||
                        formValues.decisionId
                    })}
                  >
                    <div className={cx('row', 'col-21')}>
                      <Heading.h2>
                        {this.i18n(
                          `${this.loc}.fill_approved_application_info`
                        )}
                      </Heading.h2>
                    </div>
                    <div
                      className={cx('col-10', styles.inputLabel)}
                      style={{ paddingLeft: '0px' }}
                    >
                      <label>{this.i18n(`general.journal`)}</label>
                      <Field
                        name="journalNumber"
                        data-test={`textField-journalNumber`}
                        disabled={
                          formValues.decisionId || !formValues.noDecision
                        }
                        component={renderTextField}
                        size="w240"
                      />
                    </div>
                    <div className={cx('col-12', styles.inputLabel)}>
                      <label>{this.i18n(`${this.loc}.approved_date`)}</label>
                      <Field
                        name="approvedDate"
                        data-test={`datePicker-approvedDate`}
                        size="w280"
                        maxDate={Date.now()}
                        component={renderCalendarInput}
                        locale={this.getLang()}
                        normalize={input => toStore(input)}
                        disabled={
                          formValues.decisionId || !formValues.noDecision
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={cx('row')}>
                <div className={cx('ml-xl', 'mt-l', 'col-22')}>
                  <hr className={styles.separator} />
                </div>
              </div>
              <div className="row">
                <div className={cx('ml-xl', 'mt-l', 'col-22')}>
                  <Heading.h2>
                    <FormattedMessage id={`${this.loc}.env_metadata_title`} />
                  </Heading.h2>
                  <span>
                    {this.i18n(`${this.loc}.env_metadata_guide_1`)}
                    <Link
                      to={`/${this.context.intl.locale}/tuki/artikkelit/590adae814bbb10001966f53`}
                    >
                      {this.i18n(`${this.loc}.env_metadata_link`)}
                    </Link>
                    {this.i18n(`${this.loc}.env_metadata_guide_2`)}
                  </span>
                  <Dropzone
                    className={styles.dropzone}
                    activeClassName={styles.dropzoneActive}
                    style={{ marginTop: 30 }}
                    onDrop={this.onDrop}
                    data-test={`dropZone-xml`}
                  >
                    <div className={styles.dropzoneInfo}>
                      <FormattedMessage id={`${this.loc}.xml_dropzone`} />
                    </div>
                  </Dropzone>
                  <Field
                    name="xml"
                    component={renderXMLField}
                    className={styles.xmlField}
                    placeholder={this.i18n(`${this.loc}.xml_placeholder`)}
                    data-test={`textField-xmlField`}
                  />
                </div>
              </div>
              <div className={cx('row', 'mt-l', 'mb-l')}>
                <div className={cx('ml-xl', 'col-12')}>
                  <Button
                    medium
                    type="submit"
                    disabled={!valid}
                    data-test={`button-submit`}
                  >
                    <FormattedMessage id={`${this.loc}.save`} />
                  </Button>
                </div>
                <div className="col-10">
                  <div className="text-right">
                    <Button
                      medium
                      secondary
                      onClick={() =>
                        browserHistory.push(
                          `/${
                            this.context.intl.locale
                          }/tunnistus/${encodeURIComponent(
                            this.props.currentProvider.nameFI
                          )}`
                        )
                      }
                      data-test={`button-cancel`}
                    >
                      <FormattedMessage id={`general.cancel`} />
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const selector = formValueSelector('registerEnvironmentForm');

export default pure(
  connectForm(RegisterEnvironmentView, {
    form: 'registerEnvironmentForm',
    validate,
    destroyOnUnmount: true,
    mapStateToProps: () => state => ({
      formValues: {
        targetEnv: selector(state, 'targetEnv'),
        ownEnv: selector(state, 'ownEnv'),
        noDecision: selector(state, 'noDecision'),
        decisionId: selector(state, 'decisionId'),
        journalNumber: selector(state, 'journalNumber'),
        xml: selector(state, 'xml')
      },
      initialValues: {
        targetEnv: 'testing',
        ownEnv: 'testing',
        decisionId: null,
        noDecision: false,
        journalNumber: null,
        xml: null
      }
    })
  })
);
