export const services = {
  EIDENTIFICATION: 'eIdentification',
  DATAEXCHANGELAYER: 'dataexchangelayer',
  DATAEXCHANGELAYERCERTIFICATE: 'dataexchangelayercertificate', // TODO: Not a separate service
  MESSAGES: 'messages',
  //  MESSAGESDEPLOYMENT: 'messagesdeployment', // TODO: Not a separate service
  EAUTHORIZATION: 'eauthorization',
  SERVICECATALOGUE: 'servicecatalogue',
  QUALITYTOOLS: 'qualitytools',
  JUDO: 'judo',
  DIGIRISK: 'digirisk',
  JULKRI: 'julkri'
};

export const subServices = {
  DEO: 'qualitytools'
};

export const SERVICE_NAMES = {
  0: null,
  1: 'servicecatalogue',
  4: 'eIdentification',
  8: 'sema',
  16: 'vrk_admin',
  32: 'dataexchangelayer',
  64: 'eauthorization',
  128: 'messages',
  256: 'vtj',
  512: 'vartti',
  1024: 'saha',
  2048: 'judo',
  4096: 'digirisk',
  32768: 'julkri'
};

export const SERVICE_NUMBERS = {
  no_service: 0,
  servicecatalogue: 1,
  eIdentification: 4,
  sema: 8,
  vrk_admin: 16,
  dataexchangelayer: 32,
  eauthorization: 64,
  messages: 128,
  vtj: 256,
  vartti: 512,
  saha: 1024,
  judo: 2048,
  digirisk: 4096,
  julkri: 32768
};

export const ROLE_NUMBERS = {
  no_role: 0,
  admin: 1,
  user: 2,
  viewer: 4,
  write: 8,
  external: 16,
  vrkadmin: 32,
  all_roles: 64
};

export const ROLE_NAMES = {
  // from sema-organisation-service to sema-ui
  0: 'no_role',
  1: 'admin',
  2: 'user',
  4: 'viewer',
  8: 'write',
  16: 'external',
  32: 'vrkadmin',
  64: 'all_roles'
};

export const USER_TYPES = {
  NORMAL: 1,
  API: 2,
  SYSTEM: 4,
  INVITED: 8,
  ONLY_CONTACT: 16,
  TEMPORARY: 32,
  REQUEST_INVITATION: 64,
  VRK_ADMIN: 128,
  REQUEST_INVITATION_ACCEPTED: 512
};

/*
NO_DEF(0), NORMAL(1), API(2), SYSTEM(4), INVITATION(8), ONLY_CONTACT(16),
 TEMPORARY(32), REQUEST_INVITATION(64), VRK_ADMIN(128);
 */

export const loginStyles = {
  WEAK: 'weak',
  STRONG: 'strong',
  TEMPORARY: 'temporary'
};

export const USER_STATUS_ACTIVE = 'assigned';
export const USER_STATUS_INVITED = 'pending';
export const USER_STATUS_REQUEST_INVITATION = 'request';
export const USER_STATUS_ALL = 'all';
export const SERVICE_ID_SEMA = 'sema';
export const USER_ROLE_USER = 'user';
export const USER_ROLE_ADMIN = 'admin';
export const USER_ROLE_EXT = 'ext';
export const SEMA_ADMIN = 'admin';
export const VRK_ADMIN = 'vrkadmin';
export const VRK_SERVICE_ADMIN = 'vrkadmin2';

export const SAHA_FROM_CUSTOMER_TO_CRM = 'TO_CRM';
export const SAHA_FROM_CRM_TO_CUSTOMER = 'FROM_CRM';

export const MAX_CHAR_COUNT_FOR_BRIEF = 150;
export const MAX_ROWS_FOR_BRIEF = 2;

export const MAX_METADATA_FILENAME_LENGTH = 255;

export const EIDENTIFICATION_PAHA_FORM_NAME = 'eIdentification';
export const MESSAGES_PAHA_FORM_NAME = 'messages';
export const SERVICECATALOGUE_PAHA_FORM_NAME = 'servicecatalogue';
export const DATA_EXCHANGE_LAYER_PAHA_FORM_NAME = 'dataexchangelayer';
export const MESSAGES_COMMISSIONING_PAHA_FORM_NAME = 'messagescommissioning';
export const EAUTHORIZATION_PAHA_FORM_NAME = 'eauthorization';
export const EAUTHORIZATION_COMMISSIONING_PAHA_FORM_NAME =
  'eauthorizationcommissioning';
export const DATA_EXCHANGE_LAYER_CERTIFICATE_PAHA_FORM_NAME =
  'dataexchangelayercertificate';
export const DATA_EXCHANGE_LAYER_SUBSYSTEM_PAHA_FORM_NAME =
  'dataexchangelayersubsystem';

export const formStates = {
  DRAFT: 'draft',
  SENT: 'sent',
  APPROVED: 'approved'
};

export const extentOfPersonalInformations = {
  // SAHA CRM data for Level of personal information
  BRIEF: 1,
  MEDIUM: 2,
  BROAD: 3
};

export const serviceConnectionMethods = {
  WEB: 0,
  EMAIL: 1,
  IPOST: 2,
  PRODUCT: 3
};

export const MSG_LEVEL_ERROR = 'ERROR';
export const MSG_LEVEL_WARN = 'WARN';
export const MSG_LEVEL_INFO = 'INFO';

export const organizationClass = {
  GOV: 1,
  COM: 2,
  MUN: 3,
  EDU: 4,
  ORG: 5
};

export const organizationClassByValue = {
  '1': 'gov',
  '2': 'com',
  '3': 'mun',
  '4': 'edu',
  '5': 'org'
};

export const dataExchangeLayerPurposeOfUse = {
  SELF: 1,
  PROVIDER: 2
};

export const dataExchangeLayerPurposeOfUseByValue = {
  '1': 'self',
  '2': 'provider'
};

export const dataExchangeLayerOwnershipType = {
  BUILDOWNSERVER: 1,
  USEEXISTINGSERVER: 2,
  USEEXISTINGEXTERNALSERVER: 3
};

export const dataExchangeLayerOwnershipTypeByValue = {
  '1': 'buildown',
  '2': 'useExisting',
  '3': 'useExistingExternal'
};

export const environmentType = {
  // DEV: 1,    NOT IN USE
  TEST: 2,
  PROD: 3
};

export const environmentTypeByValue = {
  '2': 'test',
  '3': 'prod'
};

export const newsTypes = {
  NEWS: 'uutinen',
  MALFUNCTION: 'haeirioetiedote',
  EVENT: 'tapahtuma',
  RELEASE: 'julkaisutiedote'
};

export const contactSubjects = {};

export const featureFlags = {
  INTERMEDIARY_PREFILL: 'intermediary.prefill',
  DIRECT_LINKS: 'direct.links'
};
