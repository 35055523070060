import { asyncConnect } from 'redux-connect';
import HomeView from '../components/HomeView';
import { push } from 'react-router-redux';
import { getImportantTopical } from '../../Topical/modules/module_CMS';

const mapStateToProps = state => {
  //filter out documents with priority 2

  return {
    importantArticles: state.topical.importantArticles,
    articles: state.topical.articles,
    isFetchingArticles: state.topical.isFetchingArticles,
    locale: state.translations.currentLang,
    features: state.features
  };
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch, getState } }) => {
        if (getState().auth.isAuthenticated) {
          dispatch(push('/dashboard'));
        } else {
          dispatch(getImportantTopical());
        }
        return Promise.resolve();
      }
    }
  ],
  mapStateToProps
)(HomeView);
