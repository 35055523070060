import * as yup from 'yup';

export const formTitle = 'intermediary.dataexchangelayer.title';

import {
  organizationClass,
  dataExchangeLayerOwnershipType,
  dataExchangeLayerPurposeOfUse
} from 'constants/index';

export const schema = yup.object({
  serviceData: yup.object({
    environmentType: yup
      .number()
      .nullable()
      .required()
      .default(null),
    dataExchangeLayerInformation: yup.object({
      purposeOfUse: yup
        .number()
        .nullable()
        .required()
        .oneOf(Object.values(dataExchangeLayerPurposeOfUse))
        .default(null)
        .label(formTitle),
      organizationClass: yup
        .number()
        .nullable()
        .required()
        .oneOf(Object.values(organizationClass))
        .default(null)
        .label(formTitle),
      subSystems: yup.array().of(yup.string()),
      serverInformation: yup.object({
        ownershipType: yup
          .number()
          .nullable()
          .required()
          .oneOf(Object.values(dataExchangeLayerOwnershipType))
          .default(null)
          .label(formTitle),
        ipv4: yup
          .array()
          .of(
            yup
              .string()
              .default('')
              .required('intermediary.dataexchangelayer.ipv4-required')
              .label(formTitle)
          )
          .default(['']),
        fqdn: yup
          .string()
          .max(300, 'intermediary.validation.max300')
          .required('intermediary.dataexchangelayer.fqdnRequired')
          .default('')
          .label(formTitle),
        ownerName: yup.string().when('ownershipType', {
          is: dataExchangeLayerOwnershipType.USEEXISTINGEXTERNALSERVER.toString(),
          then: yup
            .string()
            .max(300, 'intermediary.validation.max300')
            .required('intermediary.dataexchangelayer.ownerNameRequired')
            .label(formTitle),
          otherwise: yup.string().label(formTitle)
        }),
        isAuthorizationForCertificate: yup.boolean().default(false)
      })
    })
  })
});
