import * as yup from 'yup';

export const formTitle = 'intermediary.eAuthorizationServiceInfo.formTitle';

export const serviceSchema = yup.object({
  serviceId: yup
    .string()
    .nullable()
    .default(null),
  serviceName: yup
    .string()
    .min(3, 'intermediary.validation.min3')
    .max(300, 'intermediary.validation.max300')
    .required('general.form.validation.mandatory')
    .default('')
    .label(formTitle),
  serviceNameSv: yup
    .string()
    .max(300, 'intermediary.validation.max300')
    .default('')
    .label(formTitle),
  serviceNameEn: yup
    .string()
    .max(300, 'intermediary.validation.max300')
    .default('')
    .label(formTitle),
  serviceProviderName: yup
    .string()
    .min(3, 'intermediary.validation.min3')
    .max(300, 'intermediary.validation.max300')
    .required('intermediary.validation.nameIsRequired')
    .label(formTitle)
    .default(''),
  serviceProviderNameSv: yup
    .string()
    .max(300, 'intermediary.validation.max300')
    .label(formTitle)
    .default(''),
  serviceProviderNameEn: yup
    .string()
    .max(300, 'intermediary.validation.max300')
    .label(formTitle)
    .default(''),
  serviceUrl: yup
    .string()
    .max(300, 'intermediary.validation.max300')
    .required('general.form.validation.mandatory')
    .label(formTitle)
    .default(''),
  usageDescription: yup
    .string()
    .min(10, 'intermediary.validation.min10')
    .max(300, 'intermediary.validation.max300')
    .required('general.form.validation.mandatory')
    .label(formTitle)
    .default(''),
  serviceUsageAttachment: yup
    .array()
    .nullable()
    .default(null)
    .label(formTitle),
  serviceUsers: yup
    .string()
    .min(10, 'intermediary.validation.min10')
    .max(300, 'intermediary.validation.max300')
    .required('general.form.validation.mandatory')
    .label(formTitle)
    .default(''),
  mattersWhenActingBehalf: yup
    .string()
    .min(10, 'intermediary.validation.min10')
    .max(300, 'intermediary.validation.max300')
    .required('general.form.validation.mandatory')
    .label(formTitle)
    .default(''),
  actingOnBehalf: yup
    .boolean()
    .default(null)
    .nullable()
    .required('general.form.validation.mandatory')
    .label(formTitle),
  actingOnBehalfMinor: yup
    .boolean()
    .default(false)
    .nullable()
    .label(formTitle),
  actingOnBehalfAdult: yup
    .boolean()
    .default(false)
    .nullable()
    .label(formTitle),
  actingOnBehalfOrganization: yup
    .boolean()
    .default(null)
    .nullable()
    .required('general.form.validation.mandatory')
    .label(formTitle),
  registerBased: yup
    .boolean()
    .default(false)
    .nullable()
    .label(formTitle),
  authorizationBased: yup
    .boolean()
    .default(false)
    .nullable()
    .label(formTitle),
  actingOnBehalfForeignPersonOrCompany: yup
    .boolean()
    .default(null)
    .nullable()
    .required('general.form.validation.mandatory')
    .label(formTitle),
  onlineOnly: yup
    .boolean()
    .default(null)
    .nullable()
    .required('general.form.validation.mandatory')
    .label(formTitle),
  listedWithOwnUI: yup
    .string()
    .default(null)
    .nullable()
    .required('general.form.validation.mandatory')
    .label(formTitle),
  actingOnBehalfOther: yup
    .string()
    .min(10, 'intermediary.validation.min10')
    .max(300, 'intermediary.validation.max300')
    .required('general.form.validation.mandatory')
    .label(formTitle)
    .default(''),
  implementationDate: yup
    .string()
    .default(new Date().toISOString())
    .required('general.form.validation.mandatory')
    .label(formTitle),
  supportEstimation: yup
    .string()
    .max(300, 'intermediary.validation.max300')
    .required('general.form.validation.mandatory')
    .label(formTitle)
});

export const schema = yup.object({
  serviceData: yup.object({
    services: yup
      .array()
      .of(serviceSchema)
      .default([serviceSchema.cast({})])
      .label(formTitle)
  })
});
