import validator from 'validator';

const validate = values => {
  const errors = {};

  if (!values.loginName) {
    errors.loginName = 'general.form.validation.mandatory';
  }

  if (
    !values.sendPasswordTo ||
    !validator.isLength(values.sendPasswordTo, { min: 1, max: 256 })
  ) {
    errors.sendPasswordTo = 'general.form.validation.mandatory';
  } else if (!validator.isEmail(values.sendPasswordTo)) {
    errors.sendPasswordTo = 'general.form.validation.invalid_email';
  }

  return errors;
};

export default validate;
