const contentRoadmap = require('./../StaticArticles/components/ArticleRoadmap')
  .default;
const contentReleaseNews = require('./../StaticArticles/components/ArticleReleaseNews')
  .default;

export const ArticleRoadmap = () => ({
  path: 'tiekartta',
  getComponents(nextState, next) {
    next(null, { contentRoadmap });
  }
});

export const ArticleReleaseNews = () => ({
  path: 'julkaisu',
  getComponents(nextState, next) {
    next(null, { contentReleaseNews });
  }
});
