import * as yup from 'yup';
import { formStates, proposalStyles } from '../constants';
// "Top-level" form data
const formConstants = defaults =>
  yup.object({
    updater: yup.string().default(''),
    proposalGuid: yup.string().default(''),
    formName: yup.string().default(''),
    formState: yup.number().default(formStates.DRAFT),
    serviceType: yup.string().default(defaults.serviceType),
    intermediaryOrganizationGuid: yup
      .string()
      .default('')
      .nullable(),
    customerOrganizationGuid: yup
      .string()
      .default(defaults.customerOrganizationGuid)
      .nullable(),
    proposalStyle: yup
      .string()
      .default(defaults.proposalStyle)
      .nullable()
  });

// Create full validation schema with defaults from the partials
export const combineValidationSchema = (
  steps,
  { proposalStyle, activeOrganizationId, serviceType }
) => {
  const isIntermediary = proposalStyle === proposalStyles.INTERMEDIARY;
  const schemas = steps
    .map(
      step =>
        isIntermediary ? step.intermediarySchema || step.schema : step.schema // Use intermediary schema if isIntermediary and available
    )
    .filter(Boolean);
  const [first, ...rest] = schemas;

  const stepSchemas = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  );

  const constants = formConstants({
    serviceType,
    customerOrganizationGuid: isIntermediary ? '' : activeOrganizationId,
    proposalStyle
  });

  // Combine "top-level" data with form data
  return yup
    .object({
      formData: stepSchemas
    })
    .concat(constants);
};

// This function is ran every time the step or formdata is changed
export const validateSteps = (steps, values, setCompleted) => {
  if (!steps || !steps.length || !values) {
    return;
  }

  const lastStepId = steps[steps.length - 1].id;
  const lastStepIsValid = steps.every(step =>
    step.schema.isValidSync(values.formData, { abortEarly: true })
  );

  // Change last steps' validity based on the validity of the whole form
  setCompleted({
    id: lastStepId,
    isCompleted: lastStepIsValid
  });

  // Validate others fields
  steps.forEach(step => {
    if (step.id === lastStepId && !lastStepIsValid) {
      return; // Return early, because in theory the last step is always "valid"
    }

    setCompleted({
      id: step.id,
      isCompleted: step.schema.isValidSync(values.formData, {
        abortEarly: true
      })
    });
  });
};
