import React from 'react';
import { FormattedMessage } from 'react-intl';
import { redirect } from '../../utils';
import { injectReducer } from '../../store/reducers';
import { requireAuth, hasRole } from 'modules/auth/utils';

export const basePath = 'asiointivaltuudet';

export default (store, browserLang) => ({
  getComponents(nextState, next) {
    require.ensure(
      ['./containers/ROVAAdminContainer', './modules'],
      require => {
        const reducer = require('./modules').default;
        injectReducer(store, { key: 'rovaAdmin', reducer });

        const content = require('./containers/ROVAAdminContainer').default;
        next(null, { content });
      },
      'ROVAAdminContainer'
    );
  },
  getChildRoutes: (location, cb) => {
    require.ensure(
      [
        './containers/ServiceListContainer',
        './containers/ServiceInfoContainer',
        './containers/ServiceUsersContainer',
        './containers/ServiceSettingsContainer',
        './containers/UserListContainer',
        './containers/UserServicesEditContainer'
      ],
      require => {
        const auth = store.getState().auth;
        const authenticate = requireAuth(auth, hasRole('ROVA_ADMIN'));

        cb(null, [
          redirect(basePath, `/${browserLang}/${basePath}/asiointipalvelut`),
          {
            path: basePath,
            name: <FormattedMessage id="rova_admin.title" />,
            onEnter: authenticate,
            childRoutes: [
              {
                path: 'asiointipalvelut',
                name: <FormattedMessage id="rova_tab_services" />,
                onEnter: authenticate,
                getComponents(nextState, next) {
                  const content = require('./containers/ServiceListContainer')
                    .default;
                  next(null, content);
                },
                childRoutes: [
                  {
                    path: 'palvelun-tiedot',
                    name: <FormattedMessage id="rova_tab_service_info" />,
                    onEnter: authenticate,
                    getComponents(nextState, next) {
                      const content = require('./containers/ServiceInfoContainer')
                        .default;
                      next(null, content);
                    }
                  },
                  {
                    path: 'kayttajat',
                    name: <FormattedMessage id="rova_tab_service_users" />,
                    onEnter: authenticate,
                    getComponents(nextState, next) {
                      const content = require('./containers/ServiceUsersContainer')
                        .default;
                      next(null, content);
                    }
                  },
                  {
                    path: 'saannot',
                    name: <FormattedMessage id="rova_tab_service_rules" />,
                    onEnter: authenticate,
                    getComponents(nextState, next) {
                      const content = require('./containers/ServiceSettingsContainer')
                        .default;
                      next(null, content);
                    }
                  }
                ]
              },
              {
                path: 'kayttajien-hallinta',
                name: <FormattedMessage id="rova_tab_usermanagement" />,
                onEnter: authenticate,
                getComponents(nextState, next) {
                  const content = require('./containers/UserListContainer')
                    .default;
                  next(null, content);
                }
              },
              {
                path: 'muokkaa-kayttajan-hallintaoikeuksia',
                name: <FormattedMessage id="rova_tab_service_info" />,
                onEnter: authenticate,
                getComponents(nextState, next) {
                  const content = require('./containers/UserServicesEditContainer')
                    .default;
                  next(null, content);
                }
              }
            ]
          }
        ]);
      },
      'rovaAdmin'
    );
  }
});
