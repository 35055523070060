import { ApplicationView } from '../views/ApplicationView';
import { getApplication } from '../modules/module_OwnApplications';
import { sendErrorNotification } from 'utils/notifications';
import { asyncConnect } from 'redux-connect';
import { formStates } from '../../IntermediaryForms/constants';
import { setOverlay, unsetOverlay } from 'modules/ui';

const mapActionCreator = {
  setOverlay,
  unsetOverlay
};

const mapStateToProps = state => ({
  isFetching: state.ownApplicationsView.isFetching,
  application: state.ownApplicationsView.application,
  activeOrganizationId: state.auth.user && state.auth.user.activeOrganizationId,
  locale: state.translations.currentLang || 'fi'
});

export default asyncConnect(
  [
    {
      promise: ({
        params,
        store: { dispatch },
        helpers: { store },
        router
      }) => {
        if (!store.getState().auth.isAuthenticated) {
          return Promise.reject();
        }

        const formState = router?.location?.query?.formState || 'DRAFT';
        const proposalDecisionGuid =
          router?.location?.query.proposalDecisionGuid;

        Promise.all([
          dispatch(
            getApplication(
              params.id,
              formStates[formState],
              params.serviceType,
              proposalDecisionGuid
            ),
            store
          ).then(result => {
            if (result.error) {
              dispatch(
                sendErrorNotification(
                  'forms.notifications.own_forms_loading_error_title',
                  ''
                )
              );
            }
          })
        ]);
      }
    }
  ],
  mapStateToProps,
  mapActionCreator
)(ApplicationView);
