import { createSelector } from 'reselect';

export const getActiveOrgSelector = () =>
  createSelector(
    state => state.auth.user,
    user =>
      !!user &&
      user.organizations &&
      user.organizations.find(o => o.id === user.activeOrganizationId)
  );
