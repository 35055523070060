/* eslint-disable react/prop-types */
import React from 'react';
import { ApproveApplication } from './';
import { signOut } from '../../../../modules/auth';
import { loginStyles } from 'constants/index';

export const ApproveApplicationContainer = ({
  location,
  routeParams,
  params,
  router,
  store
}) => {
  const { query } = location;
  const { values } = query;
  const queryParams = new URLSearchParams(atob(values || ''));
  const formType = queryParams.get('formType');
  const loginStyle = store?.getState()?.auth?.user?.loginStyle;
  const isTempUser =
    loginStyle !== undefined && loginStyle === loginStyles.TEMPORARY;

  const submitCallback = () => {
    if (isTempUser) {
      store?.dispatch(signOut());
    } else {
      router.push(`/${params.lang}/own-applications/list`);
    }
  };

  if (!loginStyle) {
    // TODO: Show error message
    return null;
  }

  return (
    <ApproveApplication
      formId={routeParams?.id}
      serviceType={formType}
      submitCallback={submitCallback}
      isTempUser={isTempUser}
    />
  );
};
