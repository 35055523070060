import { validPassword } from 'utils/validator';

const validate = values => {
  const errors = {};

  if (!values.username) {
    errors.username = 'general.form.validation.username_is_mandatory';
  }

  if (!values.password) {
    errors.password = 'general.form.validation.password_is_mandatory';
  }

  return errors;
};

export const validateExpired = values => {
  const passwordFields = ['oldPassword', 'newPassword', 'rePassword'];
  let errors = {};
  if (!values.email || !values.email.trim().length) {
    errors.email = 'general.form.validation.email_is_mandatory';
  }

  passwordFields.map(field => {
    if (!values[field] || !values[field].trim().length) {
      errors[field] = 'general.form.validation.password_is_mandatory';
    }
  });

  errors = {
    ...validPassword(values, 'rePassword'),
    ...validPassword(values, 'newPassword'),
    ...errors
  };

  if (
    !Object.keys(errors).length &&
    values['newPassword'] !== values['rePassword']
  ) {
    errors['newPassword'] = 'general.form.validation.password_no_match';
    errors['rePassword'] = 'general.form.validation.password_no_match';
  }

  return errors;
};

export default validate;
