import { connect } from 'react-redux';
import { actions as notificationActions } from '../../Notifs';
import FeedbackForm from '../components/FeedbackForm';
import {
  requestAsyncPost,
  updateThanksValues,
  clearFormState
} from '../modules';

const { notifSend } = notificationActions;

const mapActionCreators = {
  requestAsyncPost,
  updateThanksValues,
  clearFormState,
  notifSend
};

const mapStateToProps = state => {
  const {
    isFetching,
    isDone,
    userName,
    userEmail,
    userMessage
  } = state.feedback;
  return { isFetching, isDone, userName, userEmail, userMessage };
};

export default connect(mapStateToProps, mapActionCreators)(FeedbackForm);
