import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  Block,
  Heading,
  RadioButton,
  RadioButtonGroup,
  Textarea
} from 'suomifi-ui-components';
import { getStatus, getStatusText } from '../../../utils';
export const DataHandlingOutsideEU = ({ i18n, formik, getRadioValue }) => {
  const vtjDataHandlingAbroadEu = getRadioValue('vtjDataHandlingAbroadEu');

  const environmentHandlingOutsideEU = getRadioValue(
    'environmentHandlingOutsideEU'
  );

  const dataTransferOutsideEU = getRadioValue('dataTransferOutsideEU');

  return (
    <>
      <Block>
        <Heading variant="h3">
          <FormattedMessage id="intermediary.dataProtection.data_management_outside_eu" />
        </Heading>
      </Block>
      <RadioButtonGroup
        labelText={i18n(
          'intermediary.dataProtection.desc_vtjDataHandlingAbroadEu'
        )}
        name="formData.statementOnInformationSecurity.vtjDataHandlingAbroadEu"
        value={vtjDataHandlingAbroadEu}
        onChange={value =>
          formik.setFieldValue(
            'formData.statementOnInformationSecurity.vtjDataHandlingAbroadEu',
            value === 'true'
          )
        }
      >
        <RadioButton value="true">
          <FormattedMessage id="intermediary.common.yes" />
        </RadioButton>
        <RadioButton value="false">
          <FormattedMessage id="intermediary.common.no" />
        </RadioButton>
      </RadioButtonGroup>
      {vtjDataHandlingAbroadEu === 'true' && (
        <Textarea
          name="formData.statementOnInformationSecurity.vtjDataHandlingAbroadEuInfo"
          labelText={i18n(
            'intermediary.dataProtection.yes_vtjDataHandlingAbroadEuInfo'
          )}
          fullWidth
          status={getStatus(
            formik,
            'formData.statementOnInformationSecurity.vtjDataHandlingAbroadEuInfo'
          )}
          statusText={getStatusText(
            i18n,
            formik,
            'formData.statementOnInformationSecurity.vtjDataHandlingAbroadEuInfo'
          )}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          {
            formik?.values?.formData?.statementOnInformationSecurity
              ?.vtjDataHandlingAbroadEuInfo
          }
        </Textarea>
      )}
      <RadioButtonGroup
        labelText={i18n(
          'intermediary.dataProtection.desc_environmentHandlingOutsideEU'
        )}
        name="formData.statementOnInformationSecurity.environmentHandlingOutsideEU"
        value={environmentHandlingOutsideEU}
        onChange={value =>
          formik.setFieldValue(
            'formData.statementOnInformationSecurity.environmentHandlingOutsideEU',
            value === 'true'
          )
        }
      >
        <RadioButton value="true">
          <FormattedMessage id="intermediary.common.yes" />
        </RadioButton>
        <RadioButton value="false">
          <FormattedMessage id="intermediary.common.no" />
        </RadioButton>
      </RadioButtonGroup>
      <RadioButtonGroup
        labelText={i18n(
          'intermediary.dataProtection.desc_dataTransferOutsideEU'
        )}
        name="formData.statementOnInformationSecurity.dataTransferOutsideEU"
        value={dataTransferOutsideEU}
        onChange={value =>
          formik.setFieldValue(
            'formData.statementOnInformationSecurity.dataTransferOutsideEU',
            value === 'true'
          )
        }
      >
        <RadioButton value="true">
          <FormattedMessage id="intermediary.common.yes" />
        </RadioButton>
        <RadioButton value="false">
          <FormattedMessage id="intermediary.common.no" />
        </RadioButton>
      </RadioButtonGroup>
    </>
  );
};

DataHandlingOutsideEU.propTypes = {
  i18n: PropTypes.func,
  formik: PropTypes.object,
  getRadioValue: PropTypes.func
};
