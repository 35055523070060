import { object, string } from 'yup';
import { userStr, backupUserSchema } from '../../utils/utility';
export const formTitle = 'intermediary.intermediaryContacts.formTitle';

export const userObject = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: ''
};

const getTranslationPath = path => `intermediary.validation.${path}`;

const userSchema = required =>
  object({
    firstName: userStr(required, 'firstNameIsRequired', formTitle),
    lastName: userStr(required, 'lastNameIsRequired', formTitle),
    email: userStr(required, 'emailIsRequired', formTitle, true),
    phoneNumber: userStr(false, 'phoneNumberIsRequired', formTitle)
  });

export const schema = (noTechnicalProcessEmail = false) =>
  object({
    intermediaryContacts: object({
      intermediaryAdministrativeContactInformation: object({
        administrativeContactPerson: userSchema(false), // not required for intermediary
        administrativeContactPersonBackup: backupUserSchema(formTitle)
      }).label(formTitle),
      intermediaryTechnicalContactInformation: object({
        technicalContactPerson: userSchema(true), // required for intermediary
        technicalContactPersonBackup: backupUserSchema(formTitle)
      }).label(formTitle),
      technicalProcessEmail: string()
        .when('intermediaryTechnicalContactInformation', (_, schema) => {
          return noTechnicalProcessEmail
            ? schema.notRequired()
            : schema.required('intermediary.validation.emailIsRequired');
        })
        .default('')
        .email(getTranslationPath('emailIsInvalid'))
        .label(formTitle)
    })
  });
