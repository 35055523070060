import React from 'react';
import { initialSteps } from './initialSteps';
import FormContainer from '../../components/Form/FormContainer';
import { formTypes } from '../../constants';

const MessagesForm = props => (
  <FormContainer
    initialSteps={initialSteps}
    serviceType={formTypes.MESSAGES}
    {...props}
  />
);

export default MessagesForm;
