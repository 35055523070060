import { createReducer, createAction, getLongLang } from '../../utils';
import { CALL_API } from '../../store/middleware/api';
import translationsFallback from 'translations/translations_source.json';
import translationsSV from 'translations/translations_sourcejson_sv.json';
import translationsEN from 'translations/translations_sourcejson_en.json';
import { browserHistory } from 'react-router';

// ------------------------------------
// Action constants
// ------------------------------------
export const SET_CURRENT_LANG = 'SET_CURRENT_LANG';
export const TRANSLATIONS_REQUEST = 'TRANSLATIONS_REQUEST';
export const TRANSLATIONS_SUCCESS = 'TRANSLATIONS_SUCCESS';
export const TRANSLATIONS_FAILURE = 'TRANSLATIONS_FAILURE';

export const setCurrentLang = createAction(SET_CURRENT_LANG);

/**
 * Flatten the nested JSON structure for react-intl to consume
 *
 * {"foo": {"bar": {"baz_qux": 1}}} ->
 * {"foo.bar.baz_qux": 1}
 *
 * @param nestedMessages
 * @param prefix
 * @param scopeSeparator between nests
 * @returns {*}
 */
export function flattenMessages(
  nestedMessages,
  prefix = '',
  scopeSeparator = '.'
) {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}${scopeSeparator}${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

/**
 * For dev uses, uses local file
 *
 * @param shortLangCode short version of lang code
 * @returns {function(*)} Redux thunk action
 */
export function requestTranslationLocal(shortLangCode) {
  const translations = {
    fi: translationsFallback,
    sv: translationsSV,
    en: translationsEN
  };

  return dispatch => {
    let newTrans = flattenMessages(translations[shortLangCode]);

    dispatch({
      type: TRANSLATIONS_SUCCESS,
      payload: newTrans
    });
    dispatch(setCurrentLang(shortLangCode));
  };
}

export function extractPathElement(curPath) {
  let regex = /\/(fi|sv|en)\/(.+)/;
  const matches = curPath.match(regex);
  return matches ? `/${matches[2]}` : '';
}

export function changeLang(shortLangCode) {
  const translations = {
    fi: translationsFallback,
    sv: translationsSV,
    en: translationsEN
  };
  return async dispatch => {
    let newTrans = flattenMessages(translations[shortLangCode]);
    await dispatch({
      type: TRANSLATIONS_SUCCESS,
      payload: newTrans
    });
    dispatch(setCurrentLang(shortLangCode));
    const localePath = `/${shortLangCode}`;
    browserHistory.push(localePath + extractPathElement(window.location.href));
  };
}

export function requestTranslation(shortLangCode) {
  return dispatch => {
    dispatch({
      [CALL_API]: {
        types: [
          TRANSLATIONS_REQUEST,
          TRANSLATIONS_SUCCESS,
          TRANSLATIONS_FAILURE
        ],
        endpoint: `localization/${getLongLang(shortLangCode)}`,
        camelize: false
      }
    }).then(() => {
      dispatch(setCurrentLang(shortLangCode));
    });
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_CURRENT_LANG]: (state, { payload }) => ({
    ...state,
    currentLang: payload
  }),
  [TRANSLATIONS_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [TRANSLATIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    translations: payload
  }),
  [TRANSLATIONS_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  })
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = {
  currentLang: null,
  translations: null,
  isFetching: false,
  error: null
};
export default createReducer(ACTION_HANDLERS, initialState);
