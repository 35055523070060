import { connect } from 'react-redux';
import SearchBox from '../components/SearchBox';
import { requestAsyncSearch } from 'routes/Search/modules';

const mapActionCreators = {
  requestAsyncSearch
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapActionCreators)(SearchBox);
