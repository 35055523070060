import React from 'react';
import PropTypes from 'prop-types';
import {
  RadioButton,
  RadioButtonGroup,
  Heading,
  Button,
  Link,
  IconArrowRight
} from 'suomifi-ui-components';
import { proposalStyles } from '../../constants';
import { FormattedMessage } from 'react-intl';
import styles from './RoleSelector.scss';

export const RoleSelector = ({ handleSetupForm }, { intl }) => {
  const [proposalStyle, setProposalStyle] = React.useState(proposalStyles.OWN);

  const handleConfirmRole = () => {
    handleSetupForm(proposalStyle);
  };

  const i18n = id => intl.formatMessage({ id });

  return (
    <>
      <Heading className={styles.roleSelector__heading} variant="h1">
        <FormattedMessage id="intermediary.roleSelector.heading" />
      </Heading>
      <div className={styles.roleSelector}>
        <RadioButtonGroup
          className={styles.roleSelector__radioButtonGroup}
          labelText={i18n('intermediary.roleSelector.currentRole')}
          groupHintText={i18n('intermediary.roleSelector.chooseRole')}
          name="role-group"
          value={proposalStyle}
          onChange={value => setProposalStyle(value)}
        >
          <RadioButton value={proposalStyles.OWN}>
            <FormattedMessage id="intermediary.roleSelector.ownOrganisation" />
          </RadioButton>
          <RadioButton value={proposalStyles.INTERMEDIARY}>
            <FormattedMessage id="intermediary.roleSelector.intermediary" />
          </RadioButton>
        </RadioButtonGroup>
        <hr />
        <div className={styles.roleSelector__buttonContainer}>
          <Link href="/">
            <Button variant="secondaryNoBorder">
              <FormattedMessage id="intermediary.roleSelector.cancel" />
            </Button>
          </Link>
          <Button
            className={styles.roleSelector__button}
            iconRight={<IconArrowRight />}
            onClick={handleConfirmRole}
          >
            <FormattedMessage id="intermediary.roleSelector.continueToApplication" />
          </Button>
        </div>
      </div>
    </>
  );
};

RoleSelector.propTypes = {
  handleSetupForm: PropTypes.func.isRequired
};

RoleSelector.contextTypes = {
  intl: PropTypes.object.isRequired
};
