import cx from 'classnames';
import AsyncLoader from 'components/AsyncLoader';
import DetailsView from 'components/DetailsView';
import { MAX_METADATA_FILENAME_LENGTH } from 'constants/index';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { Button, PageHeader, SimpleLabel } from 'sema-ui-components';
import slugify from 'slugify';
import { loadResource } from 'utils/http';
import styles from './EnvironmentDetailsStyles.scss';
import { insertAggregatedStatus } from './EnvironmentsView';

export const EnvironmentDetailsView = ({
  routes,
  params,
  currentEnvironment,
  currentEnvironmentContacts,
  setOverlay,
  unsetOverlay
}) => {
  const downloadMetadata = (entityID, isProdEnv) => {
    const url = `/api/identification-admin/xmldownload?entityID=${encodeURI(
      entityID
    )}&isProdEnv=${isProdEnv}`;

    const revision = (currentEnvironment && currentEnvironment.revision) || 'X';
    const filenameEnd = '_rev_' + revision + '_metadata.xml';

    const filenameStart = slugify(entityID, {
      strict: true
    }).slice(0, MAX_METADATA_FILENAME_LENGTH - filenameEnd.length);
    const filename = filenameStart + filenameEnd;

    setOverlay({
      component: (
        <AsyncLoader
          params={{
            url: url,
            type: 'application/xml'
          }}
          filename={filename}
          download
          init={loadResource}
          close={unsetOverlay}
        />
      )
    });
  };

  const displayOrganizationURL = serviceProviderEntity => (
    <div className={styles.displayOrganizationURL}>
      <h3>
        {' '}
        <FormattedMessage id="identification_admin.environment.details_organization_url" />
      </h3>
      <div className={styles.subtitle}>
        <FormattedMessage id="identification_admin.environment.details_organization_url_SAML" />
      </div>
      <div className="row">
        <div className="col-lg-8">
          <h5>
            <FormattedMessage id="identification_admin.environment.details_fi" />
          </h5>
        </div>
        <div className="col-lg-16">
          {serviceProviderEntity.organizationUrlFI}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <h5>
            <FormattedMessage id="identification_admin.environment.details_sv" />
          </h5>
        </div>
        <div className="col-lg-16">
          {serviceProviderEntity.organizationUrlSV}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <h5>
            <FormattedMessage id="identification_admin.environment.details_en" />
          </h5>
        </div>
        <div className="col-lg-16">
          {serviceProviderEntity.organizationUrlEN}
        </div>
      </div>
    </div>
  );

  const displayName = serviceProviderEntity => (
    <div className={styles.displayName}>
      <h3>
        <FormattedMessage id="identification_admin.environment.details_display_name" />
      </h3>
      <div className={styles.subtitle}>
        <FormattedMessage id="identification_admin.environment.details_display_name_SAML" />
      </div>
      <div className="row">
        <div className="col-lg-8">
          <h5>
            <FormattedMessage id="identification_admin.environment.details_fi" />
          </h5>
        </div>
        <div className="col-lg-16">{serviceProviderEntity.displayNameFI}</div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <h5>
            <FormattedMessage id="identification_admin.environment.details_sv" />
          </h5>
        </div>
        <div className="col-lg-16">{serviceProviderEntity.displayNameSV}</div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <h5>
            <FormattedMessage id="identification_admin.environment.details_en" />
          </h5>
        </div>
        <div className="col-lg-16">{serviceProviderEntity.displayNameEN}</div>
      </div>
    </div>
  );

  const contactPerson = (nbr, role, name, emailAddress, phoneNumber) => {
    let roleLabel;
    switch (role) {
      case 'technical':
        roleLabel = (
          <FormattedMessage
            id={`identification_admin.environment.details_role_technical`}
          />
        );
        break;
      case 'support':
        roleLabel = (
          <FormattedMessage
            id={`identification_admin.environment.details_role_support`}
          />
        );
        break;
      case 'administrative':
        roleLabel = (
          <FormattedMessage
            id={`identification_admin.environment.details_role_administrative`}
          />
        );
        break;
      default:
        roleLabel = (
          <FormattedMessage
            id={`identification_admin.environment.details_role_other`}
          />
        );
    }
    return (
      <div>
        <h3>
          <FormattedMessage id="identification_admin.environment.details_contact_persons" />
        </h3>
        <div className={styles.contactPerson} key={nbr}>
          <h4>
            <FormattedMessage id="identification_admin.environment.details_contact_person" />{' '}
            {nbr}
          </h4>
          <div className="row">
            <div className="col-lg-8">
              <h5>
                <FormattedMessage id="identification_admin.environment.details_role" />
              </h5>
            </div>
            <div className="col-lg-16">{roleLabel}</div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <h5>
                <FormattedMessage id="identification_admin.environment.details_person_name" />
              </h5>
            </div>
            <div className="col-lg-16">{name}</div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <h5>
                <FormattedMessage id="identification_admin.environment.details_email_address" />
              </h5>
            </div>
            <div className="col-lg-16">{emailAddress}</div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <h5>
                <FormattedMessage id="identification_admin.environment.details_phone_number" />
              </h5>
            </div>
            <div className="col-lg-16">{phoneNumber}</div>
          </div>
        </div>
      </div>
    );
  };

  let titleLocalized;
  switch (params.lang) {
    case 'fi':
      titleLocalized = currentEnvironment.displayNameFI;
      break;
    case 'sv':
      titleLocalized = currentEnvironment.displayNameSV;
      break;
    case 'en':
      titleLocalized = currentEnvironment.displayNameEN;
      break;
    default:
      titleLocalized = currentEnvironment.displayNameFI;
  }

  const statusIconLabel = {
    waitingForPublishing: {
      color: 'kanerva',
      abbreviation: (
        <FormattedMessage id="identification_admin.environment.wfp" />
      )
    },
    waitingForApproval: {
      color: 'taivas',
      abbreviation: (
        <FormattedMessage id="identification_admin.environment.wfa" />
      )
    },
    published: {
      color: 'havumetsa',
      abbreviation: <FormattedMessage id="identification_admin.environment.p" />
    },
    archived: {
      color: 'lakka',
      abbreviation: <FormattedMessage id="identification_admin.environment.a" />
    },
    released: {
      color: 'kanerva',
      abbreviation: (
        <FormattedMessage id="identification_admin.environment.wfp" />
      )
    }
  };

  const statusLabel = statusKey => {
    statusKey = statusKey === 'released' ? 'waitingForPublishing' : statusKey;
    return (
      <span>
        <SimpleLabel
          className={styles.label}
          labelString={statusIconLabel[statusKey].abbreviation}
          color={statusIconLabel[statusKey].color}
        />
        <FormattedMessage
          id={`identification_admin.environment.details_status_${statusKey}`}
        />
      </span>
    );
  };

  const targetEnvironmentLabel =
    currentEnvironment.targetEnvironment.name === 'production' ? (
      <FormattedMessage
        id={`identification_admin.environment.details_target_production`}
      />
    ) : (
      <FormattedMessage
        id={`identification_admin.environment.details_target_customer_test`}
      />
    );

  return (
    <div>
      <PageHeader title={titleLocalized} routes={routes} params={params} />
      <DetailsView
        className={styles.detailsView}
        locale={params.lang}
        setOverlay={setOverlay}
        unsetOverlay={unsetOverlay}
        aside={
          <div className="row">
            <div className={cx(styles.leftColumn, 'col-lg-24')}>
              <h4>
                <FormattedMessage id="identification_admin.environment.column_state" />
              </h4>
              {statusLabel(
                insertAggregatedStatus(currentEnvironment).aggregatedStatus
              )}
              <h5>
                <FormattedMessage id="identification_admin.environment.details_target_environment" />
              </h5>
              {targetEnvironmentLabel}
              <h5>
                <FormattedMessage id="identification_admin.environment.details_entityid" />
              </h5>
              {currentEnvironment.entityID}
              <h5>
                <FormattedMessage id="identification_admin.environment.details_created" />
              </h5>
              <time>
                <FormattedDate value={currentEnvironment.dateCreated} />{' '}
                <FormattedTime value={currentEnvironment.dateCreated} />
              </time>
              <h5>
                <FormattedMessage id="identification_admin.environment.details_modified" />
              </h5>
              <time>
                <FormattedDate value={currentEnvironment.lastUpdated} />{' '}
                <FormattedTime value={currentEnvironment.lastUpdated} />
              </time>
              {currentEnvironment.aggregatedStatus === 'published' && (
                <div className={styles.buttonDiv}>
                  <Button
                    small
                    onClick={async () => {
                      downloadMetadata(
                        currentEnvironment.entityID,
                        currentEnvironment.targetEnvironment &&
                          currentEnvironment.targetEnvironment.name ===
                            'production'
                      );
                    }}
                  >
                    <FormattedMessage id="identification_admin.environment.download_metadata" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        }
        content={
          <div className={cx(styles.content, 'col-lg-24')}>
            {!isEmpty(currentEnvironmentContacts) &&
              currentEnvironmentContacts.map((contact, index) =>
                contactPerson(
                  (index + 1).toString(),
                  contact.contactType.name,
                  `${contact.givenName} ${contact.surName}`,
                  contact.emailAddress,
                  contact.telephoneNumber
                )
              )}
            {displayName(currentEnvironment)}
            {displayOrganizationURL(currentEnvironment)}
          </div>
        }
      />
    </div>
  );
};

EnvironmentDetailsView.propTypes = {
  // Redux props
  // Routing
  currentEnvironment: PropTypes.object,
  currentEnvironmentContacts: PropTypes.array,
  //  isFetching: PropTypes.boolean,
  params: PropTypes.object,
  routes: PropTypes.array,
  // actionCreators
  setOverlay: PropTypes.func,
  unsetOverlay: PropTypes.func
};

export default EnvironmentDetailsView;
