// When adding additional errors, translations should be updated accordingly
const errorCodes = [
  'GENERIC_ERROR',
  'UNAUTHORIZED',
  'INTERNAL_SEMA_ERROR',
  'EMAIL_IN_USAGE',
  'PASSWORD_IS_INVALID',
  'PARAMETER_MISSING',
  'ORGANISATION_NOT_FOUND',
  'ORGANISATION_ROLE_NOT_FOUND',
  'ORGANISATION_ROLE_EXIST_ALREADY',
  'USER_NOT_FOUND',
  'USER_EXIST_ALREADY',
  'USER_NOT_RIGHTS_THIS_ORGANISATION',
  'USER_NOT_RIGHTS_THIS_SERVICE',
  'USER_NOT_RIGHTS_THIS_PROPOSAL',
  'USER_NOT_RIGHTS_THIS_ACTION',
  'INVITATION_NOT_FOUND',
  'PASSWORD_MUST_CHANGE_NOW',
  'PASSWORD_MUST_CHANGE_SOON',
  'PROPOSAL_NOT_RIGHTS',
  'PROPOSAL_NOT_FOUND',
  'PROPOSAL_DATA_IS_NOT_VALID',
  'PROPOSAL_NOT_SAVE_SAHA',
  'PROPOSAL_NOT_SAVE_PAHA',
  'USER_NOT_INTERMEDIARY'
];

export function getErrorMessage(errorCode) {
  const _errorCode = errorCodes.find(error => error === errorCode);
  return _errorCode ? `errors.${_errorCode}` : 'errors.GENERIC_ERROR';
}
