import {
  createAction as ca,
  createReducer,
  getSemaJSON as get
} from '../../../utils';
import { CALL_API } from '../../../store/middleware/api';

const initialState = {
  isFetchingDocuments: false,
  documentsByCategory: {},
  documents: [],
  selectedReport: null,
  selectedCategory: 'ptv',
  error: null
};

const PX = 'STATISTICS:';
const FETCH_DOCUMENTS = `${PX}_FETCH_DOCUMENTS`;
const FETCH_DOCUMENTS_SUCCESS = `${PX}_FETCH_DOCUMENTS_SUCCESS`;
const FETCH_DOCUMENTS_ERROR = `${PX}_FETCH_DOCUMENTS_ERROR`;
const UPDATE_VALUE = `${PX}_UPDATE_VALUE`;

const ACTION_HANDLERS = {
  [FETCH_DOCUMENTS]: state => ({
    ...state,
    isFetchingDocuments: true
  }),
  [FETCH_DOCUMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingDocuments: false,
    documents: payload
  }),
  [FETCH_DOCUMENTS_ERROR]: (state, { payload }) => ({
    ...state,
    isFetchingDocuments: false,
    error: payload
  }),
  [UPDATE_VALUE]: (state, { payload }) => {
    return {
      ...state,
      ...payload
    };
  }
};

export const updateValue = (name, value) => {
  const valueMap = {};
  valueMap[name] = value;
  return dispatch => dispatch(ca(UPDATE_VALUE)(valueMap));
};

export const getDocuments = () => ({
  [CALL_API]: {
    method: 'get',
    endpoint: `/api/documents/`,
    types: [FETCH_DOCUMENTS, FETCH_DOCUMENTS_SUCCESS, FETCH_DOCUMENTS_ERROR]
  }
});

export default createReducer(ACTION_HANDLERS, initialState);
