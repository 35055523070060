import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { pure } from 'recompose';
import { Button } from 'sema-ui-components';

const handleClick = redirect => {
  browserHistory.push({
    pathname: '/login',
    state: { nextPathname: redirect }
  });
};

export const SignIn = ({ locale, redirectAfterLogin, ...rest }) => {
  const size = rest.size ? rest.size : 'small';
  rest[size] = true;

  return (
    <Button onClick={() => handleClick(redirectAfterLogin)} {...rest}>
      <FormattedMessage id="general.sign_in" />
    </Button>
  );
};

SignIn.propTypes = {
  locale: PropTypes.string.isRequired,
  size: PropTypes.string,
  redirectAfterLogin: PropTypes.string
};

export default pure(SignIn);
