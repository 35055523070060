import React from 'react';
import { Field, useFormikContext } from 'formik';
import { TextInput, Tooltip } from 'suomifi-ui-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getStatus, getStatusText, splitName } from '../../utils/utility';

export const StyledTextInput = styled(TextInput)`
  margin-top: ${props => (props.noMargin ? '0' : '1rem')};
  margin-bottom: ${props => (props.noMargin ? '0' : '1rem')};
  width: ${props => (props.width ? props.width : '100%')};
`;

export const IntermediaryTextField = (
  { name, label, optional, tooltip, placeholder, noStyles, noMargin, ...rest },
  { intl }
) => {
  const formik = useFormikContext();
  const i18n = id => intl.formatMessage({ id });

  const status = getStatus(formik, ...splitName(name));
  const statusText = getStatusText(i18n, formik, ...splitName(name));

  return (
    <Field
      {...rest}
      as={noStyles ? TextInput : StyledTextInput}
      name={name}
      labelText={label && i18n(label)}
      optionalText={
        optional ? i18n('intermediary.validation.optional') : undefined
      }
      onChange={value => formik.setFieldValue(name, value)}
      onBlur={formik.handleBlur}
      placeholder={placeholder ? i18n(placeholder) : undefined}
      status={status}
      statusText={statusText}
      tooltipComponent={
        tooltip ? (
          <Tooltip
            ariaToggleButtonLabelText={i18n('general.show_more')}
            ariaCloseButtonLabelText={i18n('general.go_back')}
          >
            {tooltip}
          </Tooltip>
        ) : (
          undefined
        )
      }
    />
  );
};

IntermediaryTextField.contextTypes = {
  intl: PropTypes.object.isRequired
};

IntermediaryTextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  i18n: PropTypes.func,
  formik: PropTypes.object,
  fieldType: PropTypes.string,
  optional: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tooltip: PropTypes.node,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  noStyles: PropTypes.bool,
  noMargin: PropTypes.bool
};
