import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Heading,
  Expander,
  ExpanderTitleButton,
  ExpanderContent
} from 'suomifi-ui-components';
import List from '../components/List';
import { formatDate } from '../../../utils';

export const ServiceCataloguePreview = ({
  formData,
  i18n,
  defaultOpen = true
}) => (
  <>
    <Expander defaultOpen={defaultOpen}>
      <ExpanderTitleButton>
        <FormattedMessage id="intermediary.inapi.title" />
      </ExpanderTitleButton>
      <ExpanderContent>
        <Heading variant="h3" className="mb-2 mt-4">
          <FormattedMessage id="forms.servicecatalogue.page_serviceCatalogueInfo.inApi.title" />
        </Heading>
        {formData?.serviceData?.inApiUsed ? (
          <>
            <List>
              {[
                'inApiUsed',
                'inApiContentUpdatedRegularly',
                'inApiMaintainedProperly',
                'twoAdminsInvited'
              ].map(prop => (
                <List.Item
                  key={prop}
                  title={i18n(
                    `forms.servicecatalogue.page_serviceCatalogueInfo.inApi.${prop}`
                  )}
                  value={formData.serviceData[prop]}
                />
              ))}
            </List>
            <Heading variant="h3" className="mb-2 mt-4">
              <FormattedMessage id="forms.servicecatalogue.page_serviceCatalogueInfo.inApi.integratedSystemTitle" />
            </Heading>
            <List>
              <List.Item
                title={i18n('intermediary.inapi.systemNameInputLabel')}
                value={formData.serviceData.systemName}
              />
              <List.Item
                title={i18n(
                  'forms.servicecatalogue.page_serviceCatalogueInfo.inApi.usageDate'
                )}
                value={formatDate(formData.serviceData.usageDate)}
              />
            </List>
            <Heading variant="h3" className="mb-2 mt-4">
              <FormattedMessage id="forms.servicecatalogue.page_serviceCatalogueInfo.inApi.subcontractorTitle" />
            </Heading>
            <List>
              <List.Item
                title={i18n(
                  `forms.servicecatalogue.page_serviceCatalogueInfo.inApi.organizationUsesSubcontractor`
                )}
                value={formData.serviceData.organizationUsesSubcontractor}
              />
              {formData.serviceData.organizationUsesSubcontractor ? (
                <>
                  <List.Item
                    title={i18n('intermediary.inapi.apiUserNameLabel')}
                    value={formData.serviceData.apiUserName}
                  />
                  {[
                    'organizationName',
                    'businessId',
                    'firstName',
                    'lastName',
                    'email'
                  ].map(prop => (
                    <List.Item
                      key={prop}
                      title={i18n(
                        `forms.servicecatalogue.page_serviceCatalogueInfo.inApi.${prop}`
                      )}
                      value={
                        formData.serviceData.apiAccessContactInformation
                          .apiAccessContactPerson[prop]
                      }
                    />
                  ))}
                </>
              ) : null}
            </List>
          </>
        ) : (
          <List>
            <List.Item
              title={i18n(
                'forms.servicecatalogue.page_serviceCatalogueInfo.inApi.inApiUsed'
              )}
              value={i18n('intermediary.common.no')}
            />
          </List>
        )}
      </ExpanderContent>
    </Expander>
  </>
);

ServiceCataloguePreview.propTypes = {
  formData: PropTypes.object,
  i18n: PropTypes.func,
  defaultOpen: PropTypes.bool
};
