import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import { Block, Heading, Paragraph, Checkbox } from 'suomifi-ui-components';
import * as SST from './service-specific';
import { formTitle } from './schema';

export const serviceSpecificTerms = {
  servicecatalogue: SST.ServiceCatalogue,
  messages: SST.Messages,
  eauthorization: SST.EAuthorization,
  eidentification: SST.EIdentification,
  dataexchangelayer: SST.DataExchangeLayer
};

export const Form = ({ formik, role }, { intl }) => {
  const { locale } = intl;
  const i18n = id => intl.formatMessage({ id });

  const currentService = formik?.initialValues?.serviceType?.toLowerCase();
  const ServiceTerms = serviceSpecificTerms[currentService] || null;

  const toggleCheckbox = ({ checkboxState }) => {
    // For some reason, formik 'touched' property is not populated, so we have to do it manually
    formik.setTouched({
      ...formik.touched,
      formData: {
        ...formik.touched.formData,
        acceptanceOfTerms: true
      }
    });

    formik.setFieldValue(
      'formData.acceptanceOfTerms.acceptanceOfVRKPermit',
      checkboxState
    );
  };

  return (
    <div className={styles.formContainer}>
      <Block>
        <Heading variant="h2" className="borderless mb-2">
          {i18n(formTitle)}
        </Heading>
        <Paragraph>
          {role === 'intermediary'
            ? i18n('intermediary.terms.intermediary')
            : i18n('intermediary.terms.paragraph')}
        </Paragraph>
      </Block>
      <Block>
        <Heading variant="h3" className="mb-2">
          {i18n('intermediary.terms.license')}
        </Heading>
        <ServiceTerms i18n={i18n} locale={locale}>
          <Checkbox
            name="formData.acceptanceOfTerms.acceptanceOfVRKPermit"
            checked={
              formik?.values?.formData?.acceptanceOfTerms?.acceptanceOfVRKPermit
            }
            onClick={toggleCheckbox}
            statusText={
              formik?.touched?.formData?.acceptanceOfTerms &&
              !formik.values.formData.acceptanceOfTerms.acceptanceOfVRKPermit
                ? i18n('intermediary.validation.termsNotAccepted')
                : undefined
            }
            status={
              formik?.touched?.formData?.acceptanceOfTerms &&
              !formik.values.formData.acceptanceOfTerms.acceptanceOfVRKPermit
                ? 'error'
                : 'default'
            }
          >
            {i18n('intermediary.terms.acceptTitle')}
          </Checkbox>
        </ServiceTerms>
      </Block>
    </div>
  );
};
Form.propTypes = {
  formik: PropTypes.object,
  role: PropTypes.string
};

Form.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default Form;
