import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import cx from 'classnames';
import styles from './UserBox.scss';

export const UserBox = ({
  activeOrg,
  user,
  locale,
  inverse,
  right,
  children
}) => (
  <div className={cx(styles.base)}>
    <div
      className={cx(styles.userData, {
        [styles.right]: right,
        [styles.inverse]: inverse
      })}
    >
      <div className={styles.username}>
        {user.firstName} {user.lastName}
      </div>
      <div className={styles.userTitle}>
        <div className={styles.userOrg}>
          {activeOrg &&
            (activeOrg[
              `name${locale[0].toUpperCase() + locale.substring(1)}` // nameSv
            ] ||
              activeOrg.organizationName)}
        </div>
        {children}
      </div>
    </div>
  </div>
);

UserBox.propTypes = {
  activeOrg: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  inverse: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  right: PropTypes.object,
  user: PropTypes.object.isRequired
};

export default pure(UserBox);
