import { services } from 'constants/index';
import { CALL_API } from 'store/middleware/api';
import { createAction as ca, createReducer } from 'utils/index';

const initialState = {
  displayedDetailsTab: 0,
  isFetching: false,
  forms: [],
  loadedForm: null,
  identificationDecisions: [],
  isLoadingForm: false,
  isLoadingFormInfoRequest: false,
  isLoadingOrganizationInfoRequest: false,
  isLoadingInfoRequests: false,
  isLoadingNotAnsweredInfoRequestCount: false,
  isUpdatingInfoRequest: false,
  isCreatingInfoRequest: false,
  isLoadingEditedForms: false,
  formInfoRequests: [],
  organizationInfoRequests: [],
  editedInfoRequest: null,
  notAnsweredInfoRequestCount: null,
  editedForms: {},
  formsTableConfig: {
    filters: {
      service: 'all',
      state: 'all',
      isInfoBoxVisible: false
    },
    query: {},
    sortingColumns: {
      6: {
        direction: 'desc',
        position: 0
      }
    }
  },
  infoRequestOpen: {},
  answerFormOpen: {},
  error: null
};

const PX = 'OWN_FORMS_VIEW:';
const FETCH_FORMS = `${PX}_FETCH_FORMS`;
const FETCH_FORMS_SUCCESS = `${PX}_FETCH_FORMS_SUCCESS`;
const FETCH_FORMS_ERROR = `${PX}_FETCH_FORMS_ERROR`;

const LOAD_FORM_REQUEST = `${PX}_LOAD_FORM_REQUEST`;
const LOAD_FORM_SUCCESS = `${PX}_LOAD_FORM_SUCCESS`;
const LOAD_FORM_ERROR = `${PX}_LOAD_FORM_ERROR`;

const LOAD_DECISION_REQUEST = `${PX}_LOAD_DECISION_REQUEST`;
const LOAD_DECISION_SUCCESS = `${PX}_LOAD_DECISION_SUCCESS`;
const LOAD_DECISION_ERROR = `${PX}_LOAD_DECISION_ERROR`;

const LOAD_INFO_REQUEST = `${PX}_LOAD_INFO_REQUEST`;
const LOAD_INFO_REQUEST_SUCCESS = `${PX}_LOAD_INFO_REQUEST_SUCCESS`;
const LOAD_INFO_REQUEST_ERROR = `${PX}_LOAD_INFO_REQUEST_ERROR`;

const LOAD_INFO_REQUEST_FOR_FORM = `${PX}_LOAD_INFO_REQUEST_FOR_FORM`;
const LOAD_INFO_REQUEST_FOR_FORM_SUCCESS = `${PX}_LOAD_INFO_REQUEST_FOR_FORM_SUCCESS`;
const LOAD_INFO_REQUEST_FOR_FORM_ERROR = `${PX}_LOAD_INFO_REQUEST_FOR_FORM_ERROR`;

const LOAD_INFO_REQUEST_FOR_ORGANIZATION = `${PX}_LOAD_INFO_REQUEST_FOR_ORGANIZATION`;
const LOAD_INFO_REQUEST_FOR_ORGANIZATION_SUCCESS = `${PX}_LOAD_INFO_REQUEST_FOR_ORGANIZATION_SUCCESS`;
const LOAD_INFO_REQUEST_FOR_ORGANIZATION_ERROR = `${PX}_LOAD_INFO_REQUEST_FOR_ORGANIZATION_ERROR`;

const LOAD_NOT_ANSWERED_INFO_REQUEST_COUNT = `${PX}_LOAD_NOT_ANSWERED_INFO_REQUEST_COUNT`;
const LOAD_NOT_ANSWERED_INFO_REQUEST_COUNT_SUCCESS = `${PX}_LOAD_NOT_ANSWERED_INFO_REQUEST_COUNT_SUCCESS`;
const LOAD_NOT_ANSWERED_INFO_REQUEST_COUNT_ERROR = `${PX}_LOAD_NOT_ANSWERED_INFO_REQUEST_COUNT_ERROR`;

const LOAD_EDITED_FORMS = `${PX}_LOAD_EDITED_FORMS`;
const LOAD_EDITED_FORMS_SUCCESS = `${PX}_LOAD_EDITED_FORMS_SUCCESS`;
const LOAD_EDITED_FORMS_ERROR = `${PX}_LOAD_EDITED_FORMS_ERROR`;

const TOGGLE_INFO_REQUEST_OPEN = `${PX}_TOGGLE_INFO_REQUEST_OPEN`;
const TOGGLE_ANSWER_FORM = `${PX}_TOGGLE_ANSWER_FORM`;

const UPDATE_INFO_REQUEST = `${PX}_UPDATE_INFO_REQUEST`;
const UPDATE_INFO_REQUEST_SUCCESS = `${PX}_UPDATE_INFO_REQUEST_SUCCESS`;
const UPDATE_INFO_REQUEST_ERROR = `${PX}_UPDATE_INFO_REQUEST_ERROR`;

const CREATE_INFO_REQUEST_ANSWER = `${PX}_CREATE_INFO_REQUEST_ANSWER`;
const CREATE_INFO_REQUEST_ANSWER_SUCCESS = `${PX}_CREATE_INFO_REQUEST_ANSWER_SUCCESS`;
const CREATE_INFO_REQUEST_ANSWER_ERROR = `${PX}_CREATE_INFO_REQUEST_ANSWER_ERROR`;

const UPDATE_FORMS_TABLE = `${PX}_UPDATE_FORMS_TABLE`;
const UPDATE_TAB = `${PX}_UPDATE_TAB`;
const UPDATE_INFO_REQUEST_ANSWER = `${PX}_UPDATE_INFO_REQUEST_ANSWER`;

const UPDATE_FORM = `${PX}_UPDATE_FORM`;
const UPDATE_REQUEST = `${PX}_UPDATE_REQUEST`;

const ADD_SUB_SYSTEM_REQUEST = `${PX}_ADD_SUB_SYSTEM_REQUEST`;
const ADD_SUB_SYSTEM_SUCCESS = `${PX}_ADD_SUB_SYSTEM_SUCCESS`;
const ADD_SUB_SYSTEM_ERROR = `${PX}_ADD_SUB_SYSTEM_ERROR`;

const ACTION_HANDLERS = {
  [FETCH_FORMS]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [FETCH_FORMS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    forms: createFormList(payload)
  }),
  [FETCH_FORMS_ERROR]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    forms: [],
    error: payload
  }),
  [LOAD_EDITED_FORMS]: (state, { payload }) => ({
    ...state,
    isLoadingEditedForms: true
  }),
  [LOAD_EDITED_FORMS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoadingEditedForms: false,
    editedForms: { ...state.editedForms, [payload.service]: payload }
  }),
  [LOAD_EDITED_FORMS_ERROR]: (state, { payload }) => ({
    ...state,
    isLoadingEditedForms: false,
    error: payload.error
  }),
  [LOAD_INFO_REQUEST]: (state, { payload }) => ({
    ...state,
    isLoadingInfoRequests: true
  }),
  [LOAD_INFO_REQUEST_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoadingInfoRequests: false,
    formInfoRequests: payload
  }),
  [LOAD_INFO_REQUEST_ERROR]: (state, { payload }) => ({
    ...state,
    isLoadingInfoRequests: false,
    formInfoRequests: {},
    error: payload.error
  }),
  [LOAD_INFO_REQUEST_FOR_FORM]: (state, { payload }) => ({
    ...state,
    isLoadingFormInfoRequest: true
  }),
  [LOAD_INFO_REQUEST_FOR_FORM_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoadingFormInfoRequest: false,
    editedInfoRequest: payload
  }),
  [LOAD_INFO_REQUEST_FOR_FORM_ERROR]: (state, { payload }) => ({
    ...state,
    isLoadingFormInfoRequest: false,
    editedInfoRequest: {},
    error: payload.error
  }),
  [LOAD_INFO_REQUEST_FOR_ORGANIZATION]: (state, { payload }) => ({
    ...state,
    isLoadingOrganizationInfoRequest: true
  }),
  [LOAD_INFO_REQUEST_FOR_ORGANIZATION_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoadingOrganizationInfoRequest: false,
    organizationInfoRequests: payload
  }),
  [LOAD_INFO_REQUEST_FOR_ORGANIZATION_ERROR]: (state, { payload }) => ({
    ...state,
    isLoadingOrganizationInfoRequest: false,
    organizationInfoRequests: [],
    error: payload.error
  }),
  [LOAD_NOT_ANSWERED_INFO_REQUEST_COUNT]: (state, { payload }) => ({
    ...state,
    isLoadingNotAnsweredInfoRequestCount: true
  }),
  [LOAD_NOT_ANSWERED_INFO_REQUEST_COUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoadingNotAnsweredInfoRequestCount: false,
    notAnsweredInfoRequestCount: payload
  }),
  [LOAD_NOT_ANSWERED_INFO_REQUEST_COUNT_ERROR]: (state, { payload }) => ({
    ...state,
    isLoadingNotAnsweredInfoRequestCount: false,
    notAnsweredInfoRequestCount: null,
    error: payload.error
  }),
  [UPDATE_INFO_REQUEST]: (state, { payload }) => ({
    ...state,
    isUpdatingInfoRequest: true
  }),
  [UPDATE_INFO_REQUEST_SUCCESS]: (state, { payload }) => ({
    ...state,
    isUpdatingInfoRequest: false
  }),
  [UPDATE_INFO_REQUEST_ERROR]: (state, { payload }) => ({
    ...state,
    isUpdatingInfoRequest: false,
    error: payload.error
  }),
  [CREATE_INFO_REQUEST_ANSWER]: (state, { payload }) => ({
    ...state,
    isCreatingInfoRequest: true
  }),
  [CREATE_INFO_REQUEST_ANSWER_SUCCESS]: (state, { payload }) => ({
    ...state,
    isCreatingInfoRequest: false,
    infoRequestAnswers: { ...state.infoRequestAnswers, ...payload } // dismiss the sent answer text
  }),
  [CREATE_INFO_REQUEST_ANSWER_ERROR]: (state, { payload }) => ({
    ...state,
    isCreatingInfoRequest: false,
    error: payload.error
  }),
  [UPDATE_FORMS_TABLE]: (state, { payload }) => ({
    ...state,
    formsTableConfig: payload
  }),
  [UPDATE_TAB]: (state, { payload }) => ({
    ...state,
    displayedDetailsTab: payload,
    infoRequestOpen: {}
  }),
  [LOAD_FORM_REQUEST]: (state, { payload }) => ({
    ...state,
    isLoadingForm: true
  }),
  [LOAD_FORM_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoadingForm: false,
    loadedForm: payload.body.data[0]
  }),
  [LOAD_FORM_ERROR]: (state, { payload }) => ({
    ...state,
    isLoadingForm: false,
    loadedForm: null,
    error: payload
  }),
  [LOAD_DECISION_REQUEST]: (state, { payload }) => ({
    ...state,
    isLoadingForm: true
  }),
  [LOAD_DECISION_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoadingForm: false,
    identificationDecisions: state.identificationDecisions.concat(
      payload.body.data[0]
    )
  }),
  [LOAD_DECISION_ERROR]: (state, { payload }) => ({
    ...state,
    isLoadingForm: false,
    error: payload
  }),
  [TOGGLE_INFO_REQUEST_OPEN]: (state, { payload }) => ({
    ...state,
    infoRequestOpen: {
      ...state.infoRequestOpen,
      ...{ [payload]: !state.infoRequestOpen[payload] }
    }
  }),
  [TOGGLE_ANSWER_FORM]: (state, { payload }) => ({
    ...state,
    answerFormOpen: {
      ...state.answerFormOpen,
      ...{ [payload]: !state.answerFormOpen[payload] }
    }
  }),
  [UPDATE_INFO_REQUEST_ANSWER]: (state, { payload }) => ({
    ...state,
    infoRequestAnswers: { ...state.infoRequestAnswers, ...payload }
  }),
  [UPDATE_FORM]: (state, { payload }) => ({
    ...state,
    loadedForm: payload
  }),
  [UPDATE_REQUEST]: (state, { payload }) => ({
    ...state,
    editedInfoRequest: payload
  }),
  [ADD_SUB_SYSTEM_REQUEST]: state => ({
    ...state,
    isSending: true
  }),
  [ADD_SUB_SYSTEM_SUCCESS]: state => ({
    ...state,
    isSending: false
  }),
  [ADD_SUB_SYSTEM_ERROR]: state => ({
    ...state,
    isSending: false
  })
};

const convertForm = form => {
  const updatedForm = {
    ...form,
    decisionId: form.decisionId ? form.decisionId : '',
    formId: form.id,
    name: form.type,
    orgId: form.accountId,
    status: form.state
  };
  delete updatedForm['id'];
  delete updatedForm['type'];
  delete updatedForm['accountId'];
  delete updatedForm['state'];

  return updatedForm;
};

export const createFormList = formsArray =>
  (formsArray instanceof Array ? formsArray : [])
    .map(convertForm)
    .reduce((acc, curr) => {
      const children = curr.children;
      delete curr.children;
      let forms = acc.concat(curr);
      if (
        [services.MESSAGES, services.EAUTHORIZATION].includes(
          curr.mainServiceType
        )
      ) {
        forms = forms.concat(createFormList(children));
      }
      return forms;
    }, []);

export const getForms = orgId => ({
  [CALL_API]: {
    method: 'get',
    endpoint: `/api/saha/formlist/${orgId}`,
    types: [FETCH_FORMS, FETCH_FORMS_SUCCESS, FETCH_FORMS_ERROR]
  }
});

export const loadForm = (orgId, service, type, uuid, decisionId) => ({
  [CALL_API]: {
    method: 'get',
    endpoint: `/api/forms/organization/${orgId}/service/${service}/type/${type}/uuid/${uuid}/decisionId/${decisionId}`,
    types: [LOAD_FORM_REQUEST, LOAD_FORM_SUCCESS, LOAD_FORM_ERROR]
  }
});

export const fetchDecisions = (
  orgId,
  decisionIds,
  loadedDecisions,
  service,
  dispatch
) => {
  decisionIds.forEach(async decisionId => {
    if (!loadedDecisions.some(decision => decision.id === decisionId)) {
      await dispatch(fetchDecision(orgId, decisionId, service)); // load decision only if it's not loaded already
    }
  });
};
const fetchDecision = (orgId, decisionId, service) => ({
  [CALL_API]: {
    method: 'get',
    endpoint: `/api/forms/organization/${orgId}/decision/${decisionId}/service/${service}/`,
    types: [LOAD_DECISION_REQUEST, LOAD_DECISION_SUCCESS, LOAD_DECISION_ERROR]
  }
});

export const openInfoRequest = infoReqId => dispatch => {
  dispatch(ca(TOGGLE_INFO_REQUEST_OPEN)(infoReqId));
};

export const toggleAnswerForm = infoReqId => dispatch => {
  dispatch(ca(TOGGLE_ANSWER_FORM)(infoReqId));
};

export const createInfoRequestAnswer = (
  infoRequestId,
  orgId,
  formId,
  infoRequestAnswer
) => ({
  [CALL_API]: {
    method: 'post',
    endpoint: `/api/form_info_request/${orgId}/${formId}?parentId=${
      infoRequestId === 'uusi' ? '' : infoRequestId
    }`,
    types: [
      CREATE_INFO_REQUEST_ANSWER,
      (action, state) => ({
        type: CREATE_INFO_REQUEST_ANSWER_SUCCESS,
        payload: (action, state) => ({ [infoRequestId]: '' })
      }),
      CREATE_INFO_REQUEST_ANSWER_ERROR
    ],
    data: infoRequestAnswer
  }
});

export const updateMyFormsTable = value => dispatch =>
  dispatch(ca(UPDATE_FORMS_TABLE)(value));

export const updateDetailsTab = value => dispatch =>
  dispatch(ca(UPDATE_TAB)(value));

export const sendSubSystemReguest = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const subSystemName =
      state &&
      state.form &&
      state.form.subSystem &&
      state.form.subSystem.values &&
      state.form.subSystem.values.subSystemName;
    const loadedForm =
      state && state.ownFormsView && state.ownFormsView.loadedForm;
    return dispatch({
      [CALL_API]: {
        method: 'post',
        endpoint: `saha/form/dataexchangelayer/addSubSystem/${loadedForm.accountId}`,
        types: [
          ADD_SUB_SYSTEM_REQUEST,
          ADD_SUB_SYSTEM_SUCCESS,
          ADD_SUB_SYSTEM_ERROR
        ],
        data: {
          form: loadedForm,
          subSystemName: subSystemName
        }
      }
    });
  };
};

export default createReducer(ACTION_HANDLERS, initialState);
