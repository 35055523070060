import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { transFun } from 'utils';
import { Link } from 'react-router';
import { pure } from 'recompose';
import styles from './styles.scss';
import { Heading } from 'suomifi-ui-components';

const PrettyLink = pure(({ title, path }) => (
  <span className={styles.prettyLink}>
    <Link to={path}>
      {title}
      <div className={styles.caretCircle} />
    </Link>
  </span>
));

export const HomeHeader = ({ locale }, { intl }) => {
  const t = transFun(intl);

  const hero = (
    <div className="row">
      <div className="col-md-4" />
      <div className="col-md-16">
        <Heading variant="h1" className={styles.heroPhrase} color="whiteBase">
          <FormattedMessage id="home.hero_phrase" />
        </Heading>
        <Heading
          variant="h4"
          as="h2"
          className={cx(styles.heroDescription, 'v-offset-2', 'mt-4')}
          color="whiteBase"
        >
          <FormattedMessage id="home.hero_description" />
        </Heading>
      </div>
      <div className="col-md-4" />
    </div>
  );

  const links = (
    <div className="row">
      <div className="col-24 center">
        <PrettyLink
          title={t('home.info_link')}
          path={`${locale}/sivut/palveluhallinnasta`}
        />
        <PrettyLink
          title={t('home.reports_link')}
          path={`${locale}/raportit`}
        />
        <PrettyLink
          title={t('home.support_link')}
          path={`${locale}/tuki/etusivu`}
        />
        <PrettyLink
          title={t('topical.title')}
          path={`${locale}/ajankohtaista`}
        />
      </div>
    </div>
  );

  return (
    <div>
      <div className={styles.headerExtra}>
        <div className="container">{hero}</div>
      </div>
      <div className={cx(styles.linkStrip)}>
        <div className="container">{links}</div>
      </div>
    </div>
  );
};

HomeHeader.propTypes = {
  locale: PropTypes.string.isRequired
};

HomeHeader.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default pure(HomeHeader);
