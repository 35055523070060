import React from 'react';
import { FormattedMessage } from 'react-intl';
import { requireAuth } from '../../modules/auth/utils';
import { injectReducers } from '../../store/reducers';

export const basePath = 'oma-organisaatio';

export default store => ({
  getComponents(nextState, next) {
    require.ensure(
      [],
      require => {
        const userInvitationReducer = require('../UserRegistration/modules/module_Invitations')
          .default;

        injectReducers(store, [
          { key: 'userInvitation', reducer: userInvitationReducer }
        ]);

        const content = require('./containers/OwnOrganisationContainer')
          .default;
        next(null, { content });
      },
      'organization'
    );
  },
  getChildRoutes(location, next) {
    next(null, {
      path: basePath,
      onEnter: requireAuth(store.getState().auth),
      indexRoute: {
        onEnter: (nextState, replace) =>
          replace(`/${location.params.lang}/${basePath}/organisaatio/tiedot`)
      },
      childRoutes: [
        {
          path: 'organisaatio',
          getComponents(nextState, next) {
            require.ensure(
              [],
              require => {
                const content = require('./components/OrganisationTabs')
                  .default;
                next(null, content);
              },
              'organization'
            );
          },
          indexRoute: {
            onEnter: (nextState, replace) =>
              replace(
                `/${location.params.lang}/${basePath}/organisaatio/tiedot`
              )
          },
          childRoutes: [
            {
              path: 'tiedot',
              name: <FormattedMessage id="organization.tab_info_title" />,
              // onEnter: requireAuth(store.getState().auth),
              getComponents(nextState, next) {
                require.ensure(
                  [],
                  require => {
                    const content = require('./containers/OrganisationViewContainer')
                      .default;
                    next(null, content);
                  },
                  'organization'
                );
              }
            }
            /*{
              path: 'paakayttajat',
              name: <FormattedMessage id="organization.tab_admins_title" />,
              // onEnter: requireAuth(store.getState().auth),
              getComponents(nextState, next) {
                const content = require('./containers/OrganisationAdminUsersContainer')
                  .default;
                next(null, content);
              }
            }*/
          ]
        }
      ]
    });
  }
});
