import React from 'react';
import Proptypes from 'prop-types';
import styles from '../Preview.scss';
import List from '../components/List';

export const InformationManagementRequirements = ({
  i18n,
  isEIdentificationForm,
  formData
}) => {
  return (
    <ol>
      <li>
        <span className={styles.listSubHeader}>
          {i18n(`intermediary.dataProtection.personel_reguirements`)}
        </span>
        <ul>
          <li>
            {i18n(`intermediary.dataProtection.desc_processingOfPersonalData`)}
          </li>
          <li>
            {i18n(`intermediary.dataProtection.desc_securityResponsiblePerson`)}
          </li>
          <li>{i18n(`intermediary.dataProtection.desc_securityCommitment`)}</li>
          <li>
            {i18n(
              `intermediary.dataProtection.desc_procedureForCheckVrkConditions`
            )}
          </li>
        </ul>
      </li>
      <li>
        <span className={styles.listSubHeader}>
          {i18n(`intermediary.dataProtection.usage_reguirements`)}
        </span>
        <ul>
          <li>
            {i18n(`intermediary.dataProtection.desc_regularCheckForUserRights`)}
          </li>
          <li>{i18n(`intermediary.dataProtection.desc_loginSecurity`)}</li>
        </ul>
      </li>
      <li>
        <span className={styles.listSubHeader}>
          {i18n(`intermediary.dataProtection.facility_and_tools_reguirements`)}
        </span>

        <ul>
          <li>{i18n(`intermediary.dataProtection.desc_securedWorkstation`)}</li>
          <li>
            {i18n(
              `intermediary.dataProtection.desc_vtjInformationDeletionBeforeMaintenance`
            )}
          </li>
          <li>{i18n(`intermediary.dataProtection.desc_securedFacilities`)}</li>
          <li>
            {i18n(
              `intermediary.dataProtection.desc_useOfFirewallAndProtection`
            )}
          </li>
          <li>
            {i18n(
              `intermediary.dataProtection.desc_dataConnectionIsSecuredForVtjData`
            )}
          </li>
          <li>
            {i18n(
              `intermediary.dataProtection.desc_productionAndTestEnvironments`
            )}
          </li>
        </ul>
      </li>
      {!isEIdentificationForm && (
        <li>
          <span className={styles.listSubHeader}>
            {i18n(`intermediary.dataProtection.logging_reguirements`)}
          </span>

          <ul>
            <li>{i18n(`intermediary.dataProtection.desc_auditLogs`)}</li>
            <li>
              {i18n(
                `intermediary.dataProtection.desc_logsArchivedAndDestroyed`
              )}
            </li>
            <li>
              {i18n(
                `intermediary.dataProtection.desc_logsAreProtectedFromChanges`
              )}
            </li>
            <li>
              {i18n(`intermediary.dataProtection.desc_logsAreRestricted`)}
            </li>
            <li>
              {i18n(`intermediary.dataProtection.desc_regularChecksForLogs`)}
            </li>
          </ul>
        </li>
      )}
      <div className={styles.listAnswer}>
        <List>
          <List.Item
            title={i18n(
              'intermediary.dataProtection.desc_appropriateResourceHandling'
            )}
            value={
              formData?.statementOnInformationSecurity
                ?.appropriateResourceHandling // 6
                ? i18n('intermediary.common.yes')
                : i18n('intermediary.common.no')
            }
          />
        </List>
      </div>
    </ol>
  );
};

InformationManagementRequirements.propTypes = {
  i18n: Proptypes.func,
  formData: Proptypes.object,
  isEIdentificationForm: Proptypes.bool
};
