import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { transFun } from 'utils';
import { browserHistory } from 'react-router';
import { renderTextField } from 'utils/fieldWrappers';
import { Button } from 'sema-ui-components';

export class NewPasswordView extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    notifSend: PropTypes.func,
    submitting: PropTypes.bool,
    resetPassword: PropTypes.func
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  constructor() {
    super();
    this.state = { submitting: false };
  }

  formatMessage = id => this.context.intl.formatMessage({ id });

  getErrorMsg = (description, title) => {
    return {
      title,
      description,
      type: 'fail',
      dismissAfter: 5000
    };
  };

  doHandleSubmit = values => {
    const { intl } = this.context;
    const locale = intl.locale;
    const i18n = transFun(intl);
    const { code, userGuidId } = this.props.location.query;
    const params = { ...values, code, userGuidId };

    if (values.password !== values.passwordConfirmation) {
      this.props.notifSend(
        this.getErrorMsg(
          i18n('general.form.validation.password_no_match'),
          i18n('password_change_requested')
        )
      );
      return;
    }

    this.setState({ submitting: true });
    this.props.resetPassword(params).then(data => {
      if (data && data.error) {
        this.setState({ submitting: false });
        this.props.notifSend(
          this.getErrorMsg(
            i18n('sign_in.change_password.error'),
            i18n('password_change_requested')
          )
        );
      } else {
        this.setState({ submitting: false });
        browserHistory.push(
          `/${locale}/kirjautuminen/salasana-vaihdettu?title=password_changed&message=password_changed_desc`
        );
      }
    });
  };

  render() {
    const { handleSubmit, routes, params } = this.props;
    const { intl } = this.context;
    const i18n = transFun(intl);

    return (
      <section>
        <h1>
          <FormattedMessage id="userprofile.new_password" />
        </h1>
        <p>
          <FormattedMessage id="userprofile.new_password_description" />
        </p>
        <p>
          <FormattedMessage id="user_registration.register_user_and_org.new_password_desc" />
        </p>
        <div>
          <form onSubmit={handleSubmit(this.doHandleSubmit)}>
            <div className="form-group">
              <Field
                name="newPassword"
                type="password"
                component={renderTextField}
                size="w280"
                disabled={this.state.submitting}
                placeholder={i18n('userprofile.new_password')}
              />
            </div>
            <div className="form-group">
              <Field
                name="rePassword"
                type="password"
                component={renderTextField}
                size="w280"
                disabled={this.state.submitting}
                placeholder={i18n('userprofile.retype_new_password')}
              />
            </div>
            <div className="form-group">
              <div>
                <div>
                  <Button small type="submit" disabled={this.state.submitting}>
                    <FormattedMessage id="userprofile.forgotten_password_change_password" />
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default NewPasswordView;
