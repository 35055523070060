import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './AsyncLoader.scss';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/Loader';
import { Button } from 'suomifi-ui-components';

const AsyncLoaderComponent = ({ init, params, close, download, filename }) => {
  const [status, setStatus] = useState('init');
  const [fileParams, setFileParams] = useState(null);

  const onChange = useCallback((newStatus, newParams) => {
    setStatus(newStatus);
    setFileParams(newParams);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      init(params, onChange);
    }

    return () => {
      if (isMounted) {
        close();
      }
      isMounted = false;
    };
  }, [init, params, close, onChange]);

  let saveLink = null;

  if (fileParams && status === 'loaded') {
    const objectUrl = URL.createObjectURL(fileParams);
    const defaultFileName =
      fileParams.type === 'application/xml' ? 'metadata.xml' : 'asiakirja.pdf';

    saveLink = (
      <a
        href={objectUrl}
        download={download ? filename || defaultFileName : false}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage
          id={
            download
              ? 'general.fileLoader.save_file'
              : 'general.fileLoader.open_file'
          }
        />
      </a>
    );

    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      saveLink = (
        <Button
          onClick={() => {
            window.navigator.msSaveOrOpenBlob(fileParams, defaultFileName);
          }}
        >
          <FormattedMessage id="general.fileLoader.open_file" />
        </Button>
      );
    }
  }

  const statusLabels = {
    init: 'general.fileLoader.open',
    open: 'general.fileLoader.open',
    loading: 'general.fileLoader.loading',
    loaded: 'general.fileLoader.loaded',
    error: 'general.fileLoader.error'
  };

  return (
    <div
      role="presentation"
      className={styles.loaderContainer}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <div className={styles.loaderContent}>
        <div className={styles.loaderTitle}>
          <FormattedMessage id="general.fileLoader.waiting_note" />
        </div>
        <div className={styles.loaderGraphic}>
          {(status === 'open' || status === 'loading') && <Loader />}
          {status === 'loaded' && (
            <div className={styles.downloadLink}>{saveLink}</div>
          )}
        </div>
        {status !== 'loaded' && (
          <div className={styles.loaderProgress}>
            <FormattedMessage id={statusLabels[status]} />
          </div>
        )}
        {status === 'loaded' && (
          <div className={styles.closeLoader}>
            <Button variant="secondaryNoBorder" onClick={() => close()}>
              <FormattedMessage id="general.fileLoader.ready" />
            </Button>
          </div>
        )}
        {(status === 'open' || status === 'loading') && (
          <div className={styles.loaderDescription}>
            <FormattedMessage id="general.fileLoader.waiting_duration" />
          </div>
        )}
      </div>
    </div>
  );
};

AsyncLoaderComponent.propTypes = {
  init: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  download: PropTypes.bool,
  filename: PropTypes.string
};

export default AsyncLoaderComponent;
