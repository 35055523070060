import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Notification } from 'suomifi-ui-components';
import { Link } from 'react-router';
import styles from './AdminRegistrationNotification.scss';
import styled from 'styled-components';

const StyledNotification = styled(Notification)`
  @media (min-width: 1280px) {
    margin-right: 5px;
    margin-left: 15px;
    ${({ services }) =>
      Object.keys(services.servicesById).length > 0 &&
      `
  margin-bottom: 50px;
`}
  }
  border-top-color: rgba(246, 175, 9, 1) !important;
  border-radius: 0px;
  box-shadow: 0px 0px 0px 0px;
  border: 1px solid #c9cdcf;

  .fi-notification_icon-wrapper .fi-icon .fi-icon-base-fill {
    fill: rgba(246, 175, 9, 1) !important;
  }
`;

export const AdminRegistrationNotification = (
  { locale, openRegistrationRequests, services },
  { intl }
) => {
  const i18n = id => intl.formatMessage({ id });
  const [show, setShow] = React.useState(
    openRegistrationRequests > 0 ? true : false
  );

  return show ? (
    <div className="row v-offset-3">
      <StyledNotification
        closeText={i18n('notifications.close')}
        status="neutral"
        onCloseButtonClick={() => setShow(false)}
        services={services}
      >
        <FormattedMessage
          id="admin_pending_registration_notification"
          values={{
            count: openRegistrationRequests
          }}
        />
        <Link
          to={`/${locale}/kayttajahallinta/kayttajat/lista`}
          className={styles.link}
        >
          <FormattedMessage id="admin_pending_registration_notification_link_text" />
        </Link>
      </StyledNotification>
    </div>
  ) : null;
};

AdminRegistrationNotification.propTypes = {
  i18n: PropTypes.object,
  locale: PropTypes.string,
  openRegistrationRequests: PropTypes.number,
  service: PropTypes.object,
  services: PropTypes.object
};

AdminRegistrationNotification.contextTypes = {
  intl: PropTypes.object.isRequired
};
