import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import styles from './SearchView.scss';
import {
  getUpdatedAtFromPage,
  getTitleLink,
  getInfo,
  getDescription
} from './utils';

export const ResultItem = ({ id, type, item, lang }) => {
  if (type === 'article' && !item.atype) {
    return null;
  }

  return (
    <div className={styles.resultItem}>
      {getTitleLink(type, item, lang, id)}
      <div className={styles.tags}>
        {getInfo(type, item)}
        {item.tags &&
          item.tags.map(x => <span key={x.id}> &#9679; {x.name[lang]}</span>)}
        <span>
          {' '}
          &#9679;{' '}
          {type && type === 'page' ? (
            <FormattedDate value={getUpdatedAtFromPage(item)} />
          ) : (
            <FormattedDate value={item.updateDate} />
          )}
        </span>
      </div>
      {getDescription(type, item, lang)}
    </div>
  );
};
ResultItem.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  item: PropTypes.object,
  lang: PropTypes.string
};
