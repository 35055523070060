import { hasVrkServiceAdmin, hasVrkAdmin } from 'utils/accessUtils';
import { loginStyles, SERVICE_ID_SEMA } from 'constants/index';

/**
 * Helper to use with routes requiring authentication
 *
 * @param isAuthenticated
 * @param rest the rest of auth obj
 * @param predicate additional check
 * @returns {function()}
 */
export function requireAuth({ isAuthenticated, user }, predicate, pathName) {
  return (nextState, replace) => {
    if (!isAuthenticated || (!!predicate && !predicate(user))) {
      replace({
        pathname: pathName || '/rekisteroityminen',
        state: { nextPathname: nextState.location.pathname }
      });
    } else if (
      !user ||
      !user.organizations ||
      user.organizations.length === 0
    ) {
      replace({
        pathname: '/rekisteroityminen/tiedoksi',
        state: { nextPathname: nextState.location.pathname }
      });
    }
  };
}

export function requireStrongAuth(auth, predicate) {
  const pathName = '/login';

  if (
    auth.user &&
    (auth.user.sahaCookie || auth.user.loginStyle !== loginStyles.STRONG)
  ) {
    return requireAuth(
      { isAuthenticated: false, user: null },
      predicate,
      pathName
    );
  }
  return requireAuth(auth, predicate, pathName);
}

export function requireTemporaryAuth(auth, predicate) {
  const pathName = '/login';

  if (
    auth.user &&
    (auth.user.sahaCookie || auth.user.loginStyle !== loginStyles.TEMPORARY)
  ) {
    return requireAuth(
      { isAuthenticated: false, user: null },
      predicate,
      pathName
    );
  }
  return requireAuth(auth, predicate, pathName);
}
export function requireVrkAdmin(auth, predicate) {
  if (hasVrkAdmin(auth.user)) {
    return requireStrongAuth(auth, predicate);
  }
  return (nextState, replace) => {
    replace({
      pathname: '/dashboard',
      state: { nextPathname: nextState.location.pathname }
    });
  };
}

export function requireVrkServiceAdmin(auth, predicate) {
  if (hasVrkServiceAdmin(auth.user)) {
    return requireStrongAuth(auth, predicate);
  }
  return (nextState, replace) => {
    replace({
      pathname: '/dashboard',
      state: { nextPathname: nextState.location.pathname }
    });
  };
}

export function requireAuthNoOrg({ isAuthenticated, ...rest }, predicate) {
  return (nextState, replace) => {
    if (!isAuthenticated || (!!predicate && !predicate(rest.user))) {
      replace({
        pathname: '/rekisteroityminen',
        state: { nextPathname: nextState.location.pathname }
      });
    }
  };
}

export function requireService(user, predicate, pathName) {
  return (nextState, replace) => {
    if (!!predicate && !predicate(user)) {
      replace({
        pathname: pathName,
        state: { nextPathname: nextState.location.pathname }
      });
    }
  };
}

export function hasRole(role) {
  return user => !!user && user.roles.indexOf(role) > -1;
}

export function requireBusinessIdCheck(auth, userReq, predicate) {
  let lang = window.location.pathname.split('/')[1];
  return (nextState, replace) => {
    if (!auth.isAuthenticated || (!!predicate && !predicate(auth.user))) {
      replace({
        pathname: '/rekisteroityminen',
        state: { nextPathname: nextState.location.pathname }
      });
    } else if (
      (userReq.businessIdRetrieved && userReq.businessIdFound) ||
      !userReq.businessIdRetrieved ||
      userReq.currentBusinessId.length <= 0
    ) {
      replace({
        pathname: '/' + lang + '/rekisteroityminen/varmistus',
        state: { nextPathname: nextState.location.pathname }
      });
    }
  };
}

export function requireAuthAndHasSema(
  { isAuthenticated, user, ...rest },
  predicate
) {
  return (nextState, replace) => {
    if (!isAuthenticated || (!!predicate && !predicate(rest.user))) {
      replace({
        pathname: '/rekisteroityminen',
        state: { nextPathname: nextState.location.pathname }
      });
    } else if (
      !user ||
      !user.organizations ||
      user.organizations.length === 0
    ) {
      replace({
        pathname: '/rekisteroityminen/tiedoksi',
        state: { nextPathname: nextState.location.pathname }
      });
    } else if (
      !user.availServices ||
      user.availServices.indexOf(SERVICE_ID_SEMA) < 0
    ) {
      replace({
        pathname: '/rekisteroityminen/rekisterointi-valmis/org',
        state: { nextPathname: nextState.location.pathname }
      });
    } else if (user.roles.sema?.[0] === 'requestInvitation') {
      replace({
        pathname: '/rekisteroityminen/odottaa-kutsua',
        state: { nextPathname: nextState.location.pathname }
      });
    }
  };
}

export const isRequestInvitationSemaRole = roles => {
  return roles?.sema?.find(role => role === 'requestInvitation');
};
