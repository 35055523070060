/* eslint-disable react/prop-types */
import React from 'react';
import styles from './footer.scss';
import { Button, IconArrowLeft, IconArrowRight } from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import { asyncConnect } from 'redux-connect';
import { formStates } from '../../constants.js';
import { previousStep, nextStep } from '../../modules/IntermediaryForms';
import { InlineLoader } from 'components/Loader';

const mapActionCreators = {
  previousStep,
  nextStep
};
const mapStateToProps = state => ({
  currentStep: state.intermediary.currentStep,
  steps: state.intermediary.steps,
  errors: state.intermediary.errors,
  isSavingForm: state.intermediary.isSavingForm,
  nextFormState: state.intermediary.nextFormState
});

const Footer = ({
  handleSave,
  errors,
  steps,
  currentStep,
  isSavingForm,
  nextFormState,
  previousStep,
  nextStep,
  handleSubmit,
  isSubmitting
}) => {
  const firstStepStyle = currentStep === 0 ? 'hidden' : 'visible';
  const isLastStep = currentStep === steps.length - 1;
  const baseLoc = 'forms.';
  const canSubmit = errors.length === 0 && !isSubmitting;
  const submitTitle =
    nextFormState === formStates.WAITING_CUSTOMER_TO_APPROVE
      ? 'intermediary.misc.submitToClient'
      : 'general.form.submit';

  const changePageTo = changePage => {
    changePage();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  };

  return (
    <div className={styles.footer}>
      <div>
        <Button
          type="button"
          onClick={handleSave}
          variant="secondaryNoBorder"
          className={styles.footerButton}
          disabled={isSavingForm}
        >
          {isSavingForm ? (
            <FormattedMessage id={baseLoc + 'buttons.loading'} />
          ) : (
            <FormattedMessage id={baseLoc + 'buttons.finish'} />
          )}
        </Button>
      </div>
      <div>
        <Button
          variant="secondary"
          className={[styles.footerButton, styles.footerMiddleButton]}
          style={{ visibility: firstStepStyle }}
          icon={<IconArrowLeft />}
          onClick={() => changePageTo(previousStep)}
          disabled={isSavingForm}
        >
          <FormattedMessage id={baseLoc + 'buttons.previous'} />
        </Button>
        {isLastStep ? (
          <Button
            type="button"
            className={styles.footerButton}
            disabled={!canSubmit}
            onClick={handleSubmit}
          >
            {isSubmitting ? (
              <>
                <FormattedMessage id="intermediary.common.waitAMoment" />
                <InlineLoader />
              </>
            ) : (
              <FormattedMessage id={submitTitle} />
            )}
          </Button>
        ) : (
          <Button
            key="next"
            type="button"
            iconRight={<IconArrowRight />}
            className={styles.footerButton}
            onClick={() => changePageTo(nextStep)}
          >
            <FormattedMessage id={baseLoc + 'buttons.next'} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default asyncConnect([], mapStateToProps, mapActionCreators)(Footer);
