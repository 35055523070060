import React from 'react';
import PropTypes from 'prop-types';
import { maxFiles } from '../../constants';
import { Alert } from 'suomifi-ui-components';

export const FileUploadError = ({ error, setError, i18n }) => {
  return (
    <Alert
      closeText={i18n('intermediary.misc.attachments.close')}
      status="error"
      closeButtonProps={{ onClick: () => setError(false) }}
    >
      {(error === 'send' &&
        i18n('intermediary.misc.attachments.failedTryAgain')) ||
        (error === 'remove' &&
          i18n('intermediary.misc.attachments.removeFailedTryAgain')) ||
        (error === 'maxFiles' &&
          `${i18n('intermediary.misc.attachments.tooManyFiles')} ${maxFiles}.`)}
    </Alert>
  );
};
FileUploadError.propTypes = {
  error: PropTypes.bool | PropTypes.string,
  setError: PropTypes.func,
  i18n: PropTypes.func
};
