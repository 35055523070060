import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose, pure, withHandlers } from 'recompose';
import styles from './OrgSwitcher.scss';
import { Button } from 'sema-ui-components';
import iconSelected from '!!svg-inline-loader!sema-ui-components/dist/components/icons/icon-checkmark-l.svg';
import InlineSVG from 'svg-inline-react';
import {
  getOrganisationName,
  getOrganisationUnit
} from 'utils/organisationGetters';

const wrap = compose(
  pure,
  withHandlers({
    handleClick: props => e => {
      props.onClick(props.org.id);
      e.preventDefault();
    }
  })
);

export const OrgListItem = ({ handleClick, active, org }, { intl }) => (
  <li className={cx({ [styles.activeOrgListItem]: active })}>
    <a
      href="#"
      className={cx(styles.orgLink, {
        [styles.activeOrg]: active
      })}
      onClick={handleClick}
      data-test={`link-select-organization-${org.id}`}
    >
      <div className={styles.item_div}>
        <div className={styles.org_name_div}>
          <h4>{getOrganisationName(org, intl.locale)}</h4>
          <span className={styles.orgUnit}>
            {getOrganisationUnit(org, intl.locale)}
          </span>
        </div>
        <div className={styles.dflex}>
          <span>{org.businessId}</span>
          <div className={styles.svg}>
            {active && <InlineSVG className={styles.svg} src={iconSelected} />}
          </div>
          <Button secondary data-test={`button-select-organization-${org.id}`}>
            {intl.formatMessage({ id: 'general.choose' })}
          </Button>
        </div>
      </div>
    </a>
  </li>
);

OrgListItem.propTypes = {
  active: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  org: PropTypes.object.isRequired
};

OrgListItem.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default wrap(OrgListItem);
