import React from 'react';
import PropTypes from 'prop-types';
import { Block, Heading, Paragraph } from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import styles from './styles.scss';

const Wrapper = ({
  children,
  isIntermediary,
  formTitleIntermediary,
  formTitle
}) => (
  <div className={styles.container}>
    <Block>
      <Heading variant="h2" className="borderless mb-2">
        <FormattedMessage
          id={isIntermediary ? formTitleIntermediary : formTitle}
        />
      </Heading>
      <Paragraph>
        <FormattedMessage id="intermediary.organisationInfo.description" />
      </Paragraph>
    </Block>
    <Block>
      <Heading variant="h3">
        <FormattedMessage id="intermediary.organisationInfo.basicInformation" />
      </Heading>
      <Paragraph>
        <FormattedMessage id="intermediary.organisationInfo.requiredInformation" />
      </Paragraph>
    </Block>
    {children}
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isIntermediary: PropTypes.bool,
  formTitleIntermediary: PropTypes.string,
  formTitle: PropTypes.string
};

export default Wrapper;
