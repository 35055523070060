import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { Textarea, Tooltip } from 'suomifi-ui-components';
import { getStatus, getStatusText, splitName } from '../../utils/utility';
import styled from 'styled-components';

const StyledTextarea = styled(Textarea)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: ${props => (props.width ? props.width : '100%')};
`;

const translateKeyOrKeys = (labels, i18n) =>
  Array.isArray(labels)
    ? labels.map(label => i18n(label)).join(' ')
    : labels
    ? i18n(labels)
    : '';

export const IntermediaryTextarea = (
  { name, label, optional, tooltip, ...rest },
  { intl }
) => {
  const formik = useFormikContext();
  const i18n = id => intl.formatMessage({ id });

  const status = getStatus(formik, ...splitName(name));
  const statusText = getStatusText(i18n, formik, ...splitName(name));

  return (
    <Field
      {...rest}
      as={StyledTextarea}
      name={name}
      labelText={translateKeyOrKeys(label, i18n)}
      optionalText={
        optional ? i18n('intermediary.validation.optional') : undefined
      }
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      status={status}
      statusText={statusText}
      tooltipComponent={
        tooltip ? (
          <Tooltip
            ariaToggleButtonLabelText={i18n('general.show_more')}
            ariaCloseButtonLabelText={i18n('general.go_back')}
          >
            {tooltip}
          </Tooltip>
        ) : (
          undefined
        )
      }
    />
  );
};

IntermediaryTextarea.contextTypes = {
  intl: PropTypes.object.isRequired
};

IntermediaryTextarea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  i18n: PropTypes.func,
  formik: PropTypes.object,
  fieldType: PropTypes.string,
  optional: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tooltip: PropTypes.node
};
