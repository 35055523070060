/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const DD = styled.dd`
  white-space: pre-wrap;
`;

const getValue = (value, preFormatted, options = false) => {
  if (options) {
    return options[value];
  }

  switch (value) {
    case '':
    case null:
    case undefined: {
      return <FormattedMessage id="intermediary.preview.notKnown" />;
    }
    case true: {
      return <FormattedMessage id="intermediary.common.yes" />;
    }
    case false: {
      return <FormattedMessage id="intermediary.common.no" />;
    }
    default: {
      return preFormatted ? <pre>{value}</pre> : value;
    }
  }
};

const List = ({ children }) => (
  <dl style={{ fontSize: '1rem', margin: '1rem 0' }}>{children}</dl>
);

List.Item = ({ title, value, preFormatted = false, options }) => {
  return (
    <>
      <dt style={{ fontWeight: 600 }}>{title}</dt>
      <DD>{getValue(value, preFormatted, options)}</DD>
    </>
  );
};

List.SubItem = ({ title, value, visible }) => {
  if (!visible) {
    return null;
  }
  return (
    <dl style={{ borderLeft: '3px solid #292929' }}>
      <dt style={{ fontWeight: 600, marginLeft: '1rem' }}>{title}</dt>
      <dd style={{ marginLeft: '1rem' }}>{getValue(value)}</dd>
    </dl>
  );
};

export default List;
