import React from 'react';
import { shouldUpdate } from 'recompose';
import { Spinner } from 'sema-ui-components';

import styles from './Loader.scss';
const Loader = () => <div className={styles.base} />;

const LoadingSpinner = ({ children }) => (
  <div className={styles.spinnerContainer}>
    <div className={styles.spinner}>
      <Spinner className={styles.spinnerIcon} />{' '}
      <span className={styles.spinnerText}>{children}</span>
    </div>
  </div>
);

const InlineLoader = () => (
  <span className={styles.inlineloader}>
    <span className={styles.loaderdot} />
    <span className={styles.loaderdot} />
    <span className={styles.loaderdot} />
  </span>
);

export { LoadingSpinner, InlineLoader };

export default shouldUpdate(() => false)(Loader);
