import { createAction as ca, createReducer } from '../../../utils';

// ------------------------------------
// Action constants
// ------------------------------------
export const SOCK_CHAT_SEND_MESSAGE = 'SOCK/CHAT_SEND_MESSAGE';
export const CHAT_RECEIVE_MESSAGE = 'CHAT_RECEIVE_MESSAGE';
export const SOCK_CHAT_START_TYPING = 'SOCK/CHAT_START_TYPING';
export const SOCK_CHAT_STOP_TYPING = 'SOCK/CHAT_STOP_TYPING';
export const CHAT_SOMEBODY_IS_TYPING = 'CHAT_SOMEBODY_IS_TYPING';
export const CHAT_SOMEBODY_IS_NOT_TYPING = 'CHAT_SOMEBODY_IS_NOT_TYPING';

// ------------------------------------
// Action creators
// ------------------------------------
export const sendMessage = ca(SOCK_CHAT_SEND_MESSAGE);
export const startTyping = ca(SOCK_CHAT_START_TYPING);
export const stopTyping = ca(SOCK_CHAT_STOP_TYPING);

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SOCK_CHAT_SEND_MESSAGE]: (state, { payload }) => ({
    ...state,
    messages: [...state.messages, payload]
  }),
  [CHAT_RECEIVE_MESSAGE]: (state, { payload }) => ({
    ...state,
    messages: [...state.messages, payload]
  }),
  [SOCK_CHAT_START_TYPING]: state => state,
  [SOCK_CHAT_STOP_TYPING]: state => state,
  [CHAT_SOMEBODY_IS_TYPING]: (state, { payload }) => ({
    ...state,
    statuses: [...state.statuses, payload]
  }),
  [CHAT_SOMEBODY_IS_NOT_TYPING]: (state, { payload }) => ({
    ...state,
    statuses: state.statuses.filter(x => x.hetu !== payload.hetu)
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { messages: [], statuses: [] };
export default createReducer(ACTION_HANDLERS, initialState);
