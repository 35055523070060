import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import styles from './DetailsView.scss';

export const DetailsContent = ({ children, locale }) => (
  <div className={styles.content}>{children}</div>
);

DetailsContent.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired
};

export default pure(DetailsContent);
