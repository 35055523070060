import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { RadioButtonGroup, RadioButton, Tooltip } from 'suomifi-ui-components';

const getValue = val => {
  return val === true || val === false
    ? val.toString()
    : typeof val === 'string'
    ? val
    : typeof val;
};

export const RadioGroupFactory = ({
  property,
  serviceIndex,
  i18n,
  formik,
  options,
  tooltip
}) => {
  return (
    <RadioButtonGroup
      style={{ marginTop: '1rem', marginBottom: '1rem' }}
      labelText={i18n(`intermediary.eAuthorizationServiceInfo.${property}`)}
      name={`formData.serviceData.services.${serviceIndex}.${property}`}
      value={
        options
          ? formik?.values?.formData?.serviceData.services[serviceIndex][
              property
            ]
          : getValue(
              formik?.values?.formData?.serviceData.services[serviceIndex][
                property
              ]
            )
      }
      onChange={value =>
        options
          ? formik.setFieldValue(
              `formData.serviceData.services[${serviceIndex}].${property}`,
              value
            )
          : formik.setFieldValue(
              `formData.serviceData.services[${serviceIndex}].${property}`,
              value === 'true'
            )
      }
      tooltipComponent={
        tooltip ? (
          <Tooltip
            ariaToggleButtonLabelText={i18n('general.show_more')}
            ariaCloseButtonLabelText={i18n('general.go_back')}
          >
            {tooltip}
          </Tooltip>
        ) : (
          undefined
        )
      }
    >
      {options ? (
        (options || []).map(option => (
          <RadioButton key={`radiobutton-option-${option}`} value={option}>
            <FormattedMessage
              id={`intermediary.eAuthorizationServiceInfo.${option}`}
            />
          </RadioButton>
        ))
      ) : (
        <>
          <RadioButton value="true">
            <FormattedMessage id="intermediary.common.yes" />
          </RadioButton>
          <RadioButton value="false">
            <FormattedMessage id="intermediary.common.no" />
          </RadioButton>
        </>
      )}
    </RadioButtonGroup>
  );
};

RadioGroupFactory.propTypes = {
  property: PropTypes.string,
  serviceIndex: PropTypes.number,
  i18n: PropTypes.func,
  formik: PropTypes.object,
  options: PropTypes.array,
  tooltip: PropTypes.node
};
