import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Block,
  Heading,
  TextInput,
  IconPlus,
  IconRemove
} from 'suomifi-ui-components';
import { Field, FieldArray } from 'formik';
import { getStatus, getStatusText } from '../../../utils/utility';
import { notificationContactSchema } from '../index';
import styles from '../styles.scss';

const NotificationContacts = ({ formik, serviceIndex, i18n }) => {
  const helperRef = React.useRef(null);
  const contactsPath = `formData.serviceData.services.${serviceIndex}.notificationContacts`;
  const { services } = formik.values.formData.serviceData;

  const onChange = (value, contactIndex, fieldName) => {
    formik.setFieldValue(`${contactsPath}.${contactIndex}.${fieldName}`, value);
  };

  const contactFieldProps = { formik, i18n, contactsPath, onChange };

  return (
    <React.Fragment>
      <Block my="m">
        <Heading variant="h3" className="borderless mb-2">
          <FormattedMessage id="forms.viestit_registration.page_messagesInfo.notificationSection.title" />
        </Heading>
      </Block>
      <Block>
        <FieldArray
          name={`formData.serviceData.services.${serviceIndex}.notificationContacts`}
          render={helpers => {
            helperRef.current = helpers;
            return services?.[serviceIndex]?.notificationContacts?.map(
              (_, contactIndex) => (
                <div className={styles.person__container} key={contactIndex}>
                  <div className={styles.removePersonContainer}>
                    <ContactField
                      fieldName="contactFirstName"
                      label="intermediary.field.firstName"
                      contactIndex={contactIndex}
                      {...contactFieldProps}
                    />
                    <Button
                      variant="secondaryNoBorder"
                      icon={<IconRemove />}
                      onClick={() => helpers.remove(contactIndex)}
                    >
                      <FormattedMessage id="general.form.delete" />
                    </Button>
                  </div>
                  <ContactField
                    fieldName="contactLastName"
                    label="intermediary.field.lastName"
                    contactIndex={contactIndex}
                    {...contactFieldProps}
                  />
                  <ContactField
                    fieldName="contactEmail"
                    label="intermediary.field.email"
                    contactIndex={contactIndex}
                    {...contactFieldProps}
                  />
                  <ContactField
                    fieldName="contactPhone"
                    label="intermediary.field.phone"
                    contactIndex={contactIndex}
                    {...contactFieldProps}
                    optional
                  />
                </div>
              )
            );
          }}
        />
        <Button
          variant="secondary"
          icon={<IconPlus />}
          className={styles.person__button}
          onClick={() =>
            helperRef.current.push(notificationContactSchema.cast({}))
          }
        >
          <FormattedMessage id="intermediary.messaging.addReceiver" />
        </Button>
      </Block>
    </React.Fragment>
  );
};

const ContactField = ({
  fieldName,
  contactsPath,
  contactIndex,
  formik,
  onChange,
  i18n,
  label,
  optional
}) => (
  <Field
    as={TextInput}
    type="text"
    name={`${contactsPath}.${contactIndex}.${fieldName}`}
    className={styles.textinput}
    labelText={i18n(label)}
    wrapperProps={{ style: { width: '80%' } }}
    status={getStatus(formik, `${contactsPath}.${contactIndex}`, fieldName)}
    statusText={getStatusText(
      i18n,
      formik,
      `${contactsPath}.${contactIndex}`,
      fieldName
    )}
    onChange={value => onChange(value, contactIndex, fieldName)}
    optionalText={
      optional ? i18n('intermediary.validation.optional') : undefined
    }
  />
);

NotificationContacts.propTypes = {
  formik: PropTypes.object.isRequired,
  serviceIndex: PropTypes.number.isRequired,
  i18n: PropTypes.func.isRequired
};

ContactField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  contactsPath: PropTypes.string.isRequired,
  contactIndex: PropTypes.number.isRequired,
  formik: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  i18n: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  optional: PropTypes.bool
};

export default NotificationContacts;
