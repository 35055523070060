import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { pure } from 'recompose';
import InlineSVG from 'svg-inline-react';
import Logo from '../Logo';
// import NotificationBell from '../NotificationBell';
import { UserBox, UserDropdown } from 'components/Auth';
// import NotificationsDropdown from 'components/NotificationsDropdown';
import SearchBox from 'components/SearchBox';
import styles from './Header.scss';
import { Button, Alert } from 'suomifi-ui-components';
import hamburgerIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/icon-hamburger.svg';
import { LanguageSwitcher, TempUser } from './components';
import { loginStyles } from 'constants/index';

const globalNotificationEnabled = true;

const GlobalNotification = ({ withShadow = true }, { intl }) => {
  const i18n = id => intl.formatMessage({ id });
  const [show, setShow] = React.useState(true);
  const [show2, setShow2] = React.useState(true);

  if (!globalNotificationEnabled) {
    return null;
  }

  const notificationReadMoreText = i18n('global_notification_read_more');
  const notificationReadMoreIsEmpty =
    !notificationReadMoreText.trim().length ||
    notificationReadMoreText == 'global_notification_read_more';

  const Alert1 = (
    <Alert
      closeText={i18n('notifications.close')}
      status="warning"
      onCloseButtonClick={() => setShow(false)}
    >
      <FormattedMessage id="global_notification" />
      {!notificationReadMoreIsEmpty && (
        <div>
          <a href={i18n('global_notification_read_more_link')}>
            <u>
              <FormattedMessage id="global_notification_read_more" />
            </u>
          </a>
        </div>
      )}
    </Alert>
  );

  const Alert2 = (
    <Alert
      closeText={i18n('notifications.close')}
      status="warning"
      onCloseButtonClick={() => setShow2(false)}
    >
      <FormattedMessage id="global_notification_2" />

      <a href={i18n('global_notification_read_more_link_2')}>
        <u>
          <FormattedMessage id="global_notification_read_more_2" />
        </u>
      </a>
    </Alert>
  );

  return (
    (show || show2) && (
      <div>
        <div style={{ boxShadow: withShadow ? '0 -1px 0 0 #c9cdcf' : 'none' }}>
          {show && Alert1}
        </div>
        <div style={{ boxShadow: withShadow ? '0 -1px 0 0 #c9cdcf' : 'none' }}>
          {show2 && Alert2}
        </div>
      </div>
    )
  );
};

const Header = props => {
  const {
    activeOrg,
    changeOrg,
    className,
    closeModal,
    inverse,
    isAuthenticated,
    isUserDropdownOpen,
    // isNotificationsDropdownOpen,
    locale,
    openModal,
    router,
    signOut,
    toggleSideNav,
    toggleUserDropdown,
    // toggleNotificationsDropdown,
    user,
    // notifStats,
    // definitions,
    changeLang
  } = props;

  const handleSignInClick = () => {
    browserHistory.push(`/${locale}/rekisteroityminen`);
  };

  const userBox = isAuthenticated ? (
    <div className={styles.authRow}>
      <LanguageSwitcher
        locale={locale}
        inverse={inverse}
        changeLang={changeLang}
        className={styles.langSwitcher}
      />
      <div className={cx(styles.userBox, 'hidden-md-down')}>
        <UserBox
          activeOrg={activeOrg}
          inverse={inverse}
          locale={locale}
          user={user}
        />
      </div>
      {user &&
        user.organizations &&
        user.organizations.length == 0 &&
        router.location.pathname.indexOf('rekisteroi') < 0 && (
          <Button
            className={styles.registerButton}
            onClick={() =>
              browserHistory.push(`/${locale}/rekisteroityminen/tiedoksi`)
            }
          >
            <FormattedMessage id="general.register_header" />
          </Button>
        )}
      <div>
        <UserDropdown
          activeOrg={activeOrg}
          closeModal={closeModal}
          inverse={inverse}
          isOpen={isUserDropdownOpen}
          locale={locale}
          onClick={forceClose => {
            toggleSideNav();
            toggleUserDropdown({ forceClose });
          }}
          onOrgChange={changeOrg}
          onSignOut={signOut}
          openModal={openModal}
          user={user}
        >
          <div
            className={cx(styles.hamburger, {
              [styles.inverse]: inverse,
              [styles.active]: isUserDropdownOpen
            })}
          >
            <div>
              <InlineSVG src={hamburgerIcon} />
            </div>
            <div>
              <FormattedMessage id="general.hamburger_title" />
            </div>
          </div>
        </UserDropdown>
      </div>
    </div>
  ) : router.location.pathname.indexOf('rekisteroi') < 0 ? (
    <div className={styles.authRow}>
      <LanguageSwitcher
        locale={locale}
        inverse={inverse}
        changeLang={changeLang}
        className={styles.langSwitcher}
      />
      <Button onClick={handleSignInClick}>
        <FormattedMessage id="general.sign_in_header" />
      </Button>
    </div>
  ) : (
    <div className={styles.authRow}>
      <LanguageSwitcher
        locale={locale}
        inverse={inverse}
        changeLang={changeLang}
        className={styles.langSwitcher}
      />
    </div>
  );

  /* eslint-enable */

  return (
    <>
      <a className={styles.skipToContent} href="#main">
        <FormattedMessage id="general.skip_to_content" />
      </a>
      {isAuthenticated ? null : <GlobalNotification />}
      <header
        className={cx(
          styles.base,
          className,
          inverse ? styles.noLineOnTop : styles.blueLineOnTop,
          !inverse && styles.bottomBorder
        )}
      >
        <div className="container">
          <div className={styles.headerContentRow}>
            <div className="d-flex justify-content-between">
              <div className={styles.logoContainer}>
                <Logo
                  light={inverse}
                  registered={
                    user && user.organizations && user.organizations.length > 0
                  }
                />
              </div>
              {/* Not used at the moment */}
              {/* <div className={styles.notificationContainer}>
                {isAuthenticated && (
                  <NotificationsDropdown
                    closeModal={closeModal}
                    inverse={inverse}
                    isOpen={isNotificationsDropdownOpen}
                    locale={locale}
                    onClick={forceClose => {
                      toggleSideNav();
                      toggleNotificationsDropdown({ forceClose });
                    }}
                    onSignOut={signOut}
                    openModal={openModal}
                    user={user}
                    stats={notifStats}
                    definitions={definitions}
                  >
                    <NotificationBell stats={notifStats} locale={locale} />
                  </NotificationsDropdown>
                )}
              </div> */}
            </div>
            <div className={cx(styles.searchBoxContainer, 'hidden-md-down')}>
              <SearchBox locale={locale} />
            </div>
            <div>
              {user?.loginStyle === loginStyles.TEMPORARY ? (
                <TempUser
                  user={user}
                  signOut={signOut}
                  locale={locale}
                  styles={styles}
                  inverse={inverse}
                  changeLang={changeLang}
                />
              ) : (
                <div>{userBox}</div>
              )}
            </div>
          </div>
        </div>
        {isAuthenticated ? <GlobalNotification withShadow /> : null}
      </header>
    </>
  );
};

Header.propTypes = {
  activeOrg: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  changeOrg: PropTypes.func,
  className: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  inverse: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isUserDropdownOpen: PropTypes.bool.isRequired,
  isNotificationsDropdownOpen: PropTypes.bool.isRequired,
  locale: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  router: PropTypes.object,
  sideNavOpen: PropTypes.bool,
  signOut: PropTypes.func,
  startSignIn: PropTypes.func,
  toggleSideNav: PropTypes.func,
  toggleUserDropdown: PropTypes.func.isRequired,
  toggleNotificationsDropdown: PropTypes.func.isRequired,
  user: PropTypes.object,
  definitions: PropTypes.array.isRequired,
  changeLang: PropTypes.func
};

GlobalNotification.propTypes = {
  withShadow: PropTypes.bool,
  i18n: PropTypes.object
};

GlobalNotification.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default pure(Header);
