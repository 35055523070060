import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import styles from './DetailsView.scss';

export const DetailsAside = ({ children, locale }) => (
  <aside className={styles.aside}>{children}</aside>
);

DetailsAside.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired
};

export default pure(DetailsAside);
