import { asyncConnect } from 'redux-connect';
import isEmpty from 'lodash/isEmpty';
import view from '../components/EnvironmentDetailsView';
import { setOverlay, unsetOverlay } from 'modules/ui';
import { fetchEnvironmentContacts } from '../modules/index';

const mapActionCreators = {
  fetchEnvironmentContacts,
  setOverlay,
  unsetOverlay
};

const mapStateToProps = state => ({
  ...state,
  currentProvider: state.idAdmin.currentProvider,
  currentEnvironment: state.idAdmin.currentEnvironment,
  currentEnvironmentContacts: state.idAdmin.currentEnvironmentContacts,
  environments: state.idAdmin.environments
});

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch, getState } }) => {
        if (!isEmpty(getState().idAdmin.currentEnvironment)) {
          return Promise.resolve(
            dispatch(
              fetchEnvironmentContacts(
                getState().idAdmin.currentProvider.id,
                getState().idAdmin.currentEnvironment.id
              )
            )
          );
        }
      }
    }
  ],
  mapStateToProps,
  mapActionCreators
)(view);
