import * as OrganisationInfo from '../../partials/OrganisationInfo';
import * as CustomerOrganisationContacts from '../../partials/CustomerOrganisationContacts';
import * as IntermediaryOrganisationContacts from '../../partials/IntermediaryOrganisationContacts';
import * as ExtentOfData from '../../partials/ExtentOfData';
import * as DataProtectionStatement from '../../partials/DataProtectionStatement';
import * as Terms from '../../partials/Terms';
import * as SubmitForm from '../../partials/SubmitForm';
import * as ServiceInfo from '../../partials/ServiceInfoIdentification';

const isEIdentificationForm = true;

export const initialSteps = [
  {
    id: 0,
    title: OrganisationInfo.formTitle,
    titleIntermediary: OrganisationInfo.formTitleIntermediary,
    completed: false,
    Form: OrganisationInfo.Form,
    schema: OrganisationInfo.schema(false),
    intermediarySchema: OrganisationInfo.schema(true)
  },
  {
    id: 1,
    title: CustomerOrganisationContacts.formTitle,
    titleIntermediary: CustomerOrganisationContacts.formTitleIntermediary,
    completed: false,
    Form: CustomerOrganisationContacts.Form,
    schema: CustomerOrganisationContacts.schema(false, true),
    intermediarySchema: CustomerOrganisationContacts.schema(true, true)
  },
  {
    id: 2,
    title: IntermediaryOrganisationContacts.formTitle,
    completed: false,
    Form: IntermediaryOrganisationContacts.Form,
    schema: IntermediaryOrganisationContacts.schema(true),
    intermediaryOnly: true
  },
  {
    id: 3,
    title: ExtentOfData.formTitle,
    completed: false,
    Form: ExtentOfData.Form,
    schema: ExtentOfData.schema
  },
  {
    id: 4,
    title: ServiceInfo.formTitle,
    completed: false,
    Form: ServiceInfo.Form,
    schema: ServiceInfo.schema
  },
  {
    id: 5,
    title: DataProtectionStatement.formTitle,
    completed: false,
    Form: DataProtectionStatement.Form,
    schema: DataProtectionStatement.schema(isEIdentificationForm)
  },
  {
    id: 6,
    title: Terms.formTitle,
    completed: false,
    Form: Terms.Form,
    schema: Terms.schema
  },
  {
    id: 7,
    title: 'intermediary.common.send',
    completed: false,
    Form: SubmitForm.Form,
    schema: SubmitForm.schema
  }
];
