import localForage from 'localforage';
import { push } from 'react-router-redux';
import u from 'updeep';
import { putSemaJSON } from 'utils/http';
import { setSessionExpiredInCookie } from 'utils/sessionUtil';
import { API_ROOT, CALL_API } from '../../store/middleware/api';
import { INDEXEDDB } from '../../store/middleware/localForage';
import {
  createAction as ca,
  createReducer,
  getURLQueryString
} from '../../utils';

// ------------------------------------
// Action constants
// ------------------------------------
const PX = 'AUTH:';
export const SIGN_IN_START = `${PX}_SIGN_IN_START`;
export const USER_REQUEST = `${PX}_USER_REQUEST`;
export const SIGN_IN_SUCCESS = `${PX}_SIGN_IN_SUCCESS`;
export const SIGN_IN_FAILURE = `${PX}_SIGN_IN_FAILURE`;
export const SIGN_OUT_REQUEST = `${PX}_SIGN_OUT_REQUEST`;
export const SIGN_OUT_FAILURE = `${PX}_SIGN_OUT_FAILURE`;
export const CHANGE_ORG_REQUEST = `${PX}_CHANGE_ORG_REQUEST`;
export const CHANGE_ORG_SUCCESS = `${PX}_CHANGE_ORG_SUCCESS`;
export const CHANGE_ORG_FAILURE = `${PX}_CHANGE_ORG_FAILURE`;
export const UPDATE_USER_TO_STORE_REQUEST = `${PX}_UPDATE_USER_TO_STORE_REQUEST`;
export const USER_FROM_STORAGE_REQUEST = `${PX}_USER_FROM_STORAGE_REQUEST`;
export const USER_FROM_STORAGE_SUCCESS = `${PX}_USER_FROM_STORAGE_SUCCESS`;
export const USER_FROM_STORAGE_FAILURE = `${PX}_USER_FROM_STORAGE_FAILURE`;
export const USER_TO_STORAGE_REQUEST = `${PX}_USER_TO_STORAGE_REQUEST`;
export const USER_TO_STORAGE_SUCCESS = `${PX}_USER_TO_STORAGE_SUCCESS`;
export const USER_TO_STORAGE_FAILURE = `${PX}_USER_TO_STORAGE_FAILURE`;
export const WEAK_SIGN_IN_REQUEST = `${PX}_WEAK_SIGN_IN_REQUEST`;
export const WEAK_SIGN_IN_SUCCESS = `${PX}_WEAK_SIGN_IN_SUCCESS`;
export const WEAK_SIGN_IN_FAILURE = `${PX}_WEAK_SIGN_IN_FAILURE`;
export const PWD_RESET_REQ_REQUEST = `${PX}_PWD_RESET_REQ_REQUEST`;
export const PWD_RESET_REQ_SUCCESS = `${PX}_PWD_RESET_REQ_SUCCESS`;
export const PWD_RESET_REQ_FAILURE = `${PX}_PWD_RESET_REQ_FAILURE`;
export const PWD_EXPIRED_REQ_REQUEST = `${PX}_PWD_RESET_REQ_REQUEST`;
export const PWD_EXPIRED_REQ_SUCCESS = `${PX}_PWD_EXPIRED_REQ_SUCCESS`;
export const PWD_EXPIRED_REQ_FAILURE = `${PX}_PWD_EXPIRED_REQ_FAILURE`;
export const TOGGLE_USER_DROPDOWN = `${PX}_TOGGLE_USER_DROPDOWN`;
export const TOGGLE_NOTIFICATIONS_DROPDOWN = `${PX}_TOGGLE_NOTIFICATIONS_DROPDOWN`;
export const SET_SESSION_EXPIRED = `${PX}_SET_SESSION_EXPIRED`;
export const USER_DATA_UPDATE_REQUEST = `${PX}_USER_DATA_UPDATE_REQUEST`;
export const USER_DATA_UPDATE_SUCCESS = `${PX}_USER_DATA_UPDATE_SUCCESS`;
export const USER_DATA_UPDATE_ERROR = `${PX}_USER_DATA_UPDATE_ERROR`;
export const GET_USER_DATA_REQUEST = `${PX}_GET_USER_DATA_REQUEST`;
export const GET_USER_DATA_SUCCESS = `${PX}_GET_USER_DATA_SUCCESS`;
export const GET_USER_DATA_ERROR = `${PX}_GET_USER_DATA_ERROR`;
export const USER_PASSWORD_UPDATE_REQUEST = `${PX}_USER_PASSWORD_UPDATE_REQUEST`;
export const USER_PASSWORD_UPDATE_SUCCESS = `${PX}_USER_PASSWORD_UPDATE_SUCCESS`;
export const USER_PASSWORD_UPDATE_ERROR = `${PX}_USER_PASSWORD_UPDATE_ERROR`;
export const USER_ORGANISATION_UPDATE_REQUEST = `${PX}_USER_ORGANISATION_UPDATE_REQUEST`;
export const USER_ORGANISATION_UPDATE_SUCCESS = `${PX}_USER_ORGANISATION_UPDATE_SUCCESS`;
export const USER_ORGANISATION_UPDATE_ERROR = `${PX}_USER_ORGANISATION_UPDATE_ERROR`;
export const REGISTRATION_REQUEST_COUNT_REQUEST = `${PX}_REGISTRATION_REQUEST_COUNT_REQUEST`;
export const REGISTRATION_REQUEST_COUNT_SUCCESS = `${PX}_REGISTRATION_REQUEST_COUNT_SUCCESS`;
export const REGISTRATION_REQUEST_COUNT_ERROR = `${PX}_REGISTRATION_REQUEST_COUNT_ERROR`;

// ------------------------------------
// Action creators
// ------------------------------------
const requestSignOut = ca(SIGN_OUT_REQUEST);
const failSignOut = ca(SIGN_OUT_FAILURE);
export const toggleUserDropdown = ca(TOGGLE_USER_DROPDOWN);
export const toggleNotificationsDropdown = ca(TOGGLE_NOTIFICATIONS_DROPDOWN);

// ------------------------------------
// Async action thunks
// ------------------------------------
/**
 * Starts the sign-in process if we need to log it
 * or something. Now this does basically nothing.
 *
 * @returns {function()}
 */
export function startSignIn() {
  return dispatch => {
    dispatch(ca(SIGN_IN_START)());
  };
}

export function storeUser(payload) {
  return {
    [INDEXEDDB]: {
      types: [
        USER_TO_STORAGE_REQUEST,
        USER_TO_STORAGE_SUCCESS,
        USER_TO_STORAGE_FAILURE
      ],
      key: 'user',
      payload
    }
  };
}

const handlerUserSuccess = (payload, dispatch) => {
  return dispatch(storeUser(payload));
};

/**
 * Requests user from api and triggers couple actions
 * while doing it. Sets user object to IndexedDB.
 * Returns Redux thunk that returns Promise of user
 * object.
 *
 * @returns Promise of user
 */
export function requestUser() {
  setSessionExpiredInCookie(false);
  return {
    [CALL_API]: {
      types: [USER_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE],
      endpoint: 'auth/principal',
      onSuccess: handlerUserSuccess
    }
  };
}

export function requestChangeOrg(id) {
  return {
    [CALL_API]: {
      method: 'put',
      types: [CHANGE_ORG_REQUEST, CHANGE_ORG_SUCCESS, CHANGE_ORG_FAILURE],
      endpoint: `auth/change-org/${id}`
    }
  };
}

export function requestWaitForUserAndChangeOrg(userId, id, email) {
  return {
    [CALL_API]: {
      method: 'post',
      types: [CHANGE_ORG_REQUEST, CHANGE_ORG_SUCCESS, CHANGE_ORG_FAILURE],
      data: {
        newOrganizationId: `${id}`,
        userId: `${userId}`,
        email: `${email}`
      },
      endpoint: 'auth/change-org-after-user-creation'
    }
  };
}

/**
 * Creates and action to sign in weakly (direct SAHA)
 *
 * @param data FormData of weak login form
 * @returns Promise of user
 */
export function weakSignIn(data, newEndpoint) {
  setSessionExpiredInCookie(false);
  return {
    [CALL_API]: {
      types: [WEAK_SIGN_IN_REQUEST, WEAK_SIGN_IN_SUCCESS, WEAK_SIGN_IN_FAILURE],
      endpoint: newEndpoint ? `auth/login-weak-new` : `auth/login-weak`,
      data,
      onSuccess: handlerUserSuccess
    }
  };
}

export function requestPasswordReset(email) {
  return async dispatch => {
    dispatch(ca(PWD_RESET_REQ_REQUEST)());
    try {
      const result = await putSemaJSON(
        `/api/organisationservice/passwordresetrequests/email/${email}`,
        {}
      );

      dispatch(ca(PWD_RESET_REQ_SUCCESS)(result));
      return result;
    } catch (e) {
      dispatch(ca(PWD_RESET_REQ_FAILURE)(e));
      return { error: e };
    }
  };
}

export function resetPassword(passwords) {
  return async dispatch => {
    dispatch(ca(PWD_RESET_REQ_REQUEST)());
    try {
      const result = await putSemaJSON(
        `/api/organisationservice/passwordresetrequests/pwd`,
        passwords
      );

      dispatch(ca(PWD_RESET_REQ_SUCCESS)(result));
      return result;
    } catch (e) {
      dispatch(ca(PWD_RESET_REQ_FAILURE)(e));
      return { error: e };
    }
  };
}

export function resetExpiredPassword(passwords) {
  return async dispatch => {
    dispatch(ca(PWD_EXPIRED_REQ_REQUEST)());
    try {
      const result = await putSemaJSON(
        `/api/organisationservice/passwordresetrequests/expired`,
        passwords
      );

      dispatch(ca(PWD_EXPIRED_REQ_SUCCESS)(result));
      return result;
    } catch (e) {
      dispatch(ca(PWD_EXPIRED_REQ_FAILURE)(e));
      return { error: e };
    }
  };
}

/**
 * Does async user population from the IndexedDB
 */
export function initializeUserSession() {
  return {
    [INDEXEDDB]: {
      types: [
        USER_FROM_STORAGE_REQUEST,
        USER_FROM_STORAGE_SUCCESS,
        USER_FROM_STORAGE_FAILURE
      ],
      key: 'user'
    }
  };
}

/**
 * Cleans IndexedDB optimistically and redirects to logout.
 *
 * @returns {function()}
 */
export function signOut() {
  return async dispatch => {
    dispatch(requestSignOut());
    try {
      await localForage.removeItem('user');
      window.location = `${API_ROOT}auth/logout`;
    } catch (e) {
      dispatch(failSignOut(e));
    }
  };
}

/**
 * Triggers organization switch.
 *
 * @param id
 * @returns {function()}
 */
export function changeOrg(id) {
  return async (dispatch, getState) => {
    const response = await dispatch(requestChangeOrg(id));
    if (!response.error) {
      const user = u({ activeOrganizationId: id }, getState().auth.user);
      dispatch(storeUser(user));
      dispatch(push('/dashboard'));
    }
  };
}

export function getUpdatedUser(userId) {
  return async (dispatch, getState) => {
    const response = await dispatch(getOwnUserData(userId));
    if (!response.error) {
      const user = u(
        {
          email: response.email,
          phoneNumber: response.phoneNumber
        },
        getState().auth.user
      );
      dispatch(storeUser(user));
    }
  };
}

export function getOpenApplications(organisationId) {
  return async (dispatch, getState) => {
    const response = await dispatch(getOpenApplicationCount(organisationId));
    if (!response.error) {
      const user = u(
        {
          openRegistrationRequests: response.requstCount
        },
        getState().auth.user
      );
      dispatch(storeUser(user));
    }
  };
}

/**
 * Waits for the user to appear in KVH then triggers organization switch.
 *
 * @param id
 * @param stopThisMadness Won't redirect to dashboard
 * @returns {function()}
 */
export function waitForUserAndChangeOrg(userId, id, stopThisMadness, email) {
  return async (dispatch, getState) => {
    const response = await dispatch(
      requestWaitForUserAndChangeOrg(userId, id, email)
    );
    if (!response.error) {
      const user = u({ activeOrganizationId: id }, getState().auth.user);
      dispatch(storeUser(user));
      !stopThisMadness && dispatch(push('/dashboard'));
    }
  };
}

export const setSessionExpired = isSessionExpired => dispatch =>
  dispatch(ca(SET_SESSION_EXPIRED)(isSessionExpired));

/**
 * Updates user to redux store and local storage.
 */
export const updateUserToStore = user => async dispatch => {
  dispatch(ca(UPDATE_USER_TO_STORE_REQUEST)(user));
  dispatch(storeUser(user));
};

export function updateUserPassword(userData) {
  const body = {
    oldPassword: userData.oldPassword,
    newPassword: userData.newPassword,
    rePassword: userData.rePassword
  };
  return {
    [CALL_API]: {
      method: 'put',
      endpoint: '/api/organisationservice/changepassword',
      types: [
        USER_PASSWORD_UPDATE_REQUEST,
        USER_PASSWORD_UPDATE_SUCCESS,
        USER_PASSWORD_UPDATE_ERROR
      ],
      data: body,
      onError: payload => payload
    }
  };
}

export function updateOwnUserData(userId, userData) {
  return {
    [CALL_API]: {
      method: 'put',
      endpoint: `/api/organisationservice/users/own/${userId}`,
      types: [
        USER_DATA_UPDATE_REQUEST,
        USER_DATA_UPDATE_SUCCESS,
        USER_DATA_UPDATE_ERROR
      ],
      data: userData
    }
  };
}

export function addServiceToOwnOrganisation(serviceId) {
  return {
    [CALL_API]: {
      method: 'post',
      endpoint: `/api/organisationservice/users/own/organisation/service?${getURLQueryString(
        { service: serviceId }
      )}`,
      types: [
        USER_ORGANISATION_UPDATE_REQUEST,
        USER_ORGANISATION_UPDATE_SUCCESS,
        USER_ORGANISATION_UPDATE_ERROR
      ]
    }
  };
}

function getOwnUserData(userId) {
  return {
    [CALL_API]: {
      method: 'get',
      endpoint: `/api/organisationservice/users/own/${userId}`,
      types: [GET_USER_DATA_REQUEST, GET_USER_DATA_SUCCESS, GET_USER_DATA_ERROR]
    }
  };
}

//OLD
export function changePassword(userData) {
  return {
    [CALL_API]: {
      method: 'put',
      endpoint: '/api/auth/changepassword',
      types: [
        USER_DATA_UPDATE_REQUEST,
        USER_DATA_UPDATE_SUCCESS,
        USER_DATA_UPDATE_ERROR
      ],
      data: userData
    }
  };
}

const getOpenApplicationCount = guidId => {
  return {
    [CALL_API]: {
      method: 'get',
      endpoint: `organisationservice/requestinvitationcount/${guidId}`,
      types: [
        REGISTRATION_REQUEST_COUNT_REQUEST,
        REGISTRATION_REQUEST_COUNT_SUCCESS,
        REGISTRATION_REQUEST_COUNT_ERROR
      ]
    }
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SIGN_IN_START]: state => state,
  [USER_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [SIGN_IN_SUCCESS]: (state, { payload }) => ({
    ...state,
    isAuthenticated: true,
    isSessionExpired: false,
    isFetching: false,
    user: payload
  }),
  [SIGN_IN_FAILURE]: (state, { payload }) => ({
    ...state,
    isAuthenticated: false,
    isSessionExpired: false,
    isFetching: false,
    error: payload
  }),
  [SIGN_OUT_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [SIGN_OUT_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [CHANGE_ORG_REQUEST]: state => state,
  [CHANGE_ORG_SUCCESS]: (state, { payload }) => ({
    ...state,
    user: payload
  }),
  [UPDATE_USER_TO_STORE_REQUEST]: (state, { payload }) => ({
    ...state,
    user: payload
  }),
  [USER_FROM_STORAGE_SUCCESS]: (state, { payload }) => {
    // ensure that token hasn't expired
    const isAuthenticated = Date.now() < payload.exp * 1000;
    return u(
      {
        user: payload,
        isAuthenticated,
        isSessionExpired: false
      },
      state
    );
  },
  [USER_FROM_STORAGE_FAILURE]: (state, { payload }) =>
    u(
      {
        isAuthenticated: false,
        isSessionExpired: false,
        error: payload
      },
      state
    ),
  [WEAK_SIGN_IN_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [WEAK_SIGN_IN_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isAuthenticated: true,
      isSessionExpired: false,
      isFetching: false,
      user: payload
    };
  },
  [WEAK_SIGN_IN_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [TOGGLE_USER_DROPDOWN]: (state, { payload }) => ({
    ...state,
    isUserDropdownOpen:
      payload && payload.forceClose ? false : !state.isUserDropdownOpen
  }),
  [TOGGLE_NOTIFICATIONS_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      isNotificationsDropdownOpen:
        payload && payload.forceClose
          ? false
          : !state.isNotificationsDropdownOpen
    };
  },
  [SET_SESSION_EXPIRED]: (state, { payload }) => {
    const isSessionExpired = payload;
    const isAuthenticated = !isSessionExpired && state.isAuthenticated;
    return {
      ...state,
      isSessionExpired: isSessionExpired,
      isAuthenticated: isAuthenticated,
      user: isSessionExpired ? null : state.user
    };
  },
  [PWD_RESET_REQ_REQUEST]: state => ({
    ...state,
    isRequestingPwdChange: true
  }),
  [PWD_RESET_REQ_SUCCESS]: state => ({
    ...state,
    isRequestingPwdChange: false,
    pwdChange: 'ok'
  }),
  [PWD_RESET_REQ_FAILURE]: state => {
    return {
      ...state,
      isRequestingPwdChange: false,
      pwdChange: 'error'
    };
  },
  [USER_DATA_UPDATE_REQUEST]: state => ({
    ...state,
    isSubmitting: true
  }),
  [USER_DATA_UPDATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    isSubmitting: false
  }),
  [USER_DATA_UPDATE_ERROR]: state => ({
    ...state,
    isSubmitting: false
  }),
  [USER_ORGANISATION_UPDATE_REQUEST]: state => ({
    ...state,
    isSubmitting: true
  }),
  [USER_ORGANISATION_UPDATE_SUCCESS]: state => ({
    ...state,
    isSubmitting: false
  }),
  [USER_ORGANISATION_UPDATE_ERROR]: state => ({
    ...state,
    isSubmitting: false
  }),
  [GET_USER_DATA_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [GET_USER_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    user: {
      ...state.user,
      email: payload.email,
      phoneNumber: payload.phoneNumber
    }
  }),
  [GET_USER_DATA_ERROR]: state => ({
    ...state,
    isFetching: false
  }),
  [USER_PASSWORD_UPDATE_REQUEST]: state => ({
    ...state,
    isSubmitting: true
  }),
  [USER_PASSWORD_UPDATE_SUCCESS]: state => ({
    ...state,
    isSubmitting: false
  }),
  [USER_PASSWORD_UPDATE_ERROR]: state => ({
    ...state,
    isSubmitting: false
  }),
  [REGISTRATION_REQUEST_COUNT_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [REGISTRATION_REQUEST_COUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    isfetching: false,
    user: {
      ...state.user,
      openRegistrationRequests: payload.requestCount
    }
  }),
  [REGISTRATION_REQUEST_COUNT_ERROR]: state => ({
    ...state,
    isFetching: false
  })
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = {
  isAuthenticated: false,
  isFetching: false,
  isSubmitting: false,
  isSessionExpired: false,
  user: null,
  isUserDropdownOpen: false,
  isNotificationsDropdownOpen: false,
  error: null
};
export default createReducer(ACTION_HANDLERS, initialState);
