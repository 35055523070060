import React from 'react';
import PropTypes from 'prop-types';

export const StepPanel = ({ currentStep, stepId, children }) => (
  <div
    key={`panel-${stepId}`}
    id={`panel-${stepId}`}
    role="tabpanel"
    tabIndex={0}
    aria-labelledby={`tab-${stepId}`}
    hidden={currentStep !== stepId} // Can we use this, or should we render only the current panel?
  >
    {children}
  </div>
);

StepPanel.propTypes = {
  stepId: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
