import ForgottenPasswordView from '../components/ForgottenPasswordView';
import connectForm from 'utils/connectForm';
import validate from '../validate';
import { requestPasswordReset } from '../../../modules/auth';
import { actions as notificationActions } from '../../../components/Notifs';

export default connectForm(ForgottenPasswordView, {
  form: 'requestNewPwdForm',
  validate,
  mapStateToProps: state => ({}),
  mapActionCreators: {
    requestPasswordReset,
    notifSend: notificationActions.notifSend
  }
});
