import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fi from 'react-intl/locale-data/fi';
import sv from 'react-intl/locale-data/sv';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import Modal from 'react-modal';
import createStore from './store/createStore';
import RootProvider from './containers/RootProvider';
import AppContainer from './containers/AppContainer';
import { initializeUserSession } from './modules/auth';
import { requestFeatures } from './modules/features';
import config from 'config';
import './styles/fonts/fonts.scss';

// ========================================================
// Locale Settings
// ========================================================
addLocaleData([...en, ...fi, ...sv]);
const defaultLang = config.i18n.defaultLocale;
const browserLang = defaultLang; // getBrowserLang(defaultLang);

// Chat is disabled
const socket = null; // __DEV__ ? null : io(config.chatServer, {transports: ['websocket']});

// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the key 'router' in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.
const store = createStore(window.__INITIAL_STATE__, browserHistory, socket);
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: state => state.router
});

// ========================================================
// Render Setup
// ========================================================
const MOUNT_ELEMENT = document.getElementById('root');
Modal.setAppElement(MOUNT_ELEMENT);

/**
 * Render the application. User is fetched from IndexedDB
 * before by dispatching initializeUserSession Redux action.
 *
 * @param routerKey
 */
function render(routerKey = null) {
  store.dispatch(initializeUserSession()).then(() => {
    store.dispatch(requestFeatures());
    const routes = require('./routes/index').default(
      store,
      AppContainer,
      browserLang
    );
    ReactDOM.render(
      <RootProvider {...{ store, history, routes, routerKey, config }} />,
      MOUNT_ELEMENT
    );
  }, store);
}

// ========================================================
// Developer and DEBUG stuff
// ========================================================
// Enable HMR
// This code is excluded from server bundles
if (__DEV__ && module.hot) {
  module.hot.accept('./routes/index', () => {
    const routerKey = Math.random();
    const routes = require('./routes/index').default(
      store,
      AppContainer,
      browserLang
    );
    ReactDOM.render(
      <RootProvider {...{ store, history, routes, routerKey, config }} />,
      MOUNT_ELEMENT
    );
  });
}

// Use Redux DevTools chrome extension
if (__DEV__ || __INT__) {
  if (window.devToolsExtension) {
    window.devToolsExtension.open();
  }
}

// ========================================================
// Render it
// ========================================================
// Check if Intl polyfill required
if (!window.Intl) {
  require.ensure([], () => {
    require('intl');
    render();
  });
} else {
  // Polyfill wasn't needed, carry on
  render();
}
