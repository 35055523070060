import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { browserHistory, Link } from 'react-router';
import cx from 'classnames';
import TranslatableHelmet from '../../../components/TranslatableHelmet';
import Loader from '../../../components/Loader';
import { showEnvironmentsFetchFail } from './Notifications';
import {
  Checkbox,
  InfoBox,
  InfoPanel,
  PageHeader,
  Table,
  SimpleLabel,
  sort,
  search,
  Button
} from 'sema-ui-components';
import styles from './EnvironmentsView.scss';

export const filterFuns = {
  waitingForApproval: e =>
    e.status.name === 'draft' || e.status.name === 'readyForApproval',
  waitingForPublishing: e =>
    (e.status.name === 'approved' &&
      e.publishingStatus.name === 'unpublished') ||
    e.publishingStatus.name === 'released',
  published: e => e.publishingStatus.name === 'published',
  archived: e => e.publishingStatus.name === 'archived'
};

export const insertAggregatedStatus = e => {
  const doesFunMatch = (fk, e) => {
    const f = filterFuns[fk];
    return f(e);
  };
  const funKeys = Object.keys(filterFuns);
  const k = funKeys.find(k => doesFunMatch(k, e));
  e.aggregatedStatus = k;
  return e;
};

class EnvironmentsView extends React.Component {
  static propTypes = {
    children: PropTypes.object,
    currentProvider: PropTypes.object,
    environments: PropTypes.array,
    environmentsListDirty: PropTypes.bool,
    environmentStatusFilters: PropTypes.object,
    fetchEnvironmentsByProviderId: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    isInfoVisible: PropTypes.bool,
    notifSend: PropTypes.func.isRequired,
    params: PropTypes.any.isRequired,
    routes: PropTypes.any.isRequired,
    selectEnvironment: PropTypes.func,
    toggleEnvironmentInfo: PropTypes.func.isRequired,
    toggleEnvironmentStatusFilter: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      query: {},
      sortingColumns: {}
    };
  }

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  getLang = () => this.props.params.lang;
  i18n = local => this.context.intl.formatMessage({ id: local });

  updateData() {
    this.props
      .fetchEnvironmentsByProviderId(this.props.currentProvider.id)
      .then(result => {
        if (result && result.error) {
          showEnvironmentsFetchFail(this.props.notifSend, this.i18n);
        }
      });
  }

  UNSAFE_componentWillMount() {
    this.props.currentProvider
      ? this.props.environmentsListDirty && this.updateData()
      : this.backToServiceList();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    nextProps.environmentsListDirty && this.updateData();
  }

  sortable = sort.sort({
    getSortingColumns: () => this.state.sortingColumns || {},
    onSort: selectedColumn => {
      this.setState({
        sortingColumns: sort.byColumn({
          sortingColumns: this.state.sortingColumns,
          selectedColumn
        })
      });
    }
  });

  selectEnv = environment => {
    this.props.selectEnvironment(environment);
  };

  statusIconLabel = {
    waitingForPublishing: {
      color: 'kanerva',
      abbreviation: this.i18n('identification_admin.environment.wfp')
    },
    waitingForApproval: {
      color: 'taivas',
      abbreviation: this.i18n('identification_admin.environment.wfa')
    },
    published: {
      color: 'havumetsa',
      abbreviation: this.i18n('identification_admin.environment.p')
    },
    archived: {
      color: 'lakka',
      abbreviation: this.i18n('identification_admin.environment.a')
    }
  };

  columns = [
    {
      header: {
        label: (
          <span>
            <FormattedMessage id="identification_admin.environment.column_state" />
          </span>
        )
      },
      cell: {
        property: 'aggregatedStatus',
        transforms: [
          value => {
            const label = (
              <SimpleLabel
                labelString={this.statusIconLabel[value].abbreviation}
                color={this.statusIconLabel[value].color}
              />
            );
            return {
              children: label
            };
          }
        ],
        formatters: [search.highlightCell]
      }
    },
    {
      header: {
        label: (
          <span>
            <FormattedMessage id="identification_admin.environment.column_name" />
          </span>
        ),
        transforms: [this.sortable]
      },
      cell: {
        property: `displayName${this.getLang().toUpperCase()}`,
        formatters: [search.highlightCell],
        transforms: [
          (value, { rowData }) => ({
            children: (
              <Link
                to={`/${
                  this.context.intl.locale
                }/tunnistus/${encodeURIComponent(
                  this.props.currentProvider.nameFI
                )}/${encodeURIComponent(rowData.displayNameFI)}`}
                onClick={() => this.selectEnv(rowData)}
              >
                {value}
              </Link>
            )
          })
        ]
      }
    },
    {
      header: {
        label: (
          <span>
            <FormattedMessage id="identification_admin.environment.column_target_env" />
          </span>
        ),
        transforms: [this.sortable]
      },
      cell: {
        property: 'targetEnvironment',
        formatters: [search.highlightCell],
        transforms: [
          value => ({
            children: this.i18n(
              `identification_admin.environment.column_target_env_${value.name}`
            )
          })
        ]
      }
    },
    {
      header: {
        label: (
          <span>
            <FormattedMessage id="identification_admin.environment.column_entity_id" />
          </span>
        ),
        transforms: [this.sortable]
      },
      cell: {
        property: 'entityID',
        formatters: [search.highlightCell]
      }
    },
    {
      header: {
        label: (
          <span>
            <FormattedMessage id="identification_admin.environment.column_updated" />
          </span>
        ),
        transforms: [this.sortable]
      },
      cell: {
        property: 'lastUpdated',
        transforms: [
          value => ({
            children: (
              <time key={value.toString}>
                <div>
                  <FormattedDate value={value} />
                </div>
                <div>
                  (
                  <FormattedTime value={value} />)
                </div>
              </time>
            )
          })
        ]
      }
    }
  ];

  handleSearch = query => this.setState({ query });

  toggleFilter = k => {
    this.props.toggleEnvironmentStatusFilter(k);
  };

  statusCheckboxes = {
    published: {
      name: 'published',
      local: (
        <FormattedMessage id="identification_admin.environment.details_status_published" />
      ),
      getCheckbox: (label, checked, onChange) => (
        <Checkbox
          small
          inline
          havumetsa
          checked={checked}
          label={label}
          onChange={onChange}
          key="published_checkbox"
        />
      )
    },
    waitingForPublishing: {
      name: 'waitingForPublishing',
      local: (
        <FormattedMessage id="identification_admin.environment.details_status_waitingForPublishing" />
      ),
      getCheckbox: (label, checked, onChange) => (
        <Checkbox
          small
          inline
          kanerva
          checked={checked}
          label={label}
          onChange={onChange}
          key="waitingForPublishing_checkbox"
        />
      )
    },
    waitingForApproval: {
      name: 'waitingForApproval',
      local: (
        <FormattedMessage id="identification_admin.environment.details_status_waitingForApproval" />
      ),
      getCheckbox: (label, checked, onChange) => (
        <Checkbox
          small
          inline
          taivas
          checked={checked}
          label={label}
          onChange={onChange}
          key="waitingForApproval_checkbox"
        />
      )
    },
    archived: {
      name: 'archived',
      local: (
        <FormattedMessage id="identification_admin.environment.details_status_archived" />
      ),
      getCheckbox: (label, checked, onChange) => (
        <Checkbox
          small
          inline
          lakka
          checked={checked}
          label={label}
          onChange={onChange}
          key="archived_checkbox"
        />
      )
    }
  };

  getTopButtonDiv = () => (
    <div className={styles.topButtonDiv}>
      <Button
        medium
        secondary
        onClick={this.modifyService}
        className={styles.topButton}
      >
        <FormattedMessage id="identification_admin.environment.edit_service_button" />
      </Button>
    </div>
  );

  backToServiceList = () => {
    browserHistory.push(`/${this.context.intl.locale}/tunnistus`);
  };

  modifyService = () => {
    browserHistory.push(`/${this.context.intl.locale}/tunnistus/lisaa-palvelu`);
  };

  addNewEnvironment = () => {
    browserHistory.push(
      `/${this.context.intl.locale}/tunnistus/${encodeURIComponent(
        this.props.currentProvider.nameFI
      )}/rekisteroi-ymparisto`
    );
  };

  getAddButtonDiv = () => (
    <div className={styles.addButtonDiv}>
      <Button medium onClick={this.addNewEnvironment}>
        <FormattedMessage id="identification_admin.environment.add_new_environment_button" />
      </Button>
    </div>
  );

  getMiddleAddButtonDiv = () => (
    <div className={styles.middleButtonDiv}>
      <InfoPanel
        className={styles.infoPanel}
        markerType="light"
        marker="i"
        text={
          <div>
            <h3>
              <FormattedMessage id="identification_admin.add_new_env_info_panel_header" />
            </h3>
            <FormattedMessage id="identification_admin.add_new_env_info_panel_text" />
          </div>
        }
      />
      <Button className={styles.middleButton} onClick={this.addNewEnvironment}>
        <FormattedMessage id="identification_admin.environment.add_new_environment_button" />
      </Button>
    </div>
  );

  filterFuns = {
    waitingForApproval: e =>
      (e.status.name === 'draft' && e.publishingStatus.name !== 'archived') ||
      (e.status.name === 'readyForApproval' &&
        e.publishingStatus.name !== 'archived'),
    waitingForPublishing: e =>
      (e.status.name === 'approved' &&
        e.publishingStatus.name === 'unpublished') ||
      e.publishingStatus.name === 'released',
    published: e => e.publishingStatus.name === 'published',
    archived: e => e.publishingStatus.name === 'archived'
  };

  insertAggregatedStatus = e => {
    const doesFunMatch = (fk, e) => {
      const f = this.filterFuns[fk];
      return f(e);
    };
    const funKeys = Object.keys(this.filterFuns);
    const k = funKeys.find(k => doesFunMatch(k, e));
    return (e.aggregatedStatus = k); // FIX: No need to return anything
  };

  filterEnvironments = environments => {
    const statusesToShow = Object.keys(
      this.props.environmentStatusFilters
    ).filter(k => this.props.environmentStatusFilters[k]);

    const filtersToShow = statusesToShow.map(k => this.filterFuns[k]);
    return environments.filter(e => filtersToShow.some(f => f(e)));
  };

  renderStateCheckboxes = () => (
    <div className={cx(styles.filterCheckboxes)}>
      {Object.values(this.statusCheckboxes).map(state =>
        state.getCheckbox(
          this.i18n(state.local),
          this.props.environmentStatusFilters[state.name],
          () => this.toggleFilter(state.name)
        )
      )}
      <InfoBox
        className={styles.infoBox}
        isVisible={this.props.isInfoVisible}
        changeVisibility={this.props.toggleEnvironmentInfo}
        down
        right
      >
        <h6 className={styles.infoBoxHeader}>
          <FormattedMessage id="identification_admin.environment.state_info_title" />
        </h6>
        <p>
          <strong>
            <FormattedMessage id="identification_admin.environment.state_info_contenttitle1" />
          </strong>{' '}
          <FormattedMessage id="identification_admin.environment.state_info_content1" />
        </p>
        <p>
          <strong>
            <FormattedMessage id="identification_admin.environment.state_info_contenttitle2" />
          </strong>{' '}
          <FormattedMessage id="identification_admin.environment.state_info_content2" />
        </p>
        <p>
          <strong>
            <FormattedMessage id="identification_admin.environment.state_info_contenttitle3" />
          </strong>{' '}
          <FormattedMessage id="identification_admin.environment.state_info_content3" />
        </p>
        <p>
          <strong>
            <FormattedMessage id="identification_admin.environment.state_info_contenttitle4" />
          </strong>{' '}
          <FormattedMessage id="identification_admin.environment.state_info_content4" />
        </p>
      </InfoBox>
    </div>
  );

  render() {
    const {
      children,
      currentProvider,
      environments,
      isFetching,
      params,
      routes
    } = this.props;

    if (environments.length > 0) {
      environments.forEach(e => this.insertAggregatedStatus(e));
    }

    return (
      currentProvider &&
      (children || (
        <section className="row">
          <div className={cx('col-xl-16', styles.topDiv)}>
            <TranslatableHelmet
              locale={params.lang}
              subTitle={this.i18n('identification_admin.environment.title')}
            />
            <PageHeader
              title={
                <span>
                  {this.i18n('identification_admin.environment.service_prefix')}
                  {currentProvider[`name${params.lang.toUpperCase()}`]}
                </span>
              }
              description={
                <span>
                  {currentProvider[`description${params.lang.toUpperCase()}`]}
                </span>
              }
              routes={routes}
              params={params}
            />
          </div>
          {this.getTopButtonDiv()}
          <div className="col-lg-24">
            {!isFetching ? (
              environments.length > 0 ? (
                <div className={styles.tableDiv}>
                  <Table
                    data={environments}
                    sortingColumns={this.state.sortingColumns}
                    columns={this.columns}
                    rowKey="entityID"
                    noResultsMessage={this.i18n('tables_noresult')}
                    searchParameters={{
                      resultsCountMessage: this.i18n('tables_results'),
                      searchPlaceholder: this.i18n('tables_search'),
                      onSearch: this.handleSearch,
                      query: this.state.query,
                      filters: {
                        children: this.renderStateCheckboxes(),
                        filter: () => this.filterEnvironments(environments)
                      }
                    }}
                  />{' '}
                  {this.getAddButtonDiv()}
                </div>
              ) : (
                this.getMiddleAddButtonDiv()
              )
            ) : (
              <Loader />
            )}
          </div>
        </section>
      ))
    );
  }
}

export default EnvironmentsView;
