import React from 'react';
import PropTypes from 'prop-types';
import styles from './content.scss';
import { Header } from '../';

export const Content = ({ children }) => {
  return (
    <div className={styles.content}>
      <Header />
      {children}
    </div>
  );
};

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
