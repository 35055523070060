import validator from 'validator';

const validate = values => {
  const errors = {};
  if (
    !values.nameFI ||
    !validator.isLength(values.nameFI, { min: 1, max: 128 })
  ) {
    errors.nameFI = 'identification_admin.add_service.nameFi_mandatory';
  }

  if (
    !values.descriptionFI ||
    !validator.isLength(values.descriptionFI, { min: 1, max: 150 })
  ) {
    errors.descriptionFI = 'identification_admin.add_service.descFi_mandatory';
  }

  return errors;
};

export default validate;
