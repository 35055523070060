import * as yup from 'yup';

export const formTitle = 'intermediary.serviceInfo.formTitle';

export const serviceSchema = yup.object({
  serviceId: yup // Is this needed?
    .string()
    .nullable()
    .default(null),
  serviceName: yup
    .string()
    .min(3, 'intermediary.validation.min3')
    .max(300, 'intermediary.validation.max300')
    .required('intermediary.validation.nameIsRequired')
    .default('')
    .label(formTitle),
  purposeOfUse: yup
    .string()
    .min(3, 'intermediary.validation.min3')
    .max(300, 'intermediary.validation.max300')
    .required('intermediary.validation.reasoningInformationRequired')
    .default('')
    .label(formTitle),
  functionalDescriptionAttachments: yup
    .mixed()
    .default(null)
    .label(formTitle),
  lawSection: yup.object({
    fivePointOne: yup
      .boolean()
      .default(false)
      .label(formTitle),
    fivePointTwo: yup
      .boolean()
      .default(false)
      .label(formTitle),
    fivePointThree: yup
      .boolean()
      .default(false)
      .label(formTitle)
  })
});

export const schema = yup.object({
  serviceData: yup.object({
    services: yup
      .array()
      .of(serviceSchema)
      .default([serviceSchema.cast({})])
      .label(formTitle)
  })
});
