import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Paragraph,
  Text,
  Modal,
  ModalContent,
  ModalTitle,
  ModalFooter
} from 'suomifi-ui-components';

import { FormattedMessage } from 'react-intl';

export const ConfirmModal = ({
  initialValues,
  handleSubmit,
  showConfirmModal,
  setShowConfirmModal,
  isSubmitting
}) => {
  return (
    <Modal
      appElementId="root"
      visible={showConfirmModal}
      variant="default"
      scrollable={false}
      onEscKeyDown={() => setShowConfirmModal(false)}
    >
      <ModalContent>
        <ModalTitle>
          <FormattedMessage id={'intermediary.approve.confirmSending'} />
        </ModalTitle>
        <Paragraph marginBottomSpacing="l">
          <Text>
            <FormattedMessage id={'intermediary.approve.confirm'} />{' '}
            {initialValues?.intermediaryOrganizationName}
            <FormattedMessage id={'intermediary.approve.confirmSuffix'} />
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <FormattedMessage id="intermediary.approve.linkExpiry" />
          </Text>
        </Paragraph>
      </ModalContent>
      <ModalFooter>
        <Button onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? (
            <FormattedMessage id={'intermediary.approve.sending'} />
          ) : (
            <FormattedMessage id={'intermediary.approve.confirm'} />
          )}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowConfirmModal(false)}
          disabled={isSubmitting}
        >
          <FormattedMessage id={'intermediary.approve.cancel'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
ConfirmModal.propTypes = {
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.bool.isRequired,
  setShowConfirmModal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};
