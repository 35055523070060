import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledStepItemButton = styled.button`
  text-align: start;
`;

export const StepItem = ({ children, ...rest }) => (
  <StyledStepItemButton {...rest}>{children}</StyledStepItemButton>
);
StepItem.propTypes = {
  children: PropTypes.node
};
