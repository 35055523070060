import React from 'react';
import { Text } from 'suomifi-ui-components';
import styles from './styles.scss';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

export const ReadOnlyFields = () => {
  const formik = useFormikContext();

  return (
    <div className={styles.preview}>
      <div>
        <Text variant="bold">
          <FormattedMessage id="intermediary.preview.organisationName" />
        </Text>
        <Text>
          {formik.values.formData.customerOrganization.organizationName || (
            <FormattedMessage id="intermediary.preview.notKnown" />
          )}
        </Text>
      </div>
      {formik?.values?.formData?.customerOrganization?.organizationUnit ? (
        <div>
          <Text variant="bold">
            <FormattedMessage id="intermediary.preview.organisationUnit" />
          </Text>
          <Text>
            {formik.values.formData.customerOrganization.organizationUnit}
          </Text>
        </div>
      ) : null}
      <div>
        <Text variant="bold">
          <FormattedMessage id="intermediary.preview.yId" />
        </Text>
        <Text>
          {formik.values.formData.customerOrganization.businessId || (
            <FormattedMessage id="intermediary.preview.notKnown" />
          )}
        </Text>
      </div>
      <div>
        <Text variant="bold">
          <FormattedMessage id="intermediary.organisationInfo.address" />
        </Text>
        <Text>
          {formik.values.formData.customerOrganization.address || (
            <FormattedMessage id="intermediary.preview.notKnown" />
          )}
        </Text>
      </div>
      <div>
        <Text variant="bold">
          <FormattedMessage id="intermediary.organisationInfo.postalCode" />
        </Text>
        <Text>
          {formik.values.formData.customerOrganization.postalCode || (
            <FormattedMessage id="intermediary.preview.notKnown" />
          )}
        </Text>
      </div>
      <div>
        <Text variant="bold">
          <FormattedMessage id="intermediary.organisationInfo.postOffice" />
        </Text>
        <Text>
          {formik.values.formData.customerOrganization.postOffice || (
            <FormattedMessage id="intermediary.preview.notKnown" />
          )}
        </Text>
      </div>
    </div>
  );
};
