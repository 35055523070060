import { Component, Children } from 'react';
import PropTypes from 'prop-types';

export default class ConfigProvider extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    children: PropTypes.object
  };

  static childContextTypes = {
    config: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.config = props.config;
  }

  getChildContext() {
    return {
      config: this.config
    };
  }

  render() {
    const { children } = this.props;
    return Children.only(children);
  }
}
