import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { browserHistory } from 'react-router';
import { renderTextField } from 'utils/fieldWrappers';
import { Button } from 'sema-ui-components';
import { transFun } from '../../../../utils/index';

export class ForgottenPasswordView extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    notifSend: PropTypes.func,
    submitting: PropTypes.bool,
    requestPasswordReset: PropTypes.func,
    isRequestingPwdChange: PropTypes.bool,
    pwdChange: PropTypes.string
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  constructor() {
    super();
    this.state = { submitting: false };
  }

  formatMessage = id => this.context.intl.formatMessage({ id });

  doHandleSubmit = values => {
    const { intl } = this.context;
    const locale = intl.locale;
    const i18n = transFun(intl);

    const msg = {
      title: i18n('password_change_requested'),
      description: i18n('sign_in.change_password.error'),
      type: 'fail',
      dismissAfter: 5000
    };

    this.setState({ submitting: true });
    this.props
      .requestPasswordReset(values.email)
      .then(data => {
        if (data && data.error) {
          this.setState({ submitting: false });
          this.props.notifSend(msg);
        } else {
          this.setState({ submitting: false });
          browserHistory.push(
            `/${locale}/kirjautuminen/salasana-vaihdettu?title=password_change_requested&message=password_change_requested_desc`
          );
        }
      })
      .catch(err => {
        console.error(err);
        this.props.notifSend(msg);
      });
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <section>
        <h1>
          <FormattedMessage id="forgotten_password_title" />
        </h1>
        <p>
          <FormattedMessage id="forgotten_password_description" />
        </p>
        <div>
          <form onSubmit={handleSubmit(this.doHandleSubmit)}>
            <div className="form-group">
              <Field
                name="email"
                component={renderTextField}
                size="w280"
                disabled={this.state.submitting}
                placeholder="Sähköposti"
              />
            </div>
            <div className="form-group">
              <div>
                <div>
                  <Button small type="submit" disabled={this.state.submitting}>
                    <FormattedMessage id="userprofile.forgotten_password_change_password" />
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default ForgottenPasswordView;
