import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './styles.scss';
import {
  Button,
  Heading,
  Expander,
  ExpanderTitleButton,
  ExpanderContent,
  Checkbox,
  CheckboxGroup,
  InlineAlert,
  Text,
  Tooltip,
  IconRemove
} from 'suomifi-ui-components';
import { useFormikContext } from 'formik';
import { IntermediaryTextField, IntermediaryTextarea } from '../../components';
import { Attachments } from '../../components/Attachments';

export const formTitle = 'intermediary.serviceInfo.formTitle';

export const legals = [
  {
    title: '5.1 §',
    value: 'fivePointOne',
    info: 'forms.general.lawSection.fivePointOne_info'
  },
  {
    title: '5.2 §',
    value: 'fivePointTwo',
    info: 'forms.general.lawSection.fivePointTwo_info'
  },
  {
    title: '5.3 §',
    value: 'fivePointThree',
    info: 'forms.general.lawSection.fivePointThree_info'
  }
];

export const Services = ({
  remove,
  i18n,
  sendAttachment,
  deleteAttachment
}) => {
  const formik = useFormikContext();

  return formik?.values?.formData?.serviceData?.services?.map(
    (_, serviceIndex) => (
      <Expander key={`service-${serviceIndex}`} defaultOpen>
        <ExpanderTitleButton>
          <FormattedMessage id="intermediary.serviceInfo.service" />{' '}
          {serviceIndex + 1}
        </ExpanderTitleButton>
        <ExpanderContent>
          <div className={styles.expanderContentHeader}>
            <Heading variant="h3" className="borderless">
              <FormattedMessage id="intermediary.serviceInfo.formTitle" />
            </Heading>
            {serviceIndex > 0 ? (
              <Button
                variant="secondaryNoBorder"
                icon={<IconRemove />}
                onClick={() => remove(serviceIndex)}
              >
                <FormattedMessage id="general.form.delete" />
              </Button>
            ) : null}
          </div>

          <IntermediaryTextField
            name={`formData.serviceData.services.${serviceIndex}.serviceName`}
            label="general.form.name"
            width="66.66%"
          />

          <IntermediaryTextarea
            name={`formData.serviceData.services.${serviceIndex}.purposeOfUse`}
            label="intermediary.serviceInfo.purposeOfUse"
            hintText={i18n('intermediary.serviceInfo.purposeOfUseHint')}
            rows="4"
            tooltip={
              <>
                <Heading variant="h5" as="h3">
                  {i18n('intermediary.serviceInfo.examples.heading')}
                </Heading>
                <Text variant="bold">
                  {i18n('intermediary.serviceInfo.examples.title')}
                </Text>
                <ul>
                  <li>{i18n('intermediary.serviceInfo.examples.first')}</li>
                  <li>{i18n('intermediary.serviceInfo.examples.second')}</li>
                </ul>
              </>
            }
          />
          <Attachments
            i18n={i18n}
            value={
              formik?.values?.formData?.serviceData?.services[serviceIndex]
                ?.functionalDescriptionAttachments || []
            }
            onChange={value =>
              formik.setFieldValue(
                `formData.serviceData.services[${serviceIndex}].functionalDescriptionAttachments`,
                value
              )
            }
            sendAttachment={sendAttachment}
            deleteAttachment={deleteAttachment}
          />
          <CheckboxGroup
            labelText={i18n('intermediary.serviceInfo.legalRightLabel')}
            groupHintText={i18n('intermediary.serviceInfo.legalRightHint')}
          >
            {legals.map(legal => (
              <Checkbox
                key={`legal-${legal.value}`}
                name={`formData.serviceData.services[${serviceIndex}].lawSection.${legal.value}`}
                checked={
                  formik?.values?.formData?.serviceData.services[serviceIndex]
                    .lawSection[legal.value]
                }
                onClick={({ checkboxState }) =>
                  formik.setFieldValue(
                    `formData.serviceData.services[${serviceIndex}].lawSection.${legal.value}`,
                    checkboxState
                  )
                }
              >
                {legal.title}
                <Tooltip
                  ariaToggleButtonLabelText={i18n('general.show_more')}
                  ariaCloseButtonLabelText={i18n('general.go_back')}
                >
                  {i18n(legal.info)}
                </Tooltip>
              </Checkbox>
            ))}
          </CheckboxGroup>
          <div className="my-4">
            <InlineAlert>
              <FormattedMessage id="intermediary.serviceInfo.legalRight" />
            </InlineAlert>
          </div>
        </ExpanderContent>
      </Expander>
    )
  );
};

Services.contextTypes = {
  intl: PropTypes.object.isRequired
};
