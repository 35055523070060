import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Heading,
  Expander,
  ExpanderTitleButton,
  ExpanderContent
} from 'suomifi-ui-components';
import { legals } from '../../../partials/ServiceInfoMessages/components/Services';
import List from '../components/List';
import { DataProtectionPreview } from './DataProtectionPreview';

function getLegals(service, i18n) {
  const values = legals.reduce((acc, legal) => {
    if (service.lawSection[legal.value]) {
      acc.push(legal.title);
    }
    return acc;
  }, []);

  return values.length > 0
    ? values.join(', ')
    : i18n('intermediary.misc.no_legal_basis');
}

function getRecipientIds(service, i18n) {
  const recipientIds = [];

  if (service['recipientIdSsn']) {
    recipientIds.push(
      i18n('forms.viestit_deployment.recipientIdSection.idSsn')
    );
  }

  if (service['recipientIdCompany']) {
    recipientIds.push(
      i18n('forms.viestit_deployment.recipientIdSection.idCompany')
    );
  }

  return recipientIds.join(', ');
}

function getConnectionMethods(service, i18n) {
  const i18nBasePath =
    'forms.viestit_registration.page_messagesInfo.connectionMethodSection.';

  // Map service keys with i18n keys
  const map = {
    connectionMethodProduct: 'methodProduct',
    connectionMethodWeb: 'methodWeb',
    connectionMethodIpost: 'methodIpost',
    connectionMethodEmail: 'methodEmail',
    connectionMethodLater: 'methodLater'
  };

  const selectedMethods = Object.keys(map).reduce((acc, key) => {
    if (service[key]) {
      acc.push(i18n(`${i18nBasePath}${map[key]}`));
    }
    return acc;
  }, []);

  return selectedMethods.join(', ');
}

function showSendReadConfirmationAck(service) {
  return ['connectionMethodProduct', 'connectionMethodWeb'].some(
    name => service[name]
  );
}

function showNewMsgTarget(service) {
  return [
    'connectionMethodProduct',
    'connectionMethodWeb',
    'connectionMethodIpost',
    'connectionMethodEmail'
  ].some(name => service[name]);
}

const NotificationContacts = ({ contacts, i18n }) =>
  contacts.map((contact, i) => (
    <>
      {i > 0 ? <hr className="my-4 w-50 ml-0" /> : null}
      <List key={`notification-contact-${i}`}>
        <List.Item
          title={i18n('intermediary.preview.firstName')}
          value={contact?.contactFirstName}
        />
        <List.Item
          title={i18n('intermediary.preview.lastName')}
          value={contact?.contactLastName}
        />
        <List.Item
          title={i18n('intermediary.preview.email')}
          value={contact?.contactEmail}
        />
        <List.Item
          title={i18n('intermediary.preview.phone')}
          value={contact?.contactPhone}
        />
      </List>
    </>
  ));

export const MessagesPreview = ({ formData, i18n, defaultOpen = true }) => (
  <React.Fragment>
    <Expander defaultOpen={defaultOpen}>
      <ExpanderTitleButton>
        <FormattedMessage id="intermediary.serviceInfo.formTitle" />
      </ExpanderTitleButton>
      <ExpanderContent>
        {formData.serviceData?.services?.map((service, i) => (
          <React.Fragment key={`service-${service.name || i}`}>
            {i > 0 ? <hr className="my-4" /> : null}
            <List>
              <List.Item
                title={i18n('intermediary.messaging.serviceNameFin')}
                value={service.serviceNameFin}
              />
              <List.Item
                title={i18n('intermediary.messaging.serviceNameSve')}
                value={service.serviceNameSve}
              />
              <List.Item
                title={i18n('intermediary.messaging.serviceNameEng')}
                value={service.serviceNameEng}
              />
              <List.Item
                title={i18n('intermediary.messaging.functionalDescription')}
                value={service.serviceFunctionalDesc}
              />
              <List.Item
                title={i18n(
                  'forms.viestit_deployment.recipientIdSection.title'
                )}
                value={getRecipientIds(service, i18n)}
              />
              {service['recipientIdSsn'] ? (
                <List.Item
                  title={i18n('intermediary.messaging.ssnUsageExplanation')}
                  value={service.recipientIdSsnExplanation}
                />
              ) : null}
              <List.Item
                title={i18n(
                  'forms.viestit_registration.page_messagesInfo.connectionMethodSection.title'
                )}
                value={getConnectionMethods(service, i18n)}
              />
              {service.connectionMethodProduct ? (
                <List.Item
                  title={i18n(
                    'forms.viestit_registration.page_messagesInfo.connectionMethodSection.productName'
                  )}
                  value={service.productName}
                />
              ) : null}
              {showSendReadConfirmationAck(service) ? (
                <List.Item
                  title={i18n(
                    'forms.viestit_registration.page_messagesInfo.usageSettingsSection.sendReadConfirmationAck'
                  )}
                  value={
                    service.sendReadConfirmationAck
                      ? i18n('intermediary.common.yes')
                      : i18n('intermediary.common.no')
                  }
                />
              ) : null}
              {showNewMsgTarget(service) ? (
                <List.Item
                  title={i18n(
                    'forms.viestit_registration.page_messagesInfo.connectionMethodSection.newMsgTarget'
                  )}
                  value={
                    service.newMsgTarget
                      ? i18n('intermediary.common.yes')
                      : i18n('intermediary.common.no')
                  }
                />
              ) : null}

              <List.Item
                title={i18n('forms.general.lawSection.titleMessages')}
                value={getLegals(service, i18n)}
              />
            </List>
            <Heading variant="h4" className="my-2">
              <FormattedMessage id="forms.viestit_registration.page_messagesInfo.notificationSection.title" />
            </Heading>
            <NotificationContacts
              contacts={service.notificationContacts}
              i18n={i18n}
            />
          </React.Fragment>
        ))}
      </ExpanderContent>
    </Expander>

    <DataProtectionPreview
      i18n={i18n}
      defaultOpen={defaultOpen}
      formData={formData}
    />
  </React.Fragment>
);

MessagesPreview.propTypes = {
  i18n: PropTypes.func,
  formData: PropTypes.object,
  defaultOpen: PropTypes.bool
};
