import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { SearchInput } from 'suomifi-ui-components';

const SearchBox = ({ locale, requestAsyncSearch }, { intl }) => {
  const i18n = id => intl.formatMessage({ id });
  const handleSearch = searchTerm => {
    const searchPath = `/${locale}/haku`;
    const queryPath = `${searchPath}?q=${searchTerm}`;
    if (window.location.pathname !== searchPath) {
      browserHistory.push(queryPath);
    } else {
      window.history.replaceState({ queryPath }, '', queryPath);
    }
    requestAsyncSearch(searchTerm, locale, 10);
  };

  return (
    <SearchInput
      labelText={i18n('search.title')}
      searchButtonLabel={i18n('search.title')}
      clearButtonLabel={i18n('general.cancel')}
      visualPlaceholder={i18n('search.title')}
      onSearch={handleSearch}
      labelMode="hidden"
    />
  );
};

SearchBox.propTypes = {
  locale: PropTypes.string.isRequired,
  requestAsyncSearch: PropTypes.func.isRequired
};

SearchBox.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SearchBox;
