import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './styles.scss';
import {
  Block,
  Button,
  Heading,
  ExpanderGroup,
  IconPlus
} from 'suomifi-ui-components';
import { FieldArray } from 'formik';
import { RadioIntSelection } from './components/FieldHelpers';
import Services from './components/Services';
import { serviceSchema, formTitle } from './schema';

export const Form = ({ formik }, { intl }) => {
  const i18n = id => intl.formatMessage({ id });

  const helperRef = React.useRef(null);
  return (
    <div className={styles.container}>
      <Block>
        <Heading variant="h2" className="borderless mb-2">
          <FormattedMessage id={formTitle} />
        </Heading>
      </Block>
      <Block>
        <ExpanderGroup showToggleAllButton={false}>
          <FieldArray
            name="formData.serviceData.services"
            render={helpers => {
              helperRef.current = helpers;
              return (
                <Services formik={formik} remove={helpers.remove} i18n={i18n} />
              );
            }}
          />
        </ExpanderGroup>
      </Block>
      <Block>
        <Button
          variant="secondary"
          icon={<IconPlus />}
          onClick={() => helperRef?.current?.push(serviceSchema.cast({}))}
        >
          <FormattedMessage id="intermediary.serviceInfo.addService" />
        </Button>
      </Block>
      <Block>
        <Heading variant="h3" className="borderless mt-2">
          <FormattedMessage id="forms.viestit_registration.page_messagesInfo.usageSettingsSection.title" />
        </Heading>
        {['replyAllowed', 'sendDeliveryAck', 'sendReadAck'].map(name => (
          <RadioIntSelection
            i18n={i18n}
            formik={formik}
            key={name}
            name={name}
          />
        ))}
      </Block>
    </div>
  );
};

Form.propTypes = {
  formik: PropTypes.object.isRequired
};

Form.contextTypes = {
  intl: PropTypes.object.isRequired
};
