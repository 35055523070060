import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';
import cx from 'classnames';
import { Button, TextField } from 'sema-ui-components';
import {
  renderTextField,
  renderFileUpload,
  renderSelect
} from 'utils/fieldWrappers';
import connectForm from 'utils/connectForm';
import iconFail from '!!svg-inline-loader!sema-ui-components/dist/components/icons/old/icon-fail.svg';
import thanksPic from '!!svg-inline-loader!sema-ui-components/dist/components/icons/old/icon-feedback-sent.svg';
//import validate from './validate';
import styles from './FeedbackForm.scss';
import { mandatories, mandatoryFieldKey, emails } from 'utils/validator';

const validators = {
  subjectMissing: false
};

export class FeedbackForm extends React.Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    isDone: PropTypes.bool,
    notifSend: PropTypes.func,
    requestAsyncPost: PropTypes.func,
    clearFormState: PropTypes.func,
    updateThanksValues: PropTypes.func,
    handleSubmit: PropTypes.func,
    isContact: PropTypes.bool,
    sourcePage: PropTypes.string,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userMessage: PropTypes.string,
    allowAttachments: PropTypes.bool,
    className: PropTypes.string,
    translate: PropTypes.func,
    invalid: PropTypes.bool
  };

  static defaultProps = {
    sourcePage: '',
    allowAttachments: false
  };

  // honeypot field, if we even need one...
  _emailField;

  componentDidMount() {
    this.props.clearFormState();
  }

  formatMessage = id => this.context.intl.formatMessage({ id });

  doHandleSubmit = values => {
    if (!this._emailField.value !== '') {
      //values.userHeadline = this.props.isContact
      //  ? 'Yhteydenottopyyntö'
      //  : 'Uusi palaute';
      //values.feedbackType = this.props.isContact ? 'contact' : 'feedback';
      //values.userKey = '4'; //TODO what?
      //values.sourcePage = this.props.sourcePage;

      this.props
        .requestAsyncPost(values, this.props.isContact ? 'contact' : 'feedback')
        .then(res => {
          if (res && res.error) {
            const msg = {
              title: this.formatMessage('feedback.server_error_title'),
              description: this.formatMessage('feedback.server_error'),
              type: 'fail',
              dismissAfter: 5000
            };

            this.props.notifSend(msg);
          } else {
            this.props.updateThanksValues(
              values.userName,
              values.userEmail,
              values.userMessage
            );
          }
        });
    }
  };

  render() {
    const {
      isDone,
      isFetching,
      isContact,
      handleSubmit,
      userName,
      userEmail,
      userMessage,
      allowAttachments,
      translate,
      invalid
    } = this.props;
    const messageTitle = <FormattedMessage id="feedback.contact_message" />;
    const thanksMessage = <FormattedMessage id="feedback.contact_thanks" />;
    const contactSubjects = {
      SERVICECATALOGUE: 'servicecatalogue',
      DATAEXCHANGELAYER: 'dataexchangelayer',
      EIDENTIFICATION: 'eIdentification',
      EAUTHORIZATION: 'eauthorization',
      MESSAGES: 'messages',
      OTHER: 'other'
    };

    const initialForm = () => (
      <div>
        <div className="row">
          <div className="col-md-11">
            <form onSubmit={handleSubmit(this.doHandleSubmit)}>
              <div className={cx(styles.formRow, styles.contactRow)}>
                <div>
                  <FormattedMessage id="feedback.chooseSubject" />
                  <Field
                    name={'subject'}
                    component={renderSelect}
                    options={Object.values(contactSubjects).map(subject => ({
                      label: this.formatMessage(
                        `kvh_admin.service.${subject}.name`
                      ),
                      value: subject
                    }))}
                  />
                  {validators.subjectMissing && (
                    <p className={styles.mandatoryText}>
                      <FormattedMessage
                        className={styles.mandatoryText}
                        id={mandatoryFieldKey}
                      />
                    </p>
                  )}
                </div>
                <div className={styles.nameDiv}>
                  <FormattedMessage id="general.form.name" />
                  <Field
                    name={'userName'}
                    component={renderTextField}
                    size="w250"
                    maxLength={256}
                    placeholder={this.formatMessage(
                      'feedback.name_placeholder'
                    )}
                    errorMessage={this.formatMessage('feedback.name_mandatory')}
                    disabled={isFetching}
                  />
                </div>
                <div className={styles.emailDiv}>
                  <FormattedMessage id="general.form.email" />
                  <Field
                    name={'userEmail'}
                    component={renderTextField}
                    size="w250"
                    maxLength={256}
                    placeholder={this.formatMessage(
                      'feedback.email_placeholder'
                    )}
                    errorMessage={this.formatMessage(
                      'feedback.email_mandatory'
                    )}
                    disabled={isFetching}
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                {messageTitle}
                <Field
                  name={'userMessage'}
                  component={renderTextField}
                  multiline
                  rows={8}
                  size="w520"
                  counter
                  maxLength={2000}
                  placeholder={this.formatMessage(
                    'feedback.message_placeholder'
                  )}
                  errorMessage={this.formatMessage(
                    'feedback.message_mandatory'
                  )}
                  disabled={isFetching}
                />
              </div>
              {allowAttachments && (
                <div className={styles.formRow}>
                  <FormattedMessage id="general.attachments" />
                  <Field
                    name={'attachments'}
                    component={renderFileUpload}
                    maxFiles="3"
                    startUpload={this.uploadStart}
                    endUpload={this.uploadEnd}
                  />
                </div>
              )}
              <div className={cx(styles.formRow, styles.emailField)}>
                <input
                  ref={r => {
                    this._emailField = r;
                  }}
                  type="text"
                  name="email"
                />
              </div>
              <Button
                type="submit"
                small
                className={styles.sendButton}
                disabled={isFetching || invalid}
              >
                <FormattedMessage id="general.form.submit" />
              </Button>
            </form>
          </div>
        </div>
      </div>
    );

    const doneForm = () => (
      <div className={styles.thanksBackground}>
        <div className={styles.animatedForm}>
          <div className="row">
            <div className="col-md-11">
              <div className={cx(styles.formRow, styles.contactRow)}>
                <div className={styles.nameDiv}>
                  <FormattedMessage id="general.form.name" />
                  <TextField size="w250" disabled value={userName} />
                </div>
                <div className={styles.emailDiv}>
                  <FormattedMessage id="general.form.email" />
                  <TextField size="w250" disabled value={userEmail} />
                </div>
              </div>
              <div className={styles.formRow}>
                {messageTitle}
                <TextField
                  multiline
                  rows={8}
                  size="w520"
                  counter
                  maxLength={2000}
                  disabled
                  value={userMessage}
                />
              </div>
              {allowAttachments && (
                <div className={styles.formRow}>
                  <FormattedMessage id="general.form.email" />
                  <Field
                    name={'attachments'}
                    component={renderFileUpload}
                    maxFiles="3"
                    startUpload={this.uploadStart}
                    endUpload={this.uploadEnd}
                    disabled
                  />
                </div>
              )}
              <Button small disabled className={styles.sendButton}>
                <FormattedMessage id="general.form.submit" />
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.thanksArea}>
          <div className={styles.thanksPic}>
            <InlineSVG src={thanksPic} />
          </div>
          <div className={styles.thanksText}>
            {thanksMessage}
            <div className={styles.fadingEffect} />
          </div>
        </div>
      </div>
    );

    return (
      <div className={this.props.className}>
        {!isDone ? initialForm() : doneForm()}
      </div>
    );
  }
}

FeedbackForm.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default connectForm(FeedbackForm, {
  form: 'feedback',
  validate: values => {
    validators.subjectMissing = false;
    if (!values.subject) {
      validators.subjectMissing = true;
    }
    return {
      ...emails(values, 'userEmail'),
      ...mandatories(values, 'subject', 'userName', 'userEmail', 'userMessage')
    };
  },
  destroyOnUnmount: true,
  mapStateToProps: state => ({
    initialValues: {
      userName: state.auth.user
        ? state.auth.user.firstName + ' ' + state.auth.user.lastName
        : '',
      userEmail:
        state.auth.user && state.auth.user.email ? state.auth.user.email : '',
      userMessage: ''
    }
  })
});
