import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  RadioButton,
  RadioButtonGroup,
  Textarea,
  Block,
  Heading
} from 'suomifi-ui-components';
import { DiscloseInformationToThirdPartyInfo } from './DiscloseInformationToThirdPartyInfo';
import { getStatus, getStatusText } from '../../../utils';

export const StatementOnInformationSecurityIdentification = ({
  i18n,
  serviceDataSavedToRegister,
  hideSaveToRegister,
  discloseInformationToThirdParty,
  getRadioValue,
  formik
}) => {
  const vtjDataHandlingAbroadEu = getRadioValue('vtjDataHandlingAbroadEu');
  const personnelDataHandlingOutsideOrganisation = getRadioValue(
    'personnelDataHandlingOutsideOrganisation'
  );

  return (
    <>
      <Block>
        <Heading variant="h3">
          <FormattedMessage id="intermediary.dataProtection.statementOnInformationSecurityTitle" />
        </Heading>
      </Block>

      {!hideSaveToRegister && (
        <>
          <RadioButtonGroup
            labelText={i18n(
              'intermediary.dataProtection.desc_vtjDataHandlingAbroadEu'
            )}
            name="formData.statementOnInformationSecurity.vtjDataHandlingAbroadEu"
            value={vtjDataHandlingAbroadEu}
            onChange={value =>
              formik.setFieldValue(
                'formData.statementOnInformationSecurity.vtjDataHandlingAbroadEu',
                value === 'true'
              )
            }
          >
            <RadioButton value="true">
              <FormattedMessage id="intermediary.common.yes" />
            </RadioButton>
            <RadioButton value="false">
              <FormattedMessage id="intermediary.common.no" />
            </RadioButton>
          </RadioButtonGroup>
          {vtjDataHandlingAbroadEu === 'true' && (
            <Textarea
              name="formData.statementOnInformationSecurity.vtjDataHandlingAbroadEuInfo"
              labelText={i18n(
                'intermediary.dataProtection.yes_vtjDataHandlingAbroadEuInfo'
              )}
              fullWidth
              status={getStatus(
                formik,
                'formData.statementOnInformationSecurity.vtjDataHandlingAbroadEuInfo'
              )}
              statusText={getStatusText(
                i18n,
                formik,
                'formData.statementOnInformationSecurity.vtjDataHandlingAbroadEuInfo'
              )}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {
                formik?.values?.formData?.statementOnInformationSecurity
                  ?.vtjDataHandlingAbroadEuInfo
              }
            </Textarea>
          )}

          <RadioButtonGroup
            labelText={i18n(
              'intermediary.dataProtection.desc_serviceDataSavedToRegister'
            )}
            name="formData.statementOnInformationSecurity.serviceDataSavedToRegister"
            value={serviceDataSavedToRegister}
            onChange={value =>
              formik.setFieldValue(
                'formData.statementOnInformationSecurity.serviceDataSavedToRegister',
                value === 'true'
              )
            }
          >
            <RadioButton value="true">
              <FormattedMessage id="intermediary.common.yes" />
            </RadioButton>
            <RadioButton value="false">
              <FormattedMessage id="intermediary.common.no" />
            </RadioButton>
          </RadioButtonGroup>

          {serviceDataSavedToRegister === 'true' && (
            <Textarea
              name="formData.statementOnInformationSecurity.serviceDataSavedToRegisterInfo"
              labelText={i18n(
                'intermediary.dataProtection.yes_serviceDataSavedToRegisterInfo'
              )}
              fullWidth
              status={getStatus(
                formik,
                'formData.statementOnInformationSecurity.serviceDataSavedToRegisterInfo'
              )}
              statusText={getStatusText(
                i18n,
                formik,
                'formData.statementOnInformationSecurity.serviceDataSavedToRegisterInfo'
              )}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {
                formik?.values?.formData?.statementOnInformationSecurity
                  ?.serviceDataSavedToRegisterInfo
              }
            </Textarea>
          )}

          <RadioButtonGroup
            labelText={i18n(
              'intermediary.dataProtection.desc_personnelDataHandlingOutsideOrganisation'
            )}
            name="formData.statementOnInformationSecurity.personnelDataHandlingOutsideOrganisation"
            value={personnelDataHandlingOutsideOrganisation}
            onChange={value =>
              formik.setFieldValue(
                'formData.statementOnInformationSecurity.personnelDataHandlingOutsideOrganisation',
                value === 'true'
              )
            }
          >
            <RadioButton value="true">
              <FormattedMessage id="intermediary.common.yes" />
            </RadioButton>
            <RadioButton value="false">
              <FormattedMessage id="intermediary.common.no" />
            </RadioButton>
          </RadioButtonGroup>
          {personnelDataHandlingOutsideOrganisation === 'true' && (
            <Textarea
              name="formData.statementOnInformationSecurity.personnelDataHandlingOutsideOrganisationInfo"
              labelText={i18n(
                'intermediary.dataProtection.yes_personnelDataHandlingOutsideOrganisationInfo'
              )}
              fullWidth
              status={getStatus(
                formik,
                'formData.statementOnInformationSecurity.personnelDataHandlingOutsideOrganisationInfo'
              )}
              statusText={getStatusText(
                i18n,
                formik,
                'formData.statementOnInformationSecurity.personnelDataHandlingOutsideOrganisationInfo'
              )}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {
                formik?.values?.formData?.statementOnInformationSecurity
                  ?.personnelDataHandlingOutsideOrganisationInfo
              }
            </Textarea>
          )}
        </>
      )}

      <RadioButtonGroup
        labelText={i18n(
          'intermediary.dataProtection.discloseInformationToThirdPartyLabel'
        )}
        name="formData.statementOnInformationSecurity.discloseInformationToThirdParty"
        value={discloseInformationToThirdParty}
        onChange={value =>
          formik.setFieldValue(
            'formData.statementOnInformationSecurity.discloseInformationToThirdParty',
            value === 'true'
          )
        }
      >
        <RadioButton value="true">
          <FormattedMessage id="intermediary.common.yes" />
        </RadioButton>
        <RadioButton value="false">
          <FormattedMessage id="intermediary.common.no" />
        </RadioButton>
      </RadioButtonGroup>

      {discloseInformationToThirdParty === 'true' && (
        <DiscloseInformationToThirdPartyInfo i18n={i18n} formik={formik} />
      )}
    </>
  );
};

StatementOnInformationSecurityIdentification.propTypes = {
  i18n: PropTypes.func,
  serviceDataSavedToRegister: PropTypes.string,
  discloseInformationToThirdParty: PropTypes.string,
  formik: PropTypes.object,
  hideSaveToRegister: PropTypes.bool,
  getRadioValue: PropTypes.func
};
