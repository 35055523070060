/* @flow */
export default function createReducer(
  ACTION_HANDLERS: Object,
  initialState: Object = {}
) {
  return (state = initialState, action: Action): Object => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
  };
}
