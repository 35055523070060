import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import cx from 'classnames';
import styles from './DetailsView.scss';

export const DetailsView = ({ aside, content, locale }) => (
  <section className={cx('row', 'container', styles.base)}>
    <aside className="col-6">{aside}</aside>
    <div className={cx('col-18', styles.rightside)}>{content}</div>
  </section>
);

DetailsView.propTypes = {
  className: PropTypes.string,
  aside: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
  locale: PropTypes.string.isRequired
};

export default pure(DetailsView);
