import facebook from '!!url-loader?limit=10!./assets/facebook.svg';
import logo from '!!url-loader?limit=10!./assets/logo-footer.svg';
import twitter from '!!url-loader?limit=10!./assets/twitter.svg';
import youtube from '!!url-loader?limit=10!./assets/youtube.svg';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { pure } from 'recompose';
import { IconArrowUp, VisuallyHidden } from 'suomifi-ui-components';
import config from 'config';

import styles from './Footer.scss';

export const Footer = ({ children, locale, isSimpleFrontPage }) => {
  const defaultContent = (
    <div>
      <div className={styles.head}>
        <FormattedMessage id="suomi_fi_services_title" />
      </div>
      <div className="row">
        <div className={cx('col-lg-12', styles.leftListCol)}>
          <ul className={styles.linkList}>
            <li>
              <Link to={`/${locale}/oma-organisaatio`}>
                <FormattedMessage id="own_organization.title" />
              </Link>
            </li>
            <li>
              <Link to={`/${locale}/kayttajaprofiili`}>
                <FormattedMessage id="userprofile.title" />
              </Link>
            </li>
            <li>
              <Link to={`/${locale}/palvelut`}>
                <FormattedMessage id="own_suomi_fi_services_title" />
              </Link>
            </li>
          </ul>
        </div>
        <div className={cx('col-lg-12', styles.rightListCol)}>
          <ul className={styles.linkList}>
            <li>
              <Link to={`/${locale}/palvelut`}>
                <FormattedMessage id="suomi_fi_services_title" />
              </Link>
            </li>
            <li>
              <Link to={`/${locale}/raportit`}>
                <FormattedMessage id="reports.title" />
              </Link>
            </li>
            <li>
              <Link to={`/${locale}/tuki`}>
                <FormattedMessage id="support.title" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  const simpleDefaultContent = (
    <div className={styles.simpleFooter}>
      <div className={styles.head}>
        <FormattedMessage id="suomi_fi_services_title" />
      </div>
      <div className="row">
        <div className={cx('col-md-24')}>
          <p>
            <FormattedMessage id="general.footer_simple_text" />
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <footer className={styles.base}>
      <div className={styles.wrap}>
        <div className="container">
          <div className="row">
            <div className={cx('col-md-2', styles.onMobile)}>
              <div className={styles.scrollBtnContainer}>
                <a href="#logo">
                  <IconArrowUp />
                  <VisuallyHidden>
                    <FormattedMessage id="forms.general.go_back" />
                  </VisuallyHidden>
                </a>
              </div>
            </div>
            <div className="col-md-11">
              {children ||
                (isSimpleFrontPage ? simpleDefaultContent : defaultContent)}
            </div>
            <div className={cx('col-md-2', styles.aboveMobile)}>
              <div className={styles.scrollBtnContainer}>
                <a href="#logo">
                  <IconArrowUp />
                  <VisuallyHidden>
                    <FormattedMessage id="forms.general.go_back" />
                  </VisuallyHidden>
                </a>
              </div>
            </div>
            <div className="col-md-11">
              <div className={cx(styles.logo)}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.suomi.fi"
                >
                  <img src={logo} alt="suomi_fi_services_title" />
                </a>
              </div>
              <div className={cx(styles.description)}>
                <FormattedMessage id="suomi_fi_web_services_title" />
                <br />
                <FormattedMessage id="service_run_by_title" />
              </div>
              <div className={styles.toolBar}>
                <div className={cx(styles.someIcons)}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={config.acessibilityReport[locale]}
                  >
                    <FormattedMessage id="general.accessibility_report" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.suomi.fi/ohjeet-ja-tuki/yleista-verkkopalvelusta/tietosuoja"
                  >
                    <FormattedMessage id="general.privacy_statement" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/user/suomifitoimitus"
                  >
                    <img src={youtube} alt="YouTube" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/suomifi"
                  >
                    <img src={twitter} alt="Twitter" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/suomifi/"
                  >
                    <img src={facebook} alt="Facebook" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {(__INT__ || __DEV__ || __TEST_ENV__ || __QA__) && (
            <div className="row">
              <div className="col-24">
                <div style={{ textAlign: 'center', fontSize: '10px' }}>
                  {process.env.NODE_ENV} {__GIT_INFO__}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  children: PropTypes.any,
  locale: PropTypes.string.isRequired,
  isSimpleFrontPage: PropTypes.bool
};

export default pure(Footer);
