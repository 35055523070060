import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'sema-ui-components';

class XMLField extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <TextField
          multiline
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.props.onChange}
          rows={20}
          size="full"
        />
      </div>
    );
  }
}

XMLField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string
};

export default XMLField;
