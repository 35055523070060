import ca from './createAction';
import cr from './createReducer';
import { shouldUpdate } from 'recompose';

export function getBrowserLang(defaultLang) {
  return (
    window.navigator.userLanguage ||
    window.navigator.language ||
    defaultLang
  ).split('-')[0];
}

export function redirect(from, to, disable) {
  return {
    path: from,
    onEnter: (nextState, replace) => {
      const target = disable ? from : to;
      replace(target);
    }
  };
}

export function redirectOut(from, to) {
  return {
    path: from,
    onEnter: (nextState, replace) => {
      window.location.replace(to);
    }
  };
}

export function redirectWildcard(from, original, replacement) {
  return {
    path: from,
    onEnter: (nextState, replace) => {
      const url = nextState.location;
      const target = url.pathname.replace(original, replacement) + url.search;
      replace(target);
    }
  };
}

export function redirectToRoot(store, browserLang) {
  const from = `/`;
  const root = `/${browserLang}`;
  const loggedInRoot = `/${browserLang}/dashboard`;
  return {
    path: from,
    onEnter: (nextState, replace) => {
      const auth = store.getState().auth;
      if (
        auth.isAuthenticated &&
        auth.user &&
        auth.user.organizations &&
        auth.user.organizations.length > 0
      ) {
        replace(loggedInRoot);
      } else {
        replace(root);
      }
    }
  };
}

/**
 * Helper for quickly do redux FSA action and const in the same run
 * @param name names array of const
 * @returns {*[]} array of arrays including name string and FSA action
 */
export function createConsts(names) {
  return names.map(n => [n, ca(n)]);
}

/** Middleware helpers * */
export function validateTypesArray(types) {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }

  // if (!types.every(type => typeof type === 'string')) {
  //   throw new Error('Expected action types to be strings.');
  // }
}

/**
 * Transforms short language code to long form
 * @param lang
 * @returns {*}
 */
export function getLongLang(lang) {
  let longLang;
  if (lang === 'sv') {
    longLang = 'sv_SE';
  } else if (lang === 'en') {
    longLang = 'en_GB';
  } else {
    longLang = 'fi_FI';
  }
  return longLang;
}

/**
 * Creating Minio/S3 urls
 *
 * @param filesUrl
 * @param file
 * @returns {string}
 */
export function makeFileUrl(filesUrl, file) {
  const {
    file: { bucket, path, filename }
  } = file;
  return bucket ? `${filesUrl + bucket + path}/${filename}` : filename;
}

export const getURLQueryString = params => {
  let query = [];
  new URLSearchParams(params).forEach((value, key) => {
    query.push(`${key}=${encodeURIComponent(value)}`);
  });

  return query.join('&');
};

export const staticComponent = component =>
  shouldUpdate(() => false)(component);

export const transFun = intl => id => intl.formatMessage({ id });

export { getJSON } from './http';
export { getSemaJSON } from './http';
export { postSemaJSON } from './http';
export { postSemaPureJSON } from './http';
export { postJSON } from './http';
export { putJSON } from './http';
export { postXML } from './http';
export { deleteGeneric } from './http';
export { cr as createReducer };
export { ca as createAction };
export { default as humanizeBytes } from './humanizeBytes';
export { default as asyncComponent } from './asyncComponent';
export { default as getCookie } from './getCookie';
export { articleSanitizeConf } from './sanitizeConf';
