/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Heading,
  Expander,
  ExpanderTitleButton,
  ExpanderContent,
  Block
} from 'suomifi-ui-components';
import List from '../components/List';
import {
  organizationClassByValue,
  dataExchangeLayerOwnershipType,
  dataExchangeLayerOwnershipTypeByValue,
  dataExchangeLayerPurposeOfUseByValue,
  environmentTypeByValue
} from 'constants/index';

export const DataExchangeLayerPreview = ({
  formData,
  i18n,
  defaultOpen = true
}) => {
  const { serviceData } = formData;

  const apiCatalogueContactInformation =
    formData?.apiCatalogueContactInformation ||
    serviceData?.dataExchangeLayerInformation?.serverInformation
      ?.apiCatalogueContactInformation;

  return (
    <>
      <Expander defaultOpen={defaultOpen}>
        <ExpanderTitleButton>
          <FormattedMessage id="intermediary.dataexchangelayer.title" />
        </ExpanderTitleButton>
        <ExpanderContent>
          <Heading variant="h3" className="mb-2 mt-4">
            <FormattedMessage id="intermediary.dataexchangelayer.title" />
          </Heading>
          <Block mt="l" mb="l">
            <List>
              <List.Item
                title={i18n(`intermediary.dataexchangelayer.environmentType`)}
                value={
                  formData?.serviceData?.environmentType
                    ? i18n(
                        `intermediary.dataexchangelayer.${
                          environmentTypeByValue[
                            formData?.serviceData?.environmentType
                          ]
                        }`
                      )
                    : null
                }
              />

              <List.Item
                title={i18n(`intermediary.dataexchangelayer.organizationClass`)}
                value={
                  serviceData?.dataExchangeLayerInformation?.organizationClass
                    ? i18n(
                        `intermediary.dataexchangelayer.${
                          organizationClassByValue[
                            `${serviceData?.dataExchangeLayerInformation?.organizationClass}`
                          ]
                        }`
                      )
                    : null
                }
              />

              <List.Item
                title={i18n(`intermediary.dataexchangelayer.purposeOfUse`)}
                value={
                  serviceData?.dataExchangeLayerInformation?.purposeOfUse
                    ? i18n(
                        `intermediary.dataexchangelayer.${
                          dataExchangeLayerPurposeOfUseByValue[
                            serviceData?.dataExchangeLayerInformation
                              ?.purposeOfUse
                          ]
                        }`
                      )
                    : null
                }
              />

              <List.Item
                title={i18n(`intermediary.dataexchangelayer.ownershipType`)}
                value={
                  serviceData?.dataExchangeLayerInformation?.serverInformation
                    ?.ownershipType
                    ? i18n(
                        `intermediary.dataexchangelayer.${
                          dataExchangeLayerOwnershipTypeByValue[
                            serviceData?.dataExchangeLayerInformation
                              ?.serverInformation?.ownershipType
                          ]
                        }`
                      )
                    : null
                }
              />

              <List.Item
                title={i18n(`intermediary.dataexchangelayer.fqdn`)}
                value={
                  serviceData?.dataExchangeLayerInformation?.serverInformation
                    ?.fqdn
                }
              />

              <List.Item
                title={i18n(`intermediary.dataexchangelayer.ipv4`)}
                value={serviceData?.dataExchangeLayerInformation?.serverInformation?.ipv4?.join(
                  '\r\n'
                )}
                preFormatted
              />

              {serviceData?.dataExchangeLayerInformation?.serverInformation
                .ownershipType ===
                dataExchangeLayerOwnershipType.USEEXISTINGEXTERNALSERVER && (
                <List.Item
                  title={i18n(`intermediary.dataexchangelayer.ownerName`)}
                  value={
                    serviceData?.dataExchangeLayerInformation?.serverInformation
                      ?.ownerName
                  }
                />
              )}

              <List.Item
                title={i18n(
                  `intermediary.dataexchangelayer.isAuthorizationForCertificate`
                )}
                value={
                  serviceData?.dataExchangeLayerInformation?.serverInformation
                    ?.isAuthorizationForCertificate
                    ? i18n('general.form.yes')
                    : i18n('general.form.no')
                }
              />
            </List>
          </Block>

          <Block>
            <Heading variant="h3" className="mb-2 mt-4">
              <FormattedMessage id="intermediary.dataexchangelayer.apiContactTitle" />
            </Heading>
            <List>
              <List.Item
                title={i18n(`intermediary.field.organizationName`)}
                value={
                  apiCatalogueContactInformation?.apiCatalogueContactPerson
                    ?.organizationName
                }
              />

              <List.Item
                title={i18n(`intermediary.field.businessId`)}
                value={
                  apiCatalogueContactInformation?.apiCatalogueContactPerson
                    ?.businessId
                }
              />

              <List.Item
                title={i18n(`intermediary.field.firstName`)}
                value={
                  apiCatalogueContactInformation?.apiCatalogueContactPerson
                    ?.firstName
                }
              />

              <List.Item
                title={i18n(`intermediary.field.lastName`)}
                value={
                  apiCatalogueContactInformation?.apiCatalogueContactPerson
                    ?.lastName
                }
              />

              <List.Item
                title={i18n(`intermediary.field.email`)}
                value={
                  apiCatalogueContactInformation?.apiCatalogueContactPerson
                    ?.email
                }
              />

              <List.Item
                title={i18n(`intermediary.field.phone`)}
                value={
                  apiCatalogueContactInformation?.apiCatalogueContactPerson
                    ?.phoneNumber
                }
              />
            </List>
          </Block>
        </ExpanderContent>
      </Expander>
    </>
  );
};
