import { deleteGeneric, getSemaJSON, postSemaJSON } from 'utils/http';

const baseUrl = '/api/forms';

export function loadFormDataFromSAHA(orgId, service, type, uuid, decisionId) {
  // Load the form in for Detail view or for edit
  const url = `${baseUrl}/organization/${orgId}/service/${service}/type/${type}/uuid/${uuid}/decisionId/${decisionId}`;
  return getSemaJSON(url);
}

export function deleteForm(orgId, uuid, pahaFormName) {
  const url = `${baseUrl}/${orgId}/${uuid}?pahaFormName=${pahaFormName}`;
  return deleteGeneric(url);
}

export function sendInvitationRequestEmail(body) {
  const url = `/api/email/inviteforedit`;
  return postSemaJSON(url, body);
}
