import React from 'react';
import { injectReducer } from 'store/reducers';
import { FormattedMessage } from 'react-intl';
import { redirect } from 'utils';

export const basePath = 'raportit';

export default store => ({
  getComponents(nextState, next) {
    require.ensure(
      ['./containers/MainViewContainer', './modules'],
      require => {
        const reducer = require('./modules').default;
        injectReducer(store, { key: 'statistics', reducer });
        const content = require('./containers/MainViewContainer').default;
        next(null, { content });
      },
      'statistics'
    );
  },
  getChildRoutes: (location, cb) => {
    cb(null, [
      redirect(basePath, `/${location.params.lang}/${basePath}/etusivu`),
      {
        path: basePath,
        name: <FormattedMessage id="reports.title" />,
        childRoutes: [
          {
            path: 'etusivu',
            name: <FormattedMessage id="reports.title" />,
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const content = require('./components/ReportsView').default;
                  next(null, content);
                },
                'statistics'
              );
            }
          }
        ]
      }
    ]);
  }
});
