import { requestConstants, formTypes } from '../constants';
import { getErrorMessage } from 'utils/errors';

function getSubmitMethod(data) {
  // We don't have proposalGuid, until we already have saved the form once
  return data.proposalGuid.length ? 'PUT' : 'POST';
}

export const backendSchemaToFormikSchema = data => {
  // TODO: Get rid of this whole mapping after MVP is done

  const { formData } = data;

  switch (data?.serviceType) {
    case formTypes.EIDENTIFICATION: {
      return {
        ...data,
        formData: {
          ...formData,
          extentOfPersonalInformation:
            formData.serviceData.extentOfPersonalInformation,
          extentOfPersonalInformationDescription:
            formData.serviceData.extentOfPersonalInformationDescription,
          serviceData: {
            ...formData.serviceData,
            services: formData.serviceData.services
          }
        }
      };
    }

    case formTypes.DATAEXCHANGELAYER: {
      const { serviceData, ...restFormData } = formData;
      const { dataExchangeLayerInformation, ...restServiceData } = serviceData;
      const {
        apiCatalogueContactInformation,
        ...restDataExchangeLayerInformation
      } = dataExchangeLayerInformation;

      return {
        ...data,
        formData: {
          ...restFormData,
          serviceData: {
            ...restServiceData,
            dataExchangeLayerInformation: restDataExchangeLayerInformation
          },
          apiCatalogueContactInformation
        }
      };
    }

    default:
      return data;
  }
};

const formikSchemaToBackendSchema = data => {
  // TODO: Use constants, or better yet, get rid of this whole mapping after MVP is done
  switch (data?.serviceType) {
    case formTypes.EIDENTIFICATION: {
      const { formData, ...rest } = data;
      const {
        extentOfPersonalInformation,
        extentOfPersonalInformationDescription,
        ...restFormData
      } = formData;

      return {
        ...rest,
        formData: {
          ...restFormData,
          serviceData: {
            extentOfPersonalInformation,
            extentOfPersonalInformationDescription,
            services: formData.serviceData.services
          }
        }
      };
    }

    case formTypes.DATAEXCHANGELAYER: {
      const { formData, ...rest } = data;
      const {
        apiCatalogueContactInformation,
        serviceData,
        ...restFormData
      } = formData;

      return {
        ...rest,
        formData: {
          ...restFormData,
          serviceData: {
            ...serviceData,
            dataExchangeLayerInformation: {
              ...serviceData.dataExchangeLayerInformation,
              apiCatalogueContactInformation
            }
          }
        }
      };
    }
    default:
      return data;
  }
};

export async function postData(data, formState) {
  const payload = formikSchemaToBackendSchema({ ...data, formState });
  return fetch('/api/proposal/send', {
    method: getSubmitMethod(data),
    body: JSON.stringify(payload),
    ...requestConstants
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data?.errorMessages?.[0].errorCode);
      }
      return data;
    })
    .catch(error => {
      throw new Error(getErrorMessage(error?.message));
    });
}

export async function acceptTempCustomerData(data, formState) {
  const payload = formikSchemaToBackendSchema({ ...data, formState });
  console.log('Posting data:', { payload });
  return fetch('/api/proposal/accept/tempcustomer', {
    method: getSubmitMethod(data),
    body: JSON.stringify(payload),
    ...requestConstants
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data?.errorMessages?.[0].errorCode);
      }
      return data;
    })
    .catch(error => {
      throw new Error(getErrorMessage(error?.message));
    });
}

export async function getForm(formId, formState, serviceType) {
  return fetch(
    `/api/proposal/find/${formId}?formState=${formState}&serviceType=${serviceType}`,
    {
      method: 'GET',
      ...requestConstants
    }
  )
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data?.errorMessages?.[0].errorCode);
      }
      return backendSchemaToFormikSchema(data);
    })
    .catch(error => {
      throw new Error(getErrorMessage(error?.message));
    });
}
