import { createReducer } from '../../utils';

export const SET_OVERLAY = 'SET_OVERLAY';
export const UNSET_OVERLAY = 'UNSET_OVERLAY';

export function setOverlay(overlay) {
  return dispatch => {
    dispatch({
      type: SET_OVERLAY,
      payload: overlay
    });
  };
}

export function unsetOverlay(overlay) {
  return dispatch => {
    dispatch({
      type: UNSET_OVERLAY
    });
  };
}

const ACTION_HANDLERS = {
  [SET_OVERLAY]: (state, { payload }) => ({
    ...state,
    overlay: payload
  }),
  [UNSET_OVERLAY]: state => ({
    ...state,
    overlay: null
  })
};

const initialState = {
  overlay: null,
  isNotificationsDropdownOpen: false
};

export default createReducer(ACTION_HANDLERS, initialState);
