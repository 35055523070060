import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconPlus } from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const AddContactButton = ({ setVisible }) => (
  <StyledButton variant="secondary" icon={<IconPlus />} onClick={setVisible}>
    <FormattedMessage id="intermediary.common.addSecondaryContactPerson" />
  </StyledButton>
);
AddContactButton.propTypes = {
  setVisible: PropTypes.func.isRequired
};
