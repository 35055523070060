import * as yup from 'yup';

export const formTitle = 'intermediary.dataProtection.formTitle';

export const schema = isEIdentificationForm =>
  yup.object({
    statementOnInformationSecurity: yup.object({
      followInformationManagementAct: yup
        .boolean()
        .required('intermediary.validation.mandatory')
        .nullable()
        .default(null)
        .label(formTitle),

      coveredByInformationManagementAct: yup
        .boolean()
        .nullable()
        .default(null)
        .label(formTitle)
        .when('followInformationManagementAct', {
          is: true,
          then: schema => schema.required('intermediary.validation.mandatory')
        }),

      discloseInformationToThirdParty: yup
        .boolean()
        .nullable()
        .default(null)
        .label(formTitle)
        .required('intermediary.validation.mandatory'),

      discloseInformationToThirdPartyInfo: yup
        .string()
        .default('')
        .nullable()
        .label(formTitle)
        .when('discloseInformationToThirdParty', {
          is: true,
          then: schema =>
            schema
              .min(3, 'intermediary.validation.min3')
              .max(300, 'intermediary.validation.max300')
              .required('intermediary.validation.mandatory')
        }),

      loggingReguirementsMet: yup
        .boolean()
        .nullable()
        .default(null)
        .when(
          [
            'followInformationManagementAct',
            'coveredByInformationManagementAct'
          ],
          {
            is: (
              followInformationManagementAct,
              coveredByInformationManagementAct
            ) =>
              (followInformationManagementAct === true &&
                coveredByInformationManagementAct === true) ||
              isEIdentificationForm,
            then: schema =>
              schema.required('intermediary.validation.mandatory'),
            otherwise: schema => schema.notRequired()
          }
        ),
      appropriateResourceHandling: yup
        .boolean()
        .nullable()
        .default(false)
        .label(formTitle),

      serviceDataSavedToRegister: yup
        .boolean()
        .nullable()
        .default(null)
        .label(formTitle)
        .when(
          [
            'followInformationManagementAct',
            'coveredByInformationManagementAct'
          ],
          {
            is: (
              followInformationManagementAct,
              coveredByInformationManagementAct
            ) =>
              followInformationManagementAct === false ||
              (followInformationManagementAct === true &&
                coveredByInformationManagementAct === false),
            then: schema => schema.required('intermediary.validation.mandatory')
          }
        ),

      serviceDataSavedToRegisterInfo: yup
        .string()
        .nullable()
        .default('')
        .when('serviceDataSavedToRegister', {
          is: true,
          then: schema =>
            schema
              .min(3, 'intermediary.validation.min3')
              .max(300, 'intermediary.validation.max300')
              .label(formTitle)
        }),

      vtjDataHandlingAbroadEu: yup
        .boolean()
        .nullable()
        .default(null)
        .label(formTitle)
        .when(
          [
            'followInformationManagementAct',
            'coveredByInformationManagementAct'
          ],
          {
            is: (
              followInformationManagementAct,
              coveredByInformationManagementAct
            ) =>
              followInformationManagementAct === false ||
              (followInformationManagementAct === true &&
                coveredByInformationManagementAct === false),
            then: schema => schema.required('intermediary.validation.mandatory')
          }
        ),

      vtjDataHandlingAbroadEuInfo: yup
        .string()
        .nullable()
        .default('')
        .label(formTitle)
        .when('vtjDataHandlingAbroadEu', {
          is: true,
          then: schema =>
            schema
              .min(3, 'intermediary.validation.min3')
              .max(300, 'intermediary.validation.max300')
              .required('intermediary.validation.mandatory')
        }),

      personnelDataHandlingOutsideOrganisation: yup
        .boolean()
        .nullable()
        .default(null)
        .label(formTitle)
        .when(
          [
            'followInformationManagementAct',
            'coveredByInformationManagementAct'
          ],
          {
            is: (
              followInformationManagementAct,
              coveredByInformationManagementAct
            ) =>
              isEIdentificationForm &&
              !(
                followInformationManagementAct === true &&
                coveredByInformationManagementAct === true
              ),
            then: schema => schema.required('intermediary.validation.mandatory')
          }
        ),

      personnelDataHandlingOutsideOrganisationInfo: yup
        .string()
        .nullable()
        .default('')
        .label(formTitle)
        .when('personnelDataHandlingOutsideOrganisation', {
          is: true,
          then: schema =>
            schema
              .min(3, 'intermediary.validation.min3')
              .max(300, 'intermediary.validation.max300')
              .required('intermediary.validation.mandatory')
        }),
      environmentHandlingOutsideEU: yup
        .boolean()
        .nullable()
        .default(null)
        .label(formTitle)
        .when(
          [
            'followInformationManagementAct',
            'coveredByInformationManagementAct'
          ],
          {
            is: (
              followInformationManagementAct,
              coveredByInformationManagementAct
            ) =>
              !isEIdentificationForm &&
              (followInformationManagementAct === false ||
                (followInformationManagementAct === true &&
                  coveredByInformationManagementAct === false)),
            then: schema =>
              schema.required('intermediary.validation.mandatory'),
            otherwise: schema => schema.notRequired()
          }
        ),
      dataTransferOutsideEU: yup
        .boolean()
        .nullable()
        .default(null)
        .label(formTitle)
        .when(
          [
            'followInformationManagementAct',
            'coveredByInformationManagementAct'
          ],
          {
            is: (
              followInformationManagementAct,
              coveredByInformationManagementAct
            ) =>
              !isEIdentificationForm &&
              (followInformationManagementAct === false ||
                (followInformationManagementAct === true &&
                  coveredByInformationManagementAct === false)),
            then: schema =>
              schema.required('intermediary.validation.mandatory'),
            otherwise: schema => schema.notRequired()
          }
        )
    })
  });
