import iconOk from '!!svg-inline-loader!sema-ui-components/dist/components/icons/old/icon-ok.svg';
import iconFail from '!!svg-inline-loader!sema-ui-components/dist/components/icons/old/icon-fail.svg';
import { actions as notificationActions } from 'redux-notifications';

const { notifSend, notifDismiss } = notificationActions;

export function getDefinitionKey(definitions, name, value) {
  if (!definitions || definitions.length === 0 || !Array.isArray(definitions)) {
    return null;
  }

  const matchingDefs = definitions.filter(
    definition => definition.name === name
  );
  if (matchingDefs.length === 0) {
    return null;
  }

  const matchingValues = matchingDefs[0].values.filter(
    valObj => valObj.value === value
  );
  if (matchingValues.length === 0) {
    return null;
  }

  return matchingValues[0].key;
}

export function sendErrorNotification(title, description, nId) {
  return dispatch => {
    const notificationId =
      typeof nId !== 'undefined' && nId !== null ? nId : new Date().getTime();

    const notificationAction = notifSend({
      id: notificationId,
      type: 'fail',
      title,
      description,
      kind: 'danger',
      dismissAfter: 5000
    });
    dispatch(notificationAction);
  };
}

export function sendSuccessNotification(title, description, nId) {
  return dispatch => {
    const notificationId =
      typeof nId !== 'undefined' && nId !== null ? nId : new Date().getTime();

    const notificationAction = notifSend({
      id: notificationId,
      borderStyle: 'borderGreen',
      title,
      description,
      kind: 'success',
      dismissAfter: 5000
    });
    dispatch(notificationAction);
  };
}

export function sendRetryableErrorNotification(
  title,
  description,
  retryCb,
  retryLabel,
  nId
) {
  return dispatch => {
    const notificationId =
      nId !== undefined && nId !== null ? nId : new Date().getTime();
    const retryCbWithDismiss = () => {
      dispatch(notifDismiss(notificationId));
      retryCb();
    };

    const notificationAction = notifSend({
      id: notificationId,
      type: 'fail',
      title,
      description,
      kind: 'danger',
      retryCb: retryCbWithDismiss,
      retryLabel
    });
    dispatch(notificationAction);
  };
}
