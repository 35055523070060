import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { RoleSelector } from '../RoleSelector';
import { Loader } from './Loader';
import { useFormik } from 'formik';
import {
  combineValidationSchema,
  getForm,
  getSteps,
  isActiveOrgIntermediary,
  useDebouncedValidate,
  handleSetOrganisation
} from '../../utils';
import { formStates, proposalStyles } from '../../constants';
import { InlineAlert } from 'suomifi-ui-components';
import styled from 'styled-components';
import { getActiveOrgSelector } from 'modules/auth/selectors';
import FormView from '../Form/FormView';

const StyledContainer = styled.div`
  margin: 0 auto;
  padding: 2rem;
`;

const FormSetup = (props, { intl }) => {
  const i18n = id => intl.formatMessage({ id });
  const {
    activeOrgId,
    formData,
    initialSteps,
    initialValues,
    isLoading,
    nextFormState,
    params,
    proposalStyle,
    resetFormState,
    route,
    routeParams,
    router,
    serviceType,
    setIsLoading,
    setUnexpectedError,
    setupForm,
    state,
    steps,
    unexpectedError,
    user,
    validationSchema
  } = props;

  const formik = useFormik({
    initialValues, // Initial values from default values or from the backend
    validationSchema, // Validation schema used for the validation
    enableReinitialize: true, // Allow to reinitialize the form with new values
    validateOnChange: false,
    validateOnBlur: true
  });

  // Improve formik performance by debouncing the validation
  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values
  });

  const isIntermediaryOrg = isActiveOrgIntermediary(user);
  const formId = routeParams?.id;

  const [isRoleSelectorOpen, setIsRoleSelectorOpen] = React.useState(false);

  useEffect(() => {
    let values;
    if (formId && formId !== 'uusi' && !formik?.values?.proposalGuid) {
      setIsLoading(true);

      getForm(formId, formStates.DRAFT, serviceType)
        .then(data => {
          values = data;
        })
        .catch(error => {
          setUnexpectedError(error.message);
        })
        .finally(() => {
          handleSavedForm(values);
          setIsLoading(false);
        });
    } else {
      if (isIntermediaryOrg) {
        // Ask intermediary to choose role
        setIsRoleSelectorOpen(true);
      } else {
        // Set proposal style to own for non-intermediary orgs
        handleSetupForm(proposalStyles.OWN);
      }
    }
  }, []);

  useEffect(() => {
    if (proposalStyle === proposalStyles.OWN) {
      const org = getActiveOrgSelector()(state);
      // Do not override values already set
      if (!formik.values?.formData?.customerOrganization?.businessId) {
        handleSetOrganisation(
          {
            ...org,
            guid: org.id
          },
          formik
        );
      }
    }
  }, [proposalStyle]);

  const submitCallback = React.useCallback(() => {
    router.push({
      pathname: `/${params.lang}/own-applications/list`
    });
    resetFormState();
  }, []);

  const handleSavedForm = values => {
    handleSetupForm(values.proposalStyle, values);
  };

  const handleSetupForm = (proposalStyle, values = false) => {
    const schema = combineValidationSchema(
      getSteps(
        proposalStyle === proposalStyles.INTERMEDIARY,
        initialSteps,
        formId,
        formData
      ),
      {
        serviceType,
        proposalStyle,
        activeOrganizationId: activeOrgId
      }
    );

    const initialValues = values || schema.cast({});

    setupForm({
      serviceType,
      validationSchema: schema,
      initialValues,
      steps: getSteps(
        proposalStyle === proposalStyles.INTERMEDIARY,
        initialSteps,
        formId,
        formData
      ),
      nextFormState:
        proposalStyle === proposalStyles.INTERMEDIARY
          ? formStates.WAITING_CUSTOMER_TO_APPROVE
          : formStates.SENT
    });
    if (isRoleSelectorOpen) {
      setIsRoleSelectorOpen(false);
    }
  };

  // Show role selector for intermediaries
  if (isRoleSelectorOpen) {
    return <RoleSelector handleSetupForm={handleSetupForm} />;
  }

  // Show loader if form setup is not ready
  if (!(initialValues && steps && nextFormState) || isLoading) {
    return <Loader i18n={i18n} />;
  }

  if (unexpectedError) {
    return (
      <StyledContainer>
        <InlineAlert
          status="error"
          labelText={i18n('intermediary.common.error')}
        >
          {unexpectedError ? (
            <FormattedMessage id={unexpectedError} />
          ) : (
            <FormattedMessage id="intermediary.common.unexpectedError" />
          )}
        </InlineAlert>
      </StyledContainer>
    );
  }

  return (
    <FormView
      formId={formId}
      submitCallback={submitCallback}
      serviceType={serviceType}
      route={route}
      router={router}
      resetFormState={resetFormState}
      formik={formik}
      {...props}
    />
  );
};

FormSetup.contextTypes = {
  intl: PropTypes.object.isRequired
};

FormSetup.propTypes = {
  activeOrgId: PropTypes.string,
  formData: PropTypes.object,
  initialSteps: PropTypes.array,
  initialValues: PropTypes.object,
  isLoading: PropTypes.bool,
  nextFormState: PropTypes.number,
  params: PropTypes.object,
  proposalStyle: PropTypes.string,
  resetFormState: PropTypes.func,
  route: PropTypes.object,
  routeParams: PropTypes.object,
  router: PropTypes.object,
  serviceType: PropTypes.string,
  setInitialValues: PropTypes.func,
  setIsLoading: PropTypes.func,
  setUnexpectedError: PropTypes.func.isRequired,
  setupForm: PropTypes.func,
  setValidationSchema: PropTypes.func,
  state: PropTypes.object,
  steps: PropTypes.array,
  unexpectedError: PropTypes.bool,
  user: PropTypes.object,
  validationSchema: PropTypes.object
};

export default FormSetup;
