import React from 'react';
import { Heading } from 'suomifi-ui-components';
import PropTypes from 'prop-types';
import styles from './FormHeading.scss';

export const FormHeading = ({ heading, variant = 'h1' }) => (
  <Heading className={styles.heading} variant={variant}>
    {heading}
  </Heading>
);

FormHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  variant: PropTypes.string
};
