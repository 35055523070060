import * as yup from 'yup';

export const formTitle = 'intermediary.extentOfData.formTitle';
import { choiceValues } from './constants';

export const schema = yup.object({
  extentOfPersonalInformation: yup
    .number()
    .oneOf([choiceValues.COMPRESS, choiceValues.MEDIUM, choiceValues.BROAD])
    .nullable()
    .default(null)
    .label(formTitle),
  extentOfPersonalInformationDescription: yup
    .string()
    .when('extentOfPersonalInformation', {
      is: value => [choiceValues.MEDIUM, choiceValues.BROAD].includes(value),
      then: yup
        .string()
        .required('intermediary.validation.reasoningInformationRequired')
        .min(10, 'intermediary.validation.min10')
        .max(300, 'intermediary.validation.max300')
        .label(formTitle)
        .default('')
    })
});
