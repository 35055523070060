import * as yup from 'yup';

export const formTitle = 'intermediary.serviceInfo.formTitle';

export const notificationContactSchema = yup.object({
  contactFirstName: yup
    .string()
    .default('')
    .required('intermediary.validation.firstNameIsRequired')
    .min(3, 'intermediary.validation.min3')
    .max(300, 'intermediary.validation.max300')
    .label(formTitle),
  contactLastName: yup
    .string()
    .default('')
    .required('intermediary.validation.lastNameIsRequired')
    .min(3, 'intermediary.validation.min3')
    .max(300, 'intermediary.validation.max300')
    .label(formTitle),
  contactPhone: yup
    .string()
    .max(20, 'intermediary.validation.max20')
    .default('')
    .notRequired()
    .label(formTitle),
  contactEmail: yup
    .string()
    .email('intermediary.validation.emailIsInvalid')
    .default('')
    .required('intermediary.validation.emailIsRequired')
    .min(3, 'intermediary.validation.min3')
    .max(300, 'intermediary.validation.max300')
    .label(formTitle)
});

export const serviceSchema = yup.object({
  serviceNameFin: yup
    .string()
    .min(3, 'intermediary.validation.min3')
    .required('intermediary.validation.nameIsRequired')
    .default('')
    .label(formTitle),
  serviceNameEng: yup
    .string()
    .min(3, 'intermediary.validation.min3')
    .required('intermediary.validation.nameIsRequired')
    .default('')
    .label(formTitle),
  serviceNameSve: yup
    .string()
    .min(3, 'intermediary.validation.min3')
    .required('intermediary.validation.nameIsRequired')
    .default('')
    .label(formTitle),
  serviceFunctionalDesc: yup
    .string()
    .min(3, 'intermediary.validation.min3')
    .required('intermediary.validation.functionalDescriptionIsRequired')
    .default('')
    .label(formTitle),
  recipientIdSsn: yup
    .boolean()
    .default(false)
    .label(formTitle),
  recipientIdSsnExplanation: yup.string().when('recipientIdSsn', {
    is: true,
    then: yup
      .string()
      .default('')
      .label(formTitle)
      .required('intermediary.validation.reasoningInformationRequired')
  }),
  recipientIdCompany: yup
    .boolean()
    .default(false)
    .label(formTitle),
  lawSection: yup.object({
    fivePointOne: yup
      .boolean()
      .default(false)
      .label(formTitle),
    fivePointTwo: yup
      .boolean()
      .default(false)
      .label(formTitle),
    fivePointThree: yup
      .boolean()
      .default(false)
      .label(formTitle)
  }),
  connectionMethodWeb: yup
    .boolean()
    .default(false)
    .label(formTitle),
  connectionMethodEmail: yup
    .boolean()
    .default(false)
    .label(formTitle),
  connectionMethodIpost: yup
    .boolean()
    .default(false)
    .label(formTitle),
  connectionMethodLater: yup
    .boolean()
    .default(false)
    .label(formTitle),
  connectionMethodProduct: yup
    .boolean()
    .default(false)
    .label(formTitle),
  newMsgTarget: yup
    .number()
    .nullable()
    .default(null)
    .label(formTitle)
    .when(
      [
        'connectionMethodWeb',
        'connectionMethodEmail',
        'connectionMethodIpost',
        'connectionMethodProduct'
      ],
      {
        is: (
          connectionMethodWeb,
          connectionMethodEmail,
          connectionMethodIpost,
          connectionMethodProduct
        ) =>
          connectionMethodWeb ||
          connectionMethodEmail ||
          connectionMethodIpost ||
          connectionMethodProduct,
        then: schema => schema.required()
      }
    ),
  sendReadConfirmationAck: yup
    .number()
    .nullable()
    .default(null)
    .label(formTitle)
    .when(['connectionMethodWeb', 'connectionMethodProduct'], {
      is: (connectionMethodWeb, connectionMethodProduct) =>
        connectionMethodWeb || connectionMethodProduct,
      then: schema => schema.required()
    }),
  productName: yup.string().when('connectionMethodProduct', {
    is: true,
    then: yup
      .string()
      .default('')
      .label(formTitle)
      .required('intermediary.validation.productNameIsRequired')
  }),
  notificationContacts: yup
    .array()
    .of(notificationContactSchema)
    .default([notificationContactSchema.cast({})])
});

export const schema = yup.object({
  serviceData: yup.object({
    services: yup
      .array()
      .of(serviceSchema)
      .default([serviceSchema.cast({})])
      .label(formTitle),
    replyAllowed: yup
      .number()
      .required()
      .nullable()
      .default(null)
      .label(formTitle),
    sendDeliveryAck: yup
      .number()
      .required()
      .nullable()
      .default(null)
      .label(formTitle),
    sendReadAck: yup
      .number()
      .required()
      .nullable()
      .default(null)
      .label(formTitle)
  })
});
