import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectReducer } from 'store/reducers';
import { requireStrongAuth, requireService } from 'modules/auth/utils';
import { services } from 'constants/index';

export default store => ({
  getComponents(nextState, next) {
    require.ensure(
      ['./containers/ServicesContainer', './modules'],
      require => {
        const reducer = require('./modules').default;
        injectReducer(store, { key: 'idAdmin', reducer });

        const content = require('./containers/ServicesContainer').default;
        next(null, { content });
      },
      'idAdmin'
    );
  },
  getChildRoutes: (location, cb) => {
    const auth = store.getState().auth;
    const availServices = (auth && auth.user && auth.user.availServices) || [];
    const serviceCheck = () =>
      availServices.find(s => s === services.EIDENTIFICATION);
    const roleCheck = user =>
      user.roles[services.EIDENTIFICATION] &&
      user.roles[services.EIDENTIFICATION] !== 'no_role';
    const authenticate =
      requireStrongAuth(auth) &&
      requireService(
        auth.user,
        user => serviceCheck(user) && roleCheck(user),
        '/dashboard'
      );

    cb(null, [
      {
        path: 'tunnistus',
        name: <FormattedMessage id="identification_admin.title" />,
        onEnter: authenticate,
        childRoutes: [
          {
            path: 'lisaa-palvelu',
            name: (
              <FormattedMessage id="identification_admin.add_service.title" />
            ),
            getComponents(nextState, next) {
              const content = require('./containers/CreateServiceContainer')
                .default;
              next(null, content);
            }
          },
          {
            path: ':name',
            name: (
              <FormattedMessage id="identification_admin.environment.environments" />
            ),
            getComponents(nextState, next) {
              const content = require('./containers/EnvironmentsContainer')
                .default;
              next(null, content);
            },
            childRoutes: [
              {
                path: 'rekisteroi-ymparisto',
                name: (
                  <FormattedMessage id="identification_admin.environment.register.title" />
                ),
                getComponents(nextState, next) {
                  const content = require('./containers/RegisterEnvironmentContainer')
                    .default;
                  next(null, content);
                }
              },
              {
                path: ':env',
                name: 'identification_admin.environment.title',
                getComponents(nextState, next) {
                  const content = require('./containers/EnvironmentDetailsContainer')
                    .default;
                  next(null, content);
                }
              }
            ]
          }
        ]
      }
    ]);
  }
});
