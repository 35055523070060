/*eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { PageHeader } from 'sema-ui-components';

import styles from '../../StaticArticles/styles/StaticArticleStyles.scss';

import roadmap from '../images/roadmap.png';

export const ArticleRoadmap = ({ content, params, routes }, intl) => {
  return <section>
    <div className="row">
      <div className={cx(styles.ingressColumn, 'col-md-24')}>
        <PageHeader
          title={<span>Suomi.fi-palveluhallinnan kehityksen tiekartta</span>}
          routes={routes}
          params={params} />
      </div>
    </div>
    <div className={styles.tabContent}>

      <div className="row">
        <div className="col-md-4" />
        <div className="col-md-16">
          <p>
            Suomi.fi-palveluhallintaa kehitetään syksyllä 2017 siten, että se
            sisältää Suomi.fi-tunnistuksen ja -palvelutietovarannon
            hallintaliittymät sekä käyttölupahakemukset. Syksyn aikana tehdään
            myös ensimmäinen tuotantoversio integroidusta hakemuksesta
            Palveluhallinnan taustajärjestelmiin. Integroinnin myötä asiakas voi
            tarkastella lähettämänsä käyttölupahakemuksen käsittelyn tilaa ja
            lopulta myös päätöstä. Asiakkaan lähettämä hakemus tallentuu
            muistiin omalle tilille, eikä asioita tarvitse enää kaivaa
            sähköpostista. Jatkossa kertaalleen lähetetyn hakemuksen voi ottaa
            pohjaksi uudelle hakemukselle.
          </p>
          <p>
            Ohessa on kuvattu Palveluhallinnan keskeisiä kehitystehtäviä ensi
            kesään mennessä. Palvelut siirtyvät vähitellen hallintaliittymineen
            osaksi Palveluhallintaa. Uudet asiakkaat voivat ottaa
            Palveluhallinnassa käyttöön ne Suomi.fi-palvelut, joiden
            käyttöönotto on jo siirretty esuomi.fistä kokonaan
            Palveluhallintaan. Muut Suomi.fi-palvelujen käyttöönotot tehdään
            edelleen esuomi.fissä. Palveluhallinnassa ohjeistetaan, kummassa
            verkkopalvelussa hakemus kulloinkin on tehtävissä.
          </p>

        </div>
        <div className="col-md-4" />
      </div>

      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-22">
          <img className={styles.imageStyle} src={roadmap} />
        </div>
        <div className="col-md-1" />
      </div>

    </div>
  </section>;
};

ArticleRoadmap.propTypes = {
  content: PropTypes.string,
  params: PropTypes.object,
  routes: PropTypes.array
};

ArticleRoadmap.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ArticleRoadmap;
/*eslint-enable */
