import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Paragraph } from 'suomifi-ui-components';
import { renderDate } from '../utils/applicationView';
import styles from '../styles/ApplicationView.scss';
import { TruncatedText } from './TruncatedText';

export const DecisionDetails = ({ i18n, application }) => {
  const {
    formState,
    background,
    rejectionReason,
    modifiedDate
  } = application.decisionData;

  const hasBackground = background && background.length;
  const hasRejectionReason = rejectionReason && rejectionReason.length;
  const MAX_TEXT_LENGTH = 150;

  return (
    <div className={styles.detailsContainer}>
      <Heading variant="h4">
        {i18n('intermediary.decision.basic-information')}
      </Heading>
      <hr />
      <div className={styles.detail}>
        <Heading variant="h5">{i18n('intermediary.application.state')}</Heading>
        <Paragraph>{i18n(`intermediary.states.${formState}`)}</Paragraph>
      </div>
      <div className={styles.detail}>
        <Heading variant="h5">
          {i18n('intermediary.application.updated')}
        </Heading>
        <Paragraph>{renderDate(new Date(modifiedDate))}</Paragraph>
      </div>
      {hasBackground && (
        <div className={styles.detail}>
          <Heading variant="h5">
            {i18n('intermediary.decision.background')}
          </Heading>
          <TruncatedText
            textValue={background}
            maxCharacters={MAX_TEXT_LENGTH}
          />
        </div>
      )}
      {hasRejectionReason && (
        <div className={styles.detail}>
          <Heading variant="h5">
            {i18n('intermediary.decision.rejection-reason')}
          </Heading>
          <TruncatedText
            textValue={rejectionReason}
            maxCharacters={MAX_TEXT_LENGTH}
          />
        </div>
      )}
    </div>
  );
};

DecisionDetails.propTypes = {
  application: PropTypes.shape({
    decisionData: PropTypes.shape({
      formState: PropTypes.string.isRequired,
      background: PropTypes.string,
      rejectionReason: PropTypes.string,
      modifiedDate: PropTypes.string
    })
  }),
  i18n: PropTypes.func.isRequired
};
