import {
  createConsts as cc,
  createReducer,
  createAction as ca
} from '../../../utils';
import { CALL_API } from '../../../store/middleware/api';
import { updateUserProfile } from './api';
import { reset } from 'redux-form';

// ------------------------------------
// Constants & FSA actions
// ------------------------------------
export const [
  [PROFILE_RETRIEVE, retrieveProfile],
  [PROFILE_RETRIEVE_SUCCESS, receiveProfile],
  [PROFILE_RETRIEVE_ERROR, retrieveError],
  [PROFILE_UPDATE, updateProfile],
  [PROFILE_UPDATE_SUCCESS, profileUpdated],
  [PROFILE_UPDATE_ERROR, updateError],
  [CLEAR_STATE, clearState]
] = cc([
  'PROFILE_RETRIEVE',
  'PROFILE_RETRIEVE_SUCCESS',
  'PROFILE_RETRIEVE_ERROR',
  'PROFILE_UPDATE',
  'PROFILE_UPDATE_SUCCESS',
  'PROFILE_UPDATE_ERROR',
  'CLEAR_STATE'
]);

export function resetForm(formID) {
  return async dispatch => {
    dispatch(reset(formID));
  };
}

export function requestProfileUpdate(orgId, userId, profile) {
  return async dispatch => {
    dispatch(ca(PROFILE_UPDATE)());
    try {
      await updateUserProfile(orgId, userId, profile);
      dispatch(ca(PROFILE_UPDATE_SUCCESS)());
      return { error: undefined };
    } catch (err) {
      dispatch(ca(PROFILE_UPDATE_ERROR)(err));
      return { error: err };
    }
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PROFILE_RETRIEVE]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [PROFILE_RETRIEVE_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    form: payload.user.jsonObject
  }),
  [PROFILE_RETRIEVE_ERROR]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [PROFILE_UPDATE]: (state, { payload }) => ({
    ...state,
    isSubmitting: true
  }),
  [PROFILE_UPDATE_SUCCESS]: state => ({
    ...state,
    isSubmitting: false
  }),
  [PROFILE_UPDATE_ERROR]: (state, { payload }) => ({
    ...state,
    isSubmitting: false,
    error: payload
  }),
  [CLEAR_STATE]: state => ({
    ...state,
    nameValid: false,
    emailValid: false,
    messageValid: false
  })
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = {
  isInitialized: false,
  isFetching: false,
  isSubmitting: false,
  form: {}
};
export default createReducer(ACTION_HANDLERS, initialState);
