import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import cx from 'classnames';
import styles from './SearchView.scss';
import searchIcon from '!!svg-inline-loader!sema-ui-components/src/components/icons/illustration/icon-haku.svg';
import InlineSVG from 'svg-inline-react';

const LinkRow = ({ locale }) => (
  <div className="row v-offset-5">
    <div className="col-sm-24 col-md-24 col-lg-9">
      <PrettyLink
        title={<FormattedMessage id="search.no_results_link_services" />}
        path={`/${locale}/sivut/palveluhallinnasta`}
      />
    </div>
    <div className="col-sm-24 col-md-24 col-lg-6">
      <PrettyLink
        title={<FormattedMessage id="reports.title" />}
        path={`/${locale}/reports`}
      />
    </div>
    <div className="col-sm-24 col-md-24 col-lg-9">
      <PrettyLink
        title={<FormattedMessage id="search.no_results_link_support" />}
        path={`/${locale}/tuki`}
      />
    </div>
  </div>
);
LinkRow.propTypes = {
  locale: PropTypes.string
};

const PrettyLink = ({ title, path }) => (
  <div className={styles.prettyLink}>
    <Link to={path}>
      {title}
      <div className={styles.caretCircle} />
    </Link>
  </div>
);
PrettyLink.propTypes = {
  title: PropTypes.object,
  path: PropTypes.string
};

export const NoResults = ({ searchTerm, locale }) => {
  return (
    <div className={cx('row', styles.noResultsBlock)}>
      <div className="col-24 center">
        <h3>
          <FormattedMessage id="search.no_results_for" /> &ldquo;
          {searchTerm}&rdquo;
        </h3>
        <div className="v-offset-3">
          <div className={cx('offset-md-8 col-md-8')}>
            <div className={styles.svgSearchIcon}>
              <InlineSVG viewBox="0 0 192 174" src={searchIcon} />
            </div>
          </div>
          <div className={cx(styles.linkStrip)}>
            <div className="container">
              <LinkRow locale={locale} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
NoResults.propTypes = {
  searchTerm: PropTypes.string,
  locale: PropTypes.string
};
