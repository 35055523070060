import { SEMA_ADMIN, VRK_ADMIN } from 'constants/index';

export function hasVrkServiceAdmin(user) {
  return (
    (user &&
      user.vrkServices &&
      user.vrkServices.sema &&
      user.vrkServices.sema.indexOf(VRK_ADMIN) > -1) ||
    (user &&
      user.vrkServices &&
      user.vrkServices.allServices &&
      user.vrkServices.allServices.indexOf(VRK_ADMIN) > -1)
  );
}

export function hasVrkAdmin(user) {
  return (
    user &&
    user.vrkServices &&
    user.vrkServices.allServices &&
    user.vrkServices.allServices.indexOf(VRK_ADMIN) > -1
  );
}

export function checkKVHAccess(roles) {
  const sema = (roles && roles.sema) || [];
  const vrkSema =
    (roles && roles.vrkServices && roles.vrkServices.allServices) || [];
  return sema.find(r => r === SEMA_ADMIN) || vrkSema.find(r => r === VRK_ADMIN);
}
