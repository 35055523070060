import React from 'react';
import HomeViewContainer from './containers/HomeViewContainer';
import HomeHeader from './components/HomeHeader';
import reducer from './modules';
import styles from './components/HomeHeader/styles.scss';

export default store => ({
  headerClassName: styles.mainHeader,
  getComponents(nextState, next) {
    next(null, {
      headerExtra: props => <HomeHeader {...props} />,
      content: HomeViewContainer
    });
  }
});
