import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { compose, withHandlers, pure } from 'recompose';
import { FormattedMessage } from 'react-intl';
import TranslatableHelmet from 'components/TranslatableHelmet';
import { Button } from 'sema-ui-components';
import styles from './NotFound.scss';

export const NotFound = ({ handleGoHome, params: { lang } }) => (
  <div className={styles.base}>
    <TranslatableHelmet locale={lang} transKey="general.not_found.title" />
    <h2 className="center">
      <FormattedMessage id="general.not_found.title" />
    </h2>
    <div className="row">
      <div className="offset-md-7 col-md-10">
        <p className={styles.description}>
          <FormattedMessage id="general.not_found.description" />
        </p>
        <div className="text-center">
          <Button medium onClick={handleGoHome}>
            <FormattedMessage id="general.not_found.back_to_home" />
          </Button>
        </div>
      </div>
    </div>
  </div>
);

NotFound.propTypes = {
  handleGoHome: PropTypes.func.isRequired
};

export default compose(
  withHandlers({
    handleGoHome: props => () => browserHistory.push(`/${props.params.lang}`)
  }),
  pure
)(NotFound);
