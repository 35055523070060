import React from 'react';
import styles from '../styles/ApplicationView.scss';

export const renderDate = date => (
  <>
    <span>{`${date.getDate()}.${date.getMonth() +
      1}.${date.getFullYear()}`}</span>
    <span className={styles.time}>{`(${date.getHours()}:${(date.getMinutes() <
      10 && '0') + date.getMinutes()})`}</span>
  </>
);

export const printingUrl = '/api/forms/document/print/';
