import React from 'react';
import { injectReducer, injectReducers } from '../../store/reducers';
import { requireAuth, requireStrongAuth, hasRole } from 'modules/auth/utils';
import { FormattedMessage } from 'react-intl';

const authenticate = store => requireAuth(store.getState().auth);

export const tunnistusAccessForm = store => ({
  path: 'tunnistus-liittymisen-edellytykset2',
  onEnter: requireStrongAuth(store.getState().auth),
  getComponents(nextState, next) {
    require.ensure(
      ['./Forms/TunnistusAccess/TunnistusAccessContainer'],
      require => {
        const formReducer = require('./modules').default;
        const dashboardReducer = require('../Dashboard/modules').default;

        injectReducers(store, [
          { key: 'forms', reducer: formReducer },
          { key: 'dashboard', reducer: dashboardReducer }
        ]);

        const content = require('./Forms/TunnistusAccess/TunnistusAccessContainer')
          .default;
        next(null, { content });
      },
      'ownForms'
    );
  }
});

export const tunnistusSimpleRegistrationForm = store => ({
  path: 'tunnistus-hae-kayttolupaa2',
  onEnter: requireStrongAuth(store.getState().auth),
  getComponents(nextState, next) {
    require.ensure(
      [
        './Forms/TunnistusSimpleRegistration/TunnistusSimpleRegistrationContainer'
      ],
      require => {
        const emailReducer = require('./modules/module_emails').default;
        const dashboardReducer = require('../Dashboard/modules').default;
        const formReducer = require('./modules').default;
        const documentsReducer = require('./modules/module_documents').default;

        injectReducers(store, [
          { key: 'forms', reducer: formReducer },
          { key: 'dashboard', reducer: dashboardReducer },
          { key: 'formEmails', reducer: emailReducer },
          { key: 'formDocuments', reducer: documentsReducer }
        ]);

        const content = require('./Forms/TunnistusSimpleRegistration/TunnistusSimpleRegistrationContainer')
          .default;
        next(null, { content });
      },
      'ownForms'
    );
  }
});

export const acceptForms = store => ({
  path: 'accept-form/:formReference*',
  getComponents(nextState, next) {
    require.ensure(
      ['./containers/AcceptFormsContainer'],
      require => {
        const reducer = require('./modules/module_AcceptForms').default;
        injectReducer(store, { key: 'acceptFormView', reducer });

        const content = require('./containers/AcceptFormsContainer').default;
        next(null, { content });
      },
      'ownForms'
    );
  }
});
