import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormikProvider } from 'formik';
import { Block, Alert } from 'suomifi-ui-components';
import { SuccessMessage } from './SuccessMessage';
import { FormattedMessage } from 'react-intl';
import Prefill from '../Prefill';

import {
  Container,
  Content,
  Footer,
  FormHeading,
  FormModals,
  Steps
} from '../';

import {
  backendSchemaToFormikSchema,
  postData,
  validateSteps
} from '../../utils';

import { formStates } from '../../constants';

const FormView = (
  {
    closeModal,
    currentStep,
    deleteAttachment,
    errorFetchingYTJData,
    formId,
    formik,
    getYTJData,
    initialValues,
    isFetchingYTJData,
    isOpen,
    isTransferringAfterButtonClick,
    nextFormState,
    openModal,
    proposalStyle,
    resetFormState,
    route,
    router,
    sendAttachment,
    sendSuccessNotification,
    serviceType,
    setCompleted,
    setErrorFetchingYTJData,
    setErrors,
    setIsSavingForm,
    steps,
    submitCallback,
    transferAfterButtonClick,
    validationSchema,
    ytjData
  },
  { intl }
) => {
  const i18n = id => intl.formatMessage({ id });
  const [hasChanges, setHasChanges] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { location } = router;
  const { locale } = intl;

  // Form that is currently being rendered
  const CurrentForm = useMemo(
    () => steps.find((_, i) => i === currentStep)?.Form || null,
    [steps, currentStep]
  );

  // Save the form as draft
  const handleSave = async () => {
    setIsSavingForm(true);
    formik.setFieldValue('formData.language', locale);
    try {
      const data = await postData(formik.values, formStates.DRAFT);
      if ((!formId || formId === 'uusi') && !formik.values?.proposalGuid) {
        formik.setValues(backendSchemaToFormikSchema(data));
        setHasChanges(false);

        // Modify the url to include the proposal guid but do not rerender
        history.pushState(
          {},
          null,
          location.pathname?.replace('uusi', data.proposalGuid)
        );
      }
      setShowSaveSuccess(true);
      setHasChanges(false);
      setTimeout(() => setShowSaveSuccess(false), 3500);
    } catch (error) {
      console.log({ error });
      setSubmitError(error.message);
    } finally {
      setIsSavingForm(false);
    }
  };

  const sendSuccess = () => {
    sendSuccessNotification(
      'intermediary.notifications.submitted.title',
      'intermediary.notifications.submitted.description'
    );
  };

  // Submit the form
  const handleSubmit = async () => {
    formik.setFieldValue('formData.language', locale);
    try {
      setIsSubmitting(true);
      const res = await postData(formik.values, nextFormState);
      if (!res.error) {
        sendSuccess();
      }

      setHasChanges(false);
      submitCallback();
    } catch (error) {
      setSubmitError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = () => {
    if (!hasChanges) {
      setHasChanges(true);
    }
  };

  const handleBlur = () => validateSteps(steps, formik.values, setCompleted);

  const routerWillLeave = nextLocation => {
    // return false to prevent a transition w/o prompting the user,
    // or return a string to allow the user to decide:
    // return `null` or nothing to let other hooks to be executed
    //
    // NOTE: if you return true, other hooks will not be executed!
    if (
      hasChanges &&
      !isOpen &&
      !isTransferringAfterButtonClick &&
      !nextLocation?.state?.submitted
    ) {
      openModal({
        content: (
          <FormModals
            closeModal={closeModal}
            i18n={i18n}
            actionCreator={transferAfterButtonClick}
            textKey="leave_modal"
            path={nextLocation.pathname}
          />
        )
      });
      return false;
    } else {
      resetFormState();
      setHasChanges(false);
      return true;
    }
  };

  // Set the formik errors to the window object so that the error messages can be shown in the console
  useEffect(() => {
    window.formikErrors = formik.errors;
  }, [formik.errors]);

  // Set the route leave hook to trigger the modal when the user tries to leave the page
  useEffect(() => {
    router.setRouteLeaveHook(route, nextLocation =>
      routerWillLeave(nextLocation)
    );
  }, [hasChanges, isTransferringAfterButtonClick, isOpen]);

  // Keep track of errors & valid steps
  useEffect(() => {
    validateSteps(steps, formik.values, setCompleted);
    validationSchema
      ?.validate(formik.values, { abortEarly: false })
      .then(() => setErrors([]))
      .catch(err => setErrors(err.inner));
  }, [formik.values, currentStep]);

  if (!CurrentForm || !validationSchema || !initialValues || !formik.values) {
    return null;
  }

  const headingText = `${i18n(`intermediary.services.${serviceType}`)} - ${i18n(
    'intermediary.application.license'
  )}`;

  return (
    <>
      <Prefill
        formik={formik}
        proposalStyle={proposalStyle}
        serviceType={serviceType}
      />
      <FormHeading variant="h1" heading={headingText} />
      {showSaveSuccess && <SuccessMessage />}
      <Container>
        <>
          <Steps i18n={i18n} />
          <Content>
            <form
              onChange={handleChange}
              onSubmit={e => e.preventDefault()}
              onBlur={handleBlur}
            >
              <FormikProvider value={formik}>
                <CurrentForm
                  formik={formik}
                  proposalStyle={proposalStyle}
                  serviceType={serviceType}
                  sendAttachment={sendAttachment}
                  deleteAttachment={deleteAttachment}
                  getYTJData={getYTJData}
                  ytjData={ytjData}
                  isFetchingYTJData={isFetchingYTJData}
                  errorFetchingYTJData={errorFetchingYTJData}
                  setErrorFetchingYTJData={setErrorFetchingYTJData}
                />
              </FormikProvider>
              {submitError && (
                <Block my="s">
                  <Alert
                    status="warning"
                    closeText={i18n('intermediary.common.close')}
                    onCloseButtonClick={() => setSubmitError(null)}
                  >
                    <FormattedMessage id={submitError} />
                  </Alert>
                </Block>
              )}
              <Footer
                handleSave={handleSave}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            </form>
          </Content>
        </>
      </Container>
    </>
  );
};

FormView.contextTypes = {
  intl: PropTypes.object.isRequired
};

FormView.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  deleteAttachment: PropTypes.func,
  formId: PropTypes.string,
  formik: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  isTransferringAfterButtonClick: PropTypes.bool.isRequired,
  nextFormState: PropTypes.number,
  openModal: PropTypes.func.isRequired,
  resetFormState: PropTypes.func.isRequired,
  proposalStyle: PropTypes.string,
  route: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  sendAttachment: PropTypes.func,
  sendSuccessNotification: PropTypes.func.isRequired,
  serviceType: PropTypes.string.isRequired,
  setCompleted: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setIsSavingForm: PropTypes.func.isRequired,
  setUnexpectedError: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  submitCallback: PropTypes.func.isRequired,
  transferAfterButtonClick: PropTypes.func.isRequired,
  validationSchema: PropTypes.object,
  ytjData: PropTypes.array,
  isFetchingYTJData: PropTypes.bool,
  errorFetchingYTJData: PropTypes.bool,
  setErrorFetchingYTJData: PropTypes.func,
  getYTJData: PropTypes.func
};

export default FormView;
