import { createAction as ca, createReducer } from 'utils';
import { CALL_API } from 'store/middleware/api';

// ------------------------------------
// Constants & FSA actions
// ------------------------------------
const GET_NOTIFS_REQUEST = 'GET_NOTIFS_REQUEST';
const GET_NOTIFS_SUCCESS = 'GET_NOTIFS_SUCCESS';
const GET_NOTIFS_FAILURE = 'GET_NOTIFS_FAILURE';
const GET_NOTIF_STATS_REQUEST = 'GET_NOTIF_STATS_REQUEST';
const GET_NOTIF_STATS_SUCCESS = 'GET_NOTIF_STATS_SUCCESS';
const GET_NOTIF_STATS_FAILURE = 'GET_NOTIF_STATS_FAILURE';

// ------------------------------------
// Async actions
// ------------------------------------
export function asyncFetchNotifs(page, count) {
  let params = '';
  if (page !== undefined && count !== undefined) {
    params = '?page=' + page + '&count=' + count;
  }

  return {
    [CALL_API]: {
      types: [GET_NOTIFS_REQUEST, GET_NOTIFS_SUCCESS, GET_NOTIFS_FAILURE],
      endpoint: `notifications${params}`
    }
  };
}

export function asyncGetNotifStats(params) {
  return {
    [CALL_API]: {
      types: [
        GET_NOTIF_STATS_REQUEST,
        GET_NOTIF_STATS_SUCCESS,
        GET_NOTIF_STATS_FAILURE
      ],
      endpoint: `notifications/stats`
    }
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GET_NOTIFS_REQUEST]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [GET_NOTIFS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    notifications: payload
  }),
  [GET_NOTIFS_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [GET_NOTIF_STATS_REQUEST]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [GET_NOTIF_STATS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false
    //stats: payload // unused functionality
  }),
  [GET_NOTIF_STATS_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  })
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = {
  notifications: { notifications: [] },
  stats: { byGroup: {}, total: 0 },
  isFetching: false
};

export default createReducer(ACTION_HANDLERS, initialState);
