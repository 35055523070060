import * as yup from 'yup';
import {
  validateBusinessId,
  validateInvalidCharacters
} from '../../../../utils/validator';

export const formTitle = 'intermediary.organisationInfo.formTitle';
export const formTitleIntermediary = `${formTitle}Intermediary`;

export const schema = isIntermediary => {
  const title = isIntermediary ? formTitleIntermediary : formTitle;

  return yup.object({
    customerOrganization: yup.object({
      businessId: yup
        .string()
        .max(20, 'intermediary.validation.max20')
        .required('intermediary.validation.businessIdIsRequired')
        .default('')
        .label(title)
        .when('noBusinessId', {
          is: true,
          then: schema => schema.notRequired()
        }),
      organizationName: yup
        .string()
        .max(300, 'intermediary.validation.max300')
        .default('')
        .label(title)
        .required('intermediary.validation.nameIsRequired')
        .test(
          'valid-organization-name',
          'intermediary.validation.textContainsInvalidCharacters',
          value => !validateInvalidCharacters(value)
        ),
      organizationUnit: yup
        .string()
        .max(300, 'intermediary.validation.max300')
        .default('')
        .notRequired()
        .nullable()
        .label(title)
        .test(
          'valid-organization-unit',
          'intermediary.validation.textContainsInvalidCharacters',
          value => !validateInvalidCharacters(value)
        ),
      address: yup
        .string()
        .max(300, 'intermediary.validation.max300')
        .default('')
        .label(title)
        // .required('intermediary.validation.addressIdRequired'),
        .nullable()
        .notRequired(),
      postalCode: yup
        .string()
        .max(20, 'intermediary.validation.max20')
        .default('')
        .label(title)
        .nullable()
        // .required('intermediary.validation.postalCodeRequired'),
        .notRequired(),
      postOffice: yup
        .string()
        .max(300, 'intermediary.validation.max300')
        .default('')
        .label(title)
        .nullable()
        // .required('intermediary.validation.postalOfficeRequired'),
        .notRequired(),
      altBusinessId: yup
        .string()
        .default('')
        .notRequired()
        .when('noBusinessId', {
          is: true,
          then: yup
            .string()
            .required('intermediary.validation.altBusinessIdRequired')
            .max(20, 'intermediary.validation.max20')
            .label(title)
            .test(
              'valid-alt-business-id',
              'intermediary.validation.altBusinessIdIsWrongFormat',
              value => !validateBusinessId(value)
            )
        }),
      noBusinessId: yup
        .boolean()
        .default(false)
        .notRequired()
        .label(title)
    })
  });
};
