import {
  postSemaJSON,
  getSemaJSON,
  deleteGeneric,
  putSemaJSON,
  getKVHJSON
} from 'utils/http';

export async function findOrgProfile(orgId) {
  return getSemaJSON(`/api/orgprofile/${orgId}`);
}

export function createOrgProfile(orgId, profile) {
  const profileData = { organizationGuid: orgId, data: profile };
  return postSemaJSON(`/api/orgprofile/${orgId}`, profileData);
}

export function updateOrgProfile(orgId, profile) {
  const profileData = { organizationGuid: orgId, data: profile };
  return putSemaJSON(`/api/orgprofile/${orgId}`, profileData);
}

export function deleteOrgProfile(orgId) {
  return deleteGeneric(`/api/orgprofile/${orgId}`);
}

export function fetchUsersForOrg(orgId, roles, sahaGuid) {
  return getKVHJSON(`/api/saha/organizationuserandroles/${orgId}`, sahaGuid);
}
