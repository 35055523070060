import { connect } from 'react-redux';
import { actions as notificationActions } from 'redux-notifications';
import view from '../components/CreateServiceView';
import { getActiveOrgSelector } from 'modules/auth/selectors';
import { createIdProvider, updateIdProvider } from '../modules';

const { notifSend } = notificationActions;

const mapActionCreators = {
  createIdProvider,
  notifSend,
  updateIdProvider
};

const mapStateToProps = state => ({
  currentProvider: state.idAdmin.currentProvider,
  activeOrgSemaID: state.idAdmin.activeOrgSemaID
});

export default connect(mapStateToProps, mapActionCreators)(view);
