/* eslint-disable react/prop-types */
import React from 'react';
import { DateInput } from 'suomifi-ui-components';
import { format, subDays } from 'date-fns';

function DatePicker({ value = new Date(), label, ...props }) {
  return (
    <DateInput
      labelText={label}
      minDate={subDays(new Date(), 1)}
      value={format(value, 'd.M.yyyy')}
      datePickerEnabled
      wrapperProps={{ style: { width: '400px' } }}
      {...props}
    />
  );
}

export default DatePicker;
