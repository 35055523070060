import { createConsts as cc, createReducer } from '../../../utils';

// ------------------------------------
// Constants & FSA actions
// ------------------------------------
export const [[OPEN_MODAL, openModal], [CLOSE_MODAL, closeModal]] = cc([
  'OPEN_MODAL',
  'CLOSE_MODAL'
]);

export const modalActions = { closeModal, openModal };

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [OPEN_MODAL]: (state, { payload }) => ({
    isOpen: true,
    content: payload.content
  }),
  [CLOSE_MODAL]: () => ({
    isOpen: false,
    content: null
  })
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = { isOpen: false, content: null };
export default createReducer(ACTION_HANDLERS, initialState);
