import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconRemove } from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { IntermediaryTextField } from '..';

const StyledPersonContainer = styled.div`
  border-bottom: 1px solid bg-border-color(border);
  background: #f7fafd;
  padding: 0.25rem 1.5rem 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid #c8cdd0;
`;

const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const PersonFields = ({ name, onDelete }) => {
  return (
    <StyledPersonContainer>
      <StyledFlexContainer>
        <IntermediaryTextField
          name={`${name}.firstName`}
          placeholder={'intermediary.field.writeFirstName'}
          label={'intermediary.field.firstName'}
          width={'80%'}
        />
        {onDelete ? (
          <Button
            variant="secondaryNoBorder"
            icon={<IconRemove />}
            onClick={onDelete}
          >
            <FormattedMessage id="general.form.delete" />
          </Button>
        ) : null}
      </StyledFlexContainer>
      <IntermediaryTextField
        name={`${name}.lastName`}
        placeholder={'intermediary.field.writeLastName'}
        label={'intermediary.field.lastName'}
        width={'80%'}
      />

      <IntermediaryTextField
        name={`${name}.email`}
        placeholder={'intermediary.field.writeEmail'}
        label={'intermediary.field.email'}
        width={'80%'}
      />

      <IntermediaryTextField
        name={`${name}.phoneNumber`}
        placeholder={'intermediary.field.writePhone'}
        label={'intermediary.field.phone'}
        width={'60%'}
        optional
      />
    </StyledPersonContainer>
  );
};
PersonFields.propTypes = {
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func
};
