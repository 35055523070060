import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { browserHistory } from 'react-router';
import { Button } from 'sema-ui-components';
import styles from './PasswordResetResultView.scss';

export class PasswordResetResultView extends React.Component {
  signInStrong = () => {
    browserHistory.push('/login');
  };

  render() {
    const { title, message } = this.props.location.query;

    return (
      <section>
        <h1>
          <FormattedMessage id={title} />
        </h1>
        <p>
          <FormattedMessage id={message} />
        </p>
      </section>
    );
  }
}

export default PasswordResetResultView;
