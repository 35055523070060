import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import { FormattedMessage } from 'react-intl';
import {
  IconCheckCircleFilled,
  Button,
  IconRemove
} from 'suomifi-ui-components';

export const AcceptedFiles = ({ files, removeFile }) => {
  return (
    <ul>
      {(files || []).map(file => (
        <li className={styles.fileRow} key={file.tempFileName}>
          <div>
            <IconCheckCircleFilled className={styles.success} />
            {file.originalFileName}
          </div>
          <Button
            variant="secondary"
            icon={<IconRemove />}
            className={styles.removeFile}
            onClick={() => removeFile(file.tempFileName)}
          >
            <FormattedMessage id={'intermediary.common.remove'} />
          </Button>
        </li>
      ))}
    </ul>
  );
};
AcceptedFiles.propTypes = {
  files: PropTypes.array,
  removeFile: PropTypes.func
};
