const worker = () => {
  let timerInterval;
  let time = 0;
  self.onmessage = function({ data: { turn, logoutInMillis } }) {
    if (turn === 'off' || timerInterval) {
      clearInterval(timerInterval);
      time = 0;
    }
    if (turn === 'on') {
      time = logoutInMillis;
      timerInterval = setInterval(() => {
        time -= 1000;
        self.postMessage({ time });
      }, 1000);
    }
  };
};

export default worker;
