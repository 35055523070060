import { services } from 'constants/index';

export const Forms = {
  generalRegistrationForm: {
    id: 'generalRegistrationForm',
    locale: 'general_registration'
  },
  tunnistusAccessForm: {
    id: 'tunnistusAccessForm',
    locale: 'tunnistus',
    formType: 'access',
    service: services.EIDENTIFICATION,
    path: 'tunnistus-liittymisen-edellytykset'
  },
  serviceCatalogueAccessForm: {
    id: 'serviceCatalogueAccessForm',
    locale: 'servicecatalogue',
    formType: 'access',
    service: 'servicecatalogue',
    path: 'palvelutietovaranto-liittymisen-edellytykset'
  },
  verkkopalveluAccessForm: {
    id: 'verkkopalveluAccessForm',
    locale: 'verkkopalvelu',
    formType: 'access',
    service: 'verkkopalvelu'
  },
  tunnistusRegistrationForm: {
    id: 'tunnistusRegistrationForm',
    locale: 'tunnistus_registration',
    formType: 'registration',
    pahaFormName: 'eIdentification',
    path: 'tunnistus-hae-kayttolupaa/uusi',
    guidePath: 'tunnistus/kayttoonotto',
    service: services.EIDENTIFICATION,
    serviceEN: services.EIDENTIFICATION
  },
  dataExchangeLayerRegistrationForm: {
    id: 'dataExchangeLayerRegistrationForm',
    locale: 'palveluvayla_registration',
    formType: 'registration',
    pahaFormName: 'dataexchangelayer',
    path: 'palveluvayla-hae-kayttolupaa/uusi',
    guidePath: 'palveluvayla/kayttoonotto',
    service: services.DATAEXCHANGELAYER,
    serviceEN: services.DATAEXCHANGELAYER
  },
  messagesRegistrationForm: {
    id: 'messagesRegistrationForm',
    locale: 'viestit_registration',
    formType: 'registration',
    pahaFormName: 'messages',
    path: 'viestit-hae-kayttolupaa/uusi',
    guidePath: 'viestit/kayttoonotto',
    service: 'messages',
    serviceEN: 'messages'
  },
  eAuthorizationRegistrationForm: {
    id: 'eAuthorizationRegistrationForm',
    locale: 'eauthorization',
    formType: 'registration',
    pahaFormName: 'eauthorization',
    path: 'valtuudet-hae-kayttolupaa/uusi',
    guidePath: 'valtuudet/kayttoonotto',
    service: services.EAUTHORIZATION,
    serviceEN: services.EAUTHORIZATION
  },
  serviceCatalogueRegistrationForm: {
    id: 'serviceCatalogueRegistrationForm',
    locale: 'servicecatalogue',
    formType: 'registration',
    service: 'servicecatalogue',
    pahaFormName: 'servicecatalogue',
    path: 'palvelutietovaranto-hae-kayttolupaa/uusi',
    guidePath: 'palvelutietovaranto/kayttoonotto',
    serviceEN: 'serviceCatalog'
  },
  tunnistusPaperRegistrationForm: {
    id: 'tunnistusPaperRegistrationForm',
    locale: 'tunnistus_paper_registration',
    formType: 'paper_registration',
    service: services.EIDENTIFICATION,
    notUnique: true
  },
  palvelutietovarantoPaperRegistrationForm: {
    id: 'palvelutietovarantoPaperRegistrationForm',
    locale: 'palvelutietovaranto_paper_registration',
    formType: 'simple_registration',
    service: 'palvelutietovaranto',
    notUnique: true
  },
  serviceBusCertificateForm: {
    id: 'serviceBusCertificateForm',
    locale: 'palveluvayla_certificate',
    formType: 'certificate',
    pahaFormName: 'dataexchangelayercertificate',
    path: 'palveluvayla-liityntapalvelimen-sertifikaatti/uusi',
    guidePath: 'palveluvayla/kayttoonotto',
    service: services.DATAEXCHANGELAYERCERTIFICATE,
    serviceEN: services.DATAEXCHANGELAYERCERTIFICATE
  }
};

export const getPath = (targetService, targetFormType) => {
  const form = Object.values(Forms).find(
    ({ service, formType }) =>
      service === targetService && formType === targetFormType
  );
  return form ? form.path : '';
};

export const getFormLocale = (targetService, targetFormType) => {
  const form = Object.values(Forms).find(
    ({ service, formType }) =>
      service === targetService && formType === targetFormType
  );
  return form ? form.locale : '';
};

export const getStaticFormData = formId =>
  Object.values(Forms).find(f => f.id === formId);

// use manualHandling boolean, if the form needs to be manually validated after sending
