import SignInView from '../components/SignInView';
import connectForm from 'utils/connectForm';
import validate from '../validate';
import { weakSignIn } from '../../../modules/auth';
import { actions as notificationActions } from '../../../components/Notifs';

export default connectForm(SignInView, {
  form: 'signInForm',
  validate,
  mapStateToProps: state => ({
    authOrganisationService:
      state.features && state.features.authOrganisationService
  }),
  mapActionCreators: {
    weakSignIn,
    notifSend: notificationActions.notifSend
  }
});
