import {
  createReducer,
  createAction,
  getSemaJSON as get } from '../../../utils';
import { CALL_API } from '../../../store/middleware/api';
import { getJSON } from '../../../utils/http';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  isFetchingArticles: false,
  articles: [],
  importantArticles: [],
  article: '',
  error: null,
  isLoadingArticle: false,
  articleServiceTags: {},
  articleCommonTags: {}
};

const PX = 'CMS';
const FETCH_TOPICAL = `${PX}_FETCH_TOPICAL`;
const FETCH_TOPICAL_SUCCESS = `${PX}_FETCH_TOPICAL_SUCCESS`;
const FETCH_TOPICAL_ERROR = `${PX}_FETCH_TOPICAL_ERROR`;
const FETCH_IMPORTANT_TOPICAL = `${PX}_FETCH_IMPORTANT_TOPICAL`;
const FETCH_IMPORTANT_TOPICAL_SUCCESS = `${PX}_FETCH_IMPORTANT_TOPICAL_SUCCESS`;
const FETCH_IMPORTANT_TOPICAL_ERROR = `${PX}_FETCH_IMPORTANT_TOPICAL_ERROR`;
const LOAD_ARTICLE_SUCCESS = `${PX}_LOAD_ARTICLE_SUCCESS`;
const LOAD_ARTICLE_ERROR = `${PX}_LOAD_ARTICLE_ERROR`;
const LOAD_ARTICLE = `${PX}_LOAD_ARTICLE`;
const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
const FETCH_TAGS_ERROR = 'FETCH_TAGS_FAILURE';
const FETCH_ARTICLE_REQUEST = `${PX}_FETCH_ARTICLE_REQUEST`;
const FETCH_ARTICLE_SUCCESS = `${PX}_FETCH_ARTICLE_SUCCESS`;
const FETCH_ARTICLE_ERROR = `${PX}_FETCH_ARTICLE_ERROR`;

const ACTION_HANDLERS = {
  [FETCH_TOPICAL]: state => ({
    ...state,
    isFetchingArticles: true
  }),
  [FETCH_TOPICAL_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingArticles: false,
    articles: payload
  }),
  [FETCH_TOPICAL_ERROR]: (state, { payload }) => ({
    ...state,
    isFetchingArticles: false,
    error: payload
  }),
  [FETCH_IMPORTANT_TOPICAL]: state => ({
    ...state,
    isFetchingArticles: true
  }),
  [FETCH_IMPORTANT_TOPICAL_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingArticles: false,
    importantArticles: payload
  }),
  [FETCH_IMPORTANT_TOPICAL_ERROR]: (state, { payload }) => ({
    ...state,
    isFetchingArticles: false,
    error: payload
  }),
  [LOAD_ARTICLE]: state => ({
    ...state,
    isLoadingArticle: true
  }),
  [LOAD_ARTICLE_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoadingArticle: false,
    article: payload
  }),
  [LOAD_ARTICLE_ERROR]: (state, { payload }) => ({
    ...state,
    isLoadingArticle: false,
    error: payload
  }),
  [FETCH_TAGS_REQUEST]: state => ({
    ...state
  }),
  [FETCH_TAGS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      contentTags: parseCategoryList(payload.contentTags, null),
      articleServiceTags: [],
      articleCommonTags: [],
      faqTags: payload.parsedFAQTags,
      vocabularyServiceTags: payload.parsedVocabularyTags
    }
  },
  [FETCH_TAGS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload
  }),
  [FETCH_ARTICLE_REQUEST]: state => ({
    ...state,
    isLoadingArticle: true
  }),
  [FETCH_ARTICLE_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoadingArticle: false,
    article: payload
  }),
  [FETCH_ARTICLE_ERROR]: (state, { payload }) => ({
    ...state,
    isLoadingArticle: false,
    error: payload
  }),
};

export const loadArticle = (articles, id) => {
  let article = articles.find(article => article.id == id);
};

export const saveCurrentArticle = id => {
  return dispatch => dispatch(createAction(LOAD_ARTICLE_SUCCESS)(id));
};

export const getDocuments = () => ({
  [CALL_API]: {
    method: 'get',
    endpoint: `/api/documents/`,
    types: [FETCH_DOCUMENTS, FETCH_DOCUMENTS_SUCCESS, FETCH_DOCUMENTS_ERROR]
  }
});


/** tag logic not yeat in use. Will be implemented in later release */
function fetchTags(tagsTypeName) {
  return {
    [CALL_API]: {
      method: 'get',
      endpoint: `/api/tags/${tagsTypeName}`,
      types: [FETCH_TAGS_REQUEST, FETCH_TAGS_SUCCESS, FETCH_TAGS_ERROR]
    }
  }
};

export function populateNewsTags() {
  return async dispatch => {
    try {
      await dispatch(fetchTags('news'));
      await dispatch(fetchTags('service'));
    } catch(e) {}
  };
}

export const getTopical = (serviceTags = [], newsTypes = [], count = 100) => {
  return {
    [CALL_API]: {
      method: 'post',
      endpoint: `/api/articles/topical`,
      types: [FETCH_TOPICAL, FETCH_TOPICAL_SUCCESS, FETCH_TOPICAL_ERROR],
      data: {
        serviceTags: serviceTags,
        newsTypes: newsTypes,
        count: count
      }
    }
  };
};

export const getImportantTopical = () => {
  return {
    [CALL_API]: {
      method: 'get',
      endpoint: `/api/articles/importantTopical`,
      types: [
        FETCH_IMPORTANT_TOPICAL,
        FETCH_IMPORTANT_TOPICAL_SUCCESS,
        FETCH_IMPORTANT_TOPICAL_ERROR
      ]
    }
  };
};

export function fetchArticleById(id) {
  return {
    [CALL_API]: {
      method: 'get',
      types: [
        FETCH_ARTICLE_REQUEST,
        FETCH_ARTICLE_SUCCESS,
        FETCH_ARTICLE_ERROR
      ],
      endpoint: `articles/article/${id}`
    }
  };
}

function parseCategoryList(categories, tags) {
  const results = {};
  if (!!categories && !isEmpty(categories)) {
    categories.forEach(c => {
      const category = {};
      const updatedSlug = c.slug; //createSlug(c.name.fi);
      category.name = c.name;
      category.selected = !tags
        ? false
        : tags[c.name.fi.toLowerCase()].selected;
      results[updatedSlug] = category;
    });
  }
  return results;
}

function createSlug(name) {
  return name
    .replace(/ /g, '-')
    .replace(/ö/g, 'oe')
    .replace(/ä/g, 'ae')
    .toLowerCase();
}

export function populateTags() {
  return async dispatch => {
    dispatch(createAction(FETCH_TAGS_REQUEST)());
    try {
      const parsedServiceTags = parseCategoryList(
        await fetchTags('service'),
        null
      );

      console.log('PARSEDTAGS ', parsedServiceTags);

      const parsedCommonTags = parseCategoryList(
        await fetchTags('common'),
        null
      );

      dispatch(
        createAction(FETCH_TAGS_SUCCESS)({
          parsedServiceTags,
          parsedCommonTags
        })
      );
    } catch (e) {
      dispatch(createAction(FETCH_TAGS_FAILURE)(e));
      throw e;
    }
  };
}

export default createReducer(ACTION_HANDLERS, initialState);
