import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { browserHistory } from 'react-router';
import { Field } from 'redux-form';
import cx from 'classnames';
import TranslatableHelmet from '../../../components/TranslatableHelmet';
import connectForm from 'utils/connectForm';
import { renderInfobox, renderTextField } from 'utils/fieldWrappers';
import { PageHeader, Button } from 'sema-ui-components';
import validate from './ValidateServiceCreate';
import {
  showProviderCreateFail,
  showProviderUpdateFail,
  showProviderCreateSuccess,
  showProviderUpdateSuccess
} from './Notifications';
import styles from './CreateServiceView.scss';

class CreateServiceView extends React.Component {
  static propTypes = {
    createIdProvider: PropTypes.func.isRequired,
    updateIdProvider: PropTypes.func.isRequired,
    currentProvider: PropTypes.object,
    handleSubmit: PropTypes.func,
    activeOrgSemaID: PropTypes.string.isRequired,
    notifSend: PropTypes.func.isRequired,
    params: PropTypes.any.isRequired,
    routes: PropTypes.any.isRequired,
    valid: PropTypes.bool
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  getLang = () => this.props.params.lang;
  i18n = local => this.context.intl.formatMessage({ id: local });

  doHandleSubmit = (values, params) => {
    if (values.id) {
      const removeEmpty = obj => {
        Object.keys(obj).forEach(key => obj[key] === '' && delete obj[key]);
        return obj;
      };

      const mapToUpdate = removeEmpty(values);

      this.props.updateIdProvider(mapToUpdate, values.id).then(result => {
        if (result && result.error) {
          showProviderUpdateFail(this.props.notifSend, this.i18n);
        } else {
          this.serviceSaved(values);
        }
      });
    } else {
      values.organization = { id: this.props.activeOrgSemaID };

      this.props.createIdProvider(values).then(result => {
        if (result && result.error) {
          showProviderCreateFail(this.props.notifSend, this.i18n);
        } else {
          this.serviceSaved(values, params);
        }
      });
    }
  };

  serviceSaved = (values, params) => {
    values.id
      ? showProviderUpdateSuccess(
          this.props.notifSend,
          this.i18n,
          values[`name${this.getLang().toUpperCase()}`]
        )
      : showProviderCreateSuccess(
          this.props.notifSend,
          this.i18n,
          values[`name${this.getLang().toUpperCase()}`]
        );
    browserHistory.push(
      `/${this.context.intl.locale}/tunnistus/${encodeURIComponent(
        this.props.currentProvider.nameFI
      )}`
    );
  };

  cancel = event => {
    event.preventDefault();
    this.returnToServiceList();
  };

  returnToServiceList = () => {
    if (this.props.currentProvider) {
      browserHistory.push(
        `/${this.context.intl.locale}/tunnistus/${encodeURIComponent(
          this.props.currentProvider.nameFI
        )}`
      );
    } else {
      browserHistory.push(`/${this.context.intl.locale}/tunnistus/`);
    }
  };

  render() {
    const {
      routes,
      params,
      handleSubmit,
      valid,
      currentProvider,
      activeOrgSemaID
    } = this.props;

    return (
      activeOrgSemaID && (
        <section>
          <TranslatableHelmet
            locale={params.lang}
            subTitle={
              currentProvider
                ? this.i18n('identification_admin.add_service.edit_title')
                : this.i18n('identification_admin.add_service.title')
            }
          />
          <PageHeader
            title={
              <span>
                {currentProvider ? (
                  <FormattedMessage id="identification_admin.add_service.edit_title" />
                ) : (
                  <FormattedMessage id="identification_admin.add_service.title" />
                )}
              </span>
            }
            description={<span />}
            routes={routes}
            params={params}
          />
          <div className={styles.base}>
            <form
              onSubmit={handleSubmit(values =>
                this.doHandleSubmit(values, params)
              )}
            >
              <div className="row">
                <div className="offset-md-1 v-offset-4 col-md-18">
                  <h3>
                    <FormattedMessage id="identification_admin.add_service.basicTitle" />
                  </h3>
                  <p>
                    <FormattedMessage id="identification_admin.add_service.basicDesc" />
                  </p>
                  <hr />
                </div>
                <div className="offset-md-1 v-offset-3 col-md-18">
                  <h4>
                    <FormattedMessage id="identification_admin.add_service.name" />{' '}
                    <span className={styles.headerDesc}>
                      <FormattedMessage id="identification_admin.add_service.nameSpec" />
                    </span>
                  </h4>
                  <p className={styles.infoText}>
                    <FormattedMessage id="identification_admin.add_service.nameDesc" />
                  </p>
                </div>
                <div className="offset-md-1 v-offset-1 col-md-18">
                  <h6 className={styles.fieldHeader}>
                    <FormattedMessage id="identification_admin.add_service.nameHeader" />{' '}
                    <span className={styles.mandatoryStar}>*</span>
                  </h6>
                  <Field
                    className={styles.infoBox}
                    name="serviceNameInfobox"
                    component={renderInfobox}
                  >
                    <h6>
                      <FormattedMessage
                        id={`identification_admin.add_service.info_title`}
                      />
                    </h6>
                    <FormattedMessage
                      id={`identification_admin.add_service.infoBox_name`}
                    />
                  </Field>
                  <Field
                    name="nameFI"
                    component={renderTextField}
                    size="w320"
                    maxLength={128}
                    className={styles.textField}
                    label={this.i18n('identification_admin.add_service.nameFi')}
                  />
                  <Field
                    name="nameSV"
                    component={renderTextField}
                    size="w320"
                    maxLength={128}
                    className={styles.textField}
                    label={this.i18n('identification_admin.add_service.nameSv')}
                  />
                  <Field
                    name="nameEN"
                    component={renderTextField}
                    size="w320"
                    maxLength={128}
                    className={styles.textField}
                    label={this.i18n('identification_admin.add_service.nameEn')}
                  />
                  <hr className={styles.bottomRuler} />
                </div>
                <div className="offset-md-1 v-offset-3 col-md-18">
                  <h4>
                    <FormattedMessage id="identification_admin.add_service.desc" />{' '}
                    <span className={styles.headerDesc}>
                      <FormattedMessage id="identification_admin.add_service.descSpec" />
                    </span>
                  </h4>
                  <p className={styles.infoText}>
                    <FormattedMessage id="identification_admin.add_service.descDesc" />
                  </p>
                </div>
                <div className="offset-md-1 v-offset-1 col-md-18">
                  <h6 className={styles.fieldHeader}>
                    <FormattedMessage id="identification_admin.add_service.descHeader" />{' '}
                    <span className={styles.mandatoryStar}>*</span>
                  </h6>
                  <Field
                    className={styles.infoBox}
                    name="serviceDescInfobox"
                    component={renderInfobox}
                  >
                    <h6>
                      <FormattedMessage
                        id={`identification_admin.add_service.info_title`}
                      />
                    </h6>
                    <FormattedMessage
                      id={`identification_admin.add_service.infoBox_description`}
                    />
                  </Field>
                  <Field
                    name="descriptionFI"
                    component={renderTextField}
                    size="w380"
                    multiline
                    counter
                    rows={4}
                    maxLength={150}
                    className={styles.textField}
                    counterClass={styles.areaCounter}
                    label={this.i18n('identification_admin.add_service.descFi')}
                  />
                  <Field
                    name="descriptionSV"
                    component={renderTextField}
                    size="w380"
                    multiline
                    counter
                    rows={4}
                    maxLength={150}
                    className={styles.textField}
                    counterClass={styles.areaCounter}
                    label={this.i18n('identification_admin.add_service.descSv')}
                  />
                  <Field
                    name="descriptionEN"
                    component={renderTextField}
                    size="w380"
                    multiline
                    counter
                    rows={4}
                    maxLength={150}
                    className={styles.textField}
                    counterClass={styles.areaCounter}
                    label={this.i18n('identification_admin.add_service.descEn')}
                  />
                </div>
              </div>
              <div className={cx('row', 'v-offset-3', styles.buttonBar)}>
                <div className="offset-md-1 col-md-12">
                  <Button
                    medium
                    className={styles.saveButton}
                    type="submit"
                    disabled={!valid}
                  >
                    {currentProvider ? (
                      <FormattedMessage id="identification_admin.add_service.editButton" />
                    ) : (
                      <FormattedMessage id="identification_admin.add_service.addButton" />
                    )}
                  </Button>
                </div>
                <div className="col-md-10">
                  <div className="text-right">
                    <Button medium secondary onClick={this.cancel}>
                      {<FormattedMessage id="general.cancel" />}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      )
    );
  }
}

const getInitialValues = provider =>
  provider
    ? {
        id: provider.id,
        nameFI: provider.nameFI,
        nameEN: provider.nameEN,
        nameSV: provider.nameSV,
        descriptionFI: provider.descriptionFI,
        descriptionEN: provider.descriptionEN,
        descriptionSV: provider.descriptionSV
      }
    : {};

export default connectForm(CreateServiceView, {
  form: 'createIdService',
  validate,
  destroyOnUnmount: true,
  mapStateToProps: state => ({
    initialValues: getInitialValues(state.idAdmin.currentProvider)
  })
});
