import React from 'react';
import { FormattedMessage } from 'react-intl';
import SignInViewContainer from './containers/SignInViewContainer';
import ForgottenPasswordContainer from './containers/ForgottenPasswordContainer';
import NewPasswordContainer from './containers/NewPasswordContainer';
import PasswordResetResultView from './components/PasswordResetResultView';
import SignInFailed from './components/SignInFailed';
import ExpiredPasswordContainer from './containers/ExpiredPasswordContainer';
import { ExternalSignInView } from './components/ExternalSignInView/ExternalSignInView';

export const basePath = 'kirjautuminen';

const routes = [
  {
    path: 'kirjaudu',
    name: <FormattedMessage id="sign_in_title" />,
    components: { content: SignInViewContainer }
  },
  {
    path: 'unohdettu-salasana',
    name: <FormattedMessage id="forgotten_password_title" />,
    components: { content: ForgottenPasswordContainer }
  },
  {
    path: 'uusi-salasana',
    name: <FormattedMessage id="forgotten_password_title" />,
    components: { content: NewPasswordContainer }
  },
  {
    path: 'salasana-vaihdettu',
    name: <FormattedMessage id="forgotten_password_title" />,
    components: { content: PasswordResetResultView }
  },
  {
    path: 'kirjautuminen-epaonnistui',
    name: <FormattedMessage id="sign_in_failed_title" />,
    components: { content: SignInFailed }
  },
  {
    path: 'uusi-vanhentunut-salasana',
    name: <FormattedMessage id="change_expired_password_title" />,
    components: { content: ExpiredPasswordContainer }
  },
  {
    path: 'ulkoinen-kirjautuminen',
    name: <FormattedMessage id="sign_in_failed_title" />,
    components: { content: ExternalSignInView }
  }
];

export default browserLang => ({
  getChildRoutes: (location, cb) => {
    cb(null, {
      path: basePath,
      indexRoute: {
        onEnter: (nextState, replace) =>
          replace(`/${location.params.lang}/${basePath}/kirjaudu`)
      },
      childRoutes: routes
    });
  }
});
