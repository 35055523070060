import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { asyncConnect } from 'redux-connect';
import { browserHistory } from 'react-router';
import { pure } from 'recompose';
import { toggleSideNav } from 'components/SideNav/modules';
import { actions as notificationActions } from 'components/Notifs';
import CoreLayout from 'layouts/CoreLayout';
import PrintLayout from 'layouts/PrintLayout';
import {
  changeOrg,
  signOut,
  startSignIn,
  toggleUserDropdown,
  toggleNotificationsDropdown,
  setSessionExpired,
  updateUserToStore,
  getOpenApplications
} from 'modules/auth';
import { asyncGetNotifStats } from '../routes/Notifications/modules';
import { asyncFetchDefs } from '../routes/Admin/modules/notifications';
import { modalActions } from 'components/ReduxModal/modules';
import config from 'config';
import {
  requestTranslationLocal,
  extractPathElement,
  changeLang
} from 'modules/translations';
import ReduxModal from 'components/ReduxModal';
import { getActiveOrgSelector } from 'modules/auth/selectors';
import { requestNotifications } from '../components/NotificationList/modules/actions';
import {
  isSessionExpiredInCookie,
  setSessionExpiredInCookie
} from 'utils/sessionUtil';

import { setOverlay, unsetOverlay } from 'modules/ui';
import { checkKVHAccess } from 'utils/accessUtils';

/**
 * Wraps application views (under root) to the various providers and layout.constructor.
 * Access of Redux some store items and actions and passing those as props to sub-components.
 *
 * @param props
 * @returns {XML}
 */
const AppContainer = props => {
  /* eslint-disable no-unused-vars */
  const { currentLang, error, isPrint, translations } = props;
  /* eslint-enable no-unused-vars */
  return (
    <IntlProvider locale={currentLang} messages={translations}>
      <div style={{ overflow: 'hidden', height: '100%' }}>
        {isPrint ? (
          <PrintLayout {...props} locale={currentLang} />
        ) : (
          <CoreLayout {...props} locale={currentLang} />
        )}
        <ReduxModal />
      </div>
    </IntlProvider>
  );
};

/* eslint-disable react/no-unused-prop-types */
AppContainer.propTypes = {
  activeOrg: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  changeOrg: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  asyncGetNotifStats: PropTypes.func.isRequired,
  content: PropTypes.node,
  currentLang: PropTypes.string,
  error: PropTypes.object,
  errorMessage: PropTypes.string,
  footerContent: PropTypes.node,
  headerExtra: PropTypes.node,
  isAuthenticated: PropTypes.bool,
  isUserDropdownOpen: PropTypes.bool.isRequired,
  isNotificationsDropdownOpen: PropTypes.bool.isRequired,
  isPrint: PropTypes.any,
  location: PropTypes.object,
  notifSend: PropTypes.func,
  openModal: PropTypes.func.isRequired,
  params: PropTypes.object,
  sideNavOpen: PropTypes.bool,
  signOut: PropTypes.func.isRequired,
  startSignIn: PropTypes.func.isRequired,
  toggleSideNav: PropTypes.func.isRequired,
  toggleUserDropdown: PropTypes.func.isRequired,
  toggleNotificationsDropdown: PropTypes.func.isRequired,
  translations: PropTypes.object,
  user: PropTypes.object,
  notifStats: PropTypes.object,
  changeLang: PropTypes.func,
  overlay: PropTypes.object,
  setOverlay: PropTypes.func,
  unsetOverlay: PropTypes.func,
  getOpenApplications: PropTypes.func
};
/* eslint-enable react/no-unused-prop-types */

/**
 * Note: getActiveOrgSelector gives you activeOrg selector but it's memoization it's not shared with other
 * redux connected components. :(
 * This is due the current limitations of asyncConnect compared to react-redux connect.
 *
 * @param state
 * @returns {{activeOrg: *, isAuthenticated: *, authErrorMessage: *, user: *, sideNavOpen: *, error}}
 */
const mapStateToProps = state => {
  const {
    isAuthenticated,
    authErrorMessage,
    user,
    isUserDropdownOpen,
    isNotificationsDropdownOpen,
    isSessionExpired
  } = state.auth;
  const { definitions } = state.manageNotifications;
  const activeOrg = getActiveOrgSelector()(state);
  const error = state.error;
  const { translations, currentLang } = state.translations;
  const { sideNavOpen } = state.sideNav;
  const notifStats = state.notifications.stats;
  const overlay = state.ui.overlay;

  return {
    activeOrg,
    isAuthenticated,
    authErrorMessage,
    user,
    isUserDropdownOpen,
    isNotificationsDropdownOpen,
    sideNavOpen,
    definitions,
    error,
    translations,
    currentLang,
    isSessionExpired,
    notifStats,
    overlay
  };
};

const mapActionCreators = {
  startSignIn,
  signOut,
  changeOrg,
  toggleUserDropdown,
  toggleNotificationsDropdown,
  toggleSideNav,
  setSessionExpired,
  updateUserToStore,
  ...modalActions,
  notifSend: notificationActions.notifSend,
  asyncGetNotifStats,
  changeLang,
  setOverlay,
  unsetOverlay,
  getOpenApplications
};

const decorateWithAsync = (c, stateToPros, actionCreators) =>
  asyncConnect(
    [
      {
        promise: ({ params, helpers: { store } }) => {
          if (isSessionExpiredInCookie()) {
            setSessionExpiredInCookie(false);
            store.dispatch(setSessionExpired(true));
            store.dispatch(updateUserToStore(null));
          }

          const state = store.getState();

          if (
            (!state.notifications || !state.notifications.stats) &&
            state.auth &&
            state.auth.isAuthenticated &&
            state.auth.user &&
            state.auth.user.activeOrganizationId
          ) {
            store.dispatch(asyncGetNotifStats());
            store.dispatch(asyncFetchDefs());
          }

          if (
            state.translations.currentLang &&
            state.translations.currentLang != params.lang
          ) {
            let pathElement = '';
            if (state.router.locationBeforeTransitions) {
              pathElement = extractPathElement(
                state.router.locationBeforeTransitions.pathname
              );
            }
            browserHistory.push(
              `/${state.translations.currentLang}` + pathElement
            );
          }

          const { availableLocales, defaultLocale } = config.i18n;
          const currentLang = !availableLocales.includes(params.lang)
            ? ''
            : params.lang;

          // Load notifications for the user
          store.dispatch(requestNotifications());

          // If admin user and in dashboard, get open request invitations and show banner
          if (
            state.auth.user?.activeOrganizationId &&
            checkKVHAccess(state.auth.user.roles) &&
            extractPathElement(
              state.router.locationBeforeTransitions.pathname
            ) == '/dashboard'
          ) {
            store.dispatch(
              getOpenApplications(state.auth.user.activeOrganizationId)
            );
          }

          if (
            (state.translations &&
              state.translations.currentLang === currentLang) ||
            state.translations.currentLang
          ) {
            return Promise.resolve();
          }
          // Forward to not found error if lang is not valid
          currentLang || browserHistory.push(`/${defaultLocale}/not-found`);

          // use requestTranslation when ready to use network version
          return store.dispatch(requestTranslationLocal(currentLang));
        }
      }
    ],
    stateToPros,
    actionCreators
  )(c);

export default decorateWithAsync(
  pure(AppContainer),
  mapStateToProps,
  mapActionCreators
);
