import sanitizeHtml from 'sanitize-html';

export const articleSanitizeConf = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'h1', 'h2']),
  allowedAttributes: Object.assign(
    {},
    sanitizeHtml.defaults.allowedAttributes,
    {
      img: ['src', 'alt'],
      '*': ['class', 'style']
    }
  )
};
