import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Text, Block } from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import { IntermediaryTextField } from '../../components';

export const OwnOrganisationFields = () => {
  const formik = useFormikContext();

  if (!formik?.values?.formData?.customerOrganization) {
    return null;
  }

  return (
    <Block>
      <Block mb="s">
        <Text variant="bold">
          <FormattedMessage id="intermediary.preview.organisationName" />
        </Text>
        <Block>
          <Text>
            {formik.values.formData.customerOrganization.organizationName}
          </Text>
        </Block>
      </Block>
      <Block my="s">
        <Text variant="bold">
          <FormattedMessage id="intermediary.preview.yId" />:
        </Text>
        <Block>
          <Text>{formik.values.formData.customerOrganization.businessId}</Text>
        </Block>
      </Block>
      <Block my="s">
        <Text variant="bold">
          <FormattedMessage id="intermediary.preview.organisationUnit" />:{' '}
        </Text>
        <Block>
          <Text>
            {formik.values.formData.customerOrganization.organizationUnit || (
              <FormattedMessage id="intermediary.preview.notKnown" />
            )}
          </Text>
        </Block>
      </Block>
      <Block my="s">
        <IntermediaryTextField
          name={`formData.customerOrganization.address`}
          label={'intermediary.organisationInfo.address'}
          width={'66.66%'}
        />

        <IntermediaryTextField
          name={`formData.customerOrganization.postalCode`}
          label={'intermediary.organisationInfo.postalCode'}
          width={'66.66%'}
        />

        <IntermediaryTextField
          name={`formData.customerOrganization.postOffice`}
          label={'intermediary.organisationInfo.postOffice'}
          width={'66.66%'}
        />
      </Block>
    </Block>
  );
};
OwnOrganisationFields.propTypes = {
  formik: PropTypes.object,
  i18n: PropTypes.func
};
