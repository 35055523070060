import {
  DATA_EXCHANGE_LAYER_CERTIFICATE_PAHA_FORM_NAME,
  DATA_EXCHANGE_LAYER_PAHA_FORM_NAME,
  EAUTHORIZATION_PAHA_FORM_NAME,
  EIDENTIFICATION_PAHA_FORM_NAME,
  MESSAGES_PAHA_FORM_NAME,
  SERVICECATALOGUE_PAHA_FORM_NAME,
  serviceConnectionMethods
} from 'constants/index';

export const createRadioButtonValues = infoSecurityData => {
  // transfers from null, true and false to radioButton values "", "yes", "no".
  const radioButtonAttributes = [
    'coveredByInformationManagementAct',
    'followInformationManagementAct',
    'dataTransferOutsideEU',
    'environmentHandlingOutsideEU',
    'loggingReguirementsMet',
    'processingOfPersonalData',
    'procedureForCheckVrkConditions',
    'securityResponsiblePerson',
    'regularCheckForUserRights',
    'securityDeviation',
    'securedWorkstation',
    'loginSecurity',
    'vtjInformationDeletionBeforeMaintenance',
    'vtjDataHandlingAbroadEu',
    'cloudServices',
    'cloudServicesDataDeletion',
    'cloudServicesLegalContract',
    'cloudServicesResponsibility',
    'cloudServicesSecurityDeviation',
    'cloudServicesCustomerDataSecured',
    'useOfFirewallAndProtection',
    'dataConnectionIsSecuredForVtjData',
    'productionAndTestEnvironments',
    'vtjDataHandlingAbroad',
    'personnelDataHandlingOutsideOrganisation',
    'discloseInformationToThirdParty',
    'serviceDataSavedToRegister',
    'auditLogs',
    'logsArchivedAndDestroyed',
    'logsAreProtectedFromChanges',
    'logsAreRestricted',
    'regularChecksForLogs'
  ];

  return radioButtonAttributes.reduce((returnObj, key) => {
    returnObj[key] = toRadioBoxValue(infoSecurityData[key]);
    return returnObj;
  }, {});
};

export const formatAttachments = attachmentsArray =>
  attachmentsArray.reduce(
    (obj, attachment) =>
      Object.assign(obj, {
        [attachment.tempFileName]: attachment.originalFileName
      }),
    {}
  );

export const serviceAttachmentsForForm = (service, attachmentFields) => {
  if (attachmentFields && attachmentFields.length > 0) {
    attachmentFields.forEach(attachment => {
      service[attachment] = service[attachment]
        ? formatAttachments(service[attachment])
        : null;
    });
  } else {
    return null;
  }
};

export const toRadioBoxValue = value =>
  value === null ? '' : value ? 'yes' : 'no';

export const serviceBooleansToRadioBoxValue = (service, booleanFields) => {
  if (booleanFields && booleanFields.length > 0) {
    booleanFields.forEach(field => {
      service[field] = service[field] ? toRadioBoxValue(service[field]) : 'no';
    });
  }
};

export const clientServicesForForm = (
  services,
  attachmentFields,
  booleanFields
) => {
  services.forEach(service => {
    serviceBooleansToRadioBoxValue(service, booleanFields);
    serviceAttachmentsForForm(service, attachmentFields);
  });

  services.forEach(service => {
    service.id = service.serviceId;
    delete service.serviceId;
  });

  /*Object.keys(services).forEach(k => {
    // change SAHA model's 'index/key' from [i] to form model's [_i]
    let formedKey = '_' + k;
    services[formedKey] = services[k];
  });*/

  return services;
};

const convertConnectionMethod = service => {
  if (service) {
    return service.connectionMethodWeb
      ? serviceConnectionMethods.WEB
      : service.connectionMethodEmail
      ? serviceConnectionMethods.EMAIL
      : service.connectionMethodIpost
      ? serviceConnectionMethods.IPOST
      : service.connectionMethodProduct
      ? serviceConnectionMethods.PRODUCT
      : undefined;
  }
};

const serviceCatalogueForForm = data => {
  let booleanFields = [
    'inApiUsed',
    'inApiContentUpdatedRegularly',
    'inApiMaintainedProperly',
    'twoAdminsInvited',
    'organizationUsesSubcontractor'
  ];

  return {
    ...data,
    formData: {
      ...data.formData,
      ...{
        serviceCatalogueInfo: {
          ...data.formData.serviceCatalogueInfo,
          ...serviceBooleansToRadioBoxValue(
            data.formData.serviceCatalogueInfo,
            booleanFields
          )
        }
      }
    }
  };
};

const messagesForForm = data => {
  const services =
    data.formData.messagesInfo && data.formData.messagesInfo.services;

  return {
    ...data,
    formData: {
      ...data.formData,
      ...{
        statementOnInformationSecurity: data.formData
          .statementOnInformationSecurity
          ? {
              ...data.formData.statementOnInformationSecurity,
              ...createRadioButtonValues(
                data.formData.statementOnInformationSecurity
              )
            }
          : undefined
      },
      messagesInfo: {
        ...data.formData.messagesInfo,
        services: (services &&
          services.length > 0 &&
          services.map((service, index) => {
            const connectionMethod = convertConnectionMethod(service);
            delete service.connectionMethodWeb;
            delete service.connectionMethodEmail;
            delete service.connectionMethodIpost;
            delete service.connectionMethodProduct;
            if (
              service.notificationContacts &&
              service.notificationContacts.length > 0
            ) {
              service.notificationContacts = service.notificationContacts.map(
                (contact, index) => ({
                  ...contact,
                  id: index
                })
              );
            }
            return {
              ...service,
              id: index,
              connectionMethod
            };
          })) || [{ id: 0, notificationContacts: [{ id: 0 }] }]
      }
    }
  };
};

export const parseDraftFormData = (editedForm, dataForForm, user) => {
  const formData = editedForm.formData || {};

  let services = [];
  let attachmentFields = [];
  let booleanFields = [];

  switch (formData.pahaFormName) {
    case EIDENTIFICATION_PAHA_FORM_NAME: {
      // Tunnistus
      const privacyStatement =
        !!formData && formData.statementOnInformationSecurity;
      const clientServices = !!formData && formData.clientServiceInformation;
      services = clientServices && clientServices.services;
      attachmentFields = ['functionalDescriptionAttachments'];

      dataForForm = {
        ...editedForm,
        ...{
          formData: {
            ...formData,
            ...{
              clientServiceInformation: {
                ...formData.clientServiceInformation,
                services:
                  services && services.length > 0
                    ? clientServicesForForm(
                        services,
                        attachmentFields,
                        booleanFields
                      )
                    : [{ id: 0 }]
              },
              statementOnInformationSecurity: privacyStatement
                ? {
                    ...privacyStatement,
                    ...createRadioButtonValues(privacyStatement)
                  }
                : undefined
            }
          }
        }
      };
      break;
    }
    case EAUTHORIZATION_PAHA_FORM_NAME: {
      // Valtuudet
      const privacyStatement =
        !!formData && formData.statementOnInformationSecurity;
      const eauthorizationInformation =
        !!formData && formData.eauthorizationInformation;
      services =
        eauthorizationInformation && eauthorizationInformation.services;
      booleanFields = [
        'actingOnBehalfMinor',
        'actingOnBehalfAdult',
        'actingOnBehalfOrganization',
        'actingOnBehalfOrganizationWithExternalRights'
      ];
      attachmentFields = ['serviceUsageAttachment'];
      dataForForm = {
        ...editedForm,
        ...{
          formData: {
            ...formData,
            ...{
              eauthorizationInformation: {
                ...formData.eauthorizationInformation,
                services:
                  services && services.length > 0
                    ? clientServicesForForm(
                        services,
                        attachmentFields,
                        booleanFields
                      )
                    : [{ id: 0 }]
              },
              statementOnInformationSecurity: privacyStatement
                ? {
                    ...privacyStatement,
                    ...createRadioButtonValues(privacyStatement)
                  }
                : undefined
            }
          }
        }
      };
      break;
    }
    case MESSAGES_PAHA_FORM_NAME: // Messages - Viestit
      dataForForm = messagesForForm(editedForm);
      break;
    case SERVICECATALOGUE_PAHA_FORM_NAME: // Servicecatalogue - PTV
      dataForForm = serviceCatalogueForForm(editedForm);
      break;
    case DATA_EXCHANGE_LAYER_PAHA_FORM_NAME: {
      // ServiceBus - palveluväylä
      const serverInformation =
        (formData.dataExchangeLayerInformation &&
          formData.dataExchangeLayerInformation.serverInformation) ||
        {};
      dataForForm = {
        ...editedForm,
        environmentType: formData.environmentType,
        ...{
          formData: {
            ...formData,
            ...{
              dataExchangeLayerInformation: {
                ...formData.dataExchangeLayerInformation,
                ...{
                  serverInformation: {
                    ...serverInformation,
                    ipv4:
                      serverInformation.ipv4 &&
                      serverInformation.ipv4.map(ip => ({
                        value: ip
                      }))
                  }
                }
              }
            }
          }
        }
      };
      break;
    }
    case DATA_EXCHANGE_LAYER_CERTIFICATE_PAHA_FORM_NAME:
      dataForForm = {
        ...editedForm
      };
      break;
  }

  return {
    // Add 'metadata' e.g. sections that are ready
    ...dataForForm,
    ...{
      data: editedForm.formData.temp
    },
    formData: {
      ...dataForForm.formData,
      ...{
        proposerName: `${user.firstName} ${user.lastName}`
        // proposer is used for approval process
        // it's form editor/creator, who sends the form for approval
      }
    }
  };
};
