/*eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PageHeader } from 'sema-ui-components';

import styles from '../styles/StaticArticleStyles.scss';

export const ArticleReleaseNews = ({ content, params, routes }, intl) => {
  return <section>
    <div className="row">
      <div className={cx(styles.ingressColumn, 'col-md-24')}>
        <PageHeader
          title="Suomi.fi-palveluhallinta on nyt julkaistu Suomi.fi-tunnistuksen asiakkaille"
          routes={routes}
          params={params} />
      </div>
    </div>
    <div className={styles.tabContent}>
      <div className="row">
        <div className="col-md-4" />
        <div className="col-md-16">
          <p>
            Suomi.fi-palveluhallinta on julkaistu 12.10. tuotantokäyttöön
            Suomi.fi-tunnistuksen asiakasorganisaatioille. Ensimmäisenä
            pääkäyttäjät saavat henkilökohtaisen kutsulinkin Palveluhallintaan,
            jossa he voivat rekisteröidä oman organisaationsa palveluun ja
            kutsua lisää käyttäjiä organisaationsa tilille. Organisaatiotieto on
            tuotu automaattisesti Palveluhallintaan jo olemassa olevasta
            asiakasdatasta.
          </p><p>
          Suomi.fi-tunnistuksen nykyiset ja uudet asiakkaat saavat käyttöönsä
          Suomi.fi-palveluista ensimmäisinä Palveluhallinnan kautta käytettävän
          teknisen hallintaliittymän. Tunnistuksen teknisessä
          hallintaliittymässä voi tehdä konfigurointia omiin liitettyihin
          asiointipalveluihin ja niiden ympäristöihin. Lisäksi uudet asiakkaat
          voivat ottaa Tunnistus-palvelun käyttöön Palveluhallinnan kautta.
          Käyttöönoton prosessia on hieman suoraviivaistettu, ja
          organisaatioille tarjotaan vain yksi yhteydenottokanava käyttölupaan
          liittyen. Seuraavissa julkaisuissa paperiprosessit muuttuvat
          sähköisiksi, kun taustajärjestelmiin integroidut lupaprosessit tulevat
          laajamittaisesti käyttöön.
        </p><p>
          Suomi.fi-palveluhallinta kattaa jatkossa kaikkien Suomi.fi-palvelujen
          käyttöönottojen käyttölupahakemukset ja tekniset hallintaliittymät.
          Itsepalveluperiaatteella toimivat hallintaliittymät vaihtelevat
          palveluittain: Palvelutietovarannossa voidaan syöttää palvelujen
          tietoja ja Palveluväylässä on mahdollista rekisteröidä esimerkiksi
          uusia liityntäpalvelimia. Palveluhallinta lisää joka tapauksessa
          näkyvyyttä organisaation omiin tietoihin ja näyttää sen, mikä on
          jäänyt toistaiseksi hakemusten ja sähköpostiviestien uumeniin
          paperiprosessissa. Täysin uutena toimintona Palveluhallintaan tuodaan
          ensi vuoden puolella asiakaskohtaiset tilastot julkisten tilastojen
          rinnalle.
        </p><p>
          Palveluhallintaa kehitetään Digi- ja väestötietovirastossa
          asiakaslähtöisesti. Asiakkaat pääsevät osallistumaan kehittämiseen
          asiakasreferenssiryhmien kautta, joista ilmoitetaan Palveluhallinnan
          etusivulla. Osa asiakkaista kutsutaan ryhmiin myös suoraan
          sähköpostitse. Palautetta Palveluhallinnasta voi lähettää osoitteeseen
          palveluhallinta@dvv.fi
        </p><p>
          <i>Palveluhallinnan myötä esitellään kokonaan uusia tai asiakkaille
            entuudestaan tuntemattomia käsitteitä. Uusia termejä ovat muun muassa:</i>
          <ul className="v-offset-2">
            <li>
          <b>Hallintaliittymä:</b> Palveluhallinnan palvelukohtainen osio, jossa voi
          tehdä teknistä konfigurointia eli esimerkiksi lisätä Tunnistuksen
          asiointipalvelun. Palvelutietovarannossa syötetään asiointipalvelujen
          tietoja ja Valtuudet-palvelussa hallitaan säännöstöä. Palveluhallinnan
          hallintaliittymät korvaavat Suomi.fi-palveluiden nykyiset
          hallintaliittymät siten, että asiakkaalla on Palveluhallinnassa pääsy
          kaikkien käyttöönottamiensa palvelujen hallintaliittymiin yhden luukun
          periaatteella.
            </li>
            <li>
          <b>Käyttölupahakemus:</b> Käyttöönoton yhteydessä tehtävä
          hakemuskokonaisuus, joka lähetetään hallinnollista päätöstä varten
          Digi- ja väestötietovirastolle. Käyttölupa sisältää useamman osion, joita
          on aiemmin nimitetty esimerkiksi tietoluvaksi, kattoluvaksi jne.
          Palveluhallinnassa asiakas saa yhden päätöksen hakiessaan
          palvelukohtaista käyttölupaa. Hakemukset ja päätökset tallentuvat
              Palveluhallintaan, kun prosessi on sähköistetty.</li>
          </ul>
        </p>

        </div>
        <div className="col-md-4" />
      </div>
    </div>
  </section>;
};

ArticleReleaseNews.propTypes = {
  content: PropTypes.string,
  params: PropTypes.object,
  routes: PropTypes.array
};

ArticleReleaseNews.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ArticleReleaseNews;
/*eslint-enable */
