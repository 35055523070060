import { object } from 'yup';
import { userStr } from '../../utils/utility';

export const formTitle = 'intermediary.dataexchangelayer.apiContactTitle';

const userSchema = required =>
  object({
    organizationName: userStr(required, 'orgNameIsRequired', formTitle),
    businessId: userStr(required, 'businessIdIsRequired', formTitle),
    firstName: userStr(required, 'firstNameIsRequired', formTitle),
    lastName: userStr(required, 'lastNameIsRequired', formTitle),
    email: userStr(required, 'emailIsRequired', formTitle, true),
    phoneNumber: userStr(false, '')
  });

export const schema = object({
  apiCatalogueContactInformation: object({
    apiCatalogueContactPerson: userSchema(true)
  }).label(formTitle)
});
