import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import styles from './header.scss';
import { FormattedMessage } from 'react-intl';
import { Block, Paragraph } from 'suomifi-ui-components';

const mapActionCreators = {};
const mapStateToProps = state => ({
  currentStep: state.intermediary.currentStep,
  steps: state.intermediary.steps
});

const Header = ({ steps, currentStep }) => {
  const stepCount = steps?.length ? steps.length : '-';
  const baseLoc = 'forms.general.';
  return (
    <Block>
      <Paragraph marginBottomSpacing="xxs" className={styles.header}>
        <FormattedMessage id={baseLoc + 'step'} /> {currentStep + 1}/{stepCount}
      </Paragraph>
    </Block>
  );
};

Header.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.number
};

export default asyncConnect([], mapStateToProps, mapActionCreators)(Header);
