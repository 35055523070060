import React from 'react';
import PropTypes from 'prop-types';

import { Textarea } from 'suomifi-ui-components';
import { getStatus, getStatusText } from '../../../utils';

export const DiscloseInformationToThirdPartyInfo = ({ i18n, formik }) => {
  return (
    <Textarea
      name="formData.statementOnInformationSecurity.discloseInformationToThirdPartyInfo"
      labelText={i18n(
        'intermediary.dataProtection.discloseInformationToThirdPartyInfo'
      )}
      fullWidth
      status={getStatus(
        formik,
        'formData.statementOnInformationSecurity.discloseInformationToThirdPartyInfo'
      )}
      statusText={getStatusText(
        i18n,
        formik,
        'formData.statementOnInformationSecurity.discloseInformationToThirdPartyInfo'
      )}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
    >
      {
        formik?.values?.formData?.statementOnInformationSecurity
          ?.discloseInformationToThirdPartyInfo
      }
    </Textarea>
  );
};

DiscloseInformationToThirdPartyInfo.propTypes = {
  i18n: PropTypes.func,
  formik: PropTypes.object
};
