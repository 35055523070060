import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import styled from 'styled-components';

const Main = styled.main`
  height: 100%;
  background-color: hsl(0, 0%, 100%);
`;

export const PrintLayout = ({ children }) => <Main>{children}</Main>;

PrintLayout.propTypes = {
  children: PropTypes.node
};

export default pure(PrintLayout);
