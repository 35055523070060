import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose, pure, withHandlers } from 'recompose';
import { Button, IconSwapRounded } from 'suomifi-ui-components';
import OrgListItem from './OrgListItem';
import styles from './OrgSwitcher.scss';

const wrap = compose(
  pure,
  withHandlers({
    handleChange: props => value => {
      const { onOrgChange, openModal, closeModal, hideDropDown, user } = props;
      const showModal = true;
      if (showModal) {
        hideDropDown();

        const orgs =
          user &&
          user.organizations &&
          user.organizations.map(org => (
            <OrgListItem
              active={org.id === user.activeOrganizationId}
              key={org.id}
              org={org}
              organizationUnit={org.organizationUnit}
              onClick={() => {
                onOrgChange(org.id);
                closeModal();
              }}
            />
          ));
        openModal({
          content: (
            <div className={styles.orgModal}>
              <h3>
                <FormattedMessage id="organization.switcher.title" />
              </h3>
              <p>
                <FormattedMessage id="organization.switcher.description" />
              </p>
              <div>
                <ul className={styles.orgList}>{orgs}</ul>
              </div>
            </div>
          )
        });
      } else {
        onOrgChange(value);
      }
    }
  })
);

export const OrgSwitcher = props => (
  <div>
    <Button
      variant="secondaryNoBorder"
      icon={<IconSwapRounded />}
      onClick={props.handleChange}
      disabled={props.disabled || false}
    >
      <FormattedMessage id="organization.switcher.link_switch" />
    </Button>
  </div>
);

/* eslint-disable */
OrgSwitcher.propTypes = {
  handleChange: PropTypes.func.isRequired,
  select: PropTypes.bool,
  user: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};
/* eslint-enable */

export default wrap(OrgSwitcher);
