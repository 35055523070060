import 'isomorphic-fetch';

import { camelizeKeys } from 'humps';
import { setSessionExpired, updateUserToStore } from 'modules/auth';
import { validateTypesArray } from 'utils';
import { setSessionExpiredInCookie } from 'utils/sessionUtil';

export const API_ROOT = '/api/';

/**
 * Fetches an API response
 * Todo: Add normalization?
 */
export function callApi(endpoint, options, camelize, store) {
  const fullUrl =
    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  return fetch(fullUrl, options)
    .then(response => {
      return response.json().then(json => ({ json, response }));
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        if (response.status === 401) {
          setSessionExpiredInCookie(true);
          store.dispatch(setSessionExpired(true));
          store.dispatch(updateUserToStore(null));
        }
        return Promise.reject({ status: response.status, payload: json });
      }
      return camelize ? camelizeKeys(json) : json;
    });
  //.catch(error => console.log('secret error', error));
}

export function getOptions(
  data,
  method,
  contentType,
  disableStringify,
  userLang,
  noContentType
) {
  const options = {
    credentials: 'same-origin',
    headers: {
      Accept: contentType || 'application/json;charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      'X-Requested-With': 'SEMAFetch', // CSRF migitation @see http://tinyurl.com/zcyqr5u
      'Accept-Language': userLang
    }
  };

  if (!noContentType) {
    options.headers['Content-Type'] =
      contentType || 'application/json;charset=utf-8';
  }

  const isJsonContent =
    options.headers['Content-Type'] &&
    options.headers['Content-Type'].includes('application/json');

  if (data) {
    options.method = 'post';
    options.body =
      isJsonContent && !disableStringify ? JSON.stringify(data) : data;
  }
  !!method && (options.method = method);

  return options;
}

export const getError = error => {
  if (error instanceof TypeError) {
    return {
      stack: error.stack,
      message: error.message,
      name: error.name,
      fileName: error.fileName,
      lineNumber: error.lineNumber,
      columnNumber: error.columnNumber
    };
  } else if (error instanceof SyntaxError) {
    return { message: error.message };
  } else if (error.error) {
    return { message: error.error };
  }
  return { message: error };
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('CALL_API');

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint } = callAPI;
  const {
    data,
    method,
    contentType,
    meta,
    camelize,
    types,
    onSuccess,
    onFailure,
    disableStringify,
    noContentType
  } = callAPI;

  const doCamelize = camelize !== false; // true if not false

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  validateTypesArray(types);
  const lang =
    store.getState().translations && store.getState().translations.currentLang;
  const userLang = lang ? lang : 'fi';
  const options = getOptions(
    data,
    method,
    contentType,
    disableStringify,
    userLang,
    noContentType
  );

  function actionWith(data) {
    const finalAction = { ...action, ...data };
    delete finalAction[CALL_API];
    return finalAction;
  }

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType, payload: meta }));

  const callId = meta ? meta.callId : undefined;

  return callApi(endpoint, options, doCamelize, store).then(
    payload => {
      !!onSuccess && onSuccess(payload, next);

      next(
        actionWith({
          payload,
          type: successType,
          callId
        })
      );
      return payload;
    },
    error => {
      !!onFailure && onFailure(error);

      return next(
        actionWith({
          payload: getError(error),
          type: failureType,
          error: true,
          callId
        })
      );
    }
  );
};
