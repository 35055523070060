import React from 'react';
import { InlineAlert } from 'suomifi-ui-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './styles.scss';

const ErrorMessages = ({ i18n, steps, errors, setStep }) => {
  const stepsWithErrors = errors
    .reduce((acc, error) => {
      if (!acc.includes(error.params.label)) {
        acc.push(error.params.label);
      }
      return acc;
    }, [])
    .filter(Boolean);

  const jumpToStep = stepTitle => {
    const step = steps.findIndex(step => step.title === stepTitle);
    if (step !== -1) {
      setStep(step);
    }
  };

  if (stepsWithErrors.length === 0) {
    return null;
  }

  return (
    <InlineAlert
      labelText={i18n('intermediary.errorMessages.fillRequiredFields')}
      status="warning"
    >
      <ul>
        {stepsWithErrors.reverse().map(stepTitle => (
          <li key={stepTitle}>
            <button
              type="button"
              className={styles.errorListItemBtn}
              onClick={() => jumpToStep(stepTitle)}
            >
              <FormattedMessage id={stepTitle} />
            </button>
          </li>
        ))}
      </ul>
    </InlineAlert>
  );
};

ErrorMessages.propTypes = {
  i18n: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  errors: PropTypes.array,
  setStep: PropTypes.func.isRequired
};

export default ErrorMessages;
