import PropTypes from 'prop-types';
import React from 'react';
import { LanguageMenu, LanguageMenuItem } from 'suomifi-ui-components';
import styled from 'styled-components';

const languages = [
  { text: 'Suomeksi (FI)', lang: 'fi' },
  { text: 'På svenska (SV)', lang: 'sv' },
  { text: 'In English (EN)', lang: 'en' }
];

const StyledLanguageMenu = styled(LanguageMenu)`
  margin-right: 1rem;
`;

export const LangSwitcher = ({ locale: currentLocale, changeLang }) => {
  const selectedLanguage = languages.find(l => l.lang === currentLocale);

  return (
    <StyledLanguageMenu
      buttonText={selectedLanguage.text}
      aria-label={`Change language, selected language: ${selectedLanguage.text}`}
    >
      {languages.map(item => (
        <LanguageMenuItem
          onSelect={() => changeLang(item.lang)}
          data-test={`link-change-language-${item.lang}`}
          lang={item.lang}
          key={item.lang}
          selected={currentLocale === item.lang}
        >
          {item.text}
        </LanguageMenuItem>
      ))}
    </StyledLanguageMenu>
  );
};

LangSwitcher.propTypes = {
  locale: PropTypes.string.isRequired,
  changeLang: PropTypes.func.isRequired
};
