import { object, string } from 'yup';
import { userStr, backupUserSchema } from '../../utils/utility';

export const formTitle = 'intermediary.customerOrganisation.formTitle';
export const formTitleIntermediary = `${formTitle}Intermediary`;

const getTranslationPath = path => `intermediary.validation.${path}`;

const userSchema = (required, title) =>
  object({
    firstName: userStr(required, 'firstNameIsRequired', title),
    lastName: userStr(required, 'lastNameIsRequired', title),
    email: userStr(required, 'emailIsRequired', title, true),
    phoneNumber: userStr(false, 'phoneNumberIsRequired', title)
  });

export const schema = (isIntermediary, noTechnicalProcessEmail = false) => {
  const title = isIntermediary ? formTitleIntermediary : formTitle;
  return object({
    customerContacts: object({
      customerAdministrativeContactInformation: object({
        administrativeContactPerson: userSchema(true, title), // required always
        administrativeContactPersonBackup: backupUserSchema(formTitle)
      }).label(title),
      customerTechnicalContactInformation: object({
        technicalContactPerson: userSchema(
          isIntermediary ? false : true, // required only if not intermediary
          title
        ),
        technicalContactPersonBackup: backupUserSchema(formTitle)
      }).label(title),
      technicalProcessEmail: string()
        .when('customerAdministrativeContactInformation', (_, schema) => {
          return noTechnicalProcessEmail
            ? schema.notRequired()
            : schema.required('intermediary.validation.emailIsRequired');
        })
        .default('')
        .email(getTranslationPath('emailIsInvalid'))
        .label(title)
    })
  });
};
