import { asyncConnect } from 'redux-connect';
import { actions as notificationActions } from 'redux-notifications';
import RegisterEnvironmentView from '../components/RegisterEnvironmentView';
import { services } from 'constants/index';
import { postProviderXML } from '../modules';
import { getForms, fetchDecisions } from '../modules/module_OwnForms';
import { sendErrorNotification } from 'utils/notifications';
import { closeModal, openModal } from 'components/ReduxModal/modules';

const { notifSend } = notificationActions;

const mapActionCreators = {
  notifSend,
  postProviderXML,
  closeModal,
  openModal
};

const parseDecisions = formsList =>
  formsList.filter(
    formHeader =>
      formHeader.mainServiceType === services.EIDENTIFICATION &&
      // formHeader.name === 'registration' &&
      formHeader.decisionType === 'approved'
  );

const mapStateToProps = state => ({
  sahaGuid: state.auth.user && state.auth.user.activeOrganizationId,
  isFetching: state.idAdmin.isFetching || state.ownFormsView.isLoadingForm,
  currentProvider: state.idAdmin.currentProvider,
  decisionIds: state.ownFormsView.forms
    ? parseDecisions(state.ownFormsView.forms)
    : [],
  identificationDecisions: state.ownFormsView.identificationDecisions || []
});

export default asyncConnect(
  [
    {
      promise: ({ store, store: { dispatch } }) => {
        const state = store.getState();
        const orgId = state.auth.user && state.auth.user.activeOrganizationId;
        const loadedDecisions = state.ownFormsView.identificationDecisions;
        dispatch(getForms(orgId), store).then(result => {
          if (result.error) {
            dispatch(
              sendErrorNotification(
                'forms.notifications.own_forms_loading_error_title',
                ''
              )
            );
          } else {
            const decisionIds = parseDecisions(result).map(
              decision => decision.decisionId
            );
            if (
              state.ownFormsView.identificationDecisions.length <
              decisionIds.length
            ) {
              fetchDecisions(
                orgId,
                decisionIds,
                loadedDecisions,
                'eIdentification',
                dispatch
              );
            }
          }
        });
      }
    }
  ],
  mapStateToProps,
  mapActionCreators
)(RegisterEnvironmentView);
