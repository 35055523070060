import { browserHistory } from 'react-router';
import { startSignIn, requestUser } from 'modules/auth';

const getRedirectUrl = nextState =>
  nextState.location &&
  nextState.location.state &&
  nextState.location.state.nextPathname
    ? nextState.location.state.nextPathname
    : '/dashboard';

/**
 * Returns the routes dealing with authentication
 *
 * sign-in: When browser is coming back from SSO
 * to actually fetch and store current user.
 *
 * login: Deals just with the redirecting to SSO, naming
 * is a bit unclear. This should be "start-sign-in", but
 * /login feels almost standardized.
 *
 * @param store
 */
export default (store, browserLang) => [
  {
    path: '/rekisteroityminen',
    onEnter: (nextState, replace) => {
      browserHistory.push({
        pathname: `/${browserLang}/rekisteroityminen`,
        state: { nextPathname: getRedirectUrl(nextState) }
      });
    }
  },
  {
    path: 'login',
    onEnter: (nextState, replace) => {
      store.dispatch(startSignIn());
      window.location =
        '/api/auth/login?redirectUrl=' + getRedirectUrl(nextState);
    }
  },
  {
    path: 'sign-in',
    onEnter: async (nextState, replace) => {
      /*eslint-disable */
      const usr = await store.dispatch(requestUser());
      /*eslint-enable */
      const redirectUrl =
        nextState.location &&
        nextState.location.query &&
        nextState.location.query.redirectUrl
          ? nextState.location.query.redirectUrl
          : 'login';
      return browserHistory.push(redirectUrl);
    }
  }
];
