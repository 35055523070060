/**
 * Allows you to register actions that when dispatched, send the action to the
 * server via a socket.io socket.
 * `option` may be an array of action types, a test function, or a string prefix.
 */
export default function createSocketIoMiddleware(
  socket,
  option = '',
  { eventName = 'action' } = {}
) {
  return ({ dispatch }) => {
    // Wire socket.io to dispatch actions sent by the server.
    socket.on(eventName, dispatch);

    return next => action => {
      const { type } = action;

      if (type) {
        const emit = type.indexOf(option) === 0;

        if (emit) {
          socket.emit(eventName, action);
        }
      }

      return next(action);
    };
  };
}
