/* eslint-disable react/prop-types */
import React from 'react';
import { requireStrongAuth, requireTemporaryAuth } from 'modules/auth/utils';

import {
  Identification,
  ServiceCatalogue,
  DataExchangeLayer,
  Messages,
  EAuthorization
} from './forms';
import { ApproveApplicationContainer } from './pages/ApproveApplication/ApproveApplicationContainer';

const authenticate = store => requireStrongAuth(store.getState().auth);
const authenticateTemporary = store =>
  requireTemporaryAuth(store.getState().auth);

/**
 * E-Identification (Tunnistus)
 */
export const IdentificationForm = store => ({
  path: 'valitoimija/eIdentification/:id',
  onEnter: authenticate(store),
  components: {
    content: props => <Identification store={store} {...props} />
  }
});

/**
 * Service Catalogue (PTV)
 */
export const ServiceCatalogueForm = store => ({
  path: 'valitoimija/servicecatalogue/:id',
  onEnter: authenticate(store),
  components: {
    content: props => <ServiceCatalogue store={store} {...props} />
  }
});

/**
 * Messages
 */
export const MessagesForm = store => ({
  path: 'valitoimija/messages/:id',
  onEnter: authenticate(store),
  components: {
    content: props => <Messages store={store} {...props} />
  }
});

/**
 * Data exchange layer - Palveluväylä
 */
export const DataExchangeLayerForm = store => ({
  path: 'valitoimija/dataexchangelayer/:id',
  onEnter: authenticate(store),
  components: {
    content: props => <DataExchangeLayer store={store} {...props} />
  }
});

/**
 * eAuthorization (Valtuutus)
 */
export const eAuthorizationForm = store => ({
  path: 'valitoimija/eauthorization/:id',
  onEnter: authenticate(store),
  components: {
    content: props => <EAuthorization store={store} {...props} />
  }
});

/** Approve application */
export const ApproveApplication = store => ({
  path: 'valitoimija/hyvaksy-ehdot/:id',
  onEnter: () => {
    if (!store.getState().auth.isAuthenticated) {
      authenticateTemporary(store);
    }
  },
  components: {
    content: props => <ApproveApplicationContainer store={store} {...props} />
  }
});
