import React from 'react';
import styles from './Overlay.scss';
import cx from 'classnames';

class OverlayComponent extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      component: this.props.component
    };

  }

  render() {

    const { component, unset } = this.props;
    return (
      <div className={styles.overlay} onClick={() => unset()}>
        { component }
      </div>
    );

  }

}

export default OverlayComponent;
