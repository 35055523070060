import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import { Button } from 'sema-ui-components';
import iconFail from '!!svg-inline-loader!sema-ui-components/dist/components/icons/old/icon-fail.svg';
import cx from 'classnames';
import styles from './FileUpload.scss';
import { postSemaFile, deleteSemaFile } from 'utils/http';

const BASE_LOC = 'file_upload_component';

const UPLOAD_URL = '/api/file-upload';
const DELETE_URL = '/api/file-remove';

class FileUpload extends React.Component {
  static propTypes = {
    notifSend: PropTypes.func,
    onChange: PropTypes.func,
    maxFiles: PropTypes.number,
    title: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.any,
    startUpload: PropTypes.func,
    endUpload: PropTypes.func,
    value: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    value: '',
    maxFiles: 5,
    error: false
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      isCommunicating: false,
      oldValue: props.value
    };
  }

  isValueChanged = () => {
    const oldValue = this.state.oldValue;
    const newValue = this.props.value;
    const oldValueString = Object.keys(oldValue)
      .sort()
      .join();
    const newValueString = Object.keys(newValue)
      .sort()
      .join();
    return oldValueString !== newValueString;
  };

  sendError(name) {
    const loc = `${BASE_LOC}.errors.${name}.`;
    this.props.notifSend({
      title: this.formatMessage(`${loc}title`),
      description: this.formatMessage(`${loc}description`),
      type: 'fail',
      dismissAfter: 5000
    });
  }

  formatMessage = id => this.context.intl.formatMessage({ id });
  startUpload = (...args) =>
    this.props.startUpload && this.props.startUpload(...args);
  endUpload = (...args) =>
    this.props.endUpload && this.props.endUpload(...args);
  startCommunicating = () => this.setState({ isCommunicating: true });
  endCommunicating = () => this.setState({ isCommunicating: false });
  onOpenClick = () => this.dropzone.open();

  getError = errorMessage => (
    <div className={styles.errorMessage}>{errorMessage}</div>
  );

  onDrop = files => {
    if (!this.state.isCommunicating) {
      this.isTooManyFiles(files)
        ? this.sendError('maxFiles')
        : this.requestFileUpload(this.createBody(files));
    }
  };

  requestFileUpload = async files => {
    this.startUpload();
    this.startCommunicating();

    try {
      const returnValue = await postSemaFile(UPLOAD_URL, files);

      if (returnValue.httpCode === 200) {
        const returnData = returnValue.data;

        const cleanedData = this.props.value || {};

        Object.keys(returnData).forEach(key => {
          cleanedData[returnData[key].fileKey] = returnData[key].fileName;
        });

        this.props.onChange(cleanedData);
      } else {
        this.sendError('upload');
      }
    } catch (err) {
      console.error(err);
      this.sendError('upload');
    }

    this.endCommunicating();
    this.endUpload(this.props.name, this.props.value);
  };

  isTooManyFiles = files =>
    Object.keys(this.props.value).length + files.length > this.props.maxFiles;
  createBody = files => {
    const body = new FormData();
    Object.keys(files).forEach(key => {
      body.append('file', files[key]);
    });
    return body;
  };

  removeFile = async key => {
    if (!this.state.isCommunicating) {
      this.startUpload();
      this.startCommunicating();

      try {
        const url = DELETE_URL + '?fileKey=' + key;
        const returnValue = await deleteSemaFile(url, {});

        if (returnValue.httpCode === 204) {
          const newValue = {};
          Object.keys(this.props.value)
            .filter(fileKey => fileKey !== key)
            .forEach(fileKey => {
              newValue[fileKey] = this.props.value[fileKey];
            });
          this.props.onChange(newValue);
        } else {
          this.sendError('remove');
        }
      } catch (err) {
        console.error(err);
        this.sendError('remove');
      }

      this.endCommunicating();
      this.endUpload(this.props.name, this.props.value);
    }
  };

  render() {
    const { isCommunicating } = this.state;
    const {
      value,
      maxFiles,
      title,
      errorMessage,
      error,
      className,
      disabled
    } = this.props;

    const errorClass = error ? styles.error : null;
    const valueCount = Object.keys(value).length;
    const disabledClass =
      isCommunicating || valueCount >= maxFiles
        ? styles.dropzoneDisabled
        : null;
    const elementStyle = cx(styles.dropzone, errorClass, disabledClass);

    return (
      <div className={className}>
        <Dropzone
          className={elementStyle}
          activeClassName={styles.dropzoneActice}
          onDrop={this.onDrop}
          ref={node => {
            this.dropzone = node;
          }}
          disableClick
        >
          {
            title &&
              <div className={styles.uploadTitle}>
                { title }
              </div>
          }
          {valueCount === 0 ? (
            <div className={styles.dropzoneInfo} />
          ) : (
            <div className={styles.dropzoneFileContainer}>
              {Object.keys(value).map(key => (
                <div className={styles.dropzoneFile} key={key}>
                  {value[key]}
                  <div
                    className={styles.removeFile}
                    onClick={() => this.removeFile(key)}
                  >
                    x
                  </div>
                </div>
              ))}
            </div>
          )}
          <Button
            small
            secondary
            type="button"
            disabled={isCommunicating || valueCount >= maxFiles || disabled}
            className={styles.addButton}
            onClick={this.onOpenClick}
          >
            <FormattedMessage id="file_upload_add_file" />
          </Button>
        </Dropzone>
        {error && this.getError(errorMessage)}
      </div>
    );
  }
}

FileUpload.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FileUpload;
