import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  Block,
  Heading,
  Paragraph,
  RadioButton,
  RadioButtonGroup,
  Text
} from 'suomifi-ui-components';

export const LoggingReguirementsMet = ({
  i18n,
  loggingReguirementsMet,
  formik,
  isEIdentificationForm
}) => {
  return (
    <Block>
      <Heading variant="h3" className="mb-2">
        <FormattedMessage id="intermediary.dataProtection.loggingReguirementTitle" />
      </Heading>
      <RadioButtonGroup
        labelText={i18n(
          isEIdentificationForm
            ? 'intermediary.dataProtection.desc_loggingReguirementsMet_eIdentification'
            : 'intermediary.dataProtection.desc_loggingReguirementsMet'
        )}
        name="formData.statementOnInformationSecurity.loggingReguirementsMet"
        value={loggingReguirementsMet}
        onChange={value =>
          formik.setFieldValue(
            'formData.statementOnInformationSecurity.loggingReguirementsMet',
            value === 'true'
          )
        }
      >
        <RadioButton value="true">
          <FormattedMessage id="intermediary.common.yes" />
        </RadioButton>
        <RadioButton value="false">
          <FormattedMessage id="intermediary.common.no" />
        </RadioButton>
      </RadioButtonGroup>

      <Paragraph marginBottomSpacing="s">
        <Text variant="bold">
          <FormattedMessage
            id={`intermediary.dataProtection.${
              isEIdentificationForm
                ? 'desc_logging_reguirements'
                : 'followingsMet'
            }`}
          />
        </Text>
      </Paragraph>
      <ul>
        <li>
          <FormattedMessage id="intermediary.dataProtection.condition1" />
        </li>
        <li>
          <FormattedMessage id="intermediary.dataProtection.condition2" />
        </li>
        <li>
          <FormattedMessage id="intermediary.dataProtection.condition3" />
        </li>
        <li>
          <FormattedMessage id="intermediary.dataProtection.condition4" />
        </li>
        <li>
          <FormattedMessage id="intermediary.dataProtection.condition5" />
        </li>
      </ul>
    </Block>
  );
};

LoggingReguirementsMet.propTypes = {
  i18n: PropTypes.func,
  loggingReguirementsMet: PropTypes.string,
  formik: PropTypes.object,
  isEIdentificationForm: PropTypes.bool
};
