import { Modal } from 'components/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const ReduxModal = ({ isOpen, closeModal, content, contentLabel }) => (
  <Modal
    isOpen={isOpen}
    contentLabel={contentLabel}
    onRequestClose={() => closeModal()}
  >
    {content}
  </Modal>
);

ReduxModal.propTypes = {
  isOpen: PropTypes.bool,
  contentLabel: PropTypes.string,
  closeModal: PropTypes.func,
  content: PropTypes.node
};

ReduxModal.defaultProps = {
  contentLabel: 'Modal'
};
export default ReduxModal;
