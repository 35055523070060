import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { compose, withHandlers, pure } from 'recompose';
import { FormattedMessage } from 'react-intl';
import TranslatableHelmet from 'components/TranslatableHelmet';
import { Button } from 'sema-ui-components';
import styles from './SignInFailed.scss';

export const SignInFailed = ({ handleGoHome, params: { lang } }) => (
  <div className={styles.base}>
    <TranslatableHelmet
      locale={lang}
      transKey="general.restricted_usage.title"
    />
    <h2 className="center">
      <FormattedMessage id="general.restricted_usage.title" />
    </h2>
    <div className="row">
      <div className="offset-md-7 col-md-10">
        <p className={styles.description}>
          <FormattedMessage id="sign_in.strong_sign_in_failed_description" />
          <a href="mailto:suomi.fi-asiakaspalvelu@dvv.fi">
            <FormattedMessage id="support.contact_pages.malfunction_email" />
          </a>
        </p>
        <div className="text-center">
          <Button medium onClick={handleGoHome}>
            Jatka etusivulle
          </Button>
        </div>
      </div>
    </div>
  </div>
);

SignInFailed.propTypes = {
  handleGoHome: PropTypes.func.isRequired
};

export default compose(
  withHandlers({
    handleGoHome: props => () => browserHistory.push(`/${props.params.lang}`)
  }),
  pure
)(SignInFailed);