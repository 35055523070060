import { serviceConnectionMethods } from 'constants/index';
import { Forms } from '../FormIds';

const clientServiceIdToSAHAFormat = service => {
  service.serviceId = service.id;
  delete service.id;
};

const booleansToSAHAFormat = (data, booleanFields) => {
  const isBoolean = key => booleanFields.find(e => e === key);
  const fromRadioButtonToSAHAValue = value =>
    value === 'yes' ? true : value === 'no' ? false : null;
  Object.keys(data).forEach(key => {
    const value = data[key];
    data[key] = !isBoolean(key) ? value : fromRadioButtonToSAHAValue(value);
  });
};

const attachmentsToSAHAFormat = attachmentsObj =>
  Object.keys(attachmentsObj).map(k => ({
    originalFileName: attachmentsObj[k],
    tempFileName: k
  }));

const formatClientServicesAttachments = (services, attachmentFields) => {
  services.forEach(service => {
    attachmentFields.forEach(attachment => {
      service[attachment] = service[attachment]
        ? attachmentsToSAHAFormat(service[attachment])
        : null;
    });
  });
  return services;
};

export const clientServicesArrayToSAHAFormat = (
  services,
  booleanFields,
  attachmentFields
) => {
  let servicesInSAHAFormat = [];
  Object.keys(services).forEach(k => servicesInSAHAFormat.push(services[k]));
  servicesInSAHAFormat.forEach(service => {
    clientServiceIdToSAHAFormat(service);
    booleansToSAHAFormat(service, booleanFields);
  });
  return formatClientServicesAttachments(
    servicesInSAHAFormat,
    attachmentFields
  );
};

export const convertToSAHAFormat = {
  [Forms.serviceBusCertificateForm.pahaFormName]: (
    form,
    formGUID,
    isSending
  ) => {
    const formData = form && form.formData;
    const orgInf = formData && formData.organizationInformation;
    const contactInf = (formData && formData.contactInformation) || {};

    const serviceBusCertificateInfo =
      (formData && formData.serviceBusCertificateInfo) || {};
    const acceptanceOfTerms = formData && formData.acceptanceOfTerms;
    const files = [];

    Object.keys(serviceBusCertificateInfo).forEach(key => {
      if (key.startsWith('attachment')) {
        Object.keys(serviceBusCertificateInfo[key]).forEach(file => {
          files.push({
            tempFileName: file,
            originalFileName: serviceBusCertificateInfo[key][file],
            customName: key
          });
        });
      }
    });

    const toSaha = {
      id: formGUID,
      formData: {
        approverName: isSending && formData.proposerName,
        proposerName: formData.proposerName,
        id: formGUID, // This should be to the upper level, or not....
        formState: form && form.formState,
        temp: { finishedPages: form.data && form.data.finishedPages },
        organizationInformation: {
          organisationName: orgInf.organizationName || orgInf.organisationName,
          businessId: orgInf.businessId,
          organisationUnit: orgInf.organizationUnit || orgInf.organisationUnit,
          address: orgInf.address,
          postalCode: orgInf.postalCode,
          postOffice: orgInf.postOffice,
          industry: orgInf.industry
        },
        contactInformation: {
          technicalContactInformation: contactInf.technicalContactInformation,
          administrativeContactInformation:
            contactInf.administrativeContactInformation
        },
        serviceBusCertificateInfo: {
          masterUser: serviceBusCertificateInfo.masterUser,
          environmentType: serviceBusCertificateInfo.environmentType,
          requestSender: serviceBusCertificateInfo.requestSender,
          ipv4: serviceBusCertificateInfo.ipv4,
          fqdn: serviceBusCertificateInfo.fqdn
        },
        acceptanceOfTerms: {
          acceptanceOfVRKPermit:
            acceptanceOfTerms && acceptanceOfTerms.acceptanceOfVRKPermit
        },
        files
      }
    };
    return toSaha;
  }
};
