import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createSocketIoMiddleware from './middleware/createSocketIo';
import apiMiddleware from './middleware/api';
import localForageMiddleware from './middleware/localForage';
import makeRootReducer from './reducers';

export default (initialState = {}, history, socket) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    thunk,
    routerMiddleware(history),
    apiMiddleware,
    localForageMiddleware
  ];
  if (socket) {
    const socketIoMiddleware = createSocketIoMiddleware(socket, 'SOCK/');
    middleware.push(socketIoMiddleware);
  }

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];
  if (__DEV__ || __INT__) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (devToolsExtension) {
      enhancers.push(devToolsExtension());
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer(),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default;
      store.replaceReducer(reducers);
    });
  }

  return store;
};
