export const states = [
  'DRAFT',
  'SENT',
  'REQUEST_MORE_INFO',
  'PROPOSED',
  'WAITING_FOR_APPROVAL',
  'DECIDED',
  'WAITING_FOR_DELIVERY',
  'PRELIMINARY',
  'WAITING_CUSTOMER_TO_APPROVE',
  'APPROVED',
  'ENDED',
  'REJECTED'
];
