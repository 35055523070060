import {
  createConsts as cc,
  createReducer,
  createAction as ca
} from '../../../utils';
import { CALL_API } from '../../../store/middleware/api';

// ------------------------------------
// Constants & FSA actions
// ------------------------------------
export const [
  [FEEDBACK_POST, feedbackPost],
  [FEEDBACK_SUCCESS, receiveFeedback],
  [FEEDBACK_ERROR, feedbackError],
  [CLEAR_STATE, clearState],
  [UPDATE_VALUES, updateValues]
] = cc([
  'FEEDBACK_POST',
  'FEEDBACK_SUCCESS',
  'FEEDBACK_ERROR',
  'CLEAR_STATE',
  'UPDATE_VALUES'
]);

export const requestAsyncPost = (values, type) => {
  return {
    [CALL_API]: {
      types: [FEEDBACK_POST, FEEDBACK_SUCCESS, FEEDBACK_ERROR],
      endpoint: `feedback/${type}`,
      data: values
    }
  };
};

export const updateThanksValues = (userName, userEmail, userMessage) => {
  return async dispatch => {
    dispatch(updateValues({ userName, userEmail, userMessage }));
  };
};

export const clearFormState = () => {
  return async dispatch => {
    dispatch(clearState());
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FEEDBACK_POST]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [FEEDBACK_SUCCESS]: state => ({
    ...state,
    isFetching: false,
    isDone: true
  }),
  [FEEDBACK_ERROR]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [CLEAR_STATE]: state => ({
    ...state,
    isDone: false,
    isFetching: false
  }),
  [UPDATE_VALUES]: (state, { payload }) => ({
    ...state,
    userName: payload.userName,
    userEmail: payload.userEmail,
    userMessage: payload.userMessage
  })
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = {
  isFetching: false,
  isDone: false,
  userName: '',
  userEmail: '',
  userMessage: ''
};
export default createReducer(ACTION_HANDLERS, initialState);
