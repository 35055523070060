import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectReducers } from '../../store/reducers';
import { hasRole, requireStrongAuth } from 'modules/auth/utils';
import { checkKVHAccess } from 'utils/accessUtils';

export const basePath = 'kayttajahallinta';

export default (store, browserLang) => ({
  getComponents(nextState, next) {
    require.ensure(
      ['./containers/KVHAdminContainer', './modules'],
      require => {
        const kvhReducer = require('./modules/reducer').default;
        const userInvitationReducer = require('../UserRegistration/modules/module_Invitations')
          .default;
        const userAuthReducer = require('modules/auth/index').default;

        injectReducers(store, [
          { key: 'kvh', reducer: kvhReducer },
          { key: 'userInvitation', reducer: userInvitationReducer },
          { key: 'auth', reducer: userAuthReducer }
        ]);

        const content = require('./containers/KVHAdminContainer').default;
        next(null, { content });
      },
      'kvhAdmin'
    );
  },
  getChildRoutes: (location, cb) => {
    const auth = store.getState().auth || {};
    const roles = auth && auth.user && auth.user.roles;
    const isReadOnly = !(roles && checkKVHAccess(roles));
    // const auth = store.getState().auth;
    // const authenticate = requireAuth(auth, hasRole('ROVA_ADMIN'));

    cb(null, [
      {
        path: basePath,
        name: <FormattedMessage id="kvh_admin.title" />,
        onEnter: requireStrongAuth(store.getState().auth),
        indexRoute: {
          onEnter: (nextState, replace) =>
            replace(`/${location.params.lang}/${basePath}/kayttajat`)
        },
        childRoutes: [
          {
            path: 'kayttajat',
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const content = require('./containers/UserManagementTabsContainer')
                    .default;
                  next(null, content);
                },
                'kvhAdmin'
              );
            },
            indexRoute: {
              onEnter: (nextState, replace) =>
                replace(`/${location.params.lang}/${basePath}/kayttajat/lista`)
            },
            childRoutes: [
              {
                path: 'lista',
                name: <FormattedMessage id="kvh_admin.tab_users.list.title" />,
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/UserListContainer')
                        .default;
                      next(null, content);
                    },
                    'kvhAdmin'
                  );
                }
              },
              !isReadOnly && {
                path: 'kayttajien-hallinta',
                name: (
                  <FormattedMessage id="kvh_admin.tab_users.list_edit.title" />
                ),
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/UserPermissionEditListContainer')
                        .default;
                      next(null, content);
                    },
                    'kvhAdmin'
                  );
                }
              }
            ]
          },
          {
            path: 'kayttaja/:id',
            name: <FormattedMessage id="kvh_admin.user_edit.title" />,
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const content = require('./containers/UserEditViewContainer')
                    .default;
                  next(null, content);
                },
                'kvhAdmin'
              );
            }
          },
          !isReadOnly && {
            path: 'kutsu-kayttaja/:id',
            name: <FormattedMessage id="kvh_admin.invite_user.title" />,
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const content = require('./containers/InviteUserContainer')
                    .default;
                  next(null, content);
                },
                'kvhAdmin'
              );
            }
          }
        ]
      }
    ]);
  }
});
