import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { Modal } from 'components/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';

export const SessionExpiredModal = (
  { isModalOpen, closeModal, onConfirm },
  { intl }
) => {
  const i18n = id => intl.formatMessage({ id });
  const TITLE = i18n('session_expired_modal.title');
  const DESCRIPTION = i18n('session_expired_modal.description');
  const CONFIRM = i18n('session_expired_modal.onOk');
  const CANCEL = i18n('session_expired_modal.onCancel');

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="">
      <ConfirmationDialog
        title={TITLE}
        labelConfirm={CONFIRM}
        labelCancel={CANCEL}
        onConfirm={onConfirm}
        onCancel={closeModal}
      >
        {DESCRIPTION}
      </ConfirmationDialog>
    </Modal>
  );
};

SessionExpiredModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  onConfirm: PropTypes.func
};

SessionExpiredModal.contextTypes = {
  intl: PropTypes.object
};

export default pure(SessionExpiredModal);
