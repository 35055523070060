import { connect } from 'react-redux';
import { actions as notificationActions } from '../../../components/Notifs';
import FileUpload from '../components/FileUpload';

const { notifSend } = notificationActions;

const mapActionCreators = {
  notifSend
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapActionCreators)(FileUpload);
