import NewApiUserView from '../components/NewApiUserView';
import connectForm from 'utils/connectForm';
import validate from '../validate';
import { resetPassword } from '../../../modules/auth';
import { actions as notificationActions } from '../../../components/Notifs';

export default connectForm(NewApiUserView, {
  form: 'NewApiUserForm',
  validate,
  mapStateToProps: state => ({}),
  mapActionCreators: {
    resetPassword,
    notifSend: notificationActions.notifSend
  }
});
