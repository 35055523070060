/* eslint-disable react/prop-types */
import React from 'react';
import { FeatureToggle } from 'react-feature-toggles';
import { featureFlags } from 'constants';
import { Block, Button, Label } from 'suomifi-ui-components';
import servicecatalogueData from './data/servicecatalogue.json';
import servicecatalogueDataIntermediary from './data/servicecatalogue-intermediary.json';
import eIdentificationData from './data/eIdentification.json';
import eIdentificationDataIntermediary from './data/eIdentification-intermediary.json';
import dataexchangelayerData from './data/dataexchangelayer.json';
import dataexchangelayerDataIntermediary from './data/dataexchangelayer-intermediary.json';
import messagesData from './data/messages.json';
import messagesDataIntermediary from './data/messages-intermediary.json';
import eauthorizationData from './data/eauthorization.json';
import eauthorizationDataIntermediary from './data/eauthorization-intermediary.json';

import { formTypes, proposalStyles } from '../../constants';

const items = [
  {
    type: formTypes.SERVICECATALOGUE,
    proposalStyle: proposalStyles.OWN,
    data: servicecatalogueData
  },
  {
    type: formTypes.SERVICECATALOGUE,
    proposalStyle: proposalStyles.INTERMEDIARY,
    data: servicecatalogueDataIntermediary
  },
  {
    type: formTypes.EIDENTIFICATION,
    proposalStyle: proposalStyles.OWN,
    data: eIdentificationData
  },
  {
    type: formTypes.EIDENTIFICATION,
    proposalStyle: proposalStyles.INTERMEDIARY,
    data: eIdentificationDataIntermediary
  },
  {
    type: formTypes.DATAEXCHANGELAYER,
    proposalStyle: proposalStyles.OWN,
    data: dataexchangelayerData
  },
  {
    type: formTypes.DATAEXCHANGELAYER,
    proposalStyle: proposalStyles.INTERMEDIARY,
    data: dataexchangelayerDataIntermediary
  },
  {
    type: formTypes.MESSAGES,
    proposalStyle: proposalStyles.OWN,
    data: messagesData
  },
  {
    type: formTypes.MESSAGES,
    proposalStyle: proposalStyles.INTERMEDIARY,
    data: messagesDataIntermediary
  },
  {
    type: formTypes.EAUTHORIZATION,
    proposalStyle: proposalStyles.OWN,
    data: eauthorizationData
  },
  {
    type: formTypes.EAUTHORIZATION,
    proposalStyle: proposalStyles.INTERMEDIARY,
    data: eauthorizationDataIntermediary
  }
];

const Prefill = ({ formik, proposalStyle, serviceType }) => {
  const setMockData = () => {
    const fakeData = items.find(
      item => item.type === serviceType && item.proposalStyle === proposalStyle
    )?.data;
    if (!fakeData) {
      console.error('No fake data found');
      return;
    }

    const data = {
      ...formik.values,
      formData: {
        ...fakeData.formData,
        customerOrganization:
          proposalStyle === proposalStyles.OWN
            ? formik.values.formData.customerOrganization
            : fakeData.formData.customerOrganization
      }
    };

    formik.setValues(data);
  };

  return (
    <FeatureToggle featureName={featureFlags.INTERMEDIARY_PREFILL}>
      <Block my="l">
        <Label>Lomakkeen esitäyttö</Label>
        <Button onClick={() => setMockData(formik.values)}>
          Esitäytä lomake
        </Button>
      </Block>
    </FeatureToggle>
  );
};

export default Prefill;
