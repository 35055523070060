import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbLink } from 'suomifi-ui-components';

export const Breadcrumbs = ({ i18n, locale }) => {
  return (
    <Breadcrumb aria-label="breadcrumb">
      <BreadcrumbLink href="/">
        {i18n('intermediary.application.home')}
      </BreadcrumbLink>
      <BreadcrumbLink href={`/${locale}/own-applications`}>
        {i18n('intermediary.application.licenses')}
      </BreadcrumbLink>
      <BreadcrumbLink current>
        {i18n('intermediary.application.license')}
      </BreadcrumbLink>
    </Breadcrumb>
  );
};

Breadcrumbs.propTypes = {
  i18n: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired
};
