import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'components/Modal';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import worker from '../../../build/worker';
import WorkerBuilder from '../../../build/worker-builder';

const SessionTimerModal = (
  { signOut, setSessionExpiredInCookie, sessionExpires },
  { intl }
) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [countDownMillis, setCountDownMillis] = useState(0);
  const [sessionTimedOut, setSessionTimedOut] = useState(false);
  const i18n = id => intl.formatMessage({ id });
  const [title] = useState(document.title);
  const workerRef = useRef();

  const showModalDurationMillis = 1200000; // 20 minutes before logout

  const logoutMinutes = Math.floor(countDownMillis / 60000);
  const logoutSeconds = Math.floor((countDownMillis % 60000) / 1000);
  const titleMinutes = logoutMinutes < 10 ? '0' + logoutMinutes : logoutMinutes;
  const titleSeconds = logoutSeconds < 10 ? '0' + logoutSeconds : logoutSeconds;
  const countDownTitle = `${titleMinutes}:${titleSeconds} ` + title;

  const startWebWorkerTimer = () => {
    workerRef.current.postMessage({
      turn: 'on',
      logoutInMillis: sessionExpires * 1000 - Date.now()
    });
  };

  const resetWebWorkerTimer = () => {
    setCountDownMillis(0);
    workerRef.current.terminate();
  };

  const logOut = () => {
    setModalIsOpen(false);
    document.title = title;
    resetWebWorkerTimer();
    signOut();
  };

  const stayLoggedIn = () => {
    setModalIsOpen(false);
    setModalShown(true);
  };

  useEffect(() => {
    workerRef.current = new WorkerBuilder(worker);

    startWebWorkerTimer();
    workerRef.current.onmessage = () => {
      setCountDownMillis(sessionExpires * 1000 - Date.now());
    };

    return () => {
      // Cleanup function to terminate the worker when the component unmounts
      workerRef.current.terminate();
    };
  }, [sessionExpires]);

  useEffect(() => {
    setCountDownMillis(sessionExpires * 1000 - Date.now());
    if (
      showModalDurationMillis > sessionExpires * 1000 - Date.now() &&
      !modalShown
    ) {
      setModalIsOpen(true);
      setModalShown(true);
    }
    if (modalShown && countDownMillis <= 0) {
      resetWebWorkerTimer();
      setSessionTimedOut(true);
      setSessionExpiredInCookie(true);
    }
    if (modalIsOpen || (modalShown && countDownMillis >= 0)) {
      document.title = countDownTitle;
    }
  }, [
    countDownMillis,
    modalShown,
    modalIsOpen,
    sessionExpires,
    countDownTitle,
    showModalDurationMillis,
    title,
    setSessionExpiredInCookie
  ]);

  return (
    <div>
      <Modal isOpen={modalIsOpen || sessionTimedOut}>
        <ConfirmationDialog
          title={
            sessionTimedOut
              ? i18n('session_timer_modal.title_expired')
              : i18n('session_timer_modal.title')
          }
          labelConfirm={
            sessionTimedOut
              ? i18n('session_timer_modal.onOk_expired')
              : i18n('session_timer_modal.onOk')
          }
          onConfirm={sessionTimedOut ? logOut : stayLoggedIn}
        >
          <FormattedMessage
            id={
              sessionTimedOut
                ? 'session_timer_modal.description_expired'
                : 'session_timer_modal.description'
            }
            values={{
              minutes: logoutMinutes,
              seconds: logoutSeconds
            }}
          />
        </ConfirmationDialog>
      </Modal>
    </div>
  );
};

SessionTimerModal.propTypes = {
  signOut: PropTypes.func,
  setSessionExpiredInCookie: PropTypes.func,
  sessionExpires: PropTypes.number
};

SessionTimerModal.contextTypes = {
  intl: PropTypes.object
};

export default SessionTimerModal;
