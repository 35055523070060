import validator from 'validator';

const validate = values => {
  const errors = {};

  if (!values.targetEnv) {
    errors.targetEnv =
      'identification_admin.environment.register.target_env_mandatory';
  }

  if (
    values.targetEnv === 'production' &&
    !values.noDecision &&
    !values.decisionId
  ) {
    errors.noDecision =
      'identification_admin.environment.register.decision_mandatory';
  }

  if (!values.ownEnv) {
    errors.ownEnv =
      'identification_admin.environment.register.own_env_mandatory';
  }

  if (!values.xml || !validator.isLength(values.xml, { min: 1 })) {
    errors.xml = 'identification_admin.environment.register.xml_mandatory';
  }

  return errors;
};

export default validate;
