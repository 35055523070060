import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import styles from './BackLink.scss';

export function BackLinkContainer(props) {
  const { callback, children, light } = props;
  return (
    <div
      className={cx(styles.base, { [styles.light]: light })}
      onClick={callback}
    >
      <div className={styles.arrow}>
        <div className={styles.line} />
        <div className={styles.point} />
        {children}
      </div>
    </div>
  );
}

export const BackLink = ({
  previousUrl,
  light,
  beforeNavigate,
  goBack,
  className,
  customAction
}) => {
  const callback = () => {
    beforeNavigate && beforeNavigate();
    customAction
      ? customAction()
      : goBack
      ? browserHistory.goBack()
      : browserHistory.push(previousUrl);
  };

  return (
    <BackLinkContainer callback={callback} light={light}>
      <span className={cx(styles.link, className)}>
        <FormattedMessage id="general.go_back" />
      </span>
    </BackLinkContainer>
  );
};

BackLink.propTypes = {
  customAction: PropTypes.func,
  beforeNavigate: PropTypes.func,
  light: PropTypes.bool,
  goBack: PropTypes.bool,
  previousUrl: PropTypes.string,
  className: PropTypes.string
};

BackLink.defaultProps = {
  previousUrl: '/'
};

export default BackLink;
