import ErrorMessages from './ErrorMessages';
import { asyncConnect } from 'redux-connect';
import { setStep } from '../../modules/IntermediaryForms';

const mapActionCreators = { setStep };
const mapStateToProps = state => ({
  steps: state.intermediary.steps,
  errors: state.intermediary.errors
});

export default asyncConnect(
  [],
  mapStateToProps,
  mapActionCreators
)(ErrorMessages);
