import { createAction, createReducer } from 'utils';

// ------------------------------------
// Constants
// ------------------------------------
export const TOGGLE_SIDE_NAV = 'TOGGLE_SIDE_NAV';

// ------------------------------------
// Actions
// ------------------------------------
export const toggleSideNav = createAction(TOGGLE_SIDE_NAV);

// ------------------------------------
// Action handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOGGLE_SIDE_NAV]: (state, { payload }) =>
    Object.assign({}, state, { sideNavOpen: !state.sideNavOpen })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { sideNavOpen: false };
export default createReducer(ACTION_HANDLERS, initialState);
