import React from 'react';
import { RadioButton, RadioButtonGroup } from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

export const CommonRadioGroup = ({ label, name }, { intl }) => {
  const formik = useFormikContext();
  const i18n = id => intl.formatMessage({ id });
  const getValue = () => {
    if (formik.values.formData.serviceData[name] === null) {
      return null;
    }

    return formik.values.formData.serviceData[name] ? 'true' : 'false';
  };

  return (
    <RadioButtonGroup
      labelText={i18n(label)}
      name={`formData.serviceData.${name}`}
      value={getValue()}
      onChange={value =>
        formik.setFieldValue(`formData.serviceData.${name}`, value === 'true')
      }
    >
      <RadioButton value="true">
        <FormattedMessage id="intermediary.common.yes" />
      </RadioButton>
      <RadioButton value="false">
        <FormattedMessage id="intermediary.common.no" />
      </RadioButton>
    </RadioButtonGroup>
  );
};
CommonRadioGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};
CommonRadioGroup.contextTypes = {
  intl: PropTypes.object.isRequired
};
