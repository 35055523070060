import React from 'react';
import { FormattedMessage } from 'react-intl';
import { redirect } from '../../utils';
import { injectReducer } from '../../store/reducers';
import { scrollTo } from 'utils/ScrollToTop';

export const basePath = 'tuki';

export default (store, browserLang) => ({
  getComponents(nextState, next) {
    require.ensure(
      ['./containers/SupportViewContainer', './modules'],
      require => {
        const reducer = require('./modules').default;
        injectReducer(store, { key: 'support', reducer });

        const content = require('./containers/SupportViewContainer').default;
        next(null, { content });
      },
      'support'
    );
  },
  getChildRoutes: (location, cb) => {
    cb(null, [
      redirect(basePath, `/${location.params.lang}/${basePath}/etusivu`),
      {
        path: basePath,
        name: <FormattedMessage id="support.title" />,
        childRoutes: [
          {
            path: 'etusivu',
            name: <FormattedMessage id="support.title" />,
            indexRoute: {
              name: <FormattedMessage id="support.title" />,
              getComponents(nextState, next) {
                require.ensure(
                  [],
                  require => {
                    const content = require('./containers/HomeViewContainer')
                      .default;
                    next(null, content);
                  },
                  'support'
                );
              }
            }
          },
          {
            path: 'palvelut/:service',
            name: <FormattedMessage id="support.title" />,
            indexRoute: {
              name: <FormattedMessage id="support.title" />,
              getComponents(nextState, next) {
                require.ensure(
                  [],
                  require => {
                    const content = require('./containers/HomeViewContainer')
                      .default;
                    next(null, content);
                  },
                  'support'
                );
              }
            }
          },
          {
            path: 'artikkelit',
            name: <FormattedMessage id="support.articles_title" />,
            indexRoute: {
              name: <FormattedMessage id="support.articles_title" />,
              getComponents(nextState, next) {
                require.ensure(
                  [],
                  require => {
                    const content = require('./containers/ArticlesViewContainer')
                      .default;
                    next(null, content);
                  },
                  'support'
                );
              }
            },
            childRoutes: [
              {
                path: ':id',
                name: <FormattedMessage id="support.articles_title" />,
                onEnter: () =>
                  setTimeout(() => {
                    scrollTo(document, 0, 0);
                  }, 100),
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/ArticleViewContainer')
                        .default;
                      next(null, content);
                    },
                    'support'
                  );
                }
              }
            ]
          },
          {
            path: 'ukk',
            name: <FormattedMessage id="support.faq_title" />,
            indexRoute: {
              name: <FormattedMessage id="support.title" />,
              getComponents(nextState, next) {
                require.ensure(
                  [],
                  require => {
                    const content = require('./containers/FaqsViewContainer')
                      .default;
                    next(null, content);
                  },
                  'support'
                );
              }
            },
            childRoutes: [
              {
                path: ':id',
                name: <FormattedMessage id="support.title" />,
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/ArticleViewContainer')
                        .default;
                      next(null, content);
                    },
                    'support'
                  );
                }
              }
            ]
          },
          /*                {
            path: 'sanasto',
            name: <FormattedMessage id="support.vocabulary_title" />,
            getComponents(nextState, next) {
              const content = require('./containers/VocabularyViewContainer')
                .default;
              next(null, content);
            }
          },*/
          {
            path: 'yhteydenotto',
            name: <FormattedMessage id="support.contact_title" />,
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const content = require('./components/ContactViews/ContactView')
                    .default;
                  next(null, content);
                },
                'support'
              );
            }
            /*childRoutes: [
              {
                path: 'yhteydenotto',
                name: <FormattedMessage id="support.contact_title" />,
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/ContactGeneralViewContainer')
                        .default;
                      next(null, content);
                    },
                    'support'
                  );
                }
              },
              {
                path: 'palvelutietovaranto',
                name: (
                  <FormattedMessage id="support.service_titles.palvelutietovaranto" />
                ),
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/ContactServiceInfoPoolViewContainer')
                        .default;
                      next(null, content);
                    },
                    'support'
                  );
                }
              },
              {
                path: 'palveluvayla',
                name: (
                  <FormattedMessage id="support.service_titles.palveluvayla" />
                ),
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/ContactServiceArchitectureViewContainer')
                        .default;
                      next(null, content);
                    },
                    'support'
                  );
                }
              },
              {
                path: 'tunnistus',
                name: (
                  <FormattedMessage id="support.service_titles.tunnistus" />
                ),
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/ContactIdentificationViewContainer')
                        .default;
                      next(null, content);
                    },
                    'support'
                  );
                }
              },
              {
                path: 'valtuudet',
                name: (
                  <FormattedMessage id="support.service_titles.valtuudet" />
                ),
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/ContactRovaAdminViewContainer')
                        .default;
                      next(null, content);
                    },
                    'support'
                  );
                }
              }
            ]*/
          }
        ]
      }
    ]);
  }
});
