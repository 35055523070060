import { CALL_API } from '../../../store/middleware/api';

import { createReducer, createAction as ca } from '../../../utils';
import { findOrgProfile, createOrgProfile, fetchUsersForOrg } from './api';

const OWN_ORG = 'OWN_ORG';

export const FETCH_ORGANIZATION_REQUEST = `${OWN_ORG}_FETCH_ORGANIZATION_REQUEST`;
export const FETCH_ORGANIZATION_SUCCESS = `${OWN_ORG}_FETCH_ORGANIZATION_SUCCESS`;
export const FETCH_ORGANIZATION_ERROR = `${OWN_ORG}_FETCH_ORGANIZATION_ERROR`;

export const STORE_ORGANIZATION_REQUEST = `${OWN_ORG}_STORE_ORGANIZATION_REQUEST`;
export const STORE_ORGANIZATION_SUCCESS = `${OWN_ORG}_STORE_ORGANIZATION_SUCCESS`;
export const STORE_ORGANIZATION_ERROR = `${OWN_ORG}_STORE_ORGANIZATION_ERROR`;

export const USERS_FETCH_REQUEST = `${OWN_ORG}_USERS_FETCH_REQUEST`;
export const USERS_FETCH_SUCCESS = `${OWN_ORG}_USERS_FETCH_SUCCESS`;
export const USERS_FETCH_ERROR = `${OWN_ORG}_USERS_FETCH_ERROR`;

export const ORGANISATION_INTERMEDIARY_REQUEST = `${OWN_ORG}_ORGANISATION_INTERMEDIARY_REQUEST`;
export const ORGANISATION_INTERMEDIARY_SUCCESS = `${OWN_ORG}_ORGANISATION_INTERMEDIARY_SUCCESS`;
export const ORGANISATION_INTERMEDIARY_ERROR = `${OWN_ORG}_ORGANISATION_INTERMEDIARY_ERROR`;

const fetchOrganisationRequest = ca(FETCH_ORGANIZATION_REQUEST);
const fetchOrganisationSuccess = ca(FETCH_ORGANIZATION_SUCCESS);
const fetchOrganisationError = ca(FETCH_ORGANIZATION_ERROR);

export const fetchOrganisationProfile = orgId => async dispatch => {
  dispatch(fetchOrganisationRequest());
  try {
    const { org } = await findOrgProfile(orgId);
    dispatch(fetchOrganisationSuccess(org));
  } catch (err) {
    dispatch(fetchOrganisationError(err));
    throw err;
  }
};

const storeOrganisationRequest = ca(STORE_ORGANIZATION_REQUEST);
const storeOrganisationSuccess = ca(STORE_ORGANIZATION_SUCCESS);
const storeOrganisationError = ca(STORE_ORGANIZATION_ERROR);

export const storeOrganisationProfile = (orgId, orgData) => async dispatch => {
  dispatch(storeOrganisationRequest());
  try {
    await createOrgProfile(orgId, orgData);
    dispatch(storeOrganisationSuccess(orgData));
  } catch (err) {
    dispatch(storeOrganisationError(err));
    throw err;
  }
};

export const fetchAllUsers = (orgId, sahaGuid) => async dispatch => {
  dispatch(ca(USERS_FETCH_REQUEST)());
  try {
    const users = await fetchUsersForOrg(orgId, true, sahaGuid);
    dispatch(ca(USERS_FETCH_SUCCESS)(users));
    return users;
  } catch (e) {
    dispatch(ca(USERS_FETCH_ERROR)(e));
    throw e;
  }
};

const ACTION_HANDLERS = {
  [FETCH_ORGANIZATION_REQUEST]: state => ({ ...state, isFetchingOrg: true }),
  [FETCH_ORGANIZATION_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingOrg: false,
    org: payload
  }),
  [FETCH_ORGANIZATION_ERROR]: state => ({ ...state, isFetchingOrg: false }),
  [USERS_FETCH_REQUEST]: state => ({ ...state, isFetchingUsers: true }),
  [USERS_FETCH_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingUsers: false,
    users: payload
  }),
  [USERS_FETCH_ERROR]: (state, { payload }) => ({
    ...state,
    isFetchingUsers: false,
    error: payload
  }),
  [STORE_ORGANIZATION_REQUEST]: state => ({ ...state, isStoringOrg: true }),
  [STORE_ORGANIZATION_SUCCESS]: (state, { payload }) => ({
    ...state,
    isStoringOrg: false,
    org: payload
  }),
  [STORE_ORGANIZATION_ERROR]: (state, { payload }) => ({
    ...state,
    isStoringOrg: false,
    error: payload
  }),
  [ORGANISATION_INTERMEDIARY_REQUEST]: state => ({
    ...state,
    isSubmitting: true
  }),
  [ORGANISATION_INTERMEDIARY_SUCCESS]: (state, { payload }) => ({
    ...state,
    isSubmitting: false
  }),
  [ORGANISATION_INTERMEDIARY_ERROR]: state => ({
    ...state,
    isSubmitting: false
  })
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = {
  isStoringOrg: false,
  isFetchingOrg: false,
  isFetchingUsers: false,
  org: null,
  users: null,
  isInvitingUser: false,
  error: null
};
export default createReducer(ACTION_HANDLERS, initialState);

export function setOrgAsIntermediary(orgId, value) {
  return {
    [CALL_API]: {
      method: 'put',
      endpoint: `/api/organisationservice/users/own/organisation/intermediaryinfo/${orgId}?intermediary=${value}`,
      types: [
        ORGANISATION_INTERMEDIARY_REQUEST,
        ORGANISATION_INTERMEDIARY_SUCCESS,
        ORGANISATION_INTERMEDIARY_ERROR
      ]
    }
  };
}
