import React from 'react';
import PropTypes from 'prop-types';
import { Block, Heading, Paragraph } from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { IntermediaryTextField } from '..';

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 1rem;
`;

export const DisruptionEmailFields = ({ name }) => (
  <Block mb="l">
    <Heading variant="h3">
      <FormattedMessage id="intermediary.disruption.title" />
    </Heading>
    <StyledParagraph>
      <FormattedMessage id="intermediary.disruption.description" />
    </StyledParagraph>
    <IntermediaryTextField
      name={name}
      placeholder={'intermediary.field.writeEmail'}
      label={'intermediary.field.email'}
      width={'80%'}
    />
  </Block>
);

DisruptionEmailFields.propTypes = {
  name: PropTypes.string.isRequired
};
