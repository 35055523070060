import { createAction as ca, createReducer } from 'utils';
import { CALL_API } from 'store/middleware/api';

// ------------------------------------
// Constants & FSA actions
// ------------------------------------
const BUSINESS_ID_SEARCH_REQUEST = 'BUSINESS_ID_SEARCH_REQUEST';
const BUSINESS_ID_SEARCH_SUCCESS = 'BUSINESS_ID_SEARCH_SUCCESS';
const BUSINESS_ID_SEARCH_FAILURE = 'BUSINESS_ID_SEARCH_FAILURE';
const BUSINESS_ID_SEARCH_SET_TERM = 'BUSINESS_ID_SEARCH_SET_TERM';
const BUSINESS_ID_SEARCH_CLEAR_TERM = 'BUSINESS_ID_SEARCH_CLEAR_TERM';

export const setSearchTerm = ca(BUSINESS_ID_SEARCH_SET_TERM);
export const clearSearchTerm = ca(BUSINESS_ID_SEARCH_CLEAR_TERM);

// ------------------------------------
// Async actions
// ------------------------------------
export function requestAsyncSearch(q, lang) {
  return {
    [CALL_API]: {
      types: [
        BUSINESS_ID_SEARCH_REQUEST,
        BUSINESS_ID_SEARCH_SUCCESS,
        BUSINESS_ID_SEARCH_FAILURE
      ],
      endpoint: `organisationservice/findytj/${q}`,
      meta: q
    }
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [BUSINESS_ID_SEARCH_REQUEST]: (state, { payload }) => ({
    ...state,
    searchTerm: payload,
    error: null,
    isFetching: true
  }),
  [BUSINESS_ID_SEARCH_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: null,
    result: payload
  }),
  [BUSINESS_ID_SEARCH_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    result: null,
    error: payload
  }),
  [BUSINESS_ID_SEARCH_SET_TERM]: (state, { payload }) => ({
    ...state,
    searchTerm: payload
  }),
  [BUSINESS_ID_SEARCH_CLEAR_TERM]: state => ({
    ...state,
    searchTerm: '',
    error: null,
    result: null
  })
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = {
  searchTerm: '',
  error: null,
  result: null,
  isFetching: false,
  isBlurred: false,
  currentMax: 10
};
export default createReducer(ACTION_HANDLERS, initialState);
