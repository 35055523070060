import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sema-ui-components';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import TranslatableHelmet from 'components/TranslatableHelmet';
import BackLink from 'components/BackLink';
import styles from './SearchView.scss';
import { getResultItems } from './utils';
import { NoResults } from './';

const SearchView = props => {
  const {
    currentMax,
    results,
    params: { lang },
    searchTerm
  } = props;
  const resultItems = getResultItems(results.hits, lang);

  useEffect(() => {
    props.requestAsyncSearch(
      props.location.query.q,
      props.params.lang,
      props.currentMax
    );

    return () => {
      props.clearSearchTerm();
    };
  }, []);

  const searchMore = () => {
    props.requestAsyncSearch(
      props.searchTerm,
      props.params.lang,
      props.currentMax + 10
    );
  };

  return (
    <section className={styles.base}>
      <TranslatableHelmet locale={lang} transKey="search.title" />
      <div className="row">
        <div className="offset-md-4 col-md-3">
          <BackLink locale={lang} />
        </div>
        <div className={cx('col-md-13', styles.counter)}>
          {(!results.hits || results.hits.length === 0) && (
            <h6>
              <FormattedMessage id="search.no_results" />
            </h6>
          )}
          {results.hits && (
            <h6>
              {currentMax} / {results.total}{' '}
              <FormattedMessage id="search.results_for" /> &quot;{searchTerm}
              &quot;
            </h6>
          )}
        </div>
      </div>
      <div className={cx('v-offset-3')}>
        <div className={cx('row')}>
          <div className={cx('offset-md-4 col-md-16')}>
            <div className={styles.content}>
              {(!results.hits || results.hits.length === 0) && (
                <NoResults searchTerm={searchTerm} locale={lang} />
              )}
              {resultItems}
              {currentMax < results.total && (
                <Button small secondary onClick={searchMore}>
                  <FormattedMessage id="search.show_more_results" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SearchView.propTypes = {
  clearSearchTerm: PropTypes.func,
  currentMax: PropTypes.number,
  location: PropTypes.object,
  params: PropTypes.object,
  requestAsyncSearch: PropTypes.func,
  results: PropTypes.object,
  routes: PropTypes.array,
  searchTerm: PropTypes.string
};

export default SearchView;
