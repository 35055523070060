import { requireVrkServiceAdmin } from 'modules/auth/utils';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectReducer, injectReducers } from 'store/reducers';

export const basePath = 'hallinta';

export default store => ({
  getComponents(nextState, next) {
    require.ensure(
      ['./containers/AdminViewContainer', './modules/adminModule'],
      require => {
        const reducer = require('./modules/adminModule').default;
        injectReducer(store, { key: 'admin', reducer });
        const content = require('./containers/AdminViewContainer').default;
        next(null, { content });
      },
      'admin'
    );
  },
  getChildRoutes: (location, cb) => {
    const auth = store.getState().auth;
    const authenticate = requireVrkServiceAdmin(auth);

    cb(null, [
      {
        path: basePath,
        titleKey: 'admin.title',
        name: <FormattedMessage id="admin.title" />,
        onEnter: authenticate,
        childRoutes: [
          /**
           * Routes below are commented out, because they are not used
           * at the moment and they contain deprecated code.
           * They are kept here for possible later use.
           */

          /*{ 
            path: 'ilmoitukset',
            name: <FormattedMessage id="admin.notifications.title" />,
            getComponents(nextState, next) {
              require.ensure(
                ['./containers/ManageNotificationsContainer', './modules'],
                require => {
                  const reducer = require('./modules/notifications').default;
                  injectReducer(store, {
                    key: 'manageNotifications',
                    reducer
                  });
                  const content = require('./containers/ManageNotificationsContainer')
                    .default;
                  next(null, content);
                },
                'manageNotifications'
              );
            },
            childRoutes: [
              {
                path: ':id',
                name: <FormattedMessage id="admin.notifications.title" />,
                indexRoute: {
                  name: <FormattedMessage id="admin.notifications.title" />,
                  getComponents(nextState, next) {
                    require.ensure(
                      [
                        './containers/EditNotificationContainer',
                        './modules/notifications'
                      ],
                      require => {
                        const reducer = require('./modules/notifications')
                          .default;
                        injectReducer(store, {
                          key: 'manageNotifications',
                          reducer
                        });
                        const content = require('./containers/EditNotificationContainer')
                          .default;
                        next(null, content);
                      },
                      'manageNotifications'
                    );
                  }
                }
              }
            ]
          },
          {
            path: 'api-kayttaja',
            name: <FormattedMessage id="admin.api_user.title" />,
            indexRoute: {
              name: <FormattedMessage id="admin.api_user.title" />,
              getComponents(nextState, next) {
                require.ensure(
                  ['./containers/NewApiUserContainer', './modules'],
                  require => {
                    const reducer = require('./modules').default;
                    injectReducer(store, { key: 'apiUser', reducer });
                    const content = require('./containers/NewApiUserContainer')
                      .default;
                    next(null, content);
                  },
                  'apiUser'
                );
              }
            }
          },*/
          {
            path: 'lataaVanhaHakemus',
            name: <FormattedMessage id="admin.load_application.title" />,
            indexRoute: {
              name: <FormattedMessage id="admin.load_application.title" />,
              getComponents(nextState, next) {
                require.ensure(
                  ['./containers/LoadApplicationContainer', './modules'],
                  require => {
                    const content = require('./containers/LoadApplicationContainer')
                      .default;
                    next(null, content);
                  },
                  'admin'
                );
              }
            }
          },
          {
            path: 'organisaatiot',
            titleKey: 'admin.organisations.title',
            name: <FormattedMessage id="admin.organisations.title" />,
            indexRoute: {
              getComponents(nextState, next) {
                require.ensure(
                  [
                    './containers/OrganisationsListContainer',
                    './modules/manageCommon',
                    './modules/manageOrganisations'
                  ],
                  require => {
                    const manageCommon = require('./modules/manageCommon')
                      .default;
                    const manageOrganisations = require('./modules/manageOrganisations')
                      .default;
                    injectReducers(store, [
                      { key: 'manageCommon', reducer: manageCommon },
                      {
                        key: 'manageOrganisations',
                        reducer: manageOrganisations
                      }
                    ]);

                    const content = require('./containers/OrganisationsListContainer')
                      .default;
                    next(null, content);
                  },
                  'manageOrganisations'
                );
              }
            },
            childRoutes: [
              {
                path: ':id',
                indexRoute: {
                  getComponents(nextState, next) {
                    require.ensure(
                      [
                        './containers/OrganisationDetailsContainer',
                        './modules/manageCommon',
                        './modules/manageOrganisationDetails'
                      ],
                      require => {
                        const manageCommon = require('./modules/manageCommon')
                          .default;
                        const manageOrganisationDetails = require('./modules/manageOrganisationDetails')
                          .default;
                        injectReducers(store, [
                          { key: 'manageCommon', reducer: manageCommon },
                          {
                            key: 'manageOrganisationDetails',
                            reducer: manageOrganisationDetails
                          }
                        ]);

                        const content = require('./containers/OrganisationDetailsContainer')
                          .default;
                        next(null, content);
                      },
                      'manageOrganisations'
                    );
                  }
                }
              }
            ]
          },
          {
            path: 'kayttajat',
            titleKey: 'admin.users.title',
            name: <FormattedMessage id="admin.users.title" />,
            indexRoute: {
              getComponents(nextState, next) {
                require.ensure(
                  [
                    './containers/UsersListContainer',
                    './modules/manageCommon',
                    './modules/manageUsers'
                  ],
                  require => {
                    const manageCommon = require('./modules/manageCommon')
                      .default;
                    const manageUsers = require('./modules/manageUsers')
                      .default;
                    injectReducers(store, [
                      { key: 'manageCommon', reducer: manageCommon },
                      { key: 'manageUsers', reducer: manageUsers }
                    ]);

                    const content = require('./containers/UsersListContainer')
                      .default;
                    next(null, content);
                  },
                  'manageUsers'
                );
              }
            },
            childRoutes: [
              {
                path: ':id',
                indexRoute: {
                  getComponents(nextState, next) {
                    require.ensure(
                      [
                        './containers/UserDetailsContainer',
                        './modules/manageCommon',
                        './modules/manageUserDetails'
                      ],
                      require => {
                        const manageCommon = require('./modules/manageCommon')
                          .default;
                        const manageUserDetails = require('./modules/manageUserDetails')
                          .default;
                        injectReducers(store, [
                          { key: 'manageCommon', reducer: manageCommon },
                          {
                            key: 'manageUserDetails',
                            reducer: manageUserDetails
                          }
                        ]);

                        const content = require('./containers/UserDetailsContainer')
                          .default;
                        next(null, content);
                      },
                      'manageUsers'
                    );
                  }
                }
              }
            ]
          }
        ]
      }
    ]);
  }
});
