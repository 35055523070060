export const getSteps = (isIntermediary, initialSteps, formId, formData) => {
  // Remove intermediary steps if the form is not filled as intermediary
  const steps = isIntermediary
    ? initialSteps
    : initialSteps
        .filter(step => !step.intermediaryOnly)
        .map((s, i) => ({ ...s, id: i }));

  if (!formId) {
    return steps;
  }

  const lastStepIsValid = steps.every(step =>
    step.schema.isValidSync(formData, { abortEarly: true })
  );

  // Mark valid steps as completed, when restoring an existing form
  const withValidatedSteps = steps.map((step, i, { length }) => {
    const isLastStep = length - 1 === i;
    return {
      ...step,
      completed: isLastStep
        ? lastStepIsValid
        : step.schema.isValidSync(formData, {
            abortEarly: true
          })
    };
  });

  return withValidatedSteps;
};

export const handleSetOrganisation = (org, formik) => {
  const orgPath = 'formData.customerOrganization';
  const values = [
    [`${orgPath}.businessId`, org.businessId],
    [`${orgPath}.organizationName`, org.organizationName],
    [`${orgPath}.organizationUnit`, org.organizationUnit],
    [`${orgPath}.address`, org.address],
    [`${orgPath}.postalCode`, org.postalCode],
    [`${orgPath}.postOffice`, org.postOffice],
    ['customerOrganizationGuid', org.guid || null]
  ];
  values.forEach(([path, value]) => formik.setFieldValue(path, value));
};

export const handleClearOrganisation = formik => {
  const orgPath = 'formData.customerOrganization';
  const values = [
    [`${orgPath}.businessId`, ''],
    [`${orgPath}.organizationName`, ''],
    [`${orgPath}.organizationUnit`, ''],
    [`${orgPath}.address`, ''],
    [`${orgPath}.postalCode`, ''],
    [`${orgPath}.postOffice`, ''],
    ['customerOrganizationGuid', '']
  ];
  values.forEach(([path, value]) => formik.setFieldValue(path, value));
};
