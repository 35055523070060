import { asyncConnect } from 'redux-connect';
import { sendSuccessNotification } from 'utils/notifications';
import { FormSetup } from '../FormSetup';

import {
  deleteAttachment,
  getYTJData,
  resetFormState,
  sendAttachment,
  setCompleted,
  setErrorFetchingYTJData,
  setErrors,
  setInitialValues,
  setIsLoading,
  setIsSavingForm,
  setProposalStyle,
  setStep,
  setUnexpectedError,
  setupForm,
  setValidationSchema,
  transferAfterButtonClick
} from '../../modules/IntermediaryForms';

import {
  closeModal,
  openModal
} from '../../../../components/ReduxModal/modules';

const mapActionCreators = {
  closeModal,
  deleteAttachment,
  getYTJData,
  openModal,
  resetFormState,
  sendAttachment,
  sendSuccessNotification,
  setCompleted,
  setErrorFetchingYTJData,
  setErrors,
  setInitialValues,
  setIsLoading,
  setIsSavingForm,
  setProposalStyle,
  setStep,
  setUnexpectedError,
  setupForm,
  setValidationSchema,
  transferAfterButtonClick
};
const mapStateToProps = state => ({
  activeOrgId: state.auth.user?.activeOrganizationId,
  currentStep: state.intermediary.currentStep,
  errorFetchingYTJData: state.intermediary.errorFetchingYTJData,
  formData: state.intermediary.initialValues.formData,
  initialValues: state.intermediary.initialValues,
  isFetchingYTJData: state.intermediary.isFetchingYTJData,
  isLoading: state.intermediary.isLoading,
  isOpen: state.modal.isOpen,
  isTransferringAfterButtonClick:
    state.intermediary.isTransferringAfterButtonClick,
  nextFormState: state.intermediary.nextFormState,
  proposalStyle: state.intermediary.initialValues.proposalStyle,
  role: state.intermediary.role,
  state,
  steps: state.intermediary.steps,
  unexpectedError: state.intermediary.unexpectedError,
  user: state.auth.user,
  validationSchema: state.intermediary.validationSchema,
  ytjData: state.intermediary.ytjData
});

export default asyncConnect([], mapStateToProps, mapActionCreators)(FormSetup);
