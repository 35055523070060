import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

function connectForm(
  Component,
  { mapStateToProps, mapActionCreators, ...passProps }
) {
  const formComponent = reduxForm(passProps)(Component);

  return connect(
    mapStateToProps,
    mapActionCreators
  )(formComponent);
}

export default connectForm;
