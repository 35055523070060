import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Block,
  Button,
  Heading,
  Paragraph,
  Expander,
  ExpanderTitleButton,
  ExpanderContent,
  Textarea,
  Checkbox,
  CheckboxGroup,
  Tooltip,
  IconRemove
} from 'suomifi-ui-components';
import { Field } from 'formik';
import { getStatus, getStatusText } from '../../../utils/utility';
import { ServiceNameField } from './FieldHelpers';
import ConnectionMethods from './ConnectionMethods';
import NotificationContacts from './NotificationContacts';
import styles from '../styles.scss';

export const legals = [
  {
    title: '5.1 §',
    value: 'fivePointOne',
    info: 'forms.general.lawSection.fivePointOne_info'
  },
  {
    title: '5.2 §',
    value: 'fivePointTwo',
    info: 'forms.general.lawSection.fivePointTwo_info'
  },
  {
    title: '5.3 §',
    value: 'fivePointThree',
    info: 'forms.general.lawSection.fivePointThree_info'
  }
];

const Services = ({ formik, remove, i18n }) => {
  return formik.values?.formData?.serviceData?.services?.map(
    (_, serviceIndex) => (
      <Expander key={`service-${serviceIndex}`} defaultOpen>
        <ExpanderTitleButton>
          <FormattedMessage id="intermediary.serviceInfo.service" />{' '}
          {serviceIndex + 1}
        </ExpanderTitleButton>
        <ExpanderContent>
          <div className={styles.expanderContentHeader}>
            <Heading variant="h3" className="borderless">
              <FormattedMessage id="intermediary.serviceInfo.formTitle" />
            </Heading>
            {serviceIndex > 0 ? (
              <Button
                variant="secondaryNoBorder"
                icon={<IconRemove />}
                onClick={() => remove(serviceIndex)}
              >
                <FormattedMessage id="general.form.delete" />
              </Button>
            ) : null}
          </div>
          <Block mb="m">
            {['serviceNameFin', 'serviceNameSve', 'serviceNameEng'].map(
              name => (
                <ServiceNameField
                  key={name}
                  i18n={i18n}
                  formik={formik}
                  serviceName={name}
                  serviceIndex={serviceIndex}
                />
              )
            )}
          </Block>
          <Block my="m">
            <Field
              as={Textarea}
              name={`formData.serviceData.services.${serviceIndex}.serviceFunctionalDesc`}
              className="mt-4"
              labelText={i18n('intermediary.messaging.functionalDescription')}
              hintText={i18n(
                'forms.viestit_registration.page_messagesInfo.serviceSection.functionalDescDesc'
              )}
              fullWidth
              onChange={formik.handleChange}
              status={getStatus(
                formik,
                'formData.serviceData.services',
                serviceIndex,
                'serviceFunctionalDesc'
              )}
              statusText={getStatusText(
                i18n,
                formik,
                'formData.serviceData.services',
                serviceIndex,
                'serviceFunctionalDesc'
              )}
            />
          </Block>
          <hr style={{ margin: '2rem 0' }} />
          <Block my="m">
            <Heading variant="h3" className="borderless mb-2">
              <FormattedMessage id="forms.viestit_deployment.recipientIdSection.title" />
            </Heading>
            <Paragraph>
              <FormattedMessage id="forms.viestit_deployment.recipientIdSection.description" />
            </Paragraph>
          </Block>
          <Block my="m">
            <Checkbox
              checked={
                formik?.values?.formData?.serviceData?.services?.[serviceIndex]
                  ?.recipientIdSsn
              }
              onClick={({ checkboxState }) =>
                formik.setFieldValue(
                  `formData.serviceData.services[${serviceIndex}].recipientIdSsn`,
                  checkboxState
                )
              }
            >
              <FormattedMessage id="forms.viestit_deployment.recipientIdSection.idSsn" />
            </Checkbox>
            {formik?.values?.formData?.serviceData?.services?.[serviceIndex]
              ?.recipientIdSsn ? (
              <Field
                as={Textarea}
                fullWidth
                name={`formData.serviceData.services.${serviceIndex}.recipientIdSsnExplanation`}
                className="mt-4"
                labelText={i18n(
                  'forms.viestit_deployment.recipientIdSection.idSsnDesc'
                )}
                onChange={formik.handleChange}
                status={getStatus(
                  formik,
                  'formData.serviceData.services',
                  serviceIndex,
                  'recipientIdSsnExplanation'
                )}
                statusText={getStatusText(
                  i18n,
                  formik,
                  'formData.serviceData.services',
                  serviceIndex,
                  'recipientIdSsnExplanation'
                )}
              />
            ) : null}
            <Checkbox
              className="mt-2"
              checked={
                formik?.values?.formData?.serviceData?.services?.[serviceIndex]
                  ?.recipientIdCompany
              }
              onClick={({ checkboxState }) =>
                formik.setFieldValue(
                  `formData.serviceData.services[${serviceIndex}].recipientIdCompany`,
                  checkboxState
                )
              }
            >
              <FormattedMessage id="forms.viestit_deployment.recipientIdSection.idCompany" />
            </Checkbox>
          </Block>
          <Block mt="m">
            <CheckboxGroup
              labelText={i18n('forms.general.lawSection.titleMessages')}
              groupHintText={i18n(
                'forms.general.lawSection.descriptionMessages'
              )}
            >
              {legals.map(legal => (
                <Checkbox
                  key={`legal-${legal.value}`}
                  name={`formData.serviceData.services[${serviceIndex}].lawSection.${legal.value}`}
                  checked={
                    formik?.values?.formData?.serviceData.services[serviceIndex]
                      .lawSection[legal.value]
                  }
                  onClick={({ checkboxState }) =>
                    formik.setFieldValue(
                      `formData.serviceData.services[${serviceIndex}].lawSection.${legal.value}`,
                      checkboxState
                    )
                  }
                >
                  {legal.title}
                  <Tooltip
                    ariaToggleButtonLabelText={i18n('general.show_more')}
                    ariaCloseButtonLabelText={i18n('general.go_back')}
                  >
                    {i18n(legal.info)}
                  </Tooltip>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </Block>
          <hr style={{ margin: '2rem 0' }} />
          <ConnectionMethods
            formik={formik}
            serviceIndex={serviceIndex}
            i18n={i18n}
          />
          <hr style={{ margin: '2rem 0' }} />
          <NotificationContacts
            formik={formik}
            serviceIndex={serviceIndex}
            i18n={i18n}
          />
        </ExpanderContent>
      </Expander>
    )
  );
};

Services.propTypes = {
  formik: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  i18n: PropTypes.func.isRequired
};

export default Services;
