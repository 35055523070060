import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import TransitionGroup from 'react-addons-css-transition-group';
import { Notification } from 'sema-ui-components';
import { notifDismiss } from '../actions';
import styles from '../styles.scss';

// This checks to see if object is immutable and properly access it
const getter = (obj, propName) => (obj.get ? obj.get(propName) : obj[propName]);

const Notifs = (props, { intl }) => {
  const {
    notifications,
    className,
    transitionEnterTimeout,
    transitionLeaveTimeout
  } = props;

  const i18n = id => intl.formatMessage({ id });

  const renderedNotifications = notifications.map(notification => {
    const title = getter(notification, 'title')
      ? i18n(getter(notification, 'title'))
      : getter(notification, 'title');
    const description = getter(notification, 'description')
      ? i18n(getter(notification, 'description'))
      : getter(notification, 'description');
    const linkDescription = getter(notification, 'linkDescription')
      ? i18n(getter(notification, 'linkDescription'))
      : getter(notification, 'linkDescription');
    const retryLabel = getter(notification, 'retryLabel')
      ? i18n(getter(notification, 'retryLabel'))
      : getter(notification, 'retryLabel');

    return (
      <Notification
        key={getter(notification, 'id')}
        id={getter(notification, 'id')}
        title={title}
        description={description}
        icon={getter(notification, 'icon')}
        borderStyle={getter(notification, 'borderStyle')}
        type={getter(notification, 'type')}
        linkDescription={linkDescription}
        path={getter(notification, 'path')}
        className={cx(
          getter(notification, 'className'),
          styles.notificationDisplay
        )}
        onRequestClose={props.onRequestClose}
        retryCb={getter(notification, 'retryCb')}
        retryLabel={retryLabel}
      >
        {getter(notification, 'children')}
      </Notification>
    );
  });

  return (
    <div className={cx(className, styles.notifBase)}>
      <TransitionGroup
        transitionName={`notif-transition`}
        transitionEnterTimeout={transitionEnterTimeout}
        transitionLeaveTimeout={transitionLeaveTimeout}
      >
        {renderedNotifications}
      </TransitionGroup>
    </div>
  );
};

Notifs.defaultProps = {
  className: null,
  CustomComponent: null,
  transitionEnterTimeout: 600,
  transitionLeaveTimeout: 600
};

Notifs.propTypes = {
  notifications: PropTypes.array.isRequired,
  className: PropTypes.string,
  transitionEnterTimeout: PropTypes.number,
  transitionLeaveTimeout: PropTypes.number,
  onRequestClose: PropTypes.any
};

Notifs.contextTypes = {
  intl: PropTypes.object
};

function mapStateToProps(state) {
  return { notifications: state.get ? state.get('notifs') : state.notifs };
}

function mapDispatchToProps(dispatch) {
  return {
    onRequestClose: id => {
      dispatch(notifDismiss(id));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifs);
