import 'react-datepicker/dist/react-datepicker.css';

import cx from 'classnames';
import { Calendar } from 'components/Icons';
import en from 'date-fns/locale/en-US';
import fi from 'date-fns/locale/fi';
import sv from 'date-fns/locale/sv';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

import styles from './CalendarInput.scss';

registerLocale('fi', fi);
registerLocale('sv', sv);
registerLocale('en', en);
setDefaultLocale('fi');

const DEFAULT_STORE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_INPUT_FORMAT = 'DD.MM.YYYY';
const DEFAULT_UI_FORMAT = 'dd.MM.yyyy';

export const formatDate = (input, dateFormat) =>
  input && moment(input).format(dateFormat || DEFAULT_INPUT_FORMAT);

const fromStore = (input, dateFormat) => {
  dateFormat = dateFormat || DEFAULT_STORE_FORMAT;

  if (!input) {
    return null;
  }

  if (typeof input === 'string' && input.trim().length > 0) {
    const momentValue = moment(input, dateFormat);
    return momentValue.isValid() ? momentValue.toDate() : null;
  }
};

export const toStore = (input, dateFormat) => {
  dateFormat = dateFormat || DEFAULT_STORE_FORMAT;

  if (!input) {
    return null;
  }

  if (input instanceof Date) {
    const momentValue = moment(input);
    return momentValue.isValid() ? momentValue.format(dateFormat) : null;
  }

  if (typeof input === 'string' && input.trim().length > 0) {
    const momentValue = moment(input, DEFAULT_INPUT_FORMAT);
    return momentValue.isValid() ? momentValue.format(dateFormat) : null;
  }
};

export class CalendarInput extends React.Component {
  static propTypes = {
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    locale: PropTypes.string,
    dateFormat: PropTypes.string,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    placeholderText: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.node
  };

  render() {
    const {
      selected,
      locale,
      dateFormat,
      size = 'w200',
      containerClass,
      inputClass,
      error,
      errorMessage,
      readOnly,
      ...rest
    } = this.props;

    const errorClass = error && styles.error;

    return (
      <div>
        {!readOnly && (
          <div
            className={cx(
              styles.controlsContainer,
              styles[size],
              containerClass
            )}
          >
            <DatePicker
              className={cx(styles.input, inputClass, errorClass)}
              calendarClassName={styles.datePicker}
              selected={selected && fromStore(selected, dateFormat)}
              locale={locale}
              dateFormat={DEFAULT_UI_FORMAT}
              {...rest}
            />
            <div className={styles.calendarButton}>
              <Calendar className={styles.calendarIcon} />
            </div>
          </div>
        )}
        {!readOnly && error && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
        {readOnly && <div>{formatDate(selected) || '-'}</div>}
      </div>
    );
  }
}
