import React from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from 'suomifi-ui-components';
import styled from 'styled-components';

const StyledLoader = styled.div`
  margin: 0 auto;
  padding: 2rem;
`;

export const Loader = ({ i18n }) => (
  <StyledLoader>
    <LoadingSpinner
      status="loading"
      variant="normal"
      textAlign="bottom"
      text={i18n('intermediary.common.waitAMoment')}
    />
  </StyledLoader>
);
Loader.propTypes = {
  i18n: PropTypes.func.isRequired
};
