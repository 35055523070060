const setCookie = (name, value, expHours) => {
  let date = new Date();
  date.setTime(date.getTime() + expHours * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + '; ' + expires + '; path=/';
};

export const setClientAcceptCookies = (orgId, formId, formType, userMail) => {
  setCookie('clientAcceptOrgId', orgId, 24);
  setCookie('clientAcceptFormId', formId, 24);
  setCookie('clientAcceptFormType', formType, 24);
  setCookie('clientAcceptEmail', userMail, 24);
};

export const getClientAcceptInfo = () => {
  const cookies = document.cookie;
  let obj = {};
  cookies.split(';').map(x => {
    const keyVal = x.split('=');
    obj[keyVal[0].trim()] = keyVal[1].trim();
  });

  return {
    orgId: obj['clientAcceptOrgId'],
    formId: obj['clientAcceptFormId'],
    formType: obj['clientAcceptFormType'],
    userMail: obj['clientAcceptEmail']
  };
};
