import { injectReducer } from 'store/reducers';

export const basePath = 'sivut';

const routes = (store, browserLang) => {
  const r = [
    {
      path: `${basePath}/:slug*`,
      getComponents(nextState, next) {
        require.ensure(
          ['./containers/PageViewContainer', './modules'],
          require => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'contentPages', reducer });

            const content = require('./containers/PageViewContainer').default;
            next(null, { content });
          },
          'pageViewContainer'
        );
      }
    }
  ];

  if (__DEV__ || __INT__) {
    r.push({
      path: 'cms-demo',
      getComponents(nextState, next) {
        require.ensure(
          ['./components/DemoView'],
          require => {
            const content = require('./components/DemoView').default;
            next(null, { content });
          },
          'cmsDemo'
        );
      }
    });
  }

  return r;
};

export default (store, browserLang) => ({
  getChildRoutes: (location, cb) => {
    cb(null, routes(store, browserLang));
  }
});
