import closeIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/icon-close.svg';
import lockIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/icon-lock.svg';
import cx from 'classnames';
import OrgSwitcher from 'components/OrgSwitcher';
import { loginStyles } from 'constants/index';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { Icon } from 'sema-ui-components';
import { Button, IconLogout } from 'suomifi-ui-components';
import { hasVrkServiceAdmin } from 'utils/accessUtils';
import UserBox from '../UserBox';
import styles from './UserDropdown.scss';
import { isRequestInvitationSemaRole } from '../../../../modules/auth/utils';

const UserDropdown = ({
  activeOrg,
  onSignOut,
  user,
  onOrgChange,
  openModal,
  closeModal,
  className,
  children,
  locale,
  onClick,
  isOpen
}) => {
  const ref = useRef(null);

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (isOpen) {
        onClick(true);
      }
    }
  };

  const handleEscPress = event => {
    if (event.key === 'Escape' && isOpen) {
      event.preventDefault();
      onClick(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscPress);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscPress);
    };
  }, [isOpen, onClick]);

  const dd = (
    <div className={cx(styles.base, { [styles.active]: isOpen })}>
      <button
        aria-expanded={isOpen}
        aria-controls="menu-dropdown"
        aria-haspopup="menu"
        className={styles.menu_hamburger}
        onClick={() => {
          onClick();
        }}
      >
        {children}
      </button>
      <nav id="menu-dropdown" aria-hidden={!isOpen} className={styles.content}>
        <div className={styles.userDdBoxArrow} />
        <div>
          <Icon
            icon={closeIcon}
            onClick={onClick}
            className={styles.closeIcon}
            size="s40"
          />
          <UserBox activeOrg={activeOrg} locale={locale} user={user} />
          <div>
            {user.organizations && user.organizations.length > 1 && (
              <OrgSwitcher
                hideDropDown={() => onClick(true)}
                onOrgChange={onOrgChange}
                openModal={openModal}
                closeModal={closeModal}
                user={user}
                disabled={!isOpen}
              />
            )}
            <div>
              <Button
                variant="secondaryNoBorder"
                iconRight={<IconLogout />}
                onClick={onSignOut}
                disabled={!isOpen}
              >
                <FormattedMessage id="general.sign_out" />
              </Button>
            </div>
          </div>
        </div>
        {(user.sahaCookie || user.loginStyle !== loginStyles.STRONG) && (
          <div>
            <hr />
            <div className={styles.authLevel}>
              <div>
                <div className={styles.authLevelTitle}>
                  <FormattedMessage id="general.auth_level.title" />
                </div>
                <div>
                  <FormattedMessage id="general.auth_level.weak_auth" />
                </div>
              </div>
              <div>
                <Button
                  small
                  variant="secondaryNoBorder"
                  iconRight={<IconLogout />}
                  onClick={onSignOut}
                  disabled={!isOpen}
                >
                  <FormattedMessage id="sign_in.authenticate" />
                </Button>
              </div>
            </div>
          </div>
        )}

        {user &&
          user.organizations &&
          user.organizations.length > 0 &&
          !isRequestInvitationSemaRole(user.roles) && (
            <span>
              <hr />
              <ul className={styles.links}>
                <li>
                  <Link
                    to={`/${locale}/omat-tiedot`}
                    tabIndex={isOpen ? 0 : -1}
                  >
                    <FormattedMessage id="userprofile.title" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${locale}/oma-organisaatio`}
                    tabIndex={isOpen ? 0 : -1}
                  >
                    <FormattedMessage id="own_organization.title" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${locale}/own-applications/list`}
                    tabIndex={isOpen ? 0 : -1}
                  >
                    <FormattedMessage id="intermediary.title" />
                  </Link>
                </li>
                {!(
                  user.sahaCookie || user.loginStyle !== loginStyles.STRONG
                ) ? (
                  <li>
                    <Link
                      to={`/${locale}/kayttajahallinta`}
                      tabIndex={isOpen ? 0 : -1}
                    >
                      <FormattedMessage id="kvh_admin.title" />
                    </Link>
                  </li>
                ) : (
                  <li>
                    <div className={styles.lockedMenuItem}>
                      <FormattedMessage id="kvh_admin.title" />
                      <Icon
                        icon={lockIcon}
                        className={styles.lockIcon}
                        size="s30"
                      />
                    </div>
                  </li>
                )}
              </ul>
            </span>
          )}
        <hr />
        <ul className={styles.links}>
          <li>
            <Link to={`/${locale}/raportit`} tabIndex={isOpen ? 0 : -1}>
              <FormattedMessage id="reports.title" />
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/tuki`} tabIndex={isOpen ? 0 : -1}>
              <FormattedMessage id="support.title" />
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/ajankohtaista`} tabIndex={isOpen ? 0 : -1}>
              <FormattedMessage id="topical.title" />
            </Link>
          </li>
          <li className={styles.itemWithLongText}>
            <Link
              to={`/${locale}/sivut/palveluhallinnasta`}
              tabIndex={isOpen ? 0 : -1}
            >
              <p>
                <FormattedMessage id="home.info_link" />
              </p>
            </Link>
          </li>
        </ul>
        {hasVrkServiceAdmin(user) && (
          <div>
            <hr />
            <ul className={styles.links}>
              <li>
                <Link to={`/${locale}/hallinta`} tabIndex={isOpen ? 0 : -1}>
                  <FormattedMessage id="home.admin_link" />
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );

  return (
    <div ref={ref} className={`${className} userDropdown`}>
      {dd}
    </div>
  );
};

UserDropdown.propTypes = {
  activeOrg: PropTypes.object,
  onSignOut: PropTypes.func,
  user: PropTypes.object,
  onOrgChange: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  locale: PropTypes.string,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool
};

export default UserDropdown;
