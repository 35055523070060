const getOrganisationNames = organisation => ({
  fi: organisation.nameFi,
  sv: organisation.nameSv,
  en: organisation.nameEn
});

export const getOrganisationName = (organisation, lang) => {
  const names = getOrganisationNames(organisation);
  return names[lang] || names['fi'];
};

export const getOrganisationUnit = (organisation, lang) => {
  const units = {
    fi: organisation.unitFi,
    sv: organisation.unitSv,
    en: organisation.unitEn
  };
  return units[lang] || units['fi'] || '-';
};
