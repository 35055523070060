import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Block, Heading, Paragraph } from 'suomifi-ui-components';
import styles from '../CustomerOrganisationContacts/styles.scss';
import {
  AddContactButton,
  PersonFields,
  DisruptionEmailFields
} from '../../components';
import { FormattedMessage } from 'react-intl';
import { formTitle, userObject } from './schema';
import { formTypes } from '../../constants';

export const Form = ({ formik, serviceType }) => {
  const [
    technicalBackupPersonVisible,
    setTechnicalBackupPersonVisible
  ] = useState(false);

  const [
    administrativeBackupPersonVisible,
    setAdministrativeBackupPersonVisible
  ] = useState(false);

  const { setFieldValue } = formik;

  React.useEffect(() => {
    const showAdminBackup = Object.values(
      formik.values?.formData?.intermediaryContacts
        ?.intermediaryAdministrativeContactInformation
        ?.administrativeContactPersonBackup || {}
    )?.some(v => v?.length);

    const showTechnicalBackup = Object.values(
      formik.values?.formData?.intermediaryContacts
        ?.intermediaryTechnicalContactInformation
        ?.technicalContactPersonBackup || {}
    )?.some(v => v?.length);

    setAdministrativeBackupPersonVisible(showAdminBackup);
    setTechnicalBackupPersonVisible(showTechnicalBackup);
  }, []);

  const clearBackupPerson = isAdministrative => {
    if (isAdministrative) {
      setFieldValue(
        'formData.intermediaryContacts.intermediaryAdministrativeContactInformation.administrativeContactPersonBackup',
        { ...userObject }
      );
      setAdministrativeBackupPersonVisible(false);
    } else {
      setFieldValue(
        'formData.intermediaryContacts.intermediaryTechnicalContactInformation.technicalContactPersonBackup',
        { ...userObject }
      );
      setTechnicalBackupPersonVisible(false);
    }
  };

  return (
    <div>
      <Block mb="l">
        <Heading variant="h2" className="borderless mb-2">
          <FormattedMessage id={formTitle} />
        </Heading>
        <Paragraph>
          <FormattedMessage id="intermediary.intermediaryContacts.formDescription" />
        </Paragraph>
      </Block>
      <Block mb="l">
        <Heading variant="h3">
          <FormattedMessage id="intermediary.intermediaryContacts.contactsTitle" />
        </Heading>
        <Paragraph>
          <FormattedMessage id="intermediary.intermediaryContacts.contactsDescription" />
        </Paragraph>
      </Block>
      <PersonFields name="formData.intermediaryContacts.intermediaryAdministrativeContactInformation.administrativeContactPerson" />

      {administrativeBackupPersonVisible ? (
        <>
          <Heading variant="h4">
            <FormattedMessage id="intermediary.customerOrganisation.administrativeBackupContact" />
          </Heading>
          <PersonFields
            name="formData.intermediaryContacts.intermediaryAdministrativeContactInformation.administrativeContactPersonBackup"
            onDelete={() => clearBackupPerson(true)}
          />
        </>
      ) : (
        <AddContactButton
          setVisible={() => setAdministrativeBackupPersonVisible(true)}
        />
      )}

      <hr className={styles.spacer} />
      <Block mb="l">
        <Heading className={styles.repeater__heading} variant="h3">
          <FormattedMessage id="intermediary.intermediaryContacts.technicalContactsTitle" />
        </Heading>
        <Paragraph className={styles.paragraph}>
          <FormattedMessage id="intermediary.intermediaryContacts.technicalContactsDescription" />
        </Paragraph>
      </Block>
      <PersonFields name="formData.intermediaryContacts.intermediaryTechnicalContactInformation.technicalContactPerson" />
      {technicalBackupPersonVisible ? (
        <>
          <Heading variant="h4">
            <FormattedMessage id="intermediary.customerOrganisation.technicalBackupContact" />
          </Heading>
          <PersonFields
            name="formData.intermediaryContacts.intermediaryTechnicalContactInformation.technicalContactPersonBackup"
            onDelete={() => clearBackupPerson(false)}
          />
        </>
      ) : (
        <AddContactButton
          setVisible={() => setTechnicalBackupPersonVisible(true)}
        />
      )}

      {serviceType !== formTypes.EIDENTIFICATION && (
        <>
          <hr className={styles.spacer} />
          <DisruptionEmailFields
            name={'formData.intermediaryContacts.technicalProcessEmail'}
          />
        </>
      )}
    </div>
  );
};
Form.propTypes = {
  formik: PropTypes.object.isRequired,
  serviceType: PropTypes.string.isRequired
};

export default Form;
