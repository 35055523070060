import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import styles from '../styles.scss';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Expander,
  ExpanderContent,
  ExpanderTitleButton,
  Heading,
  Label,
  Paragraph,
  Tooltip,
  IconRemove
} from 'suomifi-ui-components';
import styled from 'styled-components';
import { Attachments } from '../../../components/Attachments';
import { RadioGroupFactory } from './';
import { getStatus, getStatusText } from '../../../utils';
import { parseISO, isValid } from 'date-fns';
import {
  IntermediaryTextField,
  IntermediaryTextarea
} from '../../../components';
import DatePicker from 'components/DatePicker';

export const formTitle = 'intermediary.eAuthorizationServiceInfo.formTitle';

const StyledParagraph = styled.p`
  margin-bottom: 1rem;
`;

const StyledCheckboxGroup = styled(CheckboxGroup)`
  margin-bottom: 2rem !important;
`;

export const Services = ({
  formik,
  remove,
  i18n,
  locale,
  sendAttachment,
  deleteAttachment
}) => {
  const [dateValues, setDateValues] = React.useState([]);

  const handleDateChangeForService = (e, index) => {
    if (!isValid(e.date)) {
      return;
    }

    const newDateValues = [...dateValues];
    newDateValues[index] = e.date;
    setDateValues(newDateValues);
    formik.setFieldValue(
      `formData.serviceData.services[${index}].implementationDate`,
      e.date.toISOString()
    );
  };

  React.useEffect(() => {
    const dateValues = formik.values?.formData?.serviceData?.services?.map(
      service => {
        const parsed = parseISO(service?.implementationDate);
        return parsed instanceof Date ? parsed : new Date();
      }
    );
    setDateValues(dateValues);
  }, []);

  return formik.values?.formData?.serviceData?.services?.map(
    (service, serviceIndex) => {
      const commonProps = {
        i18n,
        formik,
        serviceIndex
      };

      return (
        <Expander
          key={`service-${serviceIndex}`}
          defaultOpen
          className={styles.expander}
        >
          <ExpanderTitleButton>
            <FormattedMessage id="intermediary.serviceInfo.service" />{' '}
            {serviceIndex + 1}
          </ExpanderTitleButton>
          <ExpanderContent>
            <div className={styles.expanderContentHeader}>
              <Heading variant="h3" className="borderless">
                <FormattedMessage id="intermediary.serviceInfo.formTitle" />
              </Heading>
              {serviceIndex > 0 && (
                <Button
                  variant="secondaryNoBorder"
                  icon={<IconRemove />}
                  onClick={() => remove(serviceIndex)}
                >
                  <FormattedMessage id="general.form.delete" />
                </Button>
              )}
            </div>
            <IntermediaryTextField
              name={`formData.serviceData.services.${serviceIndex}.serviceName`}
              label={'intermediary.eAuthorizationServiceInfo.serviceName'}
            />
            <IntermediaryTextField
              name={`formData.serviceData.services.${serviceIndex}.serviceNameSv`}
              label={'intermediary.eAuthorizationServiceInfo.serviceNameSv'}
              optional
            />
            <IntermediaryTextField
              name={`formData.serviceData.services.${serviceIndex}.serviceNameEn`}
              label={'intermediary.eAuthorizationServiceInfo.serviceNameEn'}
              optional
            />
            <IntermediaryTextField
              name={`formData.serviceData.services.${serviceIndex}.serviceProviderName`}
              label={
                'intermediary.eAuthorizationServiceInfo.serviceProviderName'
              }
            />
            <IntermediaryTextField
              name={`formData.serviceData.services.${serviceIndex}.serviceProviderNameSv`}
              label={
                'intermediary.eAuthorizationServiceInfo.serviceProviderNameSv'
              }
              optional
            />
            <IntermediaryTextField
              name={`formData.serviceData.services.${serviceIndex}.serviceProviderNameEn`}
              label={
                'intermediary.eAuthorizationServiceInfo.serviceProviderNameEn'
              }
              optional
            />
            <IntermediaryTextField
              name={`formData.serviceData.services.${serviceIndex}.serviceUrl`}
              label={'intermediary.eAuthorizationServiceInfo.serviceUrl'}
            />
            <IntermediaryTextarea
              name={`formData.serviceData.services.${serviceIndex}.usageDescription`}
              label={'intermediary.eAuthorizationServiceInfo.usageDescription'}
              tooltip={
                <Paragraph>
                  <FormattedMessage id="intermediary.eAuthorizationServiceInfo.usageDescriptionTooltip" />
                </Paragraph>
              }
            />
            <hr className={styles.hr} />
            <Heading variant="h3" className="borderless mb-2">
              <FormattedMessage id="intermediary.eAuthorizationServiceInfo.useCase" />
            </Heading>

            <IntermediaryTextarea
              name={`formData.serviceData.services.${serviceIndex}.serviceUsers`}
              label={'intermediary.eAuthorizationServiceInfo.serviceUsers'}
              tooltip={
                <Paragraph>
                  <FormattedMessage id="intermediary.eAuthorizationServiceInfo.serviceUsersTooltip" />
                </Paragraph>
              }
            />
            <IntermediaryTextarea
              name={`formData.serviceData.services.${serviceIndex}.mattersWhenActingBehalf`}
              label={
                'intermediary.eAuthorizationServiceInfo.mattersWhenActingBehalf'
              }
            />

            <RadioGroupFactory {...commonProps} property="actingOnBehalf" />

            {service.actingOnBehalf && (
              <StyledCheckboxGroup
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
                labelText={i18n(
                  'intermediary.eAuthorizationServiceInfo.actingOnBehalfMinorOrAdult'
                )}
                className="mt-4"
              >
                <Checkbox
                  name="formData.serviceData.actingOnBehalfMinor"
                  checked={
                    formik?.values?.formData?.serviceData.services[serviceIndex]
                      .actingOnBehalfMinor
                  }
                  onClick={({ checkboxState }) =>
                    formik.setFieldValue(
                      `formData.serviceData.services[${serviceIndex}].actingOnBehalfMinor`,
                      checkboxState
                    )
                  }
                >
                  <FormattedMessage id="intermediary.eAuthorizationServiceInfo.actingOnBehalfMinor" />
                  <Tooltip
                    ariaToggleButtonLabelText={i18n('general.show_more')}
                    ariaCloseButtonLabelText={i18n('general.go_back')}
                  >
                    <StyledParagraph>
                      <FormattedMessage id="intermediary.eAuthorizationServiceInfo.actingOnBehalfMinorTooltip" />
                    </StyledParagraph>
                    <StyledParagraph>
                      <Link
                        to={
                          'https://palveluhallinta.suomi.fi/fi/tuki/artikkelit/592d5fad03f6d100018db5da'
                        }
                        target="_blank"
                      >
                        <FormattedMessage id="intermediary.eAuthorizationServiceInfo.actingOnBehalfMinorTooltipLink1" />
                      </Link>
                    </StyledParagraph>
                    <StyledParagraph>
                      <Link
                        to={
                          'https://www.suomi.fi/ohjeet-ja-tuki/tietoa-valtuuksista/toisen-henkilon-puolesta-asiointi'
                        }
                        target="_blank"
                      >
                        <FormattedMessage id="intermediary.eAuthorizationServiceInfo.actingOnBehalfMinorTooltipLink2" />
                      </Link>
                    </StyledParagraph>
                  </Tooltip>
                </Checkbox>

                <Checkbox
                  name="formData.serviceData.actingOnBehalfAdult"
                  checked={
                    formik?.values?.formData?.serviceData.services[serviceIndex]
                      .actingOnBehalfAdult
                  }
                  onClick={({ checkboxState }) =>
                    formik.setFieldValue(
                      `formData.serviceData.services[${serviceIndex}].actingOnBehalfAdult`,
                      checkboxState
                    )
                  }
                >
                  <FormattedMessage id="intermediary.eAuthorizationServiceInfo.actingOnBehalfAdult" />
                  <Tooltip
                    ariaToggleButtonLabelText={i18n('general.show_more')}
                    ariaCloseButtonLabelText={i18n('general.go_back')}
                  >
                    <StyledParagraph>
                      <FormattedMessage id="intermediary.eAuthorizationServiceInfo.actingOnBehalfAdultTooltipPart1" />{' '}
                      <Link
                        to={'https://www.suomi.fi/valtuudet/'}
                        target="_blank"
                      >
                        <FormattedMessage id="intermediary.eAuthorizationServiceInfo.actingOnBehalfAdultTooltipLink1" />
                      </Link>
                    </StyledParagraph>
                    <StyledParagraph>
                      <FormattedMessage id="intermediary.eAuthorizationServiceInfo.actingOnBehalfAdultTooltipPart2-1" />
                      <Link
                        to={'https://www.suomi.fi/valtuudet/valtuusasiat'}
                        target="_blank"
                      >
                        <FormattedMessage id="intermediary.eAuthorizationServiceInfo.actingOnBehalfAdultTooltipLink2" />
                      </Link>
                      <FormattedMessage id="intermediary.eAuthorizationServiceInfo.actingOnBehalfAdultTooltipPart2-2" />
                    </StyledParagraph>
                    <StyledParagraph>
                      <Link
                        to={
                          'https://www.suomi.fi/ohjeet-ja-tuki/tietoa-valtuuksista/anna-asiointivaltuus-henkilona'
                        }
                        target="_blank"
                      >
                        <FormattedMessage id="intermediary.eAuthorizationServiceInfo.actingOnBehalfAdultTooltipLink3" />
                      </Link>
                    </StyledParagraph>
                  </Tooltip>
                </Checkbox>
              </StyledCheckboxGroup>
            )}

            <RadioGroupFactory
              {...commonProps}
              property="actingOnBehalfOrganization"
            />

            {service.actingOnBehalfOrganization && (
              <StyledCheckboxGroup
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
                labelText={i18n(
                  'intermediary.eAuthorizationServiceInfo.actingOnBehalfOrganizationDescription'
                )}
                className="mt-4"
              >
                <Checkbox
                  name="formData.serviceData.registerBased"
                  checked={
                    formik?.values?.formData?.serviceData.services[serviceIndex]
                      .registerBased
                  }
                  onClick={({ checkboxState }) =>
                    formik.setFieldValue(
                      `formData.serviceData.services[${serviceIndex}].registerBased`,
                      checkboxState
                    )
                  }
                >
                  <FormattedMessage id="intermediary.eAuthorizationServiceInfo.registerBased" />
                  <Tooltip
                    ariaToggleButtonLabelText={i18n('general.show_more')}
                    ariaCloseButtonLabelText={i18n('general.go_back')}
                  >
                    <StyledParagraph>
                      <FormattedMessage id="intermediary.eAuthorizationServiceInfo.registerBasedTooltip1" />
                    </StyledParagraph>
                    <StyledParagraph>
                      <FormattedMessage id="intermediary.eAuthorizationServiceInfo.registerBasedTooltip2" />
                    </StyledParagraph>
                    <StyledParagraph>
                      <Link
                        to={
                          'https://palveluhallinta.suomi.fi/fi/tuki/artikkelit/592d67c203f6d100018db5dc'
                        }
                        target="_blank"
                      >
                        <FormattedMessage id="intermediary.eAuthorizationServiceInfo.registerBasedTooltipLink" />
                      </Link>
                    </StyledParagraph>
                  </Tooltip>
                </Checkbox>

                <Checkbox
                  name="formData.serviceData.authorizationBased"
                  checked={
                    formik?.values?.formData?.serviceData.services[serviceIndex]
                      .authorizationBased
                  }
                  onClick={({ checkboxState }) =>
                    formik.setFieldValue(
                      `formData.serviceData.services[${serviceIndex}].authorizationBased`,
                      checkboxState
                    )
                  }
                >
                  <FormattedMessage id="intermediary.eAuthorizationServiceInfo.authorizationBased" />
                  <Tooltip
                    ariaToggleButtonLabelText={i18n('general.show_more')}
                    ariaCloseButtonLabelText={i18n('general.go_back')}
                  >
                    <StyledParagraph>
                      <FormattedMessage id="intermediary.eAuthorizationServiceInfo.authorizationBasedTooltipPart1" />{' '}
                      <Link
                        to={'https://www.suomi.fi/valtuudet/'}
                        target="_blank"
                      >
                        <FormattedMessage id="intermediary.eAuthorizationServiceInfo.authorizationBasedTooltipLink1" />
                      </Link>
                    </StyledParagraph>

                    <StyledParagraph>
                      <FormattedMessage id="intermediary.eAuthorizationServiceInfo.authorizationBasedTooltipPart2-1" />{' '}
                      <Link
                        to={'https://www.suomi.fi/valtuudet/valtuusasiat'}
                        target="_blank"
                      >
                        <FormattedMessage id="intermediary.eAuthorizationServiceInfo.authorizationBasedTooltipLink2" />
                      </Link>{' '}
                      <FormattedMessage id="intermediary.eAuthorizationServiceInfo.authorizationBasedTooltipPart2-2" />{' '}
                      <Link
                        to={
                          'https://palveluhallinta.suomi.fi/fi/tuki/artikkelit/5a7819ce89c5c77cb05252ea'
                        }
                        target="_blank"
                      >
                        <FormattedMessage id="intermediary.eAuthorizationServiceInfo.authorizationBasedTooltipLink3" />
                      </Link>{' '}
                      <FormattedMessage id="intermediary.eAuthorizationServiceInfo.authorizationBasedTooltipPart2-3" />{' '}
                    </StyledParagraph>
                    <StyledParagraph>
                      <Link
                        to={
                          'https://www.suomi.fi/ohjeet-ja-tuki/tietoa-valtuuksista/anna-valtuus-yrityksena-tai-yhteisona'
                        }
                        target="_blank"
                      >
                        <FormattedMessage id="intermediary.eAuthorizationServiceInfo.authorizationBasedTooltipLink4" />
                      </Link>
                    </StyledParagraph>
                  </Tooltip>
                </Checkbox>
              </StyledCheckboxGroup>
            )}

            <RadioGroupFactory
              {...commonProps}
              property="actingOnBehalfForeignPersonOrCompany"
            />

            <RadioGroupFactory {...commonProps} property="onlineOnly" />

            <RadioGroupFactory
              {...commonProps}
              property="listedWithOwnUI"
              options={['ownUI', 'DVVUI']}
              tooltip={
                <Paragraph>
                  <FormattedMessage id="intermediary.eAuthorizationServiceInfo.listedWithOwnUITooltip1" />{' '}
                  <Link
                    to={
                      'https://palveluhallinta.suomi.fi/fi/tuki/artikkelit/5a7819ce89c5c77cb05252ea'
                    }
                    target="_blank"
                  >
                    <FormattedMessage id="intermediary.eAuthorizationServiceInfo.listedWithOwnUITooltipLink1" />
                  </Link>{' '}
                  <FormattedMessage id="intermediary.eAuthorizationServiceInfo.listedWithOwnUITooltip2" />
                </Paragraph>
              }
            />

            <IntermediaryTextarea
              name={`formData.serviceData.services.${serviceIndex}.actingOnBehalfOther`}
              label={
                'intermediary.eAuthorizationServiceInfo.actingOnBehalfOther'
              }
            />
            <Label>
              <FormattedMessage id="intermediary.eAuthorizationServiceInfo.serviceUsageAttachment" />
            </Label>
            <Attachments
              i18n={i18n}
              value={
                formik?.values?.formData?.serviceData?.services[serviceIndex]
                  ?.serviceUsageAttachment || []
              }
              onChange={value =>
                formik.setFieldValue(
                  `formData.serviceData.services[${serviceIndex}].serviceUsageAttachment`,
                  value
                )
              }
              sendAttachment={sendAttachment}
              deleteAttachment={deleteAttachment}
            />

            <div className={styles.group}>
              <Heading variant="h3" className="borderless mb-2">
                <FormattedMessage id="intermediary.eAuthorizationServiceInfo.implementationDateHeading" />
              </Heading>
              <Paragraph>
                <FormattedMessage id="intermediary.eAuthorizationServiceInfo.implementationDateDescription" />
              </Paragraph>
              <DatePicker
                id="implementationDate"
                language={locale}
                label={i18n(
                  'intermediary.eAuthorizationServiceInfo.implementationDate'
                )}
                name={`formData.serviceData.services.${serviceIndex}.implementationDate`}
                value={dateValues[serviceIndex]}
                onChange={e => handleDateChangeForService(e, serviceIndex)}
                status={getStatus(
                  formik,
                  'formData.serviceData.services',
                  serviceIndex,
                  'implementationDate'
                )}
                statusText={getStatusText(
                  i18n,
                  formik,
                  'formData.serviceData.services',
                  serviceIndex,
                  'implementationDate'
                )}
              />

              <IntermediaryTextField
                name={`formData.serviceData.services.${serviceIndex}.supportEstimation`}
                label={
                  'intermediary.eAuthorizationServiceInfo.supportEstimation'
                }
              />
            </div>
          </ExpanderContent>
        </Expander>
      );
    }
  );
};
Services.propTypes = {
  formik: PropTypes.object,
  remove: PropTypes.func,
  i18n: PropTypes.func,
  locale: PropTypes.string
};
