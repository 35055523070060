import React from 'react';

const notificationGlobal = object => {
  // object.dismissAfter = 13300;
  return object;
};

export const failNotification = object => {
  object.type = 'fail';
  return notificationGlobal(object);
};

export const successNotification = object => {
  object.type = 'ok';
  return notificationGlobal(object);
};

const base = 'identification_admin.';
const error = `${base}errors.`;
const success = `${base}success.`;

export const showProvidersFetchFail = (display, i18n) => {
  display(
    failNotification({
      title: `${error}provider_fetch_title`,
      description: `${error}provider_fetch_body`
    })
  );
};

export const showOrganizationCreateFail = (display, i18n) => {
  display(
    failNotification({
      title: `${error}organization_create_title`,
      description: `${error}organization_create_body`
    })
  );
};

export const showMissingOrganization = (display, i18n) => {
  display(
    failNotification({
      title: `${error}missing_organization_title`,
      description: `${error}missing_organization_body`
    })
  );
};

export const showEnvironmentsFetchFail = (display, i18n) => {
  display(
    failNotification({
      title: `${error}environment_fetch_title`,
      description: `${error}environment_fetch_body`
    })
  );
};

export const showProviderCreateFail = (display, i18n) => {
  display(
    failNotification({
      title: `${error}provider_create_title`,
      description: `${error}provider_create_body`
    })
  );
};

export const showEnvironmentCreateFail = (display, i18n, idAdminErrorCode) => {
  const indexForTranslation = idAdminErrorCode ? idAdminErrorCode : 'general';
  display(
    failNotification({
      title: `${error}environment_create_title`,
      description: `${error}environment_create_body.${indexForTranslation}`
    })
  );
};

export const showProviderUpdateFail = (display, i18n) => {
  display(
    failNotification({
      title: i18n(`${error}provider_update_title`),
      description: i18n(`${error}provider_update_body`)
    })
  );
};

export const showProviderCreateSuccess = (display, i18n, serviceName) => {
  display(
    successNotification({
      description: `${i18n(`${success}provider_create_body`)} (${serviceName})`
    })
  );
};

export const showProviderUpdateSuccess = (display, i18n, serviceName) => {
  display(
    successNotification({
      description: `${i18n(`${success}provider_update_body`)} (${serviceName})`
    })
  );
};

export const showEnvironmentCreateSuccess = (display, i18n) => {
  display(
    successNotification({
      title: i18n(`${success}environment_create_title`)
    })
  );
};

export const showMetadataDownloadFail = (display, i18n, errorMsg) => {
  display(
    failNotification({
      title: i18n(`${error}download_metadata_failed`),
      description: errorMsg
    })
  );
};
