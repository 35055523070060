import React from 'react';
import { Block, Heading, Paragraph } from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import { formTitle } from './schema';
import { PersonFields } from './PersonFields';

export const Form = () => {
  return (
    <div>
      <Block mb="l">
        <Heading variant="h2" className="borderless mb-2">
          <FormattedMessage id={formTitle} />
        </Heading>
        <Paragraph>
          <FormattedMessage id="intermediary.dataexchangelayer.formDescription" />
        </Paragraph>
      </Block>

      <PersonFields name="formData.apiCatalogueContactInformation.apiCatalogueContactPerson" />
    </div>
  );
};

export default Form;
