import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Text,
  Heading,
  Expander,
  ExpanderGroup,
  ExpanderTitleButton,
  ExpanderContent
} from 'suomifi-ui-components';
import styled from 'styled-components';
import List from './components/List';
import Contact from './components/Contact';
import { formTypes } from '../../constants';
import {
  IdentificationPreview,
  ServiceCataloguePreview,
  DataExchangeLayerPreview,
  MessagesPreview,
  eAuthorizationPreview
} from './service-specific-previews';

const serviceSpecificPreviews = {
  eidentification: IdentificationPreview,
  servicecatalogue: ServiceCataloguePreview,
  dataexchangelayer: DataExchangeLayerPreview,
  messages: MessagesPreview,
  eauthorization: eAuthorizationPreview
};

// Only used for changing print styles for suomifi-ui-components
const PreviewContainer = styled.div`
  .fi-expander-group_all-button {
    display: ${({ print }) => (print ? 'none' : 'inline')};
  }

  .fi-expander_title-button_button {
    ${({ print }) => (print ? `font-size: 28px;` : '')}
    ${({ print }) => (print ? `padding-bottom: 0;` : '')}
  }

  .fi-expander_title-button-icon {
    visibility: ${({ print }) => (print ? 'hidden' : 'visible')};
  }

  .fi-expander {
    ${({ print }) => (print ? `box-shadow: none;` : '')}
    ${({ print }) => (print ? `margin-top: 0 !important;` : '')}
    ${({ print }) => (print ? `margin-bottom: 0 !important;` : '')}
    ${({ print }) => (print ? `border: none !important;` : '')}
  }

  .fi-expander_content {
    ${({ print }) => (print ? `border: none;` : '')}
  }
`;

const Preview = ({
  values,
  i18n,
  serviceType,
  showTerms = true,
  defaultOpen = true,
  printMode = false
}) => {
  if (!values.formData || !values.serviceType || !values.proposalStyle) {
    return null;
  }

  const currentService = values.serviceType?.toLowerCase();
  const ServicePreview = serviceSpecificPreviews[currentService] || null;
  const isIntermediary = values.proposalStyle === 'INTERMEDIARY';

  const { formData } = values;

  return (
    <PreviewContainer print={printMode}>
      <ExpanderGroup
        openAllText={i18n('intermediary.application.open-all')}
        ariaOpenAllText={i18n('intermediary.application.open-all-expanders')}
        closeAllText={i18n('intermediary.application.close-all')}
        ariaCloseAllText={i18n('intermediary.application.close-all-expanders')}
      >
        <Expander defaultOpen={defaultOpen}>
          <ExpanderTitleButton>
            {isIntermediary ? (
              <FormattedMessage id="intermediary.organisationInfo.formTitleIntermediary" />
            ) : (
              <FormattedMessage id="intermediary.organisationInfo.formTitle" />
            )}
          </ExpanderTitleButton>
          <ExpanderContent>
            <List i18n={i18n}>
              <List.Item
                title={i18n('intermediary.preview.organisationName')}
                value={formData.customerOrganization.organizationName}
              />
              {formData.customerOrganization?.organizationUnit ? (
                <List.Item
                  title={i18n('intermediary.preview.organisationName')}
                  value={formData.customerOrganization.organizationUnit}
                />
              ) : null}
              {formData.customerOrganization?.noBusinessId ? (
                <List.Item
                  title={i18n('intermediary.preview.altBusinessId')}
                  value={formData.customerOrganization.altBusinessId}
                />
              ) : (
                <List.Item
                  title={i18n('intermediary.preview.yId')}
                  value={formData.customerOrganization.businessId}
                />
              )}
              <List.Item
                title={i18n('intermediary.preview.address')}
                value={formData.customerOrganization.address}
              />
              <List.Item
                title={i18n('intermediary.preview.postalCode')}
                value={formData.customerOrganization.postalCode}
              />
              <List.Item
                title={i18n('intermediary.preview.postOffice')}
                value={formData.customerOrganization.postOffice}
              />
            </List>
          </ExpanderContent>
        </Expander>
        <Expander defaultOpen={defaultOpen}>
          <ExpanderTitleButton>
            {isIntermediary ? (
              <FormattedMessage id="intermediary.customerOrganisation.formTitleIntermediary" />
            ) : (
              <FormattedMessage id="intermediary.customerOrganisation.formTitle" />
            )}
          </ExpanderTitleButton>
          <ExpanderContent>
            <Heading variant="h3" className="my-2 mt-4">
              <FormattedMessage id="intermediary.customerOrganisation.customerOrganisationTitle" />
            </Heading>

            <Heading variant="h4" className="my-2">
              <FormattedMessage id="intermediary.customerOrganisation.administrativeContact" />
            </Heading>
            <Contact
              i18n={i18n}
              contact={
                formData?.customerContacts
                  ?.customerAdministrativeContactInformation
                  ?.administrativeContactPerson
              }
            />
            <Heading variant="h4" className="my-2">
              <FormattedMessage id="intermediary.customerOrganisation.administrativeBackupContact" />
            </Heading>
            <Contact
              i18n={i18n}
              contact={
                formData?.customerContacts
                  ?.customerAdministrativeContactInformation
                  ?.administrativeContactPersonBackup
              }
            />
            <Heading variant="h3" className="my-2 mt-4">
              <FormattedMessage id="intermediary.customerOrganisation.customerTechnicalContactTitle" />
            </Heading>

            <Heading variant="h4" className="my-2">
              <FormattedMessage id="intermediary.customerOrganisation.technicalContact" />
            </Heading>
            <Contact
              i18n={i18n}
              contact={
                formData?.customerContacts?.customerTechnicalContactInformation
                  ?.technicalContactPerson
              }
            />
            <Heading variant="h4" className="my-2">
              <FormattedMessage id="intermediary.customerOrganisation.technicalBackupContact" />
            </Heading>
            <Contact
              i18n={i18n}
              contact={
                formData?.customerContacts?.customerTechnicalContactInformation
                  ?.technicalContactPersonBackup
              }
            />
            {serviceType !== formTypes.EIDENTIFICATION && (
              <>
                <Text variant="bold">
                  <FormattedMessage id="intermediary.disruption.title" />
                </Text>
                <List>
                  <List.Item
                    title={i18n('intermediary.common.technicalEmail')}
                    value={formData?.customerContacts?.technicalProcessEmail}
                  />
                </List>
              </>
            )}
          </ExpanderContent>
        </Expander>
        {isIntermediary && (
          <Expander defaultOpen={defaultOpen}>
            <ExpanderTitleButton>
              <FormattedMessage id="intermediary.intermediaryContacts.formTitle" />
            </ExpanderTitleButton>
            <ExpanderContent>
              <Heading variant="h3" className="my-2 mt-4">
                <FormattedMessage id="intermediary.intermediaryContacts.contactsTitle" />
              </Heading>
              <Heading variant="h4" className="my-2">
                <FormattedMessage id="intermediary.intermediaryContacts.administrativeContact" />
              </Heading>
              <Contact
                i18n={i18n}
                contact={
                  formData?.intermediaryContacts
                    ?.intermediaryAdministrativeContactInformation
                    ?.administrativeContactPerson
                }
              />
              <Heading variant="h4" className="my-2">
                <FormattedMessage id="intermediary.intermediaryContacts.administrativeBackupContact" />
              </Heading>
              <Contact
                i18n={i18n}
                contact={
                  formData?.intermediaryContacts
                    ?.intermediaryAdministrativeContactInformation
                    ?.administrativeContactPersonBackup
                }
              />
              <Heading variant="h3" className="my-2 mt-4">
                <FormattedMessage id="intermediary.intermediaryContacts.technicalContactsTitle" />
              </Heading>
              <Heading variant="h4" className="my-2">
                <FormattedMessage id="intermediary.intermediaryContacts.technicalContact" />
              </Heading>
              <Contact
                i18n={i18n}
                contact={
                  formData?.intermediaryContacts
                    ?.intermediaryTechnicalContactInformation
                    ?.technicalContactPerson
                }
              />
              <Heading variant="h4" className="my-2">
                <FormattedMessage id="intermediary.intermediaryContacts.technicalBackupContact" />
              </Heading>
              <Contact
                i18n={i18n}
                contact={
                  formData?.intermediaryContacts
                    ?.intermediaryTechnicalContactInformation
                    ?.technicalContactPersonBackup
                }
              />
              {serviceType !== formTypes.EIDENTIFICATION && (
                <>
                  <Text variant="bold">
                    <FormattedMessage id="intermediary.disruption.title" />
                  </Text>
                  <List>
                    <List.Item
                      title={i18n('intermediary.common.technicalEmail')}
                      value={
                        formData?.intermediaryContacts?.technicalProcessEmail
                      }
                    />
                  </List>
                </>
              )}
            </ExpanderContent>
          </Expander>
        )}
        <ServicePreview
          formData={formData}
          i18n={i18n}
          defaultOpen={defaultOpen}
        />
        {showTerms && (
          <Expander defaultOpen={defaultOpen}>
            <ExpanderTitleButton>
              <FormattedMessage id="intermediary.preview.approveRules" />
            </ExpanderTitleButton>
            <ExpanderContent>
              <List>
                <List.Item
                  title={i18n('intermediary.preview.commitment')}
                  value={formData.acceptanceOfTerms.acceptanceOfVRKPermit}
                />
              </List>
            </ExpanderContent>
          </Expander>
        )}
      </ExpanderGroup>
    </PreviewContainer>
  );
};

Preview.propTypes = {
  values: PropTypes.object,
  i18n: PropTypes.func,
  showTerms: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  printMode: PropTypes.bool,
  serviceType: PropTypes.string
};

export default Preview;
