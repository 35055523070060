import React from 'react';
import InlineSVG from 'svg-inline-react';

import { Icon as SemaIcon } from 'sema-ui-components';

import addImage from '!!svg-inline-loader!./icons/add_image.svg';
import attachment from '!!svg-inline-loader!./icons/attachment.svg';
import boldText from '!!svg-inline-loader!./icons/bold_text.svg';
import bulletedList from '!!svg-inline-loader!./icons/bulleted_list.svg';
import calendar from '!!svg-inline-loader!./icons/calendar.svg';
import closeChip from '!!svg-inline-loader!./icons/close_chip.svg';
import comments from '!!svg-inline-loader!./icons/comments.svg';
import comments2 from '!!svg-inline-loader!./icons/comments_2.svg';
import drag from '!!svg-inline-loader!./icons/drag.svg';
import dropdownArrow from '!!svg-inline-loader!./icons/dropdown_arrow.svg';
import dropdownTriangle from '!!svg-inline-loader!./icons/dropdown_triangle.svg';
import editPencil from '!!svg-inline-loader!./icons/edit_pencil.svg';
import history from '!!svg-inline-loader!./icons/history.svg';
import iconClose from '!!svg-inline-loader!./icons/icon-close.svg';
import italicText from '!!svg-inline-loader!./icons/italic_text.svg';
import link from '!!svg-inline-loader!./icons/link.svg';
import lock from '!!svg-inline-loader!./icons/lock.svg';
import minus from '!!svg-inline-loader!./icons/minus.svg';
import move from '!!svg-inline-loader!./icons/move.svg';
import numberedList from '!!svg-inline-loader!./icons/numbered_list.svg';
import plus from '!!svg-inline-loader!./icons/plus.svg';
import redo from '!!svg-inline-loader!./icons/redo.svg';
import search from '!!svg-inline-loader!./icons/search.svg';
import timelineLine from '!!svg-inline-loader!./icons/timeline_line.svg';
import timelinePoint from '!!svg-inline-loader!./icons/timeline_point.svg';
import toggleArrow from '!!svg-inline-loader!./icons/toggle_arrow.svg';
import trash from '!!svg-inline-loader!./icons/trash.svg';
import undo from '!!svg-inline-loader!./icons/undo.svg';
import send from '!!svg-inline-loader!./icons/send.svg';
import unstabled from '!!svg-inline-loader!./icons/unstabled.svg';
import notification from '!!svg-inline-loader!./icons/notification_icon.svg';
import checkedStable from '!!svg-inline-loader!./icons/checked_stable.svg';
import guideLink from '!!svg-inline-loader!./icons/ohje-uusi.svg';

const Icon = props => (
  <SemaIcon {...props} src={props.icon} size={props.size || ''} />
);
// TODO: refactor icons to show properly with Icon from sema-ui-components
// Icon.defaultProps = { size: 's30' };

export const AddImage = props => <Icon {...props} icon={addImage} />;
export const Attachment = props => <Icon {...props} icon={attachment} />;
export const BoldText = props => <Icon {...props} icon={boldText} />;
export const BulletedList = props => <Icon {...props} icon={bulletedList} />;
export const Calendar = props => <Icon {...props} icon={calendar} />;
export const CloseChip = props => <Icon {...props} icon={closeChip} />;
export const Comments = props => <Icon {...props} icon={comments} />;
export const Comments2 = props => <Icon {...props} icon={comments2} />;
export const Drag = props => <Icon {...props} icon={drag} />;
export const DropdownArrow = props => <Icon {...props} icon={dropdownArrow} />;
export const DropdownTriangle = props => (
  <Icon {...props} icon={dropdownTriangle} />
);
export const EditPencil = props => <Icon {...props} icon={editPencil} />;
export const History = props => <Icon {...props} icon={history} />;
export const IconClose = props => <Icon {...props} icon={iconClose} />;
export const ItalicText = props => <Icon {...props} icon={italicText} />;
export const Link = props => <Icon {...props} icon={link} />;
export const Lock = props => <Icon {...props} icon={lock} />;
export const Minus = props => <Icon {...props} icon={minus} />;
export const Move = props => <Icon {...props} icon={move} />;
export const NumberedList = props => <Icon {...props} icon={numberedList} />;
export const Plus = props => <Icon {...props} icon={plus} />;
export const Redo = props => <Icon {...props} icon={redo} />;
export const Search = props => <Icon {...props} icon={search} />;
export const TimelineLine = props => <Icon {...props} icon={timelineLine} />;
export const TimelinePoint = props => <Icon {...props} icon={timelinePoint} />;
export const ToggleArrow = props => <Icon {...props} icon={toggleArrow} />;
export const Trash = props => <Icon {...props} icon={trash} />;
export const Undo = props => <Icon {...props} icon={undo} />;
export const Send = props => <Icon {...props} icon={send} />;
export const Unstabled = props => <Icon {...props} icon={unstabled} />;
export const Notification = props => <Icon {...props} icon={notification} />;
export const CheckedStable = props => <Icon {...props} icon={checkedStable} />;
export const GuideLink = props => <Icon {...props} icon={guideLink} />;
