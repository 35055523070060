import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { compose, withHandlers, withProps, pure } from 'recompose';
import { FormattedMessage } from 'react-intl';
import TranslatableHelmet from 'components/TranslatableHelmet/index';
import { Button } from 'sema-ui-components';
import styles from './UserRegisterError.scss';

export const UserRegisterError = ({
  handleGoHome,
  params: { lang },
  errorMessage
}) => (
  <div className={styles.base}>
    <TranslatableHelmet
      locale={lang}
      transKey="user_registration.errors.title"
    />
    <h2 className="center">
      <FormattedMessage id="user_registration.errors.title" />
    </h2>
    <div className="row">
      <div className="offset-md-7 col-md-10">
        <p className={styles.description}>{errorMessage}</p>
        <div className="text-center">
          <Button medium onClick={handleGoHome}>
            <FormattedMessage id="general.go_home" />
          </Button>
        </div>
      </div>
    </div>
  </div>
);

UserRegisterError.propTypes = {
  handleGoHome: PropTypes.func.isRequired
};

export default compose(
  withProps(props => ({
    errorMessage: props.location.state.errorMessage
  })),
  withHandlers({
    handleGoHome: props => () => browserHistory.push(`/${props.params.lang}`)
  }),
  pure
)(UserRegisterError);
