import React from 'react';
import PropTypes from 'prop-types';
import { InlineAlert, SingleSelect } from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';

export const OrganisationSelector = ({
  i18n,
  organisations,
  handleSetOrganisation,
  formik
}) => {
  return (
    <>
      <InlineAlert
        labelText={`${i18n(
          'intermediary.organisationInfo.foundOrganisations'
        )} ${organisations.length} ${i18n(
          'intermediary.organisationInfo.organisations'
        )}`}
      >
        <FormattedMessage id="intermediary.organisationInfo.chooseOrganisation" />
      </InlineAlert>
      <SingleSelect
        labelText={i18n('intermediary.organisationInfo.organisation')}
        clearButtonLabel={i18n('intermediary.common.clear')}
        items={organisations}
        noItemsText={i18n('intermediary.organisationInfo.noOrganisation')}
        defaultSelectedItem={organisations[0]}
        ariaOptionsAvailableText={i18n(
          'intermediary.organisationInfo.organisationsToChoose'
        )}
        onItemSelectionChange={org => handleSetOrganisation(org, formik)}
        className="w-50"
      />
    </>
  );
};
OrganisationSelector.propTypes = {
  i18n: PropTypes.func.isRequired,
  organisations: PropTypes.array.isRequired,
  handleSetOrganisation: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired
};
