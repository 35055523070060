import * as yup from 'yup';

export const formTitle = 'intermediary.submit.send';

export const schema = yup.object({
  proposalName: yup
    .string()
    .max(300, 'intermediary.validation.max300')
    .notRequired()
    .default('')
    .label(formTitle),
  customerEmailMessage: yup
    .string()
    .max(300, 'intermediary.validation.max300')
    .notRequired()
    .default('')
    .label(formTitle)
});
