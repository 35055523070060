import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Paragraph } from 'suomifi-ui-components';
import { renderDate } from '../utils/applicationView';
import styles from '../styles/ApplicationView.scss';
import { formStateCodes } from '../../IntermediaryForms/constants';

export const ApplicationDetails = ({ i18n, application }) => {
  return (
    <div className={styles.detailsContainer}>
      <Heading variant="h4">
        {i18n('intermediary.application.basic-information')}
      </Heading>
      <hr />
      <div className={styles.detail}>
        <Heading variant="h5">{i18n('intermediary.application.state')}</Heading>
        <Paragraph>
          {application.formState
            ? i18n(
                `intermediary.states.${formStateCodes[application.formState]}`
              )
            : '-'}
        </Paragraph>
      </div>
      <div className={styles.detail}>
        <Heading variant="h5">
          {i18n('intermediary.application.updated')}
        </Heading>
        <Paragraph>
          {application.modifiedDate
            ? renderDate(new Date(application.modifiedDate))
            : '-'}
        </Paragraph>
      </div>
      <div className={styles.detail}>
        <Heading variant="h5">
          {i18n('intermediary.application.service')}
        </Heading>
        <Paragraph>
          {application.serviceType
            ? i18n(`intermediary.services.${application.serviceType}`)
            : '-'}
        </Paragraph>
      </div>
      <div className={styles.detail}>
        <Heading variant="h5">
          {i18n('intermediary.application.customer')}
        </Heading>
        <Paragraph>
          {application?.formData?.customerOrganization?.organizationName || '-'}
        </Paragraph>
      </div>
    </div>
  );
};

ApplicationDetails.propTypes = {
  application: PropTypes.object.isRequired,
  i18n: PropTypes.func.isRequired
};
