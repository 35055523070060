import React from 'react';
import { initialSteps } from './initialSteps';
import FormContainer from '../../components/Form/FormContainer';
import { formTypes } from '../../constants';

const eAuthorizationForm = props => (
  <FormContainer
    initialSteps={initialSteps}
    serviceType={formTypes.EAUTHORIZATION}
    {...props}
  />
);

export default eAuthorizationForm;
