import React from 'react';
import PropTypes from 'prop-types';
import { LangSwitcher } from '../../LangSwitcher';

export const LanguageSwitcher = ({ locale, inverse, changeLang }) => (
  <LangSwitcher locale={locale} inverse={inverse} changeLang={changeLang} />
);

LanguageSwitcher.propTypes = {
  locale: PropTypes.string,
  inverse: PropTypes.bool,
  changeLang: PropTypes.func
};
