import React from 'react';
import PropTypes from 'prop-types';
import TranslatableHelmet from '../../components/TranslatableHelmet';
import cx from 'classnames';
import { pure } from 'recompose';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Overlay from 'components/Overlay';
import SessionExpiredModal from 'components/SessionExpiredModal';
import styles from './CoreLayout.scss';
import { Alert } from 'sema-ui-components';
import { Notifs as Notifications } from 'components/Notifs';
import { browserHistory } from 'react-router';
import { setSessionExpiredInCookie } from 'utils/sessionUtil';
import SessionTimerModal from 'components/SessionTimerModal';

function checkIfCookiesDisabled() {
  if (navigator.cookieEnabled) {
    return true;
  }
  document.cookie = 'pahatest=1';
  const ret = document.cookie.indexOf('pahatest=') !== -1;
  document.cookie = 'pahatest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
  return ret;
}

export const CoreLayout = props => {
  const {
    activeOrg,
    changeOrg,
    className,
    closeModal,
    content,
    footer,
    footerContent,
    isAuthenticated,
    isUserDropdownOpen,
    isNotificationsDropdownOpen,
    header,
    headerExtra,
    locale,
    openModal,
    router,
    sideNavOpen,
    signOut,
    startSignIn,
    toggleSideNav,
    toggleUserDropdown,
    toggleNotificationsDropdown,
    user,
    setSessionExpired,
    isSessionExpired,
    currentLang,
    changeLang,
    notifStats,
    definitions,
    asyncGetNotifStats,
    overlay,
    unsetOverlay
  } = props;

  return (
    <div
      className={cx(styles.flexWrapper, { [styles.sideNavOpen]: sideNavOpen })}
    >
      {!checkIfCookiesDisabled() && (
        <Alert warning>
          Palveluhallinta vaatii selaimelta päällä olevat evästeet.
        </Alert>
      )}

      {isSessionExpired && (
        <SessionExpiredModal
          isModalOpen={isSessionExpired}
          closeModal={() => {
            setSessionExpiredInCookie(false);
            setSessionExpired(false);
            browserHistory.push({
              pathname: `/${currentLang || 'fi'}/`,
              state: { isSessionExpired: true }
            });
          }}
          onConfirm={() => {
            setSessionExpiredInCookie(false);
            setSessionExpired(false);
            const currentLocation = window.location.pathname;
            browserHistory.push({
              pathname: '/rekisteroityminen',
              state: { isSessionExpired: true, nextPathname: currentLocation }
            });
          }}
        />
      )}
      {isAuthenticated && typeof Worker !== 'undefined' && (
        <SessionTimerModal
          signOut={signOut}
          setSessionExpiredInCookie={setSessionExpiredInCookie}
          sessionExpires={user.exp}
        />
      )}
      <TranslatableHelmet
        meta={[{ name: 'description', content: 'Service Management' }]}
      />

      {header !== undefined ? (
        header
      ) : (
        <div
          className={cx(
            'header-container',
            headerExtra && headerExtra.props.route.headerClassName
          )}
        >
          <Header
            {...{
              activeOrg,
              changeOrg,
              className,
              closeModal,
              inverse: !!headerExtra,
              isAuthenticated,
              isUserDropdownOpen,
              isNotificationsDropdownOpen,
              locale,
              openModal,
              router,
              sideNavOpen,
              signOut,
              startSignIn,
              toggleSideNav,
              toggleUserDropdown,
              toggleNotificationsDropdown,
              user,
              definitions,
              notifStats,
              asyncGetNotifStats,
              changeLang
            }}
          />
          {headerExtra && React.cloneElement(headerExtra, { ...{ locale } })}
        </div>
      )}
      <main className={styles.mainContainer} id="main">
        {!isSessionExpired && overlay && (
          <Overlay unset={unsetOverlay} component={overlay.component} />
        )}
        {!isSessionExpired && (
          <div id="content" className="container">
            <div>
              <div className={cx('row', styles.notificationRow)}>
                <div className="col-md-24">
                  <Notifications />
                </div>
              </div>
              {content}
            </div>
          </div>
        )}
      </main>
      {footer !== undefined ? (
        footer
      ) : (
        <Footer isSimpleFrontPage locale={locale}>
          {footerContent}
        </Footer>
      )}
    </div>
  );
};

CoreLayout.propTypes = {
  activeOrg: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  changeOrg: PropTypes.func.isRequired,
  className: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
  footer: PropTypes.any,
  footerContent: PropTypes.node,
  header: PropTypes.any,
  headerExtra: PropTypes.node,
  isAuthenticated: PropTypes.bool,
  isUserDropdownOpen: PropTypes.bool.isRequired,
  isNotificationsDropdownOpen: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  router: PropTypes.object,
  sideNavOpen: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  startSignIn: PropTypes.func.isRequired,
  toggleSideNav: PropTypes.func.isRequired,
  toggleUserDropdown: PropTypes.func.isRequired,
  toggleNotificationsDropdown: PropTypes.func.isRequired,
  user: PropTypes.object,
  isSessionExpired: PropTypes.bool,
  setSessionExpired: PropTypes.func,
  currentLang: PropTypes.string,
  changeLang: PropTypes.func,
  notifStats: PropTypes.object,
  definitions: PropTypes.array,
  asyncGetNotifStats: PropTypes.func,
  overlay: PropTypes.object,
  unsetOverlay: PropTypes.func
};

export default pure(CoreLayout);
