import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectReducer, injectReducers } from 'store/reducers';
import {
  requireAuth,
  requireAuthNoOrg,
  requireBusinessIdCheck
} from 'modules/auth/utils';

export default store => ({
  getChildRoutes: (location, next) => {
    next(null, [
      {
        path: 'rekisteroityminen',
        name: <FormattedMessage id="user_registration.title" />,
        indexRoute: {
          getComponents(nextState, next) {
            require.ensure(
              [],
              require => {
                const reducer = require('./modules').default;
                const documentsReducer = require('../Statistics/modules/module_CMS')
                  .default;
                injectReducers(store, [
                  { key: 'userRegistration', reducer: reducer },
                  { key: 'formDocuments', reducer: documentsReducer }
                ]);

                const content = require('./containers/LoginOrSignupContainer')
                  .default;
                next(null, { content });
              },
              'userRegistration'
            );
          }
        },
        childRoutes: [
          {
            path: 'tiedoksi',
            name: <FormattedMessage id="user_registration.info.title" />,
            onEnter: requireAuthNoOrg(store.getState().auth),
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const content = require('./containers/InfoContainer').default;
                  next(null, { content });
                },
                'userRegistration'
              );
            }
          },
          {
            path: 'varmistus',
            name: <FormattedMessage id="user_registration.confirm.title" />,
            onEnter: requireAuthNoOrg(store.getState().auth),
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const content = require('./containers/ConfirmRegistrationContainer')
                    .default;
                  next(null, { content });
                },
                'userRegistration'
              );
            }
          },
          {
            path: 'pyyda-kutsua',
            name: (
              <FormattedMessage id="user_registration.request_invitation.title" />
            ),
            onEnter: requireAuthNoOrg(store.getState().auth),
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const content = require('./containers/RequestInvitationContainer')
                    .default;
                  next(null, { content });
                },
                'userRegistration'
              );
            }
          },
          {
            path: 'rekisteroi-kayttaja-ja-organisaatio',
            name: (
              <FormattedMessage id="user_registration.register_user_and_org.title" />
            ),
            onEnter: requireBusinessIdCheck(
              store.getState().auth,
              store.getState().userRegistration
            ),
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const documentsReducer = require('../Statistics/modules/module_CMS')
                    .default;
                  injectReducers(store, [
                    { key: 'formDocuments', reducer: documentsReducer }
                  ]);
                  const content = require('./containers/RegisterUserAndOrgContainer')
                    .default;
                  next(null, { content });
                },
                'userRegistration'
              );
            }
          },
          {
            path: 'rekisterointi-valmis/:type',
            name: (
              <FormattedMessage id="user_registration.register_user_and_org.title" />
            ),
            onEnter: requireAuthNoOrg(store.getState().auth),
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const content = require('./containers/RegisterCompleteContainer')
                    .default;
                  next(null, { content });
                },
                'userRegistration'
              );
            }
          },
          {
            path: 'odottaa-kutsua',
            name: (
              <FormattedMessage id="user_registration.register_user_and_org.title" />
            ),
            onEnter: requireAuthNoOrg(store.getState().auth),
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const content = require('./containers/WaitingForInvitationContainer')
                    .default;
                  next(null, { content });
                },
                'userRegistration'
              );
            }
          }
        ]
      }
    ]);
  }
});

export const RegisterInvitedUser = store => ({
  path: 'rekisteroi-kayttaja/:invitationId*',
  onEnter: requireAuthNoOrg(store.getState().auth),
  getComponents(nextState, next) {
    require.ensure(
      [],
      require => {
        const reducer = require('./modules').default;
        const documentsReducer = require('../Statistics/modules/module_CMS')
          .default;
        const userInvitationReducer = require('./modules/module_Invitations')
          .default;

        injectReducers(store, [
          { key: 'userRegistration', reducer: reducer },
          { key: 'formDocuments', reducer: documentsReducer },
          { key: 'userInvitation', reducer: userInvitationReducer }
        ]);

        const content = require('./containers/RegisterInvitedUserContainer')
          .default;
        next(null, { content });
      },
      'userRegistration'
    );
  }
});
