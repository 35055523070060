import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import {
  Button,
  Label,
  IconRemove,
  IconPlus,
  Tooltip
} from 'suomifi-ui-components';
import { FieldArray } from 'formik';
import { FormattedMessage } from 'react-intl';

import { IntermediaryTextField } from '../../components';

export const IPAddresses = ({ serviceData, i18n }) => {
  const { ipv4 } =
    serviceData?.dataExchangeLayerInformation?.serverInformation || {};
  return (
    <>
      <Label
        className="mt-4"
        tooltipComponent={
          <Tooltip
            ariaToggleButtonLabelText={i18n('general.show_more')}
            ariaCloseButtonLabelText={i18n('general.go_back')}
          >
            <FormattedMessage id="intermediary.dataexchangelayer.publicIPv4_tooltip" />
          </Tooltip>
        }
      >
        <FormattedMessage id="intermediary.dataexchangelayer.publicIPv4" />
      </Label>

      <FieldArray
        name="formData.serviceData.dataExchangeLayerInformation.serverInformation.ipv4"
        render={arrayHelpers => (
          <>
            <div className={styles.ip4__container}>
              <Label>
                <FormattedMessage id="intermediary.dataexchangelayer.ipv4" />
              </Label>
              {ipv4 &&
                ipv4.length > 0 &&
                ipv4.map((friend, index) => (
                  <div key={index} className={styles.ip4__flexcontainer}>
                    <IntermediaryTextField
                      name={`formData.serviceData.dataExchangeLayerInformation.serverInformation.ipv4.${index}`}
                      noStyles
                    />

                    <div className={styles.ip4__button}>
                      {ipv4.length > 1 && (
                        <Button
                          variant="secondaryNoBorder"
                          icon={<IconRemove />}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <FormattedMessage id="intermediary.dataexchangelayer.remove" />
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <Button
              variant="secondary"
              icon={<IconPlus />}
              onClick={() => arrayHelpers.insert(ipv4.length, '')} // insert an empty string at a position
            >
              <FormattedMessage id="intermediary.dataexchangelayer.addNewipv4" />
            </Button>
          </>
        )}
      />
    </>
  );
};
IPAddresses.propTypes = {
  i18n: PropTypes.func,
  formik: PropTypes.object,
  serviceData: PropTypes.object
};
