import React from 'react';
import Proptypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Expander,
  ExpanderTitleButton,
  ExpanderContent
} from 'suomifi-ui-components';
import { legals } from '../../../partials/ServiceInfoIdentification';
import { choices } from '../../../partials/ExtentOfData';
import List from '../components/List';
import { DataProtectionPreview } from './DataProtectionPreview';

function getLegals(service, i18n) {
  const values = legals.reduce((acc, legal) => {
    if (service.lawSection[legal.value]) {
      acc.push(legal.title);
    }
    return acc;
  }, []);

  return values.length > 0
    ? values.join(', ')
    : i18n('intermediary.misc.no_legal_basis');
}

function getInformationType(formData, i18n) {
  const value =
    formData?.extentOfPersonalInformation ||
    formData?.serviceData?.extentOfPersonalInformation;
  const option = choices.find(c => c.value === value)?.label;
  return option ? i18n(option) : null;
}

export const IdentificationPreview = ({
  formData,
  i18n,
  defaultOpen = true
}) => {
  if (!formData) {
    return null;
  }

  return (
    <React.Fragment>
      <Expander defaultOpen={defaultOpen}>
        <ExpanderTitleButton>
          <FormattedMessage id="intermediary.extentOfData.formTitle" />
        </ExpanderTitleButton>
        <ExpanderContent>
          <List>
            <List.Item
              title={i18n('intermediary.preview.informationType')}
              value={getInformationType(formData, i18n)}
            />
            <List.Item
              title={i18n('intermediary.preview.reasons')}
              value={
                formData?.extentOfPersonalInformationDescription ||
                formData?.serviceData?.extentOfPersonalInformationDescription ||
                null
              }
            />
          </List>
        </ExpanderContent>
      </Expander>

      <Expander defaultOpen={defaultOpen}>
        <ExpanderTitleButton>
          <FormattedMessage id="intermediary.serviceInfo.formTitle" />
        </ExpanderTitleButton>
        <ExpanderContent>
          {formData.serviceData?.services?.map((service, i) => (
            <List key={`service-${service.name || i}`}>
              <List.Item
                title={i18n('intermediary.serviceInfo.serviceName')}
                value={service.serviceName}
              />
              <List.Item
                title={i18n('intermediary.preview.intendedUse')}
                value={service.purposeOfUse}
              />
              {service.functionalDescriptionAttachments !== null ? (
                <List.Item
                  title={i18n('intermediary.preview.attachments')}
                  value={service.functionalDescriptionAttachments
                    .map(a => a.originalFileName)
                    .join(', ')}
                />
              ) : null}
              <List.Item
                title={i18n('intermediary.preview.lawBasis')}
                value={getLegals(service, i18n)}
              />
            </List>
          ))}
        </ExpanderContent>
      </Expander>

      {/* Tietojen suojaus esikatselu tähän */}
      <DataProtectionPreview
        i18n={i18n}
        defaultOpen={defaultOpen}
        formData={formData}
        isEIdentificationForm={true}
      />
    </React.Fragment>
  );
};

IdentificationPreview.propTypes = {
  defaultOpen: Proptypes.bool,
  i18n: Proptypes.func,
  formData: Proptypes.object
};
