import localForage from 'localforage';
import { validateTypesArray } from '../../utils';

export const INDEXEDDB = Symbol('INDEXEDDB');

export default store => next => action => {
  const indexedDB = action[INDEXEDDB];
  if (typeof indexedDB === 'undefined') {
    return next(action);
  }
  const { key, types, payload } = indexedDB;

  validateTypesArray(types);

  const [requestType, successType, failureType] = types;

  function actionWith(data) {
    const finalAction = { ...action, ...data };
    delete finalAction[INDEXEDDB];
    return finalAction;
  }

  next(actionWith({ type: requestType }));

  const errHandler = err =>
    next(
      actionWith({
        payload: err,
        type: failureType,
        error: true
      })
    );

  if (payload !== undefined) {
    return localForage.setItem(key, payload).then(() => {
      next(
        actionWith({
          type: successType
        })
      );
    }, errHandler);
  }
  return localForage.getItem(key).then(data => {
    if (data) {
      next(
        actionWith({
          payload: data,
          type: successType
        })
      );
    }
  }, errHandler);
};
