import { states } from '../components/FormStates';

export const getFormsOfProfile = profile =>
  profile && profile.jsonObject && profile.jsonObject.registrationForms;
export const getFormOfProfile = (profile, formId) => {
  const forms = getFormsOfProfile(profile);
  return forms && forms[formId];
};

export const updateFormFieldOfProfile = (profile, formId, fieldId, value) => {
  const newProfile = profile || {};
  const jsonObject = newProfile.jsonObject || {};
  const registrationForms = jsonObject.registrationForms || {};
  const form = registrationForms[formId] || {};
  return {
    ...newProfile,
    jsonObject: {
      ...jsonObject,
      registrationForms: {
        ...registrationForms,
        [formId]: {
          ...form,
          [fieldId]: value
        }
      }
    }
  };
};

export const updateFormOfProfile = (profile, formId, form, uuid) => {
  const newProfile = profile || {};
  const jsonObject = newProfile.jsonObject || {};
  const registrationForms = jsonObject.registrationForms || {};
  return {
    ...newProfile,
    jsonObject: {
      ...jsonObject,
      registrationForms: {
        ...registrationForms,
        [formId]: uuid ? { ...registrationForms[formId], [uuid]: form } : form
      }
    }
  };
};

const getDate = () => new Date().toUTCString();
export const addTimesAndState = (form, state) => ({
  ...form,
  header: {
    ...form.header,
    createDate: (form.header && form.header.createDate) || getDate(),
    lastUpdate: getDate(),
    state: state || states.draft.name
  }
});

export const addAllTimesAndState = (form, state) => ({
  ...form,
  header: {
    ...form.header,
    createDate: (form.header && form.header.createDate) || getDate(),
    sentDate: (form.header && form.header.createDate) || getDate(),
    lastUpdate: getDate(),
    state: state || states.draft.name
  }
});
