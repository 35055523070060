/* @flow */
/**
 * FSA (Flux Standard Action) creator
 *
 * Stolen from https://github.com/acdlite/redux-actions
 * Just added flow typing.
 */

function identity(t) {
  return t;
}

export default function createAction(
  type: string,
  actionCreator: any,
  metaCreator: ?Function
): Action {
  const finalActionCreator =
    typeof actionCreator === 'function' ? actionCreator : identity;

  return (...args) => {
    const action = {
      type,
      payload: finalActionCreator(...args)
    };

    if (action.payload instanceof Error) {
      // Handle FSA errors where the payload is an Error object. Set error.
      action.error = true;
    }

    if (metaCreator) {
      action.meta = metaCreator(...args);
    }

    return action;
  };
}
