import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  TextInput,
  RadioButton,
  RadioButtonGroup
} from 'suomifi-ui-components';
import { Field } from 'formik';
import { getStatus, getStatusText } from '../../../utils/utility';

export const ServiceNameField = ({
  i18n,
  formik,
  serviceIndex,
  serviceName
}) => (
  <Field
    as={TextInput}
    name={`formData.serviceData.services.${serviceIndex}.${serviceName}`}
    labelText={i18n(`intermediary.messaging.${serviceName}`)}
    wrapperProps={{ style: { width: '80%', marginBottom: '1rem' } }}
    onChange={value =>
      formik.setFieldValue(
        `formData.serviceData.services[${serviceIndex}].${serviceName}`,
        value
      )
    }
    status={getStatus(
      formik,
      'formData.serviceData.services',
      serviceIndex,
      serviceName
    )}
    statusText={getStatusText(
      i18n,
      formik,
      'formData.serviceData.services',
      serviceIndex,
      serviceName
    )}
  />
);

ServiceNameField.propTypes = {
  i18n: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
  serviceIndex: PropTypes.number.isRequired,
  serviceName: PropTypes.string.isRequired
};

export const RadioIntSelection = ({ name, formik, i18n }) => {
  const getRadioValue = () => {
    const value = formik?.values?.formData?.serviceData[name];
    return value === null ? null : value ? '1' : '0'; // null, '1' or '0'
  };

  return (
    <RadioButtonGroup
      className="mt-4"
      name={`formData.serviceData.${name}`}
      value={getRadioValue()}
      onChange={value =>
        formik.setFieldValue(
          `formData.serviceData.${name}`,
          parseInt(value, 10)
        )
      }
      labelText={i18n(
        `forms.viestit_registration.page_messagesInfo.usageSettingsSection.${name}`
      )}
    >
      <RadioButton value="1">
        <FormattedMessage id="general.form.yes" />
      </RadioButton>
      <RadioButton value="0">
        <FormattedMessage id="general.form.no" />
      </RadioButton>
    </RadioButtonGroup>
  );
};

RadioIntSelection.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  i18n: PropTypes.func.isRequired
};
