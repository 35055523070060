import { createReducer, createAction as ca } from '../../../utils';
import config from 'config';
import { CALL_API } from '../../../store/middleware/api';
import { services, formStates, SERVICE_NUMBERS } from 'constants/index';
import { changeOrg, addServiceToOwnOrganisation } from 'modules/auth';

import {
  loadFormDataFromSAHA,
  sendInvitationRequestEmail as sendEmail,
  deleteForm as deleteFormApi
} from './formsApi';
import {
  findUserProfileForOrg,
  findOrganization
} from '../../UserProfile/modules/api';
import {
  updateFormFieldOfProfile,
  updateFormOfProfile
} from '../utils/ProfileForm';
import { parseDraftFormData } from '../utils/dataToFormConversion';
import { states } from '../components/FormStates';
import { convertToSAHAFormat } from '../utils/dataToSAHAConversion';
import { getActiveOrganization } from 'utils/stateUtils';
import { Forms } from '../FormIds';

const PX = 'FORMS:';

const initialState = {
  accessibleServices: [],
  filledForms: {}, // Maybe for keeping record of filled (full) forms; RegistrationForm, AccessForm
  formReference: null,
  editedForm: {},
  isCheckingExistance: false,
  isCheckingServiceAccess: false,
  isDeleting: false,
  isFetchingDraft: false,
  isModalOpen: false,
  isSaving: false,
  isSendingEmail: false,
  isSubmitting: false, // for Access Form saving (maybe)?
  isTransferringAfterButtonClick: false,
  isUpdating: false,
  isSendingIdentificationFormToSaha: false,
  error: null,
  organization: {},
  profile: {},
  defaultContactsFromSAHA: {}
};

const FETCH_FORM_DRAFT = `${PX}_FETCH_FORM_DRAFT`; // From SAHA
export const FETCH_FORM_DRAFT_SUCCESS = `${PX}_FETCH_FORM_DRAFT_SUCCESS`;
const FETCH_FORM_DRAFT_ERROR = `${PX}_FETCH_FORM_DRAFT_ERROR`;

const FORM_LOAD_FROM_PROFILE_SERVICE_ERROR = `${PX}_FORM_LOAD_FROM_PROFILE_SERVICE_ERROR`;

const FORM_UPDATE_TO_PROFILE_SERVICE = `${PX}_FORM_UPDATE_TO_PROFILE_SERVICE`;
const FORM_UPDATE_TO_PROFILE_SERVICE_SUCCESS = `${PX}_FORM_UPDATE_TO_PROFILE_SERVICE_SUCCESS`;
const FORM_UPDATE_TO_PROFILE_SERVICE_ERROR = `${PX}_FORM_UPDATE_TO_PROFILE_SERVICE_ERROR`;

const SAVE = `${PX}_SAVE`;
const SAVE_SUCCESS = `${PX}_SAVE_SUCCESS`;
const SAVE_ERROR = `${PX}_SAVE_ERROR`;

const DELETE_FORM = `${PX}_DELETE_FORM`;
export const DELETE_FORM_SUCCESS = `${PX}_DELETE_FORM_SUCCESS`;
const DELETE_FORM_ERROR = `${PX}_DELETE_FORM_ERROR`;

const EMAIL_SEND = `${PX}_EMAIL_SEND`;
const EMAIL_SUCCESS = `${PX}_EMAIL_SUCCESS`;
const EMAIL_ERROR = `${PX}_EMAIL_ERROR`;

const CHECK_SERVICE_ACCESS = `${PX}_CHECK_SERVICE_ACCESS`;
const CHECK_SERVICE_ACCESS_SUCCESS = `${PX}_CHECK_SERVICE_ACCESS_SUCCES`;
const CHECK_SERVICE_ACCESS_ERROR = `${PX}_CHECK_SERVICE_ACCESS_ERROR`;

const CREATE_NEW_FORM = `${PX}_CREATE_NEW_FORM`;
const UPDATE_FIELD = `${PX}_UPDATE_FIELD`;

const FORM_UPDATE_PROFILE_FIELD = `${PX}_FORM_UPDATE_PROFILE_FIELD`;
const FORM_STORE_PROFILE = `${PX}_FORM_STORE_PROFILE`;
const FORM_STORE_ORGANIZATION_REGISTRATION_FORMS = `${PX}_FORM_STORE_ORGANIZATION_REGISTRATION_FORMS`;
const FORM_REQUEST_FROM_PROFILE_SERVICE = `${PX}_FORM_REQUEST_FROM_PROFILE_SERVICE`;

export const TRANSFER_AFTER_BUTTON_CLICK = `${PX}_TRANSFER_AFTER_BUTTON_CLICK`;

const SEND_FORM_REQUEST = `${PX}_SEND_FORM_REQUEST`;
const SEND_FORM_SUCCESS = `${PX}_SEND_FORM_SUCCESS`;
const SEND_FORM_ERROR = `${PX}_SEND_FORM_ERROR`;
const GET_CUSTOMER_GROUPS = `${PX}_GET_CUSTOMER_GROUPS`;
const GET_CUSTOMER_GROUPS_SUCCESS = `${PX}_GET_CUSTOMER_GROUPS_SUCCESS`;
const GET_CUSTOMER_GROUPS_ERROR = `${PX}_GET_CUSTOMER_GROUPS_ERROR`;
const GET_DEFAULT_CONTACTS = `${PX}_GET_DEFAULT_CONTACTS`;
const GET_DEFAULT_CONTACTS_SUCCESS = `${PX}_GET_DEFAULT_CONTACTS_SUCCESS`;
const GET_DEFAULT_CONTACTS_ERROR = `${PX}_GET_DEFAULT_CONTACTS_ERROR`;
const ROLE_UPDATE_REQUEST = `${PX}_ROLE_UPDATE_REQUEST`;
const ROLE_UPDATE_ERROR = `${PX}_ROLE_UPDATE_ERROR`;
const ROLE_UPDATE_SUCCESS = `${PX}_ROLE_UPDATE_SUCCESS`;

export const reducerPluginForFormDestroy = {
  // extend formReducer to destroy the form when whole form page is closed

  serviceBusRegistrationForm: (state, action) => {
    switch (action.type) {
      case SEND_FORM_SUCCESS:
      case TRANSFER_AFTER_BUTTON_CLICK:
      case FETCH_FORM_DRAFT_SUCCESS:
      case DELETE_FORM_SUCCESS: {
        return {}; // Destroy the form.
      }
      default:
        return state;
    }
  },

  serviceBusCertificateForm: (state, action) => {
    switch (action.type) {
      case SEND_FORM_SUCCESS:
      case TRANSFER_AFTER_BUTTON_CLICK:
      case FETCH_FORM_DRAFT_SUCCESS:
      case DELETE_FORM_SUCCESS: {
        return {}; // Destroy the form.
      }
      default:
        return state;
    }
  }
};

const ACTION_HANDLERS = {
  [FETCH_FORM_DRAFT]: (state, { payload }) => ({
    ...state,
    isFetchingDraft: true
  }),
  [FETCH_FORM_DRAFT_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingDraft: false,
    editedForm: payload
  }),
  [FETCH_FORM_DRAFT_ERROR]: (state, { payload }) => ({
    ...state,
    isFetchingDraft: false,
    editedForm: null,
    error: payload.error
  }),
  [FORM_LOAD_FROM_PROFILE_SERVICE_ERROR]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    profile: {},
    error: payload.error
  }),
  [FORM_STORE_ORGANIZATION_REGISTRATION_FORMS]: (state, { payload }) => ({
    ...state,
    organization: { registrationForms: payload.org.registrationForms }
  }),
  [FORM_REQUEST_FROM_PROFILE_SERVICE]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [FORM_STORE_PROFILE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    profile: payload
  }),
  [FORM_UPDATE_TO_PROFILE_SERVICE]: (state, { payload }) => ({
    ...state,
    isSubmitting: true
  }),
  [FORM_UPDATE_TO_PROFILE_SERVICE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isSubmitting: false
    };
  },
  [FORM_UPDATE_TO_PROFILE_SERVICE_ERROR]: (state, { payload }) => ({
    ...state,
    isSubmitting: false,
    error: payload
  }),
  [DELETE_FORM]: (state, { payload }) => ({
    ...state,
    isDeleting: true,
    isTransferringAfterButtonClick: true
  }),
  [DELETE_FORM_SUCCESS]: (state, { payload }) => ({
    ...state,
    isDeleting: false,
    editedForm: {}
  }),
  [DELETE_FORM_ERROR]: (state, { payload }) => ({
    ...state,
    isDeleting: false,
    error: payload.error,
    isTransferringAfterButtonClick: false
  }),
  [SAVE]: (state, { payload }) => ({
    ...state,
    isSaving: true
  }),
  [SAVE_SUCCESS]: (state, { payload }) => ({
    ...state,
    isSaving: false,
    editedForm: payload
  }),
  [SAVE_ERROR]: (state, { payload }) => ({
    ...state,
    isSaving: false,
    error: payload.error
  }),
  [CHECK_SERVICE_ACCESS]: (state, { payload }) => ({
    ...state,
    isCheckingServiceAccess: true
  }),
  [CHECK_SERVICE_ACCESS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isCheckingServiceAccess: false,
    accessibleServices: state.accessibleServices.some(s => s === payload)
      ? state.accessibleServices
      : state.accessibleServices.concat(payload)
  }),
  [CHECK_SERVICE_ACCESS_ERROR]: (state, { payload }) => ({
    ...state,
    isCheckingServiceAccess: false,
    error: payload.error
  }),
  [EMAIL_SEND]: (state, { payload }) => ({
    ...state,
    isSendingEmail: true
  }),
  [EMAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    isSendingEmail: false
  }),
  [EMAIL_ERROR]: (state, { payload }) => ({
    ...state,
    isSendingEmail: false,
    error: payload
  }),
  [CREATE_NEW_FORM]: (state, { payload }) => ({
    ...state,
    editedForm: payload,
    formReference: null,
    isTransferringAfterButtonClick: false
  }),
  [TRANSFER_AFTER_BUTTON_CLICK]: state => ({
    ...state,
    isTransferringAfterButtonClick: true
  }),
  [FORM_UPDATE_PROFILE_FIELD]: (state, { payload }) => ({
    ...state,
    profile: updateFormFieldOfProfile(
      state.profile,
      payload.formId,
      payload.fieldId,
      payload.value
    )
  }),
  [UPDATE_FIELD]: (state, { payload }) => {
    const fieldId = payload.fieldId;
    const value = payload.value;
    const form = state.editedForm || {};
    const data = form.data || {};

    return {
      ...state,
      editedForm: {
        ...form,
        data: {
          ...data,
          [fieldId]: value
        }
      }
    };
  },
  [SEND_FORM_REQUEST]: state => ({
    ...state,
    isSendingIdentificationFormToSaha: true,
    isSubmitted: true
  }),
  [SEND_FORM_SUCCESS]: state => ({
    ...state,
    isSendingIdentificationFormToSaha: false,
    isSubmitted: true,
    formsNew: {}
  }),
  [SEND_FORM_ERROR]: (state, { payload }) => ({
    ...state,
    isSendingIdentificationFormToSaha: false,
    error: payload
  }),
  [GET_CUSTOMER_GROUPS]: state => ({
    ...state,
    isGettingCustomerGroups: true
  }),
  [GET_CUSTOMER_GROUPS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isGettingCustomerGroups: false,
    customerGroups: payload
  }),
  [GET_CUSTOMER_GROUPS_ERROR]: (state, { payload }) => ({
    ...state,
    isGettingCustomerGroups: false,
    error: payload
  }),
  [GET_DEFAULT_CONTACTS]: state => ({
    ...state,
    isGettingCustomerGroups: true
  }),
  [GET_DEFAULT_CONTACTS_SUCCESS]: (state, { payload }) => {
    const arrayToObject = (array, key) =>
      array.reduce((obj, item) => {
        obj[item[key]] = item;
        return obj;
      }, {});
    return {
      ...state,
      defaultContactsFromSAHA: arrayToObject(payload.data, 'type'),
      isGettingCustomerGroups: false
    };
  },
  [GET_DEFAULT_CONTACTS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    defaultContactsFromSAHA: {},
    isGettingCustomerGroups: false
  }),
  [ROLE_UPDATE_REQUEST]: state => ({
    ...state,
    isSubmitting: true
  }),
  [ROLE_UPDATE_SUCCESS]: state => ({
    ...state,
    isSubmitting: false
  }),
  [ROLE_UPDATE_ERROR]: (state, { payload }) => ({
    ...state,
    isSubmitting: false,
    error: payload
  })
};

export const API = {
  updateFormField: (formId, fieldId, value) => dispatch =>
    dispatch(ca(FORM_UPDATE_PROFILE_FIELD)({ formId, fieldId, value })),
  getForms: (orgId, userId) => async dispatch => {
    dispatch(ca(FORM_REQUEST_FROM_PROFILE_SERVICE)());
    try {
      const profile = await findUserProfileForOrg(orgId, userId);
      if (profile) {
        const organization = await findOrganization(orgId);
        dispatch(ca(FORM_STORE_ORGANIZATION_REGISTRATION_FORMS)(organization));
      }
      dispatch(ca(FORM_STORE_PROFILE)(profile.user));
      return profile.user;
    } catch (e) {
      dispatch(ca(FORM_LOAD_FROM_PROFILE_SERVICE_ERROR)(e));
      throw e;
    }
  },
  updateProfile: (orgId, userId, profile) => ({
    [CALL_API]: {
      method: 'post',
      endpoint: `/api/userprofile/${orgId}`,
      types: [
        FORM_UPDATE_TO_PROFILE_SERVICE,
        FORM_UPDATE_TO_PROFILE_SERVICE_SUCCESS,
        FORM_UPDATE_TO_PROFILE_SERVICE_ERROR
      ],
      data: { organizationKey: orgId, userKey: userId, jsonObject: profile }
    }
  }),
  saveForm: (profile, formId, form, orgId, userId) => ({
    // Used to save Liittymisen edellytyksen (Access) form
    [CALL_API]: {
      method: 'post',
      endpoint: `/api/userprofile/${orgId}`,
      types: [
        FORM_UPDATE_TO_PROFILE_SERVICE,
        FORM_UPDATE_TO_PROFILE_SERVICE_SUCCESS,
        FORM_UPDATE_TO_PROFILE_SERVICE_ERROR
      ],
      data: {
        ...updateFormOfProfile(profile, formId, form),
        organizationKey: orgId,
        userKey: userId
      }
    }
  }),
  getDefaultContacts: (orgId, service, type) => ({
    [CALL_API]: {
      method: 'get',
      endpoint: `/api/saha/contactperson/${orgId}?service=${service}&type=${type}`,
      types: [
        GET_DEFAULT_CONTACTS,
        GET_DEFAULT_CONTACTS_SUCCESS,
        GET_DEFAULT_CONTACTS_ERROR
      ]
    }
  }),
  updateServicesFromToken: orgId => async dispatch => {
    try {
      //TODO: use proper user org update method
      await dispatch(changeOrg(orgId));
      dispatch(ca(ROLE_UPDATE_SUCCESS)());
    } catch (e) {
      dispatch(ca(ROLE_UPDATE_ERROR)(e));
    }
  },
  updateKVHAccess: (orgId, sahaGuid, serviceName) => async dispatch => {
    // updates service availability to KVH e.g. when storing Liittymisen edellytykset (=accessForm)
    if (
      // TODO: Check is this if-clause needed?
      !(
        serviceName === services.EIDENTIFICATION ||
        serviceName === services.DATAEXCHANGELAYER ||
        serviceName === services.MESSAGES
      )
    ) {
      dispatch(ca(ROLE_UPDATE_SUCCESS)());
    } else {
      dispatch(ca(ROLE_UPDATE_REQUEST)());
      try {
        //TODO: use proper update call once available.
        //TODO add eidentification to oranisation
        if (serviceName == services.EIDENTIFICATION) {
          await dispatch(
            addServiceToOwnOrganisation(SERVICE_NUMBERS[serviceName])
          );
        }
        await dispatch(changeOrg(orgId));
        dispatch(ca(ROLE_UPDATE_SUCCESS)());
      } catch (e) {
        dispatch(ca(ROLE_UPDATE_ERROR)(e));
      }
    }
  }
};

export const transferAfterButtonClick = ca(TRANSFER_AFTER_BUTTON_CLICK);

export const getEditedForm2 = (orgId, service, type, formId, user, language) =>
  wrap(FETCH_FORM_DRAFT, FETCH_FORM_DRAFT_ERROR, async dispatch => {
    let result = {};
    try {
      result = await loadFormDataFromSAHA(orgId, service, type, formId, '');
      const editedForm =
        (result && result.body && result.body.data && result.body.data[0]) ||
        {};
      const dataForForm = parseDraftFormData(editedForm, {}, user);
      dispatch(ca(FETCH_FORM_DRAFT_SUCCESS)(dataForForm));
      return dataForForm;
    } catch (e) {
      result = { error: e };
      dispatch(ca(FETCH_FORM_DRAFT_ERROR)(result));
      return result;
    }
  });

export const createNewForm = (orgId, service, type, pahaFormName) => (
  dispatch,
  getState
) => {
  const user = getState().auth.user;
  const activeOrgData = getActiveOrganization(getState());
  const baseOrganizationInformation = {
    organizationName: activeOrgData.organizationName,
    businessId: activeOrgData.businessId,
    address: activeOrgData.address,
    organizationUnit: activeOrgData.organizationUnit
  };

  const newForm = {
    organizationKey: orgId,
    type: type,
    service: service,
    state: states.draft.name,
    data: {},
    formData: {
      pahaFormName: pahaFormName,
      organizationInformation: baseOrganizationInformation,
      proposerName: `${user.firstName} ${user.lastName}`
    },
    uuid: null
  };
  return dispatch(ca(CREATE_NEW_FORM)(newForm));
};

export const deleteForm = form =>
  wrap(DELETE_FORM, DELETE_FORM_ERROR, async dispatch => {
    if (!!form.accountId && !!form.id) {
      await deleteFormApi(form.accountId, form.id, form.formData.pahaFormName);
    }
    // If an attachment is created, but the form is not saved to SAHA
    // before the form is deleted, an orphan attachment will be left in Minio.
    // SAHA's maintenance thread will take care of cleaning orphans from the sema-document-handler.
    dispatch(ca(DELETE_FORM_SUCCESS)());
    return {};
  });

export const sendInvitationForEditEmail = (
  serviceName,
  intl,
  user,
  hashId,
  targetAddress
) => async dispatch => {
  dispatch(ca(EMAIL_SEND)());
  try {
    const baseUrl = config.siteUrl;
    const url = `${baseUrl}${intl.locale}/accept-form/${hashId}`;

    const emailData = {
      targetAddress,
      serviceName,
      locale: intl.locale,
      returnAddress: url,
      user: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email
      }
    };
    await sendEmail(emailData);
    dispatch(ca(EMAIL_SUCCESS)());
  } catch (e) {
    console.error('Error during email sending.', e);
    dispatch(ca(EMAIL_ERROR)(e));
    throw e;
  }
};

export const updateField = (fieldId, value) => dispatch => {
  dispatch(ca(UPDATE_FIELD)({ fieldId, value }));
};

const wrap = (START, ERROR, operation) => async dispatch => {
  dispatch(ca(START)());
  try {
    return await operation(dispatch);
  } catch (e) {
    console.error(`Error during ${START} :`, e);
    dispatch(ca(ERROR)(e));
    return { error: e };
  }
};

export const sendFormGeneral = (
  organizationId,
  form,
  sendingOption,
  formGUID
) => {
  // sema-saha-service updates service access to KVH when form is sent (1st time)

  const isSendingToVRKProcess = sendOption => {
    return typeof sendOption === 'boolean'
      ? sendOption
      : sendOption === formStates.SENT;
  };

  const data = convertToSAHAFormat[form.formData.pahaFormName](
    form,
    formGUID,
    isSendingToVRKProcess(sendingOption)
  );

  const formData = form.formData;
  const createPath = formData => {
    // TODO: rename paths so that pahaFormName is used for endpoint (also to sema-bus)
    const serviceInPath = {
      [Forms.tunnistusRegistrationForm.pahaFormName]: 'identification',
      [Forms.serviceBusCertificateForm.pahaFormName]:
        'dataexchangelayercertificate'
    };

    const pathEnding = `?formState=${sendingOption}`;

    return `/api/saha/form/service/${
      serviceInPath[formData.pahaFormName]
    }/organization/${organizationId}${pathEnding}`;
  };

  return {
    [CALL_API]: {
      method: 'post',
      endpoint: createPath(formData),
      types: [SEND_FORM_REQUEST, SEND_FORM_SUCCESS, SEND_FORM_ERROR],
      data: data
    }
  };
};

export const getCustomerGroups = language => ({
  [CALL_API]: {
    method: 'get',
    endpoint: `/api/saha/findresource/customerGroup/${language}`,
    types: [
      GET_CUSTOMER_GROUPS,
      GET_CUSTOMER_GROUPS_SUCCESS,
      GET_CUSTOMER_GROUPS_ERROR
    ]
  }
});

export default createReducer(ACTION_HANDLERS, initialState);
