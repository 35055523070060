import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Link, browserHistory, withRouter } from 'react-router';
import { renderTextField, renderDropdownText } from 'utils/fieldWrappers';
import { Button, PageHeader } from 'sema-ui-components';
import styles from './SignInView.scss';

const getErrorCode = payload => {
  try {
    return payload.payload.message.payload.error.errorMessages[0].errorCode;
  } catch (e) {
    return null;
  }
};

export class SignInView extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    notifSend: PropTypes.func,
    submitting: PropTypes.bool,
    weakSignIn: PropTypes.func,
    location: PropTypes.object,
    routes: PropTypes.array,
    params: PropTypes.object,
    authOrganisationService: PropTypes.string
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  formatMessage = id => this.context.intl.formatMessage({ id });

  handleErrors = (data, msg) => {
    if (getErrorCode(data) == 'PASSWORD_MUST_CHANGE_NOW') {
      browserHistory.push('/fi/kirjautuminen/uusi-vanhentunut-salasana');
    }
    this.props.notifSend(msg);
  };

  getRedirectUrl = () =>
    this.props.location.state && this.props.location.state.nextPathname;

  signInStrong = () => {
    browserHistory.push({
      pathname: '/login',
      state: { nextPathname: this.getRedirectUrl() }
    });
  };

  doHandleSubmit = values => {
    const msg = {
      title: this.formatMessage('sign_in.weak_sign_in_failed'),
      description: this.formatMessage(
        'sign_in.weak_sign_in_failed_description'
      ),
      type: 'fail',
      dismissAfter: 5000
    };

    this.props
      .weakSignIn(values, this.props.authOrganisationService)
      .then(data => {
        if (data && !!data.error) {
          this.handleErrors(data, msg);
        } else {
          browserHistory.push(this.getRedirectUrl() || '/dashboard');
        }
      })
      .catch(err => {
        console.error(err);
        this.props.notifSend(msg);
      });
  };

  render() {
    const { handleSubmit, submitting, routes, params } = this.props;
    const {
      intl: { locale }
    } = this.context;

    return (
      <section>
        <PageHeader
          title={
            <div>
              <div>
                <FormattedMessage id="sign_in.title" />
              </div>
            </div>
          }
          routes={routes}
          params={params}
        />

        <div className={styles.loginLayout}>
          <div className={styles.login}>
            <div className={styles.weakLogin}>
              <h4>
                <div>
                  <FormattedMessage id="sign_in.weak_sign_in" />
                </div>
              </h4>
              <form onSubmit={handleSubmit(this.doHandleSubmit)}>
                <div className="form-group">
                  <Field
                    name="username"
                    component={renderTextField}
                    size="w280"
                    disabled={submitting}
                    placeholder={this.formatMessage('sign_in.username')}
                  />
                </div>
                <div className="form-group">
                  <Field
                    name="password"
                    type="password"
                    component={renderTextField}
                    size="w280"
                    disabled={submitting}
                    placeholder={this.formatMessage('sign_in.password')}
                  />
                </div>
                <div className="form-group">
                  <div className={styles.loginButtons}>
                    <div className={styles.loginButton}>
                      <Button small type="submit" disabled={submitting}>
                        <div>
                          <FormattedMessage id="sign_in.sign_in" />
                        </div>
                      </Button>
                      <div>
                        <Link
                          to={`/${locale}/kirjautuminen/unohdettu-salasana`}
                        >
                          <FormattedMessage id="sign_in.forgotten_password" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <hr />
            <div className="form-group">
              <h4>
                <div>
                  <FormattedMessage id="sign_in.description.strong.title" />
                </div>
              </h4>
              <Button secondary small onClick={this.signInStrong}>
                <div>
                  <FormattedMessage id="sign_in.authenticate" />
                </div>
              </Button>
            </div>
            <hr />
            <div>
              <Field
                title={this.formatMessage('sign_in.is_user_new')}
                name="newUser"
                className="v-offset-3"
                component={renderDropdownText}
              >
                <div>
                  <p>
                    <FormattedMessage id="user_registration.register.ask_invitation_desc" />
                  </p>
                  <p>
                    <FormattedMessage id="user_registration.register.register_account_desc" />
                  </p>
                  <Button
                    secondary
                    small
                    className={styles.signUpButton}
                    onClick={() =>
                      browserHistory.push({
                        pathname: '/login',
                        state: {
                          nextPathname: `/${locale}/rekisteroityminen/tiedoksi`
                        }
                      })
                    }
                  >
                    <FormattedMessage id="user_registration.register.start_button_label" />
                  </Button>
                </div>
              </Field>
            </div>
          </div>

          <div className={styles.description}>
            <div className={styles.descriptionContent}>
              <h3 className={styles.noBottomMargin}>
                <div>
                  <FormattedMessage id="sign_in.description.title" />
                </div>
              </h3>
              <br />
              <h4 className={styles.noBottomMargin}>
                <div>
                  <FormattedMessage id="sign_in.description.weak.title" />
                </div>
              </h4>
              <p>
                <div>
                  <FormattedMessage id="sign_in.description.weak.text" />
                </div>
              </p>
              <p>
                <div>
                  <FormattedMessage id="sign_in.description.weak.notify_text" />
                </div>
              </p>
              <br />
              <h4 className={styles.noBottomMargin}>
                <div>
                  <FormattedMessage id="sign_in.description.strong.title" />
                </div>
              </h4>
              <div>
                <FormattedMessage id="sign_in.description.strong.text" />
              </div>
              <br />
              <hr />
              <br />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(SignInView);
