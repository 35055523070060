import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'suomifi-ui-components';
export const NoInformationManagement = ({
  i18n,
  isEIdentificationForm,
  hasNoInformationManagement,
  styles,
  formik
}) => {
  if (!hasNoInformationManagement) {
    return null;
  }

  const appropriateResourceHandling =
    formik.values?.formData?.statementOnInformationSecurity
      ?.appropriateResourceHandling;

  return (
    <>
      <h4>{i18n(`intermediary.dataProtection.orgnization_resources_title`)}</h4>

      <ol>
        <li>
          <span className={styles.listSubHeader}>
            {i18n(`intermediary.dataProtection.personel_reguirements`)}
          </span>
          <ul>
            <li>
              {i18n(
                `intermediary.dataProtection.desc_processingOfPersonalData`
              )}
            </li>
            <li>
              {i18n(
                `intermediary.dataProtection.desc_securityResponsiblePerson`
              )}
            </li>
            <li>
              {i18n(`intermediary.dataProtection.desc_securityCommitment`)}
            </li>
            <li>
              {i18n(
                `intermediary.dataProtection.desc_procedureForCheckVrkConditions`
              )}
            </li>
          </ul>
        </li>
        <li>
          <span className={styles.listSubHeader}>
            {i18n(`intermediary.dataProtection.usage_reguirements`)}
          </span>
          <ul>
            <li>
              {i18n(
                `intermediary.dataProtection.desc_regularCheckForUserRights`
              )}
            </li>
            <li>{i18n(`intermediary.dataProtection.desc_loginSecurity`)}</li>
          </ul>
        </li>
        <li>
          <span className={styles.listSubHeader}>
            {i18n(
              `intermediary.dataProtection.facility_and_tools_reguirements`
            )}
          </span>

          <ul>
            <li>
              {i18n(`intermediary.dataProtection.desc_securedWorkstation`)}
            </li>
            <li>
              {i18n(
                `intermediary.dataProtection.desc_vtjInformationDeletionBeforeMaintenance`
              )}
            </li>
            <li>
              {i18n(`intermediary.dataProtection.desc_securedFacilities`)}
            </li>
            <li>
              {i18n(
                `intermediary.dataProtection.desc_useOfFirewallAndProtection`
              )}
            </li>
            <li>
              {i18n(
                `intermediary.dataProtection.desc_dataConnectionIsSecuredForVtjData`
              )}
            </li>
            <li>
              {i18n(
                `intermediary.dataProtection.desc_productionAndTestEnvironments`
              )}
            </li>
          </ul>
        </li>
        {!isEIdentificationForm && (
          <li>
            <span className={styles.listSubHeader}>
              {i18n(`intermediary.dataProtection.logging_reguirements`)}
            </span>

            <ul>
              <li>{i18n(`intermediary.dataProtection.desc_auditLogs`)}</li>
              <li>
                {i18n(
                  `intermediary.dataProtection.desc_logsArchivedAndDestroyed`
                )}
              </li>
              <li>
                {i18n(
                  `intermediary.dataProtection.desc_logsAreProtectedFromChanges`
                )}
              </li>
              <li>
                {i18n(`intermediary.dataProtection.desc_logsAreRestricted`)}
              </li>
              <li>
                {i18n(`intermediary.dataProtection.desc_regularChecksForLogs`)}
              </li>
            </ul>
          </li>
        )}
        <div className={styles.listAnswer}>
          <Checkbox
            name="formData.statementOnInformationSecurity.appropriateResourceHandling"
            checked={appropriateResourceHandling}
            onClick={({ checkboxState }) =>
              formik.setFieldValue(
                'formData.statementOnInformationSecurity.appropriateResourceHandling',
                checkboxState
              )
            }
          >
            {i18n(
              'intermediary.dataProtection.desc_appropriateResourceHandling'
            )}
          </Checkbox>
        </div>
      </ol>
    </>
  );
};

NoInformationManagement.propTypes = {
  i18n: PropTypes.func,
  styles: PropTypes.object,
  isEIdentificationForm: PropTypes.bool,
  hasNoInformationManagement: PropTypes.bool,
  formik: PropTypes.object
};
