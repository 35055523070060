import React from 'react';
import styles from './styles.scss';
import { Block, Heading, Paragraph } from 'suomifi-ui-components';
import ErrorMessages from './ErrorMessagesContainer';
import PropTypes from 'prop-types';
import Preview from '../../components/Preview';
import { proposalStyles } from '../../constants';
import { FormattedMessage } from 'react-intl';
import { IntermediaryTextField, IntermediaryTextarea } from '../../components';
import { formTitle } from './schema';

export const Form = ({ formik, proposalStyle, serviceType }, { intl }) => {
  const i18n = id => intl.formatMessage({ id });
  const isIntermediary = proposalStyle === proposalStyles.INTERMEDIARY;

  return (
    <div className={styles.formContainer}>
      <Block>
        <Heading variant="h2" className="borderless mb-2">
          {i18n(formTitle)}
        </Heading>
        <Paragraph>
          {isIntermediary ? (
            <FormattedMessage id="intermediary.submit.intermediaryDescription" />
          ) : (
            <FormattedMessage id="intermediary.submit.customerDescription" />
          )}
        </Paragraph>
      </Block>
      <Block>
        <IntermediaryTextField
          name="formData.proposalName"
          label={'intermediary.submit.naming'}
          hintText={i18n('intermediary.submit.optionalName')}
          optional={'intermediary.submit.optional'}
          width="66.66%"
        />

        {isIntermediary && (
          <IntermediaryTextarea
            name="formData.customerEmailMessage"
            label={'intermediary.submit.customerMessageLabel'}
            optional={'intermediary.submit.optional'}
            hintText={i18n('intermediary.submit.customerMessageDesc')}
            className={styles.textarea}
            width="66.66%"
          />
        )}
      </Block>
      <Block>
        <ErrorMessages i18n={i18n} />
      </Block>
      <Block>
        <Heading variant="h3">{i18n('intermediary.submit.preview')}</Heading>
        <Preview
          values={formik?.values}
          i18n={i18n}
          serviceType={serviceType}
        />
      </Block>
    </div>
  );
};
Form.propTypes = {
  formik: PropTypes.object,
  proposalStyle: PropTypes.string,
  serviceType: PropTypes.string
};

Form.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default Form;
