import { createAction as ca } from '../utils';

const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';

export const resetErrorMessage = ca(RESET_ERROR_MESSAGE);

/**
 * Generic errors reducer, kidnaps action if
 * it has error === true.
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function errorReducer(state = null, action) {
  const { type, error, payload } = action;

  if (type === RESET_ERROR_MESSAGE) {
    return null;
  } else if (error) {
    return { error: payload };
  }

  return state;
}
