import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  InlineAlert,
  Heading,
  Paragraph,
  Block,
  Checkbox
} from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import { acceptTempCustomerData } from '../../utils';
import { ConfirmModal } from './ConfirmModal';
import { formStates } from '../../constants';
import { serviceSpecificTerms } from '../../partials/Terms/Terms';
import Preview from '../../components/Preview';
import styles from './ApproveApplication.scss';
import useFormData from '../../hooks/useFormData';

export const ApproveApplication = (
  { formId, serviceType, submitCallback, isTempUser },
  { intl }
) => {
  const i18n = id => intl?.formatMessage({ id });
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [accepted, setAccepted] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);

  const { initialValues, error } = useFormData({
    formId,
    serviceType,
    formState: formStates.WAITING_CUSTOMER_TO_APPROVE
  });

  const currentService = initialValues?.serviceType?.toLowerCase();
  const ServiceTerms = serviceSpecificTerms[currentService] || null;

  // Handle saving form as customer approved
  const handleSubmit = async () => {
    setIsSubmitting(true);
    acceptTempCustomerData(initialValues, formStates.APPROVED)
      .then(() => {
        if (isTempUser) {
          setShowConfirmModal(false);
          setShowSuccess(true);
          setTimeout(submitCallback, 5000);
        } else {
          submitCallback();
        }
      })
      .catch(error => {
        setShowConfirmModal(false);
        setSubmitError(error);
        console.warn(error);
      })
      .finally(() => setIsSubmitting(false));
  };

  if (error || submitError) {
    console.log({ submitError });
    return (
      <div className={styles.container}>
        <InlineAlert
          status="error"
          labelText={i18n('intermediary.common.error')}
        >
          <FormattedMessage id={error ? error.message : submitError.message} />
        </InlineAlert>
      </div>
    );
  }

  if (!initialValues || !initialValues?.formData) {
    return null;
  }

  if (showSuccess) {
    return (
      <div className={styles.container}>
        <Heading variant="h1" className="mb-m">
          <FormattedMessage id={'intermediary.approve.applicationSent'} />
        </Heading>
        <Paragraph>
          <FormattedMessage id={'intermediary.approve.willBeSignedOut'} />
        </Paragraph>
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <Heading variant="h1" className="mb-m">
          {i18n('intermediary.application.preview')}
        </Heading>
        <Paragraph className="mb-m">
          <FormattedMessage id={'intermediary.approve.previewApplication'} />
        </Paragraph>
        <Paragraph className="mb-m">
          <FormattedMessage id={'intermediary.approve.mandatory'} />
        </Paragraph>

        <InlineAlert className="mb-l">
          <FormattedMessage id={'intermediary.approve.notifyIntermediary'} />
        </InlineAlert>
        <div className={styles.content}>
          <Preview
            values={initialValues}
            i18n={i18n}
            showTerms={false}
            serviceType={initialValues?.serviceType}
          />
          <hr className="my-4" />
          <div className={styles.formContainer}>
            <Block>
              <Heading variant="h2" className="borderless mb-2">
                <FormattedMessage id={'intermediary.preview.approveRules'} />
              </Heading>
              <Paragraph>
                <FormattedMessage id={'intermediary.approve.signingPerson'} />
              </Paragraph>
            </Block>
            <ServiceTerms i18n={i18n}>
              <Checkbox
                name="acceptanceOfTerms"
                checked={accepted}
                onClick={({ checkboxState }) => setAccepted(checkboxState)}
              >
                <FormattedMessage id={'intermediary.approve.commitment'} />
              </Checkbox>
            </ServiceTerms>
          </div>
          <div className={styles.approveButton}>
            <Button
              disabled={!accepted}
              onClick={() => setShowConfirmModal(true)}
            >
              <FormattedMessage id={'intermediary.approve.approve'} />
            </Button>
          </div>
        </div>
      </div>
      <ConfirmModal
        {...{
          initialValues,
          handleSubmit,
          showConfirmModal,
          setShowConfirmModal,
          isSubmitting
        }}
      />
    </>
  );
};

ApproveApplication.contextTypes = {
  intl: PropTypes.object.isRequired
};

ApproveApplication.propTypes = {
  formId: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  submitCallback: PropTypes.func.isRequired,
  isTempUser: PropTypes.bool.isRequired
};
