import { createAction, createReducer } from 'utils';
import { CALL_API } from 'store/middleware/api';

const PX = 'ADMIN';
const SEARCH_ORGS_REGUEST = `${PX}_SEARCH_ORGS_REQUEST`;
const SEARCH_ORGS_SUCCESS = `${PX}_SEARCH_ORGS_SUCCESS`;
const SEARCH_ORGS_FAILURE = `${PX}_SEARCH_ORGS_FAILURE`;
const GENERIC_UPDATE_VALUE = 'GENERIC_UPDATE_VALUE';
const UPLOAD_MIGRATION_CSV_REQUEST = `${PX}_UPLOAD_MIGRATION_CSV_REQUEST`;
const UPLOAD_MIGRATION_CSV_SUCCESS = `${PX}_UPLOAD_MIGRATION_CSV_SUCCESS`;
const UPLOAD_MIGRATION_CSV_FAILURE = `${PX}_UPLOAD_MIGRATION_CSV_FAILURE`;

/**
 * insert string to search form all Paha organizations
 * minimum of three letters
 * @param {string} organizationString
 */
export function searchFromAllOrganizations(organizationString) {
  return {
    [CALL_API]: {
      //method: 'get',
      types: [SEARCH_ORGS_REGUEST, SEARCH_ORGS_SUCCESS, SEARCH_ORGS_FAILURE],
      endpoint: `admin/saha/organizations?q=${organizationString}`
    }
  };
}

export function uploadMigrationCsv(values) {
  return {
    [CALL_API]: {
      method: 'post',
      types: [
        UPLOAD_MIGRATION_CSV_REQUEST,
        UPLOAD_MIGRATION_CSV_SUCCESS,
        UPLOAD_MIGRATION_CSV_FAILURE
      ],
      data: values,
      endpoint: 'admin/saha/migration/identification/csv'
    }
  };
}

export function updateValue(name, value) {
  const valueMap = {};
  valueMap[name] = value;
  return dispatch => dispatch(createAction(GENERIC_UPDATE_VALUE)(valueMap));
}

const ACTION_HANDLERS = {
  [SEARCH_ORGS_REGUEST]: state => ({
    ...state,
    isSearchingOrgs: true
  }),
  [SEARCH_ORGS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isSearchingOrgs: false,
    showOrgsResults: true,
    organizations: payload
  }),
  [SEARCH_ORGS_FAILURE]: state => ({
    ...state,
    isSearchingOrgs: false
  }),
  [GENERIC_UPDATE_VALUE]: (state, { payload }) => {
    return {
      ...state,
      ...payload
    };
  },
  [UPLOAD_MIGRATION_CSV_REQUEST]: state => ({
    ...state,
    isUploadingCsv: true
  }),
  [UPLOAD_MIGRATION_CSV_SUCCESS]: state => ({
    ...state,
    isUploadingCsv: false
  }),
  [UPLOAD_MIGRATION_CSV_FAILURE]: state => ({
    ...state,
    isUploadingCsv: false
  })
};

const initialState = {
  organizations: [],
  isSearchingOrgs: false,
  invitation: {
    language: 'fi',
    selectedOrganizations: [],
    invitationLanguage: 'fi',
    organizationRole: 'user',
    content: [{ language: 'fi' }, { language: 'sv' }, { language: 'en' }]
  },
  isFetching: false,
  isUploadingCsv: false,
  showOrgsResults: false
};

export default createReducer(ACTION_HANDLERS, initialState);
