import React from 'react';
import PropTypes from 'prop-types';
import {
  WizardNavigation,
  WizardNavigationItem,
  RouterLink
} from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { StepItem } from './StepItem';
import { formStates } from '../../../constants';
import { getStepStatus } from '../../../utils';

const StyledSteps = styled.div`
  padding: 1.25rem 0;
  border: 1px solid #c9cdcf;
  background-color: #ffffff;
  width: 100%;

  @media only screen and (min-width: 1280px) {
    width: 360px;
    flex-shrink: 0;
  }
`;

const Steps = ({ steps, currentStep, nextFormState, setStep, i18n }) => {
  const getTitle = step => {
    const isIntermediary =
      nextFormState === formStates.WAITING_CUSTOMER_TO_APPROVE;

    if (!isIntermediary) {
      return step.title;
    }
    return step.titleIntermediary || step.title;
  };

  return (
    <StyledSteps>
      <WizardNavigation
        heading={i18n('intermediary.misc.steps')}
        aria-label="Main"
      >
        {steps.map((step, i) => (
          <WizardNavigationItem
            key={`step-${step.id}`}
            status={getStepStatus(currentStep, step, i)}
          >
            <RouterLink asComponent={StepItem} onClick={() => setStep(i)}>
              <FormattedMessage id={getTitle(step)} />
            </RouterLink>
          </WizardNavigationItem>
        ))}
      </WizardNavigation>
    </StyledSteps>
  );
};
Steps.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  nextFormState: PropTypes.number,
  setStep: PropTypes.func,
  i18n: PropTypes.func
};

export default Steps;
