import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Text, ExternalLink } from 'suomifi-ui-components';
import { userPermitOfSuomiServices } from '../../../constants';

const ServiceCatalogue = ({ children, i18n, locale }) => (
  <>
    <Paragraph marginBottomSpacing="s">
      <Text>
        {i18n('intermediary.common.read')}{' '}
        <ExternalLink
          href={userPermitOfSuomiServices(locale)}
          labelNewWindow={i18n('general.links.opens_in_new_window')}
        >
          {i18n('intermediary.terms.suomifiTerms')}
        </ExternalLink>{' '}
        {i18n('intermediary.common.and')}{' '}
        <ExternalLink
          href="https://palveluhallinta.suomi.fi/storage/cms.files/ypmoUIXqTVfNc-Zx.pdf"
          labelNewWindow={i18n('general.links.opens_in_new_window')}
        >
          {i18n('intermediary.terms.servicecatalogueLicense1')}
        </ExternalLink>{' '}
        {i18n('intermediary.common.and')}{' '}
        <ExternalLink
          href="https://palveluhallinta.suomi.fi/fi/tuki/artikkelit/5a7abb176b6a0463a88240a8"
          labelNewWindow={i18n('general.links.opens_in_new_window')}
        >
          {i18n('intermediary.terms.servicecatalogueLicense2')}
        </ExternalLink>
        {'.'}
      </Text>
    </Paragraph>
    {children}
    <ul className="mt-2">
      <li>{i18n('intermediary.terms.suomifiLicense')}</li>
      <li>{i18n('intermediary.terms.servicecatalogueLicense1')}</li>
      <li>{i18n('intermediary.terms.servicecatalogueLicense2')}</li>
    </ul>
  </>
);
ServiceCatalogue.propTypes = {
  children: PropTypes.node,
  i18n: PropTypes.func,
  locale: PropTypes.string
};

export default ServiceCatalogue;
