import { ConfirmationDialog } from 'components/ConfirmationDialog';
import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { pure } from 'recompose';

export const FormModals = ({
  closeModal,
  i18n,
  actionCreator,
  path,
  textKey
}) => (
  <ConfirmationDialog
    title={i18n(`forms.modals.${textKey}.title`)}
    labelConfirm={i18n(`forms.modals.${textKey}.confirm`)}
    labelCancel={i18n('general.form.cancel')}
    onConfirm={() => {
      if (actionCreator) {
        actionCreator();
      }
      if (path) {
        browserHistory.push(path);
      }
      closeModal();
    }}
    onCancel={closeModal}
  >
    {i18n(`forms.modals.${textKey}.content`)}
  </ConfirmationDialog>
);

FormModals.propTypes = {
  closeModal: PropTypes.func,
  i18n: PropTypes.func,
  path: PropTypes.string,
  actionCreator: PropTypes.func,
  textKey: PropTypes.string
};

export default pure(FormModals);
