import { useEffect, useState } from 'react';
import { getForm } from '../utils';

const useFormData = ({
  formId = undefined,
  defaultInitialValues = {},
  serviceType = undefined,
  formState = undefined,
  setIsLoading = () => {}
}) => {
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!formId) {
      setIsLoading(false);
      return;
    }

    const fetchForm = async () => {
      try {
        const data = await getForm(formId, formState, serviceType);
        setInitialValues(data);
      } catch (error) {
        setError(error);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    fetchForm();
  }, [serviceType, formState, formId]);

  return {
    error,
    initialValues,
    setInitialValues
  };
};

export default useFormData;
