import { connect } from 'react-redux';
import SearchView from '../components/SearchView';
import { requestAsyncSearch, clearSearchTerm } from '../modules';

const mapActionCreators = {
  requestAsyncSearch,
  clearSearchTerm
};

const mapStateToProps = state => {
  const { results, searchTerm, isFetching, currentMax } = state.search;
  return { results, searchTerm, isFetching, currentMax };
};

export default connect(mapStateToProps, mapActionCreators)(SearchView);
