import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router';

const localizeMetadata = (i18n, lang) => {
  document.querySelectorAll('link[href^="/assets/"]').forEach(node => {
    let href = node.getAttribute('href');
    href = href.replace(/assets\/..\//, `assets/${lang}/`);
    node.setAttribute('href', href);
  });

  document.querySelectorAll('meta[name="application-name"]').forEach(node => {
    node.setAttribute('content', i18n('site_title'));
  });
};

const TranslatableHelmet = ({ subTitle, titleFromRoute, routes }, { intl }) => {
  const i18n = id => intl.formatMessage({ id });
  const lang = intl.locale;
  const siteTitle = i18n('site_title');

  localizeMetadata(i18n, lang);

  const titleParts = [];
  subTitle && titleParts.push(subTitle);
  titleFromRoute &&
    titleParts.push(
      ...routes
        .filter(route => route.titleKey)
        .map(route => i18n(route.titleKey))
        .reverse()
    );

  return (
    <Helmet defaultTitle={siteTitle} titleTemplate={`%s - ${siteTitle}`}>
      <html lang={lang} />
      <title>{titleParts.join(' - ')}</title>
    </Helmet>
  );
};

TranslatableHelmet.propTypes = {
  subTitle: PropTypes.string,
  titleFromRoute: PropTypes.bool,
  routes: PropTypes.array
};

TranslatableHelmet.contextTypes = {
  intl: PropTypes.object.isRequired
};

/**

Examples:

<TranslatableHelmet titleFromRoute />
<TranslatableHelmet titleFromRoute subTitle="some additional item name" />
<TranslatableHelmet subTitle="custom title" />
 
*/
export default withRouter(TranslatableHelmet);
