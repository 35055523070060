import PropTypes from 'prop-types';
import React from 'react';
import styles from './Table.scss';

export const Table = ({ columns, data, isFetching }, { intl }) => {
  const t = id => intl.formatMessage({ id });

  return (
    <table className={styles.table}>
      <thead className={styles.header}>
        <tr>
          {columns.map(({ header }, index) => (
            <th key={index} className={styles.headerCell}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={styles.body}>
        {!isFetching &&
          data.length > 0 &&
          data.map((row, index) => (
            <tr key={row.id || index} className={styles.row}>
              {columns.map(({ header, property }) => (
                <td
                  key={`${index}-${header}-${property}`}
                  className={styles.cell}
                >
                  {row[property] || '-'}
                </td>
              ))}
            </tr>
          ))}
        {!isFetching && data.length < 1 && (
          <tr className={styles.row}>
            <td colSpan={columns.length} className={styles.cellNoData}>
              {t('general.table.no_results')}
            </td>
          </tr>
        )}
        {isFetching && (
          <tr className={styles.row}>
            <td colSpan={columns.length} className={styles.cellNoData}>
              {t('general.table.fetching_results')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool
};

Table.contextTypes = {
  intl: PropTypes.object.isRequired
};
