import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Block, Heading, Paragraph } from 'suomifi-ui-components';
import { asyncConnect } from 'redux-connect';
import { FormattedMessage } from 'react-intl';
import styles from './styles.scss';
import { formTitle, formTitleIntermediary } from './schema';
import { formStates, formTypes } from '../../constants';
import {
  AddContactButton,
  PersonFields,
  DisruptionEmailFields
} from '../../components';

const mapStateToProps = state => ({
  nextFormState: state.intermediary.nextFormState
});

export const CustomerOrganisationContacts = ({
  formik,
  nextFormState,
  serviceType
}) => {
  const [
    technicalBackupPersonVisible,
    setTechnicalBackupPersonVisible
  ] = useState(false);
  const [
    administrativeBackupPersonVisible,
    setAdministrativeBackupPersonVisible
  ] = useState(false);
  const { setFieldValue } = formik;
  const isIntermediary =
    nextFormState === formStates.WAITING_CUSTOMER_TO_APPROVE;

  React.useEffect(() => {
    const showAdminBackup = Object.values(
      formik.values?.formData?.customerContacts
        ?.customerAdministrativeContactInformation
        ?.administrativeContactPersonBackup || {}
    )?.some(v => v?.length);

    const showTechnicalBackup = Object.values(
      formik.values?.formData?.customerContacts
        ?.customerTechnicalContactInformation?.technicalContactPersonBackup ||
        {}
    )?.some(v => v?.length);

    setAdministrativeBackupPersonVisible(showAdminBackup);
    setTechnicalBackupPersonVisible(showTechnicalBackup);
  }, []);

  const clearBackupPerson = isAdministrative => {
    if (isAdministrative) {
      setFieldValue(
        'formData.customerContacts.customerAdministrativeContactInformation.administrativeContactPersonBackup',
        {}
      );
      setAdministrativeBackupPersonVisible(false);
    } else {
      setFieldValue(
        'formData.customerContacts.customerTechnicalContactInformation.technicalContactPersonBackup',
        {}
      );
      setTechnicalBackupPersonVisible(false);
    }
  };

  return (
    <div>
      <Block mb="l">
        <Heading variant="h2" className="borderless mb-2">
          <FormattedMessage
            id={isIntermediary ? formTitleIntermediary : formTitle}
          />
        </Heading>
        <Paragraph>
          <FormattedMessage id="intermediary.customerOrganisation.formDescription" />
        </Paragraph>
      </Block>
      <Block mb="l">
        <Heading variant="h3">
          <FormattedMessage
            id={`intermediary.customerOrganisation.${
              isIntermediary
                ? 'customerOrganisationTitleIntermediary'
                : 'customerOrganisationTitle'
            }`}
          />
        </Heading>
        <Paragraph>
          {isIntermediary ? (
            <FormattedMessage id="intermediary.customerOrganisation.customerOrganisationDescriptionIntermediary" />
          ) : (
            <FormattedMessage id="intermediary.customerOrganisation.customerOrganisationDescription" />
          )}
        </Paragraph>
      </Block>
      <PersonFields name="formData.customerContacts.customerAdministrativeContactInformation.administrativeContactPerson" />

      {administrativeBackupPersonVisible ? (
        <>
          <Heading variant="h4">
            <FormattedMessage id="intermediary.customerOrganisation.administrativeBackupContact" />
          </Heading>
          <PersonFields
            name="formData.customerContacts.customerAdministrativeContactInformation.administrativeContactPersonBackup"
            onDelete={() => clearBackupPerson(true)}
          />
        </>
      ) : (
        <AddContactButton
          setVisible={() => setAdministrativeBackupPersonVisible(true)}
        />
      )}

      <hr className={styles.spacer} />
      <Block mb="l">
        <Heading variant="h3">
          <FormattedMessage
            id={`intermediary.customerOrganisation.${
              isIntermediary
                ? 'customerTechnicalContactTitleIntermediary'
                : 'customerTechnicalContactTitle'
            }`}
          />
        </Heading>
        <Paragraph>
          <FormattedMessage id="intermediary.customerOrganisation.customerTechnicalContactDescription" />
        </Paragraph>
      </Block>
      <PersonFields name="formData.customerContacts.customerTechnicalContactInformation.technicalContactPerson" />

      {technicalBackupPersonVisible ? (
        <>
          <Heading variant="h4">
            <FormattedMessage id="intermediary.customerOrganisation.technicalBackupContact" />
          </Heading>
          <PersonFields
            name="formData.customerContacts.customerTechnicalContactInformation.technicalContactPersonBackup"
            onDelete={() => clearBackupPerson(false)}
          />
        </>
      ) : (
        <AddContactButton
          setVisible={() => setTechnicalBackupPersonVisible(true)}
        />
      )}

      {serviceType !== formTypes.EIDENTIFICATION && (
        <>
          <hr className={styles.spacer} />

          <DisruptionEmailFields
            name={'formData.customerContacts.technicalProcessEmail'}
          />
        </>
      )}
    </div>
  );
};
CustomerOrganisationContacts.propTypes = {
  formik: PropTypes.object.isRequired,
  nextFormState: PropTypes.number,
  serviceType: PropTypes.string.isRequired
};

export const Form = asyncConnect(
  [],
  mapStateToProps,
  {}
)(CustomerOrganisationContacts);
