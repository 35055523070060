import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import { Block, Heading, Paragraph } from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import {
  CoveredByInformationManagementAct,
  FollowInformationManagementAct,
  LoggingReguirementsMet,
  NoInformationManagement,
  StatementOnInformationSecurity,
  DataHandlingOutsideEU,
  StatementOnInformationSecurityIdentification
} from './components';

import { formTitle } from './schema';

export const Form = ({ formik, serviceType }, { intl }) => {
  const i18n = id => intl.formatMessage({ id });
  const getRadioValue = path => {
    const value =
      formik?.values?.formData?.statementOnInformationSecurity?.[path];

    if (value === null || value === undefined) {
      return null;
    }

    return value ? 'true' : 'false';
  };

  const followInformationManagementAct = getRadioValue(
    'followInformationManagementAct'
  );

  const coveredByInformationManagementAct = getRadioValue(
    'coveredByInformationManagementAct'
  );

  const hideSaveToRegister =
    followInformationManagementAct === 'true' &&
    coveredByInformationManagementAct === 'true';

  const discloseInformationToThirdParty = getRadioValue(
    'discloseInformationToThirdParty'
  );

  const serviceDataSavedToRegister = getRadioValue(
    'serviceDataSavedToRegister'
  );

  const loggingReguirementsMet = getRadioValue('loggingReguirementsMet');

  const hasInformationManagement =
    coveredByInformationManagementAct === 'true' &&
    followInformationManagementAct === 'true';

  const hasNoInformationManagement =
    coveredByInformationManagementAct === 'false' ||
    followInformationManagementAct === 'false';

  const isEIdentificationForm = serviceType === 'eIdentification';

  return (
    <div className={styles.container}>
      <Block>
        <Heading variant="h2" className="borderless mb-2">
          <FormattedMessage id={formTitle} />
        </Heading>
        <Paragraph>
          <FormattedMessage id="intermediary.dataProtection.formDescription" />
        </Paragraph>
      </Block>
      <div className={styles.group}>
        <Block>
          <Heading variant="h3">
            <FormattedMessage id="intermediary.dataProtection.subtitle" />
          </Heading>
          <Paragraph marginBottomSpacing="s">
            <FormattedMessage id="intermediary.dataProtection.description" />
          </Paragraph>
        </Block>

        {/*Tiedonhallintalaki*/}
        <FollowInformationManagementAct
          i18n={i18n}
          followInformationManagementAct={followInformationManagementAct}
          formik={formik}
        />

        {followInformationManagementAct === 'true' && (
          <CoveredByInformationManagementAct
            i18n={i18n}
            coveredByInformationManagementAct={
              coveredByInformationManagementAct
            }
            formik={formik}
          />
        )}

        {hasNoInformationManagement && (
          <>
            <hr className={styles.hr} />

            <NoInformationManagement
              i18n={i18n}
              hasNoInformationManagement={hasNoInformationManagement}
              isEIdentificationForm={isEIdentificationForm}
              styles={styles}
              formik={formik}
            />
          </>
        )}
      </div>

      {/*Tietojenkäsittely organisaatiossa*/}
      {(hasInformationManagement || hasNoInformationManagement) && (
        <div className={styles.group}>
          <hr className={styles.hr} />
          {isEIdentificationForm ? (
            <StatementOnInformationSecurityIdentification
              i18n={i18n}
              formik={formik}
              discloseInformationToThirdParty={discloseInformationToThirdParty}
              serviceDataSavedToRegister={serviceDataSavedToRegister}
              getRadioValue={getRadioValue}
              hideSaveToRegister={hideSaveToRegister}
            />
          ) : (
            <StatementOnInformationSecurity
              i18n={i18n}
              formik={formik}
              discloseInformationToThirdParty={discloseInformationToThirdParty}
              serviceDataSavedToRegister={serviceDataSavedToRegister}
              hideSaveToRegister={hideSaveToRegister}
            />
          )}

          <hr className={styles.hr} />
        </div>
      )}
      <div className={styles.group}>
        {/*Lokivaatimukset*/}

        {((isEIdentificationForm && hasNoInformationManagement) ||
          hasInformationManagement) && (
          <LoggingReguirementsMet
            i18n={i18n}
            loggingReguirementsMet={loggingReguirementsMet}
            hasNoInformationManagement={hasNoInformationManagement}
            isEIdentificationForm={isEIdentificationForm}
            formik={formik}
          />
        )}
        {hasNoInformationManagement && !isEIdentificationForm && (
          <DataHandlingOutsideEU
            i18n={i18n}
            formik={formik}
            getRadioValue={getRadioValue}
          />
        )}
      </div>
    </div>
  );
};
Form.propTypes = {
  serviceType: PropTypes.string,
  formik: PropTypes.object
};

Form.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default Form;
