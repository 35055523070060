import { createAction, createReducer } from 'utils/index';
import { CALL_API } from '../../../store/middleware/api';
import { proposalStyles } from '../constants';

const initialState = {
  currentStep: 0,
  errors: [],
  initialValues: {
    proposalStyle: undefined
  },
  isLoading: false,
  isSavingForm: false,
  isTransferringAfterButtonClick: false,
  nextFormState: undefined,
  proposalStyle: undefined,
  reduxStore: undefined,
  isIntermediary: false,
  isSendingAttachment: false,
  errorSendingAttachment: false,
  isDeletingAttachment: false,
  errorDeletingAttachment: false,
  serviceType: undefined,
  steps: [],
  unexpectedError: false,
  validationSchema: undefined,
  ytjData: undefined,
  errorFetchingYTJData: false
};

const PX = 'INTERMEDIARY';
const SET_COMPLETED = `${PX}_SET_COMPLETED`;
const NEXT_STEP = `${PX}_NEXT_STEP`;
const SET_STEP = `${PX}_SET_STEP`;
const PREVIOUS_STEP = `${PX}_PREVIOUS_STEP`;
const SETUP_FORM = `${PX}_SETUP_FORM`;
const SET_PROPOSAL_STYLE = `${PX}_SET_PROPOSAL_STYLE`;
const SET_ISLOADING = `${PX}_SET_ISLOADING`;
const SET_UNEXPECTED_ERROR = `${PX}_SET_UNEXPECTED_ERROR`;
const SET_IS_SAVING_FORM = `${PX}_SET_IS_SAVING_FORM`;
const SET_ERRORS = `${PX}_SET_ERRORS`;
const RESET_FORM = `${PX}_RESET_FORM`;
const SEND_ATTACHMENT = `${PX}_SEND_ATTACHMENT`;
const SEND_ATTACHMENT_SUCCESS = `${PX}_SEND_ATTACHMENT_SUCCESS`;
const SEND_ATTACHMENT_ERROR = `${PX}_SEND_ATTACHMENT_ERROR`;
const DELETE_ATTACHMENT = `${PX}_DELETE_ATTACHMENT`;
const DELETE_ATTACHMENT_SUCCESS = `${PX}_DELETE_ATTACHMENT_SUCCESS`;
const DELETE_ATTACHMENT_ERROR = `${PX}_DELETE_ATTACHMENT_ERROR`;
const SET_SCHEMA = `${PX}_SET_SCHEMA`;
const SET_INITIAL_VALUES = `${PX}_SET_INITIAL_VALUES`;
const GET_YTJ_DATA = `${PX}_GET_YTJ_DATA`;
const GET_YTJ_DATA_SUCCESS = `${PX}_GET_YTJ_DATA_SUCCESS`;
const GET_YTJ_DATA_ERROR = `${PX}_GET_YTJ_DATA_ERROR`;
const SET_YTJ_ERROR = `${PX}_SET_YTJ_ERROR`;

export const TRANSFER_AFTER_BUTTON_CLICK = `${PX}_TRANSFER_AFTER_BUTTON_CLICK`;

const ACTION_HANDLERS = {
  [SET_COMPLETED]: (state, { payload }) => {
    const { id, isCompleted } = payload;
    return {
      ...state,
      steps: state.steps.map(step =>
        step.id === id ? { ...step, completed: isCompleted } : step
      )
    };
  },
  [NEXT_STEP]: state => ({
    ...state,
    currentStep: state.steps
      ? Math.min(state.currentStep + 1, state.steps.length - 1)
      : 0 // Never go out of indexes
  }),
  [PREVIOUS_STEP]: state => {
    return state.currentStep > 0
      ? {
          ...state,
          currentStep: state.currentStep - 1
        }
      : state;
  },
  [SET_STEP]: (state, { payload }) => ({
    ...state,
    currentStep: payload
  }),
  [SETUP_FORM]: (state, { payload }) => {
    return {
      ...state,
      steps: payload.steps,
      nextFormState: payload.nextFormState,
      validationSchema: payload.validationSchema,
      initialValues: {
        ...payload.initialValues
      },
      serviceType: payload.serviceType,
      reduxStore: payload.reduxStore
    };
  },
  [SET_PROPOSAL_STYLE]: (state, { payload }) => {
    return {
      ...state,
      initialValues: {
        ...state.initialValues,
        proposalStyle: payload
      },
      steps:
        payload === proposalStyles.INTERMEDIARY
          ? (state.steps || [])
              .filter(step => !step.intermediaryOnly)
              .map((s, i) => ({ ...s, id: i }))
          : state.steps
    };
  },
  [SET_ISLOADING]: (state, { payload }) => ({
    ...state,
    isLoading: payload
  }),
  [SET_UNEXPECTED_ERROR]: (state, { payload }) => ({
    ...state,
    unexpectedError: payload
  }),
  [SET_IS_SAVING_FORM]: (state, { payload }) => ({
    ...state,
    isSavingForm: payload
  }),
  [SET_ERRORS]: (state, { payload }) => ({
    ...state,
    errors: payload
  }),
  [RESET_FORM]: () => ({
    ...initialState
  }),
  [TRANSFER_AFTER_BUTTON_CLICK]: state => ({
    ...state,
    isTransferringAfterButtonClick: true
  }),
  [SEND_ATTACHMENT]: state => ({
    ...state,
    isSendingAttachment: true,
    errorSendingAttachment: false
  }),
  [SEND_ATTACHMENT_SUCCESS]: state => ({
    ...state,
    isSendingAttachment: false,
    errorSendingAttachment: false
  }),
  [SEND_ATTACHMENT_ERROR]: state => ({
    ...state,
    isSendingAttachment: false,
    errorSendingAttachment: true
  }),
  [DELETE_ATTACHMENT]: state => ({
    ...state,
    isDeleting: true,
    errorDeletingAttachment: false
  }),
  [DELETE_ATTACHMENT_SUCCESS]: state => ({
    ...state,
    isDeletingAttachment: false,
    errorDeletingAttachment: false
  }),
  [DELETE_ATTACHMENT_ERROR]: state => ({
    ...state,
    isDeletingAttachment: false,
    errorDeletingAttachment: true
  }),
  [SET_SCHEMA]: (state, { payload }) => ({
    ...state,
    validationSchema: payload
  }),
  [SET_INITIAL_VALUES]: (state, { payload }) => ({
    ...state,
    initialValues: payload
  }),
  [GET_YTJ_DATA]: state => ({
    ...state,
    isFetchingYTJData: true,
    errorFetchingYTJData: false
  }),
  [GET_YTJ_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingYTJData: false,
    errorFetchingYTJData: false,
    ytjData: payload
  }),
  [GET_YTJ_DATA_ERROR]: state => ({
    ...state,
    isFetchingYTJData: false,
    errorFetchingYTJData: true
  }),
  [SET_YTJ_ERROR]: (state, { payload }) => ({
    ...state,
    errorFetchingYTJData: payload
  })
};

export const transferAfterButtonClick = createAction(
  TRANSFER_AFTER_BUTTON_CLICK
);

export const setupForm = payload => dispatch => {
  dispatch(createAction(SETUP_FORM)(payload));
};

export const setProposalStyle = payload => dispatch => {
  dispatch(createAction(SET_PROPOSAL_STYLE)(payload));
};

export const setStep = payload => dispatch =>
  dispatch(createAction(SET_STEP)(payload));

export const setCompleted = payload => dispatch =>
  dispatch(createAction(SET_COMPLETED)(payload));

export const setIsLoading = payload => dispatch =>
  dispatch(createAction(SET_ISLOADING)(payload));

export const previousStep = () => dispatch =>
  dispatch(createAction(PREVIOUS_STEP)());

export const nextStep = () => dispatch => dispatch(createAction(NEXT_STEP)());

export const setUnexpectedError = payload => dispatch =>
  dispatch(createAction(SET_UNEXPECTED_ERROR)(payload));

export const setIsSavingForm = payload => dispatch =>
  dispatch(createAction(SET_IS_SAVING_FORM)(payload));

export const setErrors = payload => dispatch =>
  dispatch(createAction(SET_ERRORS)(payload));

export const resetFormState = () => dispatch =>
  dispatch(createAction(RESET_FORM)());

export function sendAttachment(data) {
  return {
    [CALL_API]: {
      noContentType: true,
      method: 'post',
      endpoint: '/api/file-upload',
      data,
      types: [SEND_ATTACHMENT, SEND_ATTACHMENT_SUCCESS, SEND_ATTACHMENT_ERROR]
    }
  };
}

export function deleteAttachment(key) {
  return {
    [CALL_API]: {
      method: 'delete',
      endpoint: `/api/file-remove/?fileKey=${key}`,
      types: [
        DELETE_ATTACHMENT,
        DELETE_ATTACHMENT_SUCCESS,
        DELETE_ATTACHMENT_ERROR
      ]
    }
  };
}

export function getYTJData(businessId) {
  return {
    [CALL_API]: {
      method: 'get',
      endpoint: `/api/organisationservice/intermediary/organisation/find?businessId=${businessId}`,
      types: [GET_YTJ_DATA, GET_YTJ_DATA_SUCCESS, GET_YTJ_DATA_ERROR]
    }
  };
}

export const setErrorFetchingYTJData = payload => dispatch => {
  dispatch(createAction(SET_YTJ_ERROR)(payload));
};

export const setValidationSchema = payload => dispatch =>
  dispatch(createAction(SET_SCHEMA)(payload));

export const setInitialValues = payload => dispatch =>
  dispatch(createAction(SET_INITIAL_VALUES)(payload));

export default createReducer(ACTION_HANDLERS, initialState);
