import {
  postSemaJSON,
  getSemaJSON,
  deleteGeneric,
  putSemaJSON,
  putKVHJSON
} from 'utils/http';

export function createUserProfile(orgId, userId, profile) {
  const profileData = {
    organizationKey: orgId,
    userKey: userId,
    jsonObject: profile
  };
  return postSemaJSON(`/api/userprofile/${orgId}`, profileData);
}

export const createOrg = userAndOrgData => {
  return postSemaJSON(`/api/organisationservice/organisations`, userAndOrgData);
};

export const fetchUserWithEmail = emailAddress => {
  return getSemaJSON(`/api/organisationservice/users/${emailAddress}`);
};

export function createOrgProfileInSAHA(profileData) {
  return postSemaJSON(`/api/saha/createorganizationjoinuser`, profileData);
}

export function createUserAndOrgProfile(profileData) {
  return postSemaJSON(`/api/saha/createorganization`, profileData);
}

export function createOrgProfile(orgId, profile) {
  const profileData = { organizationGuid: orgId, data: profile };
  return postSemaJSON(`/api/orgprofile/${orgId}`, profileData);
}

export function bootstrapUserKVHCall(sahaGuid, orgId) {
  return putKVHJSON(`/api/auth/kvhregister`, { orgId: orgId }, sahaGuid);
}

export function getInvitation(invitationId) {
  return getSemaJSON(`/api/organisationservice/invitations/${invitationId}`);
}

// ei tarvita
export function updateInvitation(invitationId, accountId, data) {
  return postSemaJSON(
    `/api/saha/account/${accountId}/invitation/${invitationId}`,
    data
  );
}

// korvattu registeInvitedUser:lla
export function activateInvitation(invitationId) {
  return getSemaJSON(`/api/saha/invitationactivate/${invitationId}`);
}

export const registerInvitedUser = (invitationId, userData) => {
  return postSemaJSON(
    `/api/organisationservice/invitations/${invitationId}`,
    userData
  );
};

export function organizationExists(orgId) {
  return getSemaJSON(`/api/saha/organizationexists/${orgId}`);
}

export function inviteUser(invitationData) {
  return postSemaJSON(`/api/saha/invitation`, invitationData);
}

export function inviteUserNew(invitationData) {
  return postSemaJSON(`/api/kvhAdmin/invitation`, invitationData);
}

export function userExists(email) {
  return getSemaJSON(`/api/saha/userexists/email/${email}`);
}
