import { createReducer, createAction as ca } from 'utils';
import { CALL_API } from 'store/middleware/api';
import { actions as notificationActions } from 'redux-notifications';

const { notifSend } = notificationActions;

// ------------------------------------
// Actions
// ------------------------------------
export const ID_ENVIRONMENT_CONTACTS_REQUEST =
  'ID_ENVIRONMENT_CONTACTS_REQUEST';
export const ID_ENVIRONMENT_CONTACTS_SUCCESS =
  'ID_ENVIRONMENT_CONTACTS_SUCCESS';
export const ID_ENVIRONMENT_CONTACTS_FAILURE =
  'ID_ENVIRONMENT_CONTACTS_FAILURE';
export const ID_ORG_REQUEST = 'ID_ACTIVE_ORG_REQUEST';
export const ID_ORG_SUCCESS = 'ID_ACTIVE_ORG_SUCCESS';
export const ID_ORG_FAILURE = 'ID_ACTIVE_ORG_FAILURE';
export const ID_PROVIDERS_REQUEST = 'ID_PROVIDERS_REQUEST';
export const ID_PROVIDERS_SUCCESS = 'ID_PROVIDERS_SUCCESS';
export const ID_PROVIDERS_FAILURE = 'ID_PROVIDERS_FAILURE';
export const ID_PROVIDERS_CREATE_REQUEST = 'ID_PROVIDERS_CREATE_REQUEST';
export const ID_PROVIDERS_CREATE_SUCCESS = 'ID_PROVIDERS_CREATE_SUCCESS';
export const ID_PROVIDERS_CREATE_FAILURE = 'ID_PROVIDERS_CREATE_FAILURE';
export const ID_ENVIRONMENT_UPDATE_REQUEST = 'ID_ENVIRONMENT_UPDATE_REQUEST';
export const ID_ENVIRONMENTS_REQUEST = 'ID_ENVIRONMENTS_REQUEST';
export const ID_ENVIRONMENTS_SUCCESS = 'ID_ENVIRONMENTS_SUCCESS';
export const ID_ENVIRONMENTS_FAILURE = 'ID_ENVIRONMENTS_FAILURE';
export const ID_PROVIDERS_ADD_XML_REQUEST = 'ID_PROVIDERS_ADD_XML_REQUEST';
export const ID_PROVIDERS_ADD_XML_SUCCESS = 'ID_PROVIDERS_ADD_XML_SUCCESS';
export const ID_PROVIDERS_ADD_XML_FAILURE = 'ID_PROVIDERS_ADD_XML_FAILURE';
export const ID_DELETE_PROVIDER_CONFIRMATION_VISIBLE =
  'ID_DELETE_PROVIDER_CONFIRMATION_VISIBLE';
export const ID_SELECT_PROVIDER = 'ID_SELECT_PROVIDER';
export const ID_SELECT_ENVIRONMENT = 'ID_SELECT_ENVIRONMENT';
export const ID_ORGANIZATION_CREATE_REQUEST = 'ID_ORGANIZATION_CREATE_REQUEST';
export const ID_ORGANIZATION_CREATE_SUCCESS = 'ID_ORGANIZATION_CREATE_SUCCESS';
export const ID_ORGANIZATION_CREATE_FAILURE = 'ID_ORGANIZATION_CREATE_FAILURE';
export const ID_TOGGLE_ENVIRONMENT_STATUS_FILTER =
  'ID_TOGGLE_ENVIRONMENT_STATUS_FILTER';
export const ID_TOGGLE_ENVIRONMENT_INFO = 'ID_TOGGLE_ENVIRONMENT_INFO';
export const ID_TOGGLE_SERVICE_PROVIDER_INFO =
  'ID_TOGGLE_SERVICE_PROVIDER_INFO';

export const createOrganization = organization => {
  return {
    [CALL_API]: {
      method: 'post',
      types: [
        ID_ORGANIZATION_CREATE_REQUEST,
        ID_ORGANIZATION_CREATE_SUCCESS,
        ID_ORGANIZATION_CREATE_FAILURE
      ],
      endpoint: `identification-admin/organizations`,
      data: organization
    }
  };
};

export const fetchEnvironmentContacts = (providerId, environmentId) => {
  return {
    [CALL_API]: {
      types: [
        ID_ENVIRONMENT_CONTACTS_REQUEST,
        ID_ENVIRONMENT_CONTACTS_SUCCESS,
        ID_ENVIRONMENT_CONTACTS_FAILURE
      ],
      endpoint: `identification-admin/providers/${providerId}/environment/${environmentId}/contactpersons`
    }
  };
};

export const fetchActiveOrganization = organizationID => {
  return {
    [CALL_API]: {
      types: [ID_ORG_REQUEST, ID_ORG_SUCCESS, ID_ORG_FAILURE],
      endpoint: `identification-admin/organizations/${organizationID}`
    }
  };
};

export const fetchIdProviders = orgId => {
  return {
    [CALL_API]: {
      types: [ID_PROVIDERS_REQUEST, ID_PROVIDERS_SUCCESS, ID_PROVIDERS_FAILURE],
      endpoint: `identification-admin/providers?orgId=${orgId}`
    }
  };
};

export const fetchEnvironmentsByProviderId = providerId => {
  return {
    [CALL_API]: {
      types: [
        ID_ENVIRONMENTS_REQUEST,
        ID_ENVIRONMENTS_SUCCESS,
        ID_ENVIRONMENTS_FAILURE
      ],
      endpoint: `identification-admin/environments/${providerId}`
    }
  };
};

export const createIdProvider = provider => {
  return {
    [CALL_API]: {
      method: 'post',
      types: [
        ID_PROVIDERS_CREATE_REQUEST,
        ID_PROVIDERS_CREATE_SUCCESS,
        ID_PROVIDERS_CREATE_FAILURE
      ],
      endpoint: `identification-admin/providers`,
      data: provider
    }
  };
};

export const updateIdProvider = (provider, providerId) => {
  return {
    [CALL_API]: {
      method: 'put',
      types: [
        ID_PROVIDERS_CREATE_REQUEST,
        ID_PROVIDERS_CREATE_SUCCESS,
        ID_PROVIDERS_CREATE_FAILURE
      ],
      endpoint: `identification-admin/providers/${providerId}`,
      data: provider
    }
  };
};

export const postProviderXML = (
  serviceProviderID,
  sahaGuid,
  targetEnv,
  sourceEnv,
  providerName,
  xml,
  decisionId,
  permitLevel,
  journalNumber,
  approvedDate
) => {
  // =create environment API call
  const levelOfPermitQueryParam = permitLevel
    ? `&levelOfPermit=${permitLevel}`
    : '';
  const decisionIdQueryParam = decisionId ? `&decisionId=${decisionId}` : '';
  const journalParam = journalNumber ? `&journalNumber=${journalNumber}` : '';
  const approvedDateParam = approvedDate ? `&approvedDate=${approvedDate}` : '';

  return {
    [CALL_API]: {
      method: 'post',
      types: [
        ID_PROVIDERS_ADD_XML_REQUEST,
        ID_PROVIDERS_ADD_XML_SUCCESS,
        ID_PROVIDERS_ADD_XML_FAILURE
      ],
      endpoint:
        `identification-admin/xmlimport?sourceEnv=${sourceEnv}` +
        `&targetEnv=${targetEnv}&providerName=${providerName}&sahaGuid=${sahaGuid}` +
        levelOfPermitQueryParam +
        decisionIdQueryParam +
        journalParam +
        approvedDateParam +
        `&providerID=${serviceProviderID}`,
      data: xml,
      contentType: 'application/xml',
      disableStringify: true
    }
  };
};

export const selectProvider = provider => {
  return dispatch => dispatch(ca(ID_SELECT_PROVIDER)(provider));
};

export const selectEnvironment = environment => {
  return dispatch => dispatch(ca(ID_SELECT_ENVIRONMENT)(environment));
};

export const toggleEnvironmentInfo = visibility => {
  return dispatch => dispatch(ca(ID_TOGGLE_ENVIRONMENT_INFO)(visibility));
};

export function toggleEnvironmentStatusFilter(k) {
  let newStatusFilters;
  return (dispatch, getStore) => {
    newStatusFilters = { ...getStore().idAdmin.environmentStatusFilters };
    newStatusFilters[k] = !newStatusFilters[k];
    dispatch(ca(ID_TOGGLE_ENVIRONMENT_STATUS_FILTER)(newStatusFilters));
  };
}

export const toggleServiceProviderInfo = visibility => {
  return dispatch => dispatch(ca(ID_TOGGLE_SERVICE_PROVIDER_INFO)(visibility));
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ID_ENVIRONMENT_CONTACTS_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [ID_ENVIRONMENT_CONTACTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    currentEnvironmentContacts: payload,
    isFetching: false
  }),
  [ID_ENVIRONMENT_CONTACTS_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [ID_ORG_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [ID_ORG_SUCCESS]: (state, { payload }) => ({
    ...state,
    activeOrgSemaID: payload.id ? payload.id.toString() : '',
    isFetching: false
  }),
  [ID_ORG_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [ID_PROVIDERS_REQUEST]: state => ({
    ...state,
    providerListDirty: false,
    isFetching: true
  }),
  [ID_PROVIDERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    providers: payload
  }),
  [ID_PROVIDERS_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [ID_PROVIDERS_CREATE_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [ID_PROVIDERS_CREATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    providerListDirty: true,
    currentProvider: payload,
    providers: [...state.providers, payload]
  }),
  [ID_PROVIDERS_CREATE_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [ID_ENVIRONMENTS_REQUEST]: state => ({
    ...state,
    environmentsListDirty: false,
    isFetching: true
  }),
  [ID_ENVIRONMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    environments: payload,
    currentEnvironment: null,
    environmentsListDirty: false
  }),
  [ID_ENVIRONMENTS_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [ID_ENVIRONMENT_UPDATE_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [ID_PROVIDERS_ADD_XML_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [ID_PROVIDERS_ADD_XML_SUCCESS]: state => ({
    ...state,
    isFetching: false,
    environmentsListDirty: true,
    providerListDirty: true
  }),
  [ID_PROVIDERS_ADD_XML_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [ID_SELECT_PROVIDER]: (state, { payload }) => ({
    ...state,
    currentProvider: payload,
    environmentsListDirty: true
  }),
  [ID_SELECT_ENVIRONMENT]: (state, { payload }) => {
    return {
      ...state,
      currentEnvironment: payload
    };
  },
  [ID_ORGANIZATION_CREATE_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [ID_ORGANIZATION_CREATE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      activeOrgSemaID: payload.id ? payload.id.toString() : '',
      isFetching: false
    };
  },
  [ID_ORGANIZATION_CREATE_FAILURE]: (state, { payload }) => ({
    ...state,
    error: payload,
    isFetching: false
  }),
  [ID_TOGGLE_ENVIRONMENT_STATUS_FILTER]: (state, { payload }) => ({
    ...state,
    environmentStatusFilters: payload
  }),
  [ID_TOGGLE_ENVIRONMENT_INFO]: (state, { payload }) => ({
    ...state,
    envInfoVisible: payload
  }),
  [ID_TOGGLE_SERVICE_PROVIDER_INFO]: (state, { payload }) => ({
    ...state,
    spInfoVisible: payload
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  activeOrgSemaID: null,
  sahaGuid: null,
  providers: [],
  environments: [],
  currentProvider: null,
  providerListDirty: true,
  environmentsListDirty: true,
  currentEnvironment: null,
  currentEnvironmentContacts: null,
  isFetching: false,
  environmentStatusFilters: {
    archived: true,
    published: true,
    waitingForApproval: true,
    waitingForPublishing: true
  },
  envInfoVisible: false,
  spInfoVisible: false,
  acceptedTerms: [],
  acceptedTermsFetched: false
};

export default createReducer(ACTION_HANDLERS, initialState);
