import React from 'react';
import PropTypes from 'prop-types';
import { Router, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import { ReduxAsyncConnect } from 'redux-connect';
import ConfigProvider from 'providers/ConfigProvider';
import FeatureProvider from 'providers/FeatureProvider';
import { uglyQuickFix } from '../utils/http';

const RestoreFocus = ({ children }) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    browserHistory.listen(() => {
      if (ref.current) {
        ref.current.focus();
      }
    });
  }, []);

  return (
    <div ref={ref} tabIndex={-1}>
      {children}
    </div>
  );
};

RestoreFocus.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

/**
 * Provides the entry point to whole component hierarchy
 */
const RootProvider = props => {
  const { history, routes, routerKey, store, config } = props;

  uglyQuickFix.store = store;

  return (
    <RestoreFocus>
      <Provider store={store}>
        <ConfigProvider config={config}>
          <FeatureProvider>
            <Router
              history={history}
              key={routerKey}
              render={props => (
                <ReduxAsyncConnect {...props} helpers={{ store }} />
              )}
            >
              {routes}
            </Router>
          </FeatureProvider>
        </ConfigProvider>
      </Provider>
    </RestoreFocus>
  );
};

RootProvider.propTypes = {
  history: PropTypes.object.isRequired,
  routes: PropTypes.any.isRequired,
  routerKey: PropTypes.number,
  store: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};

export default RootProvider;
