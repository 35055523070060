import React from 'react';
import { useMedia } from 'react-use';
import { Heading as SuomifiHeading } from 'suomifi-ui-components';
import styles from './Heading.scss';

export const Heading = ({ ...passProps }) => {
  const isTabletOrMobile = useMedia('(max-width: 768px)');

  return (
    <SuomifiHeading
      smallScreen={isTabletOrMobile}
      {...passProps}
      className={styles.reset}
    />
  );
};
['h1', 'h2', 'h3', 'h4', 'h5'].forEach(variant => {
  Heading[variant] = ({ ...passProps }) => (
    <Heading variant={variant} {...passProps} />
  );
});
