import { createConsts as cc, createReducer, getJSON } from '../../../utils';

// ------------------------------------
// Constants & FSA actions
// ------------------------------------
export const [
  [LATEST_TOPICAL_REQUEST, requestLatestTopical],
  [LATEST_TOPICAL_SUCCESS, receiveLatestTopical],
  [LATEST_TOPICAL_FAILURE, latestTopicalError],
  [TOPICAL_ITEM_REQUEST, requesTopicalById],
  [TOPICAL_ITEM_SUCCESS, receiveTopicalById],
  [TOPICAL_ITEM_FAILURE, topicalByIdError]
] = cc([
  'LATEST_TOPICAL_REQUEST',
  'LATEST_TOPICAL_SUCCESS',
  'LATEST_TOPICAL_FAILURE',
  'TOPICAL_ITEM_REQUEST',
  'TOPICAL_ITEM_SUCCESS',
  'TOPICAL_ITEM_FAILURE'
]);

// ------------------------------------
// Async actions
// ------------------------------------
export function requestAsyncLatestTopical() {
  return async dispatch => {
    dispatch(requestLatestTopical());
    const results = await getJSON('/api/articles/topical');
    dispatch(receiveLatestTopical(results));
  };
}

export function requestAsyncTopicalById(id) {
  return async dispatch => {
    dispatch(requesTopicalById(id));
    const result = await getJSON(`/api/articles/${id}`);
    dispatch(receiveTopicalById(result));
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LATEST_TOPICAL_REQUEST]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [LATEST_TOPICAL_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    articles: payload
  }),
  [LATEST_TOPICAL_FAILURE]: (state, { error }) => ({
    ...state,
    isFetching: false,
    error
  }),
  [TOPICAL_ITEM_REQUEST]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [TOPICAL_ITEM_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    article: payload
  }),
  [TOPICAL_ITEM_FAILURE]: (state, { error }) => ({
    ...state,
    isFetching: false,
    error
  })
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = { articles: [], article: null, isFetching: false };
export default createReducer(ACTION_HANDLERS, initialState);
