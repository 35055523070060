import Steps from './Steps';

import { asyncConnect } from 'redux-connect';
import { setStep } from '../../../modules/IntermediaryForms';

const mapActionCreators = {
  setStep
};
const mapStateToProps = state => ({
  currentStep: state.intermediary.currentStep,
  nextFormState: state.intermediary.nextFormState,
  steps: state.intermediary.steps
});

export default asyncConnect([], mapStateToProps, mapActionCreators)(Steps);
