export default class WorkerBuilder {
  constructor(worker) {
    // Check if the Worker class exists
    if (typeof Worker !== 'undefined') {
      const code = worker.toString();
      const blob = new Blob([`(${code})()`]);
      return new Worker(URL.createObjectURL(blob));
    } else {
      // If the Worker class is not available, log an error message to the console
      console.error('Web Worker support is missing in this environment.');

      return null;
    }
  }
}
