import { createReducer, createAction as ca } from '../../../utils';
import { getSemaJSON } from 'utils/http';
import { findUserProfileForOrg } from '../../UserProfile/modules/api';
import { services } from 'constants/index';

const initialState = {
  isFetching: false,
  forms: {},
  popups: [],
  serviceInfoBoxes: {},
  serviceMessages: []
};

initialState.serviceInfoBoxes[services.EIDENTIFICATION] = false;
initialState[services.EAUTHORIZATION] = []; // Hack to get serviceMsg visible esuomi-migration
initialState[services.DATAEXCHANGELAYER] = []; // Hack to get serviceMsg visible esuomi-migration

const PX = 'DASHBOARD_';
const FORM_REQUEST = `${PX}FORM_REQUEST`;
const FORM_STORE_PROFILE = `${PX}FORM_STORE_PROFILE`;
const FORM_LOAD_ERROR = `${PX}FORM_LOAD_ERROR`;
const SUMMARY_REQUEST = `SUMMARY_REQUEST`;
const SUMMARY_LOAD_SUCCESS = `SUMMARY_LOAD_SUCCESS`;
const SUMMARY_LOAD_ERROR = `SUMMARY_LOAD_ERROR`;
const MESSAGES_STATUS_REQUEST = `MESSAGES_STATUS_REQUEST`;
const MESSAGES_STATUS_SUCCESS = `MESSAGES_STATUS_SUCCESS`;
const MESSAGES_STATUS_ERROR = `MESSAGES_STATUS_ERROR`;
const DATAEXCHANGE_STATUS_REQUEST = `DATAEXCHANGE_STATUS_REQUEST`;
const DATAEXCHANGE_STATUS_SUCCESS = `DATAEXCHANGE_STATUS_SUCCESS`;
const DATAEXCHANGE_STATUS_ERROR = `DATAEXCHANGE_STATUS_ERROR`;
const UPDATE_POPUPS = `${PX}UPDATE_POPUPS`;
const UPDATE_SERVICE_MESSAGES = `${PX}UPDATE_SERVICE_MESSAGES`;
const DISMISS_SERVICE_MESSAGES = `${PX}DISMISS_SERVICE_MESSAGES`;
const UPDATE_SERVICE_INFO = `${PX}UPDATE_SERVICE_INFO`;

const ACTION_HANDLERS = {
  [SUMMARY_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [FORM_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [FORM_STORE_PROFILE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    forms: payload
  }),
  [SUMMARY_LOAD_SUCCESS]: (state, { payload }) => {
    const resultState = {
      ...state,
      isFetching: false
    };
    resultState[services.EIDENTIFICATION] = payload;
    return resultState;
  },
  [MESSAGES_STATUS_SUCCESS]: (state, { payload }) => {
    const resultState = {
      ...state,
      isFetching: false
    };
    resultState[services.MESSAGES] = payload;
    return resultState;
  },
  [DATAEXCHANGE_STATUS_SUCCESS]: (state, { payload }) => {
    const resultState = {
      ...state,
      isFetching: false
    };
    resultState[services.DATAEXCHANGELAYER] = payload;
    return resultState;
  },
  [UPDATE_POPUPS]: (state, { payload }) => ({
    ...state,
    popups: payload
  }),
  [FORM_LOAD_ERROR]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    forms: {},
    error: payload.error
  }),
  [SUMMARY_LOAD_ERROR]: (state, { payload }) => {
    const resultState = {
      ...state,
      isFetching: false,
      error: payload.error
    };
    resultState[services.EIDENTIFICATION] = { error: payload };
    return resultState;
  },
  [UPDATE_SERVICE_MESSAGES]: (state, { payload }) => {
    return {
      ...state,
      serviceMessages: payload
    };
  },
  [DISMISS_SERVICE_MESSAGES]: (state, { payload }) => ({
    ...state,
    serviceMessages: state.serviceMessages.filter(({ id }) => id !== payload.id)
  }),
  [UPDATE_SERVICE_INFO]: (state, { payload }) => ({
    ...state,
    serviceInfoBoxes: payload
  })
};

const getApplicationStatus = async (dispatch, orgId, service) => {
  const codes =
    service === services.MESSAGES
      ? [
          MESSAGES_STATUS_REQUEST,
          MESSAGES_STATUS_SUCCESS,
          MESSAGES_STATUS_ERROR
        ]
      : [
          DATAEXCHANGE_STATUS_REQUEST,
          DATAEXCHANGE_STATUS_SUCCESS,
          DATAEXCHANGE_STATUS_ERROR
        ];

  dispatch(ca(codes[0])());
  try {
    const applicationStatus = await getSemaJSON(
      `/api/forms/organization/${orgId}/service/${service}/type/0/state/0`
    );
    dispatch(ca(codes[1])(applicationStatus));
    return applicationStatus;
  } catch (e) {
    dispatch(ca(codes[2])(e));
    return e;
  }
};

export const API = {
  getForms: (orgId, userId) => async dispatch => {
    dispatch(ca(FORM_REQUEST)());
    try {
      const profile = await findUserProfileForOrg(orgId, userId);
      const forms = profile.user.jsonObject.registrationForms;
      dispatch(ca(FORM_STORE_PROFILE)(forms));
      return profile.registrationForms;
    } catch (e) {
      console.log(e);
      dispatch(ca(FORM_LOAD_ERROR)(e));
      return e;
    }
  },
  getServiceSummary: orgId => async dispatch => {
    dispatch(ca(SUMMARY_REQUEST)());
    try {
      const serviceSummary = await getSemaJSON(
        `/api/identification-admin/service-summary?orgId=${orgId}`
      );
      dispatch(ca(SUMMARY_LOAD_SUCCESS)(serviceSummary));
      return serviceSummary;
    } catch (e) {
      dispatch(ca(SUMMARY_LOAD_ERROR)(e));
      return e;
    }
  },
  getMessagesApplicationStatus: orgId => async dispatch => {
    return getApplicationStatus(dispatch, orgId, services.MESSAGES);
  },
  getDataExchangeApplicationStatus: orgId => async dispatch => {
    return getApplicationStatus(dispatch, orgId, services.DATAEXCHANGELAYER);
  },
  updatePopUps: value => async dispatch => dispatch(ca(UPDATE_POPUPS)(value)),
  updateServiceMessages: value => async dispatch =>
    dispatch(ca(UPDATE_SERVICE_MESSAGES)(value)),
  dismissServiceMessage: message => async dispatch =>
    dispatch(ca(DISMISS_SERVICE_MESSAGES)(message)),
  updateServiceInfo: value => async dispatch =>
    dispatch(ca(UPDATE_SERVICE_INFO)(value))
};

export default createReducer(ACTION_HANDLERS, initialState);
