import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Block, Toast } from 'suomifi-ui-components';

export const SuccessMessage = () => (
  <Block my="s">
    <Toast>
      <FormattedMessage id="intermediary.common.savedAsDraft" />
    </Toast>
  </Block>
);
