import {
  postSemaJSON,
  getSemaJSON,
  deleteGeneric,
  putSemaJSON
} from 'utils/http';

// export async function findProfilesForOrg(orgId) {
//  return getSemaJSON(`/api/userprofile/${orgId}`);
// }

export function findUserProfileForOrg(orgId, userId) {
  return getSemaJSON(`/api/userprofile/${orgId}/${userId}`);
}

export function findOrganization(orgId) {
  return getSemaJSON(`/api/orgprofile/${orgId}`);
}

export function storeUserProfile(orgId, userId, profile) {
  const profileData = {
    organizationKey: orgId,
    userKey: userId,
    jsonObject: profile
  };
  return postSemaJSON(`/api/userprofile/${orgId}`, profileData);
}

export function updateUserProfile(orgId, userId, profile) {
  const profileData = {
    organizationKey: orgId,
    userKey: userId,
    jsonObject: profile
  };
  return putSemaJSON(`/api/userprofile/${orgId}/${userId}`, profileData);
}

export function deleteProfile(orgId, userId) {
  return deleteGeneric(`/api/userprofile/${orgId}/${userId}`);
}
