import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import { IntermediaryTextField } from '../../components';

export const PersonFields = ({ name }) => {
  return (
    <div className={styles.person__container}>
      <IntermediaryTextField
        name={`${name}.organizationName`}
        label={'intermediary.field.organizationName'}
        width={'80%'}
      />

      <IntermediaryTextField
        name={`${name}.businessId`}
        label={'intermediary.field.businessId'}
        width={'60%'}
      />

      <IntermediaryTextField
        name={`${name}.firstName`}
        label={'intermediary.field.firstName'}
        width={'80%'}
      />

      <IntermediaryTextField
        name={`${name}.lastName`}
        label={'intermediary.field.lastName'}
        width={'80%'}
      />

      <IntermediaryTextField
        name={`${name}.email`}
        label={'intermediary.field.email'}
        width={'80%'}
      />

      <IntermediaryTextField
        name={`${name}.phoneNumber`}
        label={'intermediary.field.phone'}
        width={'50%'}
        optional={true}
      />
    </div>
  );
};
PersonFields.propTypes = {
  name: PropTypes.string.isRequired
};
