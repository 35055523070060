import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Block,
  Heading,
  Paragraph,
  TextInput,
  Checkbox,
  RadioButton,
  RadioButtonGroup
} from 'suomifi-ui-components';
import { Field } from 'formik';
import { getStatus, getStatusText } from '../../../utils/utility';

const ConnectionMethods = ({ formik, serviceIndex, i18n }) => {
  const isSelected = methodName =>
    formik?.values?.formData?.serviceData?.services?.[serviceIndex][methodName];

  const showProductNameField = isSelected('connectionMethodProduct');
  const showSendReadConfirmationAck = [
    'connectionMethodProduct',
    'connectionMethodWeb'
  ].some(name => isSelected(name));

  const showNewMsgTarget = [
    'connectionMethodProduct',
    'connectionMethodWeb',
    'connectionMethodIpost',
    'connectionMethodEmail'
  ].some(name => isSelected(name));

  const getRadioValue = path => {
    const value =
      formik?.values?.formData?.serviceData?.services[serviceIndex][path];
    return value === null ? null : value ? '1' : '0'; // null, '1' or '0'
  };

  return (
    <React.Fragment>
      <Block my="m">
        <Heading variant="h3" className="borderless mb-2">
          <FormattedMessage id="forms.viestit_registration.page_messagesInfo.connectionMethodSection.title" />
        </Heading>
        <Paragraph>
          <FormattedMessage id="forms.viestit_registration.page_messagesInfo.connectionMethodSection.description" />
        </Paragraph>
      </Block>
      <Block>
        <Checkbox
          name={`formData.serviceData.services.${serviceIndex}.connectionMethodWeb`}
          checked={isSelected('connectionMethodWeb')}
          onClick={({ checkboxState }) =>
            formik.setFieldValue(
              `formData.serviceData.services[${serviceIndex}].connectionMethodWeb`,
              checkboxState
            )
          }
        >
          <FormattedMessage id="forms.viestit_registration.page_messagesInfo.connectionMethodSection.methodWeb" />
        </Checkbox>
        <Checkbox
          name={`formData.serviceData.services.${serviceIndex}.connectionMethodEmail`}
          checked={isSelected('connectionMethodEmail')}
          onClick={({ checkboxState }) =>
            formik.setFieldValue(
              `formData.serviceData.services[${serviceIndex}].connectionMethodEmail`,
              checkboxState
            )
          }
        >
          <FormattedMessage id="forms.viestit_registration.page_messagesInfo.connectionMethodSection.methodEmail" />
        </Checkbox>
        <Checkbox
          name={`formData.serviceData.services.${serviceIndex}.connectionMethodIpost`}
          checked={isSelected('connectionMethodIpost')}
          onClick={({ checkboxState }) =>
            formik.setFieldValue(
              `formData.serviceData.services[${serviceIndex}].connectionMethodIpost`,
              checkboxState
            )
          }
        >
          <FormattedMessage id="forms.viestit_registration.page_messagesInfo.connectionMethodSection.methodIpost" />
        </Checkbox>
        <Checkbox
          name={`formData.serviceData.services.${serviceIndex}.connectionMethodProduct`}
          checked={isSelected('connectionMethodProduct')}
          onClick={({ checkboxState }) =>
            formik.setFieldValue(
              `formData.serviceData.services[${serviceIndex}].connectionMethodProduct`,
              checkboxState
            )
          }
        >
          <FormattedMessage id="forms.viestit_registration.page_messagesInfo.connectionMethodSection.methodProduct" />
        </Checkbox>
        <Checkbox
          name={`formData.serviceData.services.${serviceIndex}.connectionMethodLater`}
          checked={isSelected('connectionMethodLater')}
          onClick={({ checkboxState }) =>
            formik.setFieldValue(
              `formData.serviceData.services[${serviceIndex}].connectionMethodLater`,
              checkboxState
            )
          }
        >
          <FormattedMessage id="forms.viestit_registration.page_messagesInfo.connectionMethodSection.methodLater" />
        </Checkbox>
      </Block>
      <Block mt="m">
        {showProductNameField ? (
          <Field
            as={TextInput}
            name={`formData.serviceData.services.${serviceIndex}.productName`}
            wrapperProps={{ style: { width: '80%', marginBottom: '1rem' } }}
            labelText={i18n(
              `forms.viestit_registration.page_messagesInfo.connectionMethodSection.productName`
            )}
            onChange={value =>
              formik.setFieldValue(
                `formData.serviceData.services[${serviceIndex}].productName`,
                value
              )
            }
            value={
              formik?.values?.formData?.serviceData?.services[serviceIndex]
                .productName
            }
            status={getStatus(
              formik,
              'formData.serviceData.services',
              serviceIndex,
              'productName'
            )}
            statusText={getStatusText(
              i18n,
              formik,
              'formData.serviceData.services',
              serviceIndex,
              'productName'
            )}
          />
        ) : null}
        {showSendReadConfirmationAck ? (
          <RadioButtonGroup
            className="my-4"
            labelText={i18n(
              'forms.viestit_registration.page_messagesInfo.usageSettingsSection.sendReadConfirmationAck'
            )}
            name={`formData.serviceData.services.${serviceIndex}.sendReadConfirmationAck`}
            value={getRadioValue('sendReadConfirmationAck')}
            onChange={value =>
              formik.setFieldValue(
                `formData.serviceData.services[${serviceIndex}].sendReadConfirmationAck`,
                parseInt(value, 10)
              )
            }
          >
            <RadioButton value="1">{i18n('general.form.yes')}</RadioButton>
            <RadioButton value="0">{i18n('general.form.no')}</RadioButton>
          </RadioButtonGroup>
        ) : null}
        {showNewMsgTarget ? (
          <RadioButtonGroup
            labelText={i18n(
              'forms.viestit_registration.page_messagesInfo.connectionMethodSection.newMsgTarget'
            )}
            name={`formData.serviceData.services.${serviceIndex}.newMsgTarget`}
            value={getRadioValue('newMsgTarget')}
            onChange={value =>
              formik.setFieldValue(
                `formData.serviceData.services[${serviceIndex}].newMsgTarget`,
                parseInt(value, 10)
              )
            }
          >
            <RadioButton value="1">{i18n('general.form.yes')}</RadioButton>
            <RadioButton value="0">{i18n('general.form.no')}</RadioButton>
          </RadioButtonGroup>
        ) : null}
      </Block>
    </React.Fragment>
  );
};

ConnectionMethods.propTypes = {
  formik: PropTypes.object.isRequired,
  serviceIndex: PropTypes.number.isRequired,
  i18n: PropTypes.func.isRequired
};

export default ConnectionMethods;
