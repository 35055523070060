import * as yup from 'yup';

export const formTitle = 'intermediary.preview.approveRules';

export const schema = yup.object({
  acceptanceOfTerms: yup.object({
    acceptanceOfVRKPermit: yup
      .boolean()
      .oneOf([true], 'intermediary.validation.termsNotAccepted')
      .default(false)
      .label(formTitle)
  })
});
