import { connect } from 'react-redux';
import { actions as notificationActions } from 'redux-notifications';
import view from '../components/EnvironmentsView';
import {
  fetchEnvironmentsByProviderId,
  selectEnvironment,
  toggleEnvironmentInfo,
  toggleEnvironmentStatusFilter
} from '../modules';

const { notifSend } = notificationActions;

const mapActionCreators = {
  fetchEnvironmentsByProviderId,
  notifSend,
  selectEnvironment,
  toggleEnvironmentInfo,
  toggleEnvironmentStatusFilter
};

const mapStateToProps = state => ({
  currentProvider: state.idAdmin.currentProvider,
  environments: state.idAdmin.environments,
  environmentsListDirty: state.idAdmin.environmentsListDirty,
  environmentStatusFilters: state.idAdmin.environmentStatusFilters,
  isFetching: state.idAdmin.isFetching,
  isInfoVisible: state.idAdmin.envInfoVisible
});

export default connect(mapStateToProps, mapActionCreators)(view);
