import React from 'react';
import Proptypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Heading,
  Expander,
  ExpanderTitleButton,
  ExpanderContent
} from 'suomifi-ui-components';
import List from '../components/List';
import { InformationManagementRequirements } from './InformationManagementRequirements';

export const DataProtectionPreview = ({
  defaultOpen,
  formData,
  i18n,
  isEIdentificationForm = false
}) => {
  const getRadioValue = path => {
    const value = formData?.statementOnInformationSecurity?.[path];

    if (value === null || value === undefined) {
      return null;
    }

    return value;
  };

  const getTextualValue = path => {
    const val = getRadioValue(path);
    switch (val) {
      case true: {
        return i18n('intermediary.common.yes');
      }
      case false: {
        return i18n('intermediary.common.no');
      }
      default: {
        return i18n('intermediary.preview.notKnown');
      }
    }
  };

  const followInformationManagementAct = getRadioValue(
    'followInformationManagementAct'
  );

  const coveredByInformationManagementAct = getRadioValue(
    'coveredByInformationManagementAct'
  );

  const hasInformationManagement =
    coveredByInformationManagementAct === true &&
    followInformationManagementAct === true;

  const hasNoInformationManagement =
    coveredByInformationManagementAct === false ||
    followInformationManagementAct === false;

  return (
    <Expander defaultOpen={defaultOpen}>
      <ExpanderTitleButton>
        <FormattedMessage id="intermediary.dataProtection.formTitle" />
      </ExpanderTitleButton>
      <ExpanderContent>
        <Heading variant="h3" className="mb-2 mt-4">
          <FormattedMessage id="intermediary.dataProtection.subtitle" />
        </Heading>
        <List>
          <List.Item
            title={i18n(
              'intermediary.dataProtection.followInformationManagementActLabel'
            )}
            value={getTextualValue('followInformationManagementAct')}
          />

          {followInformationManagementAct === true && (
            <List.Item
              title={i18n('intermediary.preview.organisationDataProtection')}
              value={getTextualValue('coveredByInformationManagementAct')}
            />
          )}

          {hasNoInformationManagement && (
            <>
              <Heading variant="h3" className="mb-2 mt-4">
                <FormattedMessage id="intermediary.dataProtection.orgnization_resources_title" />
              </Heading>

              <InformationManagementRequirements
                i18n={i18n}
                isEIdentificationForm={isEIdentificationForm}
                formData={formData}
              />
              {(hasInformationManagement || hasNoInformationManagement) && (
                <>
                  <List.Item
                    title={i18n(
                      'intermediary.dataProtection.desc_vtjDataHandlingAbroadEu'
                    )}
                    value={getTextualValue('vtjDataHandlingAbroadEu')}
                  />
                  {formData?.statementOnInformationSecurity
                    ?.vtjDataHandlingAbroadEu && (
                    <List.SubItem
                      title={i18n(
                        'intermediary.dataProtection.yes_vtjDataHandlingAbroadEuInfo'
                      )}
                      value={
                        formData?.statementOnInformationSecurity
                          ?.vtjDataHandlingAbroadEuInfo // 12
                      }
                      visible={
                        formData?.statementOnInformationSecurity
                          ?.vtjDataHandlingAbroadEu
                      }
                    />
                  )}
                </>
              )}

              {hasNoInformationManagement && (
                <>
                  <List.Item
                    title={i18n(
                      'intermediary.dataProtection.desc_serviceDataSavedToRegister'
                    )}
                    value={getTextualValue('serviceDataSavedToRegister')}
                  />
                  {formData?.statementOnInformationSecurity
                    ?.serviceDataSavedToRegister && (
                    <List.SubItem
                      title={i18n(
                        'intermediary.dataProtection.yes_serviceDataSavedToRegisterInfo'
                      )}
                      value={
                        formData?.statementOnInformationSecurity
                          ?.serviceDataSavedToRegisterInfo // 12
                      }
                      visible={
                        formData?.statementOnInformationSecurity
                          ?.serviceDataSavedToRegister
                      }
                    />
                  )}
                </>
              )}

              {isEIdentificationForm && hasNoInformationManagement && (
                <>
                  <List.Item
                    title={i18n(
                      'intermediary.dataProtection.desc_personnelDataHandlingOutsideOrganisation'
                    )}
                    value={getTextualValue(
                      'personnelDataHandlingOutsideOrganisation'
                    )}
                  />
                  {formData?.statementOnInformationSecurity
                    ?.personnelDataHandlingOutsideOrganisation && (
                    <List.SubItem
                      title={i18n(
                        'intermediary.dataProtection.yes_personnelDataHandlingOutsideOrganisationInfo'
                      )}
                      value={
                        formData?.statementOnInformationSecurity
                          ?.personnelDataHandlingOutsideOrganisationInfo // 12
                      }
                      visible={
                        formData?.statementOnInformationSecurity
                          ?.personnelDataHandlingOutsideOrganisation
                      }
                    />
                  )}
                </>
              )}
            </>
          )}

          {(hasInformationManagement || hasNoInformationManagement) && (
            <>
              <List.Item
                title={i18n('intermediary.preview.thirdParty')}
                value={getTextualValue('discloseInformationToThirdParty')}
              />
              {formData?.statementOnInformationSecurity
                ?.discloseInformationToThirdParty && (
                <List.SubItem
                  title={i18n('intermediary.preview.whichThirdParty')}
                  value={
                    formData?.statementOnInformationSecurity
                      ?.discloseInformationToThirdPartyInfo // 4
                  }
                  visible={
                    formData?.statementOnInformationSecurity
                      ?.discloseInformationToThirdParty
                  }
                />
              )}
            </>
          )}

          {!isEIdentificationForm && hasNoInformationManagement && (
            <>
              <List.Item
                title={i18n(
                  'intermediary.dataProtection.desc_environmentHandlingOutsideEU'
                )}
                value={getTextualValue('environmentHandlingOutsideEU')}
              />

              <List.Item
                title={i18n(
                  'intermediary.dataProtection.desc_dataTransferOutsideEU'
                )}
                value={getTextualValue('dataTransferOutsideEU')}
              />
            </>
          )}
        </List>
        {((isEIdentificationForm && hasNoInformationManagement) ||
          hasInformationManagement) && (
          <>
            <Heading variant="h3" className="mb-2 mt-4">
              <FormattedMessage id="intermediary.preview.logRequirements" />
            </Heading>
            <List>
              <List.Item
                title={i18n('intermediary.preview.multipleOrganisations')}
                value={
                  formData?.statementOnInformationSecurity
                    ?.loggingReguirementsMet
                    ? i18n('intermediary.common.yes')
                    : i18n('intermediary.common.no')
                }
              />
            </List>

            <FormattedMessage
              id={`intermediary.dataProtection.${
                isEIdentificationForm
                  ? 'desc_logging_reguirements'
                  : 'followingsMet'
              }`}
            />
            <ul>
              <li>{i18n(`intermediary.dataProtection.desc_auditLogs`)}</li>
              <li>
                {i18n(
                  `intermediary.dataProtection.desc_logsArchivedAndDestroyed`
                )}
              </li>
              <li>
                {i18n(
                  `intermediary.dataProtection.desc_logsAreProtectedFromChanges`
                )}
              </li>
              <li>
                {i18n(`intermediary.dataProtection.desc_logsAreRestricted`)}
              </li>
              <li>
                {i18n(`intermediary.dataProtection.desc_regularChecksForLogs`)}
              </li>
            </ul>
          </>
        )}
      </ExpanderContent>
    </Expander>
  );
};

DataProtectionPreview.propTypes = {
  defaultOpen: Proptypes.bool,
  i18n: Proptypes.func,
  formData: Proptypes.object,
  isEIdentificationForm: Proptypes.bool
};
