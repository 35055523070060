import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone14';
import styles from './styles.scss';
import {
  Paragraph,
  Text,
  Button,
  Block,
  LoadingSpinner,
  IconPlus
} from 'suomifi-ui-components';
import { AcceptedFiles, RejectedFiles, FileUploadError } from '.';
import { createBody } from '../../utils';
import { maxFiles, maxSize } from '../../constants';

export const Attachments = ({
  value = [],
  onChange,
  i18n,
  mandatory,
  mandatoryDescription,
  sendAttachment,
  deleteAttachment
}) => {
  const [hasError, setError] = useState(false); // ['send', 'remove', false]
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  const hasTooManyFiles = (value, files) =>
    Object.keys(value).length + files.length > maxFiles;

  const removeRejectedFile = name => {
    const filtered = rejectedFiles.filter(i => i.file?.name !== name);
    setRejectedFiles(filtered);
  };

  const onDrop = async (acceptedFiles, fileRejections) => {
    setIsBusy(true);
    setRejectedFiles([]);

    // Handle the situation when there are too many files total (already uploaded + new ones)
    if (hasTooManyFiles(value, acceptedFiles)) {
      setError('maxFiles');
      setIsBusy(false);
      return;
    }

    // Send accepted files to the server
    if (acceptedFiles && acceptedFiles.length > 0) {
      try {
        const res = await sendAttachment(createBody(acceptedFiles));
        if (res.httpCode === 200) {
          setError(false);
          const { data } = res;
          const files = [];

          Object.keys(data).forEach(key => {
            files.push({
              tempFileName: data[key].fileKey,
              originalFileName: data[key].fileName,
              mimeType: '',
              customName: ''
            });
          });

          // Set files to formik state with previous files
          onChange(value.concat(files));
        }
      } catch (e) {
        setError('send');
        console.log(e);
      }
    }

    // Set rejected files to state
    if (fileRejections && fileRejections.length > 0) {
      setRejectedFiles(fileRejections);
    }
    setIsBusy(false);
  };

  const removeFile = async key => {
    if (!isBusy) {
      setIsBusy(true);
      try {
        const returnValue = await deleteAttachment(key);

        if (returnValue.httpCode === 204) {
          const newValue = value.filter(file => file.tempFileName !== key);
          onChange(newValue);
        } else {
          setError('remove');
        }
      } catch (err) {
        console.error(err);
        setError('remove');
      }

      setIsBusy(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    onDrop,
    onError: e => console.log({ e }),
    maxFiles,
    maxSize
  });

  return (
    <div className={styles.attachmentContainer} {...getRootProps()}>
      <input {...getInputProps()} />
      {isBusy ? (
        <Block mb="m">
          <LoadingSpinner
            status="loading"
            variant="small"
            textAlign="right"
            text={`${i18n('general.fileLoader.loading')}...`}
          />
        </Block>
      ) : null}

      {isDragActive ? (
        <Text variant="bold">
          {i18n('intermediary.misc.attachments.dropHere')}
        </Text>
      ) : (
        <div>
          <Text variant="bold">
            {i18n('intermediary.misc.attachments.attachOrDrag')}
            {mandatory && i18n('intermediary.misc.attachments.mandatory')}
          </Text>
          {mandatoryDescription && (
            <Paragraph>{mandatoryDescription}</Paragraph>
          )}
          <Paragraph>
            {i18n('intermediary.misc.attachments.instructions')}
          </Paragraph>

          <AcceptedFiles files={value} removeFile={removeFile} />
          <RejectedFiles
            files={rejectedFiles}
            removeRejectedFile={removeRejectedFile}
          />

          <Button
            className={styles.addButton}
            variant="secondary"
            onClick={open}
            icon={<IconPlus />}
          >
            {i18n('intermediary.misc.attachments.add')}
          </Button>
        </div>
      )}
      {hasError && (
        <Block mt="m">
          <FileUploadError error={hasError} setError={setError} i18n={i18n} />
        </Block>
      )}
    </div>
  );
};

Attachments.propTypes = {
  i18n: PropTypes.func.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func,
  mandatory: PropTypes.bool,
  mandatoryDescription: PropTypes.string,
  sendAttachment: PropTypes.func,
  deleteAttachment: PropTypes.func
};
