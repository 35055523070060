import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './styles.scss';
import {
  Block,
  Button,
  Heading,
  Paragraph,
  ExpanderGroup,
  IconPlus
} from 'suomifi-ui-components';
import { FieldArray } from 'formik';
import { Services } from './Services';
import { serviceSchema, formTitle } from './schema';

export const Form = ({ sendAttachment, deleteAttachment }, { intl }) => {
  const i18n = id => intl.formatMessage({ id });
  const helperRef = React.useRef(null);
  return (
    <div className={styles.container}>
      <Block>
        <Heading variant="h2" className="borderless mb-2">
          <FormattedMessage id={formTitle} />
        </Heading>
        <Paragraph>
          <FormattedMessage id="intermediary.serviceInfo.description" />
        </Paragraph>
      </Block>
      <Block>
        <ExpanderGroup showToggleAllButton={false}>
          <FieldArray
            name="formData.serviceData.services"
            render={helpers => {
              helperRef.current = helpers;
              return (
                <Services
                  remove={helpers.remove}
                  i18n={i18n}
                  sendAttachment={sendAttachment}
                  deleteAttachment={deleteAttachment}
                />
              );
            }}
          />
        </ExpanderGroup>
      </Block>
      <Block>
        <Button
          variant="secondary"
          icon={<IconPlus />}
          onClick={() => helperRef?.current?.push(serviceSchema.cast({}))}
        >
          <FormattedMessage id="intermediary.serviceInfo.addService" />
        </Button>
      </Block>
    </div>
  );
};
Form.propTypes = {
  sendAttachment: PropTypes.func,
  deleteAttachment: PropTypes.func
};

Form.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default Form;
