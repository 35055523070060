import React from 'react';
import styles from './styles.scss';
import { Block, Heading, Paragraph } from 'suomifi-ui-components';
import { getStatus, getStatusText } from '../../utils';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { parseISO, isValid } from 'date-fns';
import DatePicker from 'components/DatePicker';
import { IntermediaryTextField, CommonRadioGroup } from '../../components';

const StyledDatePickerContainer = styled.div`
  width: 400px;
`;
const getTranslationKey = id =>
  `forms.servicecatalogue.page_serviceCatalogueInfo.inApi.${id}`;

export const Form = ({ formik }, { intl }) => {
  const i18n = id => intl.formatMessage({ id });

  const showSubcontractorFields =
    formik?.values?.formData?.serviceData?.organizationUsesSubcontractor;

  const handleDateChange = e => {
    if (isValid(e.date)) {
      formik.setFieldValue(
        'formData.serviceData.usageDate',
        e.date.toISOString()
      );
    }
  };

  return (
    <div className={styles.formContainer}>
      <Block>
        <Heading variant="h2" className="borderless mb-2">
          {i18n(getTranslationKey('title'))}
        </Heading>
        <Paragraph>{i18n(getTranslationKey('description'))}</Paragraph>
      </Block>
      <CommonRadioGroup
        label={getTranslationKey('inApiUsed')}
        name="inApiUsed"
      />
      {formik?.values?.formData?.serviceData?.inApiUsed ? (
        <>
          {[
            'inApiContentUpdatedRegularly',
            'inApiMaintainedProperly',
            'twoAdminsInvited'
          ].map(prop => (
            <CommonRadioGroup
              key={prop}
              label={getTranslationKey(prop)}
              name={prop}
            />
          ))}

          <hr className={styles.spacer} />

          <Block>
            <Heading variant="h3" className="borderless mb-2">
              {i18n(getTranslationKey('integratedSystemTitle'))}
            </Heading>
            <Paragraph>
              {i18n(getTranslationKey('integratedSystemDescription'))}
            </Paragraph>
          </Block>

          <IntermediaryTextField
            name={'formData.serviceData.systemName'}
            label={'intermediary.inapi.systemNameInputLabel'}
            width={'80%'}
          />

          <StyledDatePickerContainer>
            <DatePicker
              id="usageDate"
              label={i18n(getTranslationKey('usageDate'))}
              name="formData.serviceData.usageDate"
              value={parseISO(formik?.values?.formData?.serviceData?.usageDate)}
              onChange={handleDateChange}
              status={getStatus(formik, 'formData.serviceData', 'usageDate')}
              statusText={getStatusText(
                i18n,
                formik,
                'formData.serviceData',
                'usageDate'
              )}
            />
          </StyledDatePickerContainer>
          <hr className={styles.spacer} />
          <Block>
            <Heading variant="h3" className="borderless mb-2">
              {i18n(getTranslationKey('subcontractorTitle'))}
            </Heading>
            <CommonRadioGroup
              label={getTranslationKey('organizationUsesSubcontractor')}
              name="organizationUsesSubcontractor"
            />
          </Block>
          {showSubcontractorFields ? (
            <>
              <IntermediaryTextField
                name={'formData.serviceData.apiUserName'}
                label={'intermediary.inapi.apiUserNameLabel'}
                width={'80%'}
              />
              <Block>
                <Heading variant="h4" className="borderless mb-2">
                  {i18n(getTranslationKey('integratedSystemTitle'))}
                </Heading>
                <Paragraph>
                  {i18n(getTranslationKey('integratedSystemDescription'))}
                </Paragraph>
              </Block>
              {[
                'organizationName',
                'businessId',
                'firstName',
                'lastName',
                'email'
              ].map(prop => (
                <IntermediaryTextField
                  key={prop}
                  type={prop === 'email' ? 'email' : 'text'}
                  name={`formData.serviceData.apiAccessContactInformation.apiAccessContactPerson.${prop}`}
                  label={getTranslationKey(prop)}
                  width={'80%'}
                  noMargin
                />
              ))}
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
Form.propTypes = {
  formik: PropTypes.object
};

Form.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default Form;
