import ExpiredPasswordView from '../components/ExpiredPasswordView';
import connectForm from 'utils/connectForm';
import { validateExpired } from '../validate';
import { resetExpiredPassword } from '../../../modules/auth';
import { actions as notificationActions } from '../../../components/Notifs';

export default connectForm(ExpiredPasswordView, {
  form: 'changeExpiredPwdForm',
  validate: validateExpired,
  mapStateToProps: state => ({}),
  mapActionCreators: {
    resetExpiredPassword,
    notifSend: notificationActions.notifSend
  }
});
