import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { browserHistory } from 'react-router';
import { renderTextField } from 'utils/fieldWrappers';
import { Button } from 'sema-ui-components';

export class NewApiUserView extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    notifSend: PropTypes.func,
    submitting: PropTypes.bool,
    resetPassword: PropTypes.func
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  formatMessage = id => this.context.intl.formatMessage({ id });

  signInStrong = () => {
    browserHistory.push('/login');
  };

  getErrorMsg = description => {
    return {
      title: 'Api Käyttäjä',
      description,
      type: 'fail',
      dismissAfter: 5000
    };
  };

  doHandleSubmit = values => {
    const { code, email } = this.props.location.query;
    const params = { ...values, code, email };

    this.props
      .resetPassword(params)
      .then(data => {
        if (data && data.error) {
          this.props.notifSend(
            this.getErrorMsg('API tunnuksen luonti epäonnistui')
          );
        } else {
          browserHistory.push('/fi/');
        }
      })
      .catch(err => {
        console.error(err);
        this.props.notifSend(
          this.getErrorMsg('API tunnuksen luonti epäonnistui')
        );
      });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    const {
      intl: { locale }
    } = this.context;

    return (
      <section>
        <h1>
          <FormattedMessage id="paha_admin_new_api_user_title" />
        </h1>
        <div>
          <form onSubmit={handleSubmit(this.doHandleSubmit)}>
            <div className="form-group">
              <Field
                name="loginName"
                type="text"
                component={renderTextField}
                size="w280"
                disabled={submitting}
                placeholder="Api tunnus"
              />
            </div>
            <div className="form-group">
              <Field
                name="sendPasswordTo"
                type="text"
                component={renderTextField}
                size="w280"
                disabled={submitting}
                placeholder="Lähetä salasana tähän sähköpostiin"
              />
            </div>
            <div className="form-group">
              <div>
                <div>
                  <Button small type="submit" disabled={submitting}>
                    <FormattedMessage id="general.form.submit" />
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default NewApiUserView;
