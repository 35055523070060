import React from 'react';
import styles from './styles.scss';
import { IntermediaryTextField } from '../../components';

export const InputFields = () => (
  <div className={styles.fields}>
    <IntermediaryTextField
      name={'formData.customerOrganization.altBusinessId'}
      label={'intermediary.organisationInfo.altBusinessId'}
      width="66.66%"
    />

    <IntermediaryTextField
      name={'formData.customerOrganization.organizationName'}
      label={'intermediary.organisationInfo.organisationName'}
      width="66.66%"
    />
    <IntermediaryTextField
      name={'formData.customerOrganization.address'}
      label={'intermediary.organisationInfo.address'}
      width="66.66%"
    />
    <IntermediaryTextField
      name={'formData.customerOrganization.postalCode'}
      label={'intermediary.organisationInfo.postalCode'}
      width="66.66%"
    />

    <IntermediaryTextField
      name={'formData.customerOrganization.postOffice'}
      label={'intermediary.organisationInfo.postOffice'}
      width="66.66%"
    />
  </div>
);
