import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import styles from './SearchView.scss';
import { ResultItem } from './ResultItem';

export const getArticleUrl = (item, lang, id) => {
  if (item.atype.slug === 'artikkeli' || item.atype.slug === 'uutinen') {
    return `/${lang}/tuki/artikkelit/${id}`;
  } else if (item.atype.slug === 'ukk') {
    return `/${lang}/tuki/ukk/${id}`;
  } else if (item.atype.slug === 'sanasto') {
    return `/${lang}/tuki/sanasto`;
  }

  return '';
};

export const getTitleLink = (type, item, lang, id) => {
  switch (type) {
    case 'document':
      return (
        <a
          target="_blank"
          href={`/storage/${item.file.bucket}/${item.file.filename}`}
          rel="noreferrer"
        >
          <h4>{item.title[lang]}</h4>
        </a>
      );
    case 'article':
      return (
        <Link to={getArticleUrl(item, lang, id)}>
          <h4>{item.title[lang]}</h4>
        </Link>
      );
    default:
      return (
        <Link to={`/${lang}/sivut/${item.url}`}>
          <h4>{item.title[lang]}</h4>
        </Link>
      );
  }
};

export const getInfo = (type, item) => {
  if (type) {
    switch (type) {
      case 'article':
        return <FormattedMessage id={`search.resulttype_${item.atype.slug}`} />;
      default:
        return <FormattedMessage id={`search.resulttype_${type}`} />;
    }
  }

  return null;
};

export const getDescription = (type, item, lang) => {
  if (type) {
    switch (type) {
      case 'page':
        return (
          <div
            className={styles.resultContent}
            dangerouslySetInnerHTML={{
              __html: getBriefFromPage(item, lang).replace(/(<([^>]+)>)/gi, '')
            }}
          />
        );

      case 'article':
        return (
          <div
            className={styles.resultContent}
            dangerouslySetInnerHTML={{
              __html: item.brief[lang].replace(/(<([^>]+)>)/gi, '')
            }}
          />
        );

      case 'document':
        return (
          <div className={styles.resultContent}>
            {item.description[lang] && item.description[lang] !== 'undefined'
              ? item.description[lang]
              : ''}
          </div>
        );
    }
  }

  return null;
};

export const getUpdatedAtFromPage = page => {
  let finalDate = null;

  page.placeholders.forEach(ph => {
    if (!finalDate || ph.updatedAt > finalDate) {
      finalDate = ph.updatedAt;
    }

    ph.modules.forEach(md => {
      if (md.updatedAt > finalDate) {
        finalDate = md.updatedAt;
      }

      md.contentItems.forEach(ci => {
        if (ci.updatedAt > finalDate) {
          finalDate = ci.updatedAt;
        }
      });
    });
  });

  return finalDate;
};

export const getBriefFromPage = (page, lang) => {
  if (
    page.placeholders[0] &&
    page.placeholders[0].modules[0] &&
    page.placeholders[0].modules[0].contentItems[0]
  ) {
    return page.placeholders[0].modules[0].contentItems[0].content[lang].slice(
      0,
      200
    );
  }

  return '';
};

export const getResultItems = (items, lang) => {
  return items
    ? items.map(({ id, type, source }) => (
        <ResultItem key={id} lang={lang} type={type} id={id} item={source} />
      ))
    : [];
};
