import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux';
import { reducer as reduxAsyncConnect } from 'redux-connect';
import { reducer as formReducer } from 'redux-form';
import { reducer as notifReducer } from 'redux-notifications';
import feedbackReducer from '../components/FeedbackForm/modules';
import modalReducer from '../components/ReduxModal/modules';
import searchReducer from '../routes/Search/modules';
import businessIdReducer from '../routes/BusinessIdSearch/modules';
import notificationsReducer from '../routes/Notifications/modules';
import manageNotificationsReducer from '../routes/Admin/modules/notifications';
import authReducer from '../modules/auth';
import translationsReducer from '../modules/translations';
import uiReducer from '../modules/ui';
import errorReducer from '../modules/error';
import sideNavReducer from '../components/SideNav/modules';
import topicalReducer from '../routes/Topical/modules/module_CMS.js';
import chatReducer from '../routes/Chat/modules';
import userProfileReducer from '../routes/UserProfile/modules';
import orgProfileReducer from '../routes/Organisation/modules';
import userRegistrationReducer from '../routes/UserRegistration/modules';
import dashboardReducer from '../routes/Dashboard/modules';
import adminReducer from '../routes/Admin/modules/adminModule';
import statisticReducer from '../routes/Statistics/modules';
import intermediaryReducer from '../routes/IntermediaryForms/modules/IntermediaryForms';
import { reducerPluginForFormDestroy } from '../routes/FormsDigi/modules/module_Forms';
import { featureReducer } from '../modules/features';
import module_OwnForms from '../routes/IdentificationAdmin/modules/module_OwnForms';

export const reducers = asyncReducers =>
  combineReducers({
    // Add sync reducers here
    feedback: feedbackReducer,
    userProfile: userProfileReducer,
    orgProfile: orgProfileReducer,
    form: formReducer.plugin(reducerPluginForFormDestroy),
    notifs: notifReducer,
    modal: modalReducer,
    error: errorReducer,
    reduxAsyncConnect,
    router,
    search: searchReducer,
    businessId: businessIdReducer,
    ownFormsView: module_OwnForms,
    notifications: notificationsReducer,
    manageNotifications: manageNotificationsReducer,
    topical: topicalReducer,
    auth: authReducer,
    translations: translationsReducer,
    ui: uiReducer,
    chat: chatReducer,
    sideNav: sideNavReducer,
    statistics: statisticReducer,
    userRegistration: userRegistrationReducer,
    dashboard: dashboardReducer,
    admin: adminReducer,
    features: featureReducer,
    intermediary: intermediaryReducer,
    ...asyncReducers
  });

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(reducers(store.asyncReducers));
};

export const injectReducerIf = (store, { key, reducer }) => {
  if (!store.asyncReducers[key]) {
    store.asyncReducers[key] = reducer;
    store.replaceReducer(reducers(store.asyncReducers));
  }
};

export const injectReducers = (store, reducerList) => {
  reducerList.forEach(({ key, reducer }) => {
    store.asyncReducers[key] = reducer;
  });
  store.replaceReducer(reducers(store.asyncReducers));
};

export default reducers;
