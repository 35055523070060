/* eslint-disable react/prop-types */
import FileUpload from 'components/FileUpload';
import XMLField from 'components/XMLField';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactSelect from 'react-select';
import { Field } from 'redux-form';
import styles from 'routes/FormsDigi/styles/RegistrationFormStyles.scss';
import {
  Checkbox,
  DropdownText,
  InfoBox,
  RadioGroup,
  Select,
  TextField
} from 'sema-ui-components';
import {
  Button,
  IconClose,
  IconPlus,
  IconSearch,
  Paragraph,
  SingleSelect,
  Text,
  Textarea,
  TextInput
} from 'suomifi-ui-components';
import CalendarInput from '../components/CalendarInput';

/**
 * @deprecated use renderTextInput instead
 */
export function renderTextField({
  input,
  meta: { touched, error },
  ...custom
}) {
  return (
    <TextField
      error={touched && error && error.length > 0}
      errorMessage={
        touched && error && error.length > 0 ? (
          <FormattedMessage id={error} />
        ) : (
          ''
        )
      }
      {...input}
      {...custom}
    />
  );
}

export function renderXMLField({ input, meta: { touched, error }, ...custom }) {
  return (
    <XMLField
      error={touched && error && error.length > 0}
      errorMessage={
        touched && error && error.length > 0 ? (
          <FormattedMessage id={error} />
        ) : (
          ''
        )
      }
      {...input}
      {...custom}
    />
  );
}

export function renderCheckbox({ input, ...custom }) {
  return <Checkbox checked={!!input.value} {...input} {...custom} />;
}

/**
 * @deprecated use renderText instead
 */
export const renderStaticText = ({ input, className, size, ...custom }) => {
  const widthStyle = size ? { maxWidth: `${size.substring(1)}px` } : null;
  return (
    <p style={widthStyle} className={className}>
      {input.value ? input.value : '-'}
    </p>
  );
};

export function renderInfobox({ input, ...custom }) {
  return (
    <InfoBox
      isVisible={!!input.value}
      changeVisibility={value => input.onChange(value)}
      {...input}
      {...custom}
    />
  );
}

export function renderDropdownText({ input, ...custom }) {
  return (
    <DropdownText
      isOpen={!!input.value}
      changeState={value => input.onChange(value)}
      {...input}
      {...custom}
    />
  );
}

export function renderRadioGroup({ input, ...custom }) {
  return <RadioGroup value={input.value} {...input} {...custom} />;
}

export function renderList({
  input,
  elementRender,
  addButtonLabel,
  addButtonClass,
  className,
  disabled,
  defaultValues,
  disableAddMore = false
}) {
  const elementKeys = Object.keys(input.value);
  const elementCount = elementKeys.length;
  const nextKey = elementCount;

  const getDelete = id => () => {
    const newValue = {};
    Object.keys(input.value)
      .filter(key => key !== id)
      .forEach((key, index) => {
        newValue[index] = { ...input.value[key], id: index };
      });
    input.onChange(newValue);
  };

  const addMore = () => {
    input.onChange({
      ...input.value,
      [nextKey]: { id: nextKey, ...(defaultValues || {}) }
    });
  };

  return (
    <section className={className}>
      {elementKeys.map((key, index) =>
        elementRender({
          id: key,
          index: index + 1,
          isLast: index + 1 === elementCount,
          onDelete: getDelete(key),
          disabled
        })
      )}

      {!disabled && !disableAddMore && (
        <Button type="button" className={addButtonClass} link onClick={addMore}>
          {addButtonLabel}
        </Button>
      )}
    </section>
  );
}

// {onSelection, renderContent(value, onChange}

export function renderRadioPlusContent({ input, ...custom }) {
  const contents = custom.contents || [];

  const onExtraValueChange = (id, value) =>
    input.onChange({
      ...input.value,
      additionalValues: {
        ...input.value.additionalValues,
        [id]: value
      }
    });

  const renderExtraContent = value => {
    const selection = value && value.selection;
    const content = contents.find(content => content.onSelection === selection);
    const currentExtraValue =
      value.additionalValues && value.additionalValues[selection];
    return (
      content &&
      content.renderContent(currentExtraValue, extraValue =>
        onExtraValueChange(selection, extraValue)
      )
    );
  };

  return (
    <div>
      <RadioGroup
        value={input.value && input.value.selection}
        onChange={value => input.onChange({ ...input.value, selection: value })}
        {...custom}
      />
      {input.value && renderExtraContent(input.value)}
    </div>
  );
}

export function renderSelect(props) {
  return (
    <Select
      {...props}
      value={props.input.value}
      onChange={option => {
        props.input.onChange(option.value);
      }}
      onBlur={() => props.input.onBlur(props.input.value)}
      options={props.options}
    />
  );
}

export function renderSingleSelect(props) {
  return (
    <SingleSelect
      {...props}
      selectedItem={props.options.find(o => o.value === props.input.value)}
      onItemSelect={option => {
        props.input.onChange(option);
      }}
      onBlur={() => props.input.onBlur(props.input.value)}
      items={props.options}
    />
  );
}

export function renderFileUpload({
  input,
  onOperationFinished,
  meta: { error },
  className,
  ...custom
}) {
  return (
    <FileUpload
      className={className}
      endUpload={onOperationFinished}
      error={error && error.length > 0}
      errorMessage={
        error && error.length > 0 ? <FormattedMessage id={error} /> : ''
      }
      {...input}
      {...custom}
    />
  );
}

export function renderCalendarInput({
  input,
  meta: { touched, error },
  ...rest
}) {
  return (
    <CalendarInput
      selected={input.value}
      onChange={input.onChange}
      onBlur={input.onBlur}
      error={touched && error && error.length > 0}
      errorMessage={
        touched && error && error.length > 0 ? (
          <FormattedMessage id={error} />
        ) : (
          ''
        )
      }
      {...rest}
    />
  );
}

export function renderSearchInput({ input, ...passProps }) {
  return <TextInput {...input} {...passProps} icon={<IconSearch />} />;
}

export function renderTextInput({
  input,
  meta: { touched, error },
  label,
  optional,
  hideLabel = false,
  ...passProps
}) {
  const hasError = touched && error && error.length > 0;
  // fix to allow narrow input field
  const width = '100%';
  const additionalInfo = optional ? (
    <FormattedMessage id="general.form.optionalField" />
  ) : (
    ''
  );

  return (
    <TextInput
      labelText={
        !hideLabel && (
          <Fragment>
            {label}
            {additionalInfo}
          </Fragment>
        )
      }
      status={hasError ? 'error' : 'default'}
      statusText={hasError ? <FormattedMessage id={error} /> : undefined}
      style={{ width, minWidth: width, maxWidth: width }}
      {...input}
      {...passProps}
      labelMode={hideLabel ? 'hidden' : 'visible'}
    />
  );
}

export function renderText({
  input,
  label,
  'data-test': dataTest,
  ...passProps
}) {
  return (
    <div {...passProps}>
      <Paragraph className="mb-s">
        <Text variant="bold" smallScreen>
          {label}
        </Text>
      </Paragraph>
      <div>
        <Text data-test={dataTest}>{input.value ? input.value : '-'}</Text>
      </div>
    </div>
  );
}

export const renderTextFieldArray = ({
  fields,
  label,
  buttonText,
  labelInfo
}) => (
  <Fragment>
    <label>{label}</label>
    {labelInfo}
    <ul className={styles.ipList}>
      {fields.map((value, index) => (
        <li key={index}>
          <Field
            className={styles.ipField}
            name={`${value}.value`}
            component={renderTextInput}
            data-test={`${value}`}
            hideLabel
          />
          {index !== 0 && (
            <Button
              variant="secondaryNoBorder"
              className="align-top"
              icon={<IconClose />}
              onClick={() => fields.remove(index)}
              data-test={`${value}-close`}
              aria-label="remove field"
            />
          )}
        </li>
      ))}
    </ul>
    <Button
      variant="link"
      icon={<IconPlus />}
      onClick={() => fields.push({ value: null })}
      data-test={`button-addField`}
    >
      {buttonText}
    </Button>
  </Fragment>
);

/**
 * TODO missing:
 * - proper look & feel
 * - show validation errors
 * - a11y
 * (waiting for suomi.fi component which would implement all above)
 */
export function renderMultiSelect({ input, ...passProps }) {
  return (
    <ReactSelect
      {...input}
      onChange={option => input.onChange(option)}
      onBlur={() => input.onBlur(input.value)}
      isMulti
      placeholder=""
      noOptionsMessage={() => '-'}
      {...passProps}
    />
  );
}

export function renderTextarea(props) {
  const {
    input,
    meta: { touched, error },
    label,
    width = '100%',
    optional,
    hideLabel = false,
    ...passProps
  } = props;

  const hasError = touched && error && error.length > 0;
  const additionalInfo = optional ? (
    <FormattedMessage id="general.form.optionalField" />
  ) : (
    ''
  );

  return (
    <Textarea
      labelText={
        !hideLabel && (
          <Fragment>
            {label}
            {additionalInfo}
          </Fragment>
        )
      }
      status={hasError ? 'error' : 'default'}
      statusText={hasError ? <FormattedMessage id={error} /> : undefined}
      containerProps={{ style: { width } }}
      {...input}
      {...passProps}
      labelMode={hideLabel ? 'hidden' : 'visible'}
    />
  );
}
