/**
 * Scrolls to position
 *
 * @param element, the element that is used to get top position
 * @param to position, 0 means top etc
 * @param duration, time to scroll
 */
export function scrollTo(element, to = 0, duration = 200) {
  const start = element.documentElement.scrollTop || element.body.scrollTop;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const animateScroll = () => {
    currentTime += increment;
    const eioq = easeInOutQuad(currentTime, start, change, duration);
    if (
      element.documentElement.scrollTop ||
      element.documentElement.scrollTop === 0
    ) {
      element.documentElement.scrollTop = eioq;
    } else if (element.body.scrollTop || element.body.scrollTop === 0) {
      element.body.scrollTop = eioq;
    }

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

/**
 *
 * @param _t current time
 * @param b start value
 * @param c change in value
 * @param d duration
 * @returns {*}
 */
function easeInOutQuad(_t, b, c, d) {
  let t = _t;
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}
