import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TranslatableHelmet from '../../../components/TranslatableHelmet';
import FeedbackForm from '../../../components/FeedbackForm';
import { PageHeader } from 'sema-ui-components';
import mailboxIcon from 'sema-ui-components/dist/components/icons/old/icon-mailbox.svg';
import styles from './FeedbackView.scss';

export const FeedbackView = ({ routes, params }) => (
  <section>
    <TranslatableHelmet locale={params.lang} transKey="support.contact_title" />
    <div className="row">
      <div className="col-md-12">
        <PageHeader
          icon={<img src={mailboxIcon} alt="" />}
          title={
            <span>
              <FormattedMessage id="support.contact_title" />
            </span>
          }
          description={<span />}
          routes={routes}
          params={params}
        />
      </div>
    </div>
    <div className={styles.base}>
      <div className="row v-offset-4" />
      <FeedbackForm sourcePage="Palaute" />
      <div className="row v-offset-6" />
    </div>
  </section>
);

FeedbackView.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FeedbackView;
