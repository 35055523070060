/* eslint-disable react/prop-types */
import React from 'react';
import List from './List';

const Contact = ({
  contact = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  },
  i18n
}) => (
  <List>
    <List.Item
      title={i18n('intermediary.preview.firstName')}
      value={contact?.firstName}
    />
    <List.Item
      title={i18n('intermediary.preview.lastName')}
      value={contact?.lastName}
    />
    <List.Item
      title={i18n('intermediary.preview.email')}
      value={contact?.email}
    />
    <List.Item
      title={i18n('intermediary.preview.phone')}
      value={contact?.phoneNumber}
    />
  </List>
);

export default Contact;
