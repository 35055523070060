import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, sort, search, Select } from 'sema-ui-components';

import { states } from '../constants/states';
import styles from '../styles/MyForms.scss';
import { Link } from 'react-router';
import { renderDate, filterForms } from '../utils/index';

const baseLoc = 'intermediary.';
const tableLoc = `${baseLoc}applications.`;

const ApplicationsTable = (props, { intl }) => {
  const {
    allForms,
    sortingColumns,
    updateSorting,
    showIntermediaryApplications,
    filters = {},
    updateFilter,
    updateQuery,
    query,
    organisation
  } = props;

  const { service: serviceFilter, state: stateFilter } = filters;

  const filteredForms = useMemo(
    () => filterForms(allForms, showIntermediaryApplications, organisation),
    [allForms, showIntermediaryApplications, organisation.id]
  );

  const i18n = id => intl.formatMessage({ id });

  const { locale } = intl;

  const sortable = sort.sort({
    getSortingColumns: () => sortingColumns || [],
    onSort: selectedColumn => {
      updateSorting({
        sortingColumns: sort.byColumns({
          sortingColumns,
          selectedColumn
        })
      });
    }
  });

  const statusColumn = {
    header: {
      label: <span>{i18n(`${tableLoc}status`)}</span>
    },
    cell: {
      property: 'formState',
      resolve: value =>
        value ? i18n(`intermediary.states.${value || ''}`) : '',
      formatters: [search.highlightCell]
    }
  };

  const addDesicionGuideParamToURL = (url, proposalDecisionGuid) =>
    proposalDecisionGuid
      ? url + `&proposalDecisionGuid=${proposalDecisionGuid}`
      : url;

  const nameColumn = {
    header: {
      label: <span>{i18n(`${tableLoc}application`)}</span>
    },
    cell: {
      property: 'proposalGuid',
      formatters: [
        (value, { rowData }) => {
          let href = '';
          switch (rowData.formState) {
            case 'DRAFT': {
              href = `/${locale}/valitoimija/${rowData.serviceType}/${value}`;
              break;
            }
            case 'WAITING_CUSTOMER_TO_APPROVE': {
              if (showIntermediaryApplications) {
                href = `/${locale}/own-applications/application/sent/${value}/${rowData.serviceType}?formState=${rowData.formState}`;
              } else {
                href = `/${locale}/valitoimija/hyvaksy-ehdot/${value}`;
              }
              break;
            }
            default: {
              href = `/${locale}/own-applications/application/sent/${value}/${rowData.serviceType}?formState=${rowData.formState}`;
              href = addDesicionGuideParamToURL(
                href,
                rowData.proposalDecisionGuid
              );
              break;
            }
          }

          return (
            <Link href={href}>
              {rowData.proposalName || i18n('intermediary.application.title')}
            </Link>
          );
        }
      ]
    }
  };

  const journalColumn = {
    header: {
      label: <span>{i18n(`general.journal`)}</span>
    },
    cell: {
      property: 'journalNumber',
      resolve: val => val ?? ' ',
      formatters: [search.highlightCell]
    }
  };

  const serviceColumn = {
    header: {
      label: <span>{i18n(`${tableLoc}service`)}</span>
    },
    cell: {
      property: 'serviceType',
      resolve: value => i18n(`intermediary.services.${value}`),
      formatters: [search.highlightCell]
    }
  };

  const customerOrganisation = {
    header: {
      label: <span>{i18n(`${tableLoc}customerOrganisation`)}</span>
    },
    cell: {
      resolve: val => val ?? ' ',
      property: 'customerOrganizationName',
      formatters: [search.highlightCell]
    }
  };

  const intermediaryOrganisation = {
    header: {
      label: <span>{i18n(`${tableLoc}intermediaryOrganisation`)}</span>
    },
    cell: {
      resolve: val => val ?? ' ',
      property: 'intermediaryOrganizationName',
      formatters: [search.highlightCell]
    }
  };

  const updatedAtColumn = {
    header: {
      label: <span>{i18n(`${tableLoc}updatedAt`)}</span>,
      transforms: [sortable]
    },
    cell: {
      property: 'modifiedDate',
      formatters: [value => (value ? renderDate(new Date(value)) : '-')]
    }
  };

  const columns = [
    nameColumn,
    serviceColumn,
    journalColumn,
    customerOrganisation,
    intermediaryOrganisation,
    statusColumn,
    updatedAtColumn
  ];

  const updateSelectedService = serviceFilter => {
    updateFilter('service', serviceFilter.value);
  };

  const updateSelectedStatus = statusFilter => {
    updateFilter('state', statusFilter.value);
  };

  const searchTable = query => {
    updateQuery(query);
  };

  const serviceNames = Array.isArray(allForms)
    ? allForms.map(({ serviceType }) => serviceType)
    : [];
  const uniqueServiceNames = serviceNames.filter(
    (value, index, self) => self.indexOf(value) === index
  );

  const serviceSelectorValues = [
    {
      value: 'all',
      label: i18n(`${tableLoc}all`)
    }
  ].concat(
    uniqueServiceNames.map(service => ({
      value: service,
      label: i18n(`intermediary.services.${service}`)
    }))
  );

  const stateSelectorValues = [
    {
      value: 'all',
      label: i18n(`${tableLoc}all`)
    }
  ].concat(
    states.map(state => ({
      value: state,
      label: i18n(`intermediary.states.${state?.toUpperCase()}`)
    }))
  );

  const renderFilterUI = () => (
    <div className={styles.filterUI}>
      <div className={styles.serviceDropdown}>
        <Select
          label={i18n(`${tableLoc}service`)}
          options={serviceSelectorValues}
          onChange={updateSelectedService}
          value={filters.service}
        />
      </div>
      <div className={styles.stateCheckboxes}>
        <Select
          label={i18n(`${tableLoc}status`)}
          options={stateSelectorValues}
          onChange={updateSelectedStatus}
          value={filters.state}
        />
      </div>
    </div>
  );

  return (
    <Table
      className={styles.myFormsTable}
      data={Array.isArray(filteredForms) ? filteredForms : []}
      sortingColumns={sortingColumns || {}}
      columns={columns}
      rowKey="uuid"
      noResultsMessage={i18n('tables_noresult')}
      searchParameters={{
        resultsCountMessage: i18n('tables_results'),
        searchPlaceholder: i18n('tables_search'),
        onSearch: query => searchTable(query),
        query,
        filters: {
          children: renderFilterUI(),
          filter: data =>
            data
              .filter(
                item => stateFilter === 'all' || stateFilter === item.formState
              )
              .filter(
                item =>
                  serviceFilter === 'all' || serviceFilter === item.serviceType
              )
        }
      }}
    />
  );
};

ApplicationsTable.propTypes = {
  allForms: PropTypes.array,
  sortingColumns: PropTypes.object,
  updateSorting: PropTypes.func,
  loadForm: PropTypes.func,
  showIntermediaryApplications: PropTypes.bool,
  filters: PropTypes.object,
  updateFilter: PropTypes.func,
  query: PropTypes.object,
  organisation: PropTypes.object,
  updateQuery: PropTypes.func
};

ApplicationsTable.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ApplicationsTable;
