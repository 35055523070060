import cx from 'classnames';
import { Heading } from 'components/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Block, Button } from 'suomifi-ui-components';
import styles from './ConfirmationDialog.scss';

export const ConfirmationDialog = ({
  title,
  children,
  onConfirm,
  onCancel,
  labelConfirm,
  labelCancel,
  className
}) => (
  <section className={cx(styles.container, className)}>
    <div className={styles.header}>
      <Heading.h3 as="h1">{title}</Heading.h3>
    </div>
    <Block>{children}</Block>
    <div className={styles.actions}>
      <Button
        data-test="button-confirm"
        className={styles.button}
        onClick={onConfirm}
      >
        {labelConfirm || <FormattedMessage id="general.dialog.yes" />}
      </Button>
      {onCancel && (
        <Button
          variant="secondary"
          data-test="button-cancel"
          className={styles.button}
          onClick={onCancel}
        >
          {labelCancel || <FormattedMessage id="general.dialog.cancel" />}
        </Button>
      )}
    </div>
  </section>
);

ConfirmationDialog.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  labelConfirm: PropTypes.node,
  labelCancel: PropTypes.node,
  className: PropTypes.string
};
