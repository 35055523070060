import { injectReducer } from '../../store/reducers';

export default store => ({
  path: 'saha/print',
  getComponents(nextState, next) {
    require.ensure(
      ['./containers/PrintHtmlContainer', './modules'],
      require => {
        const reducer = require('./modules').default;
        injectReducer(store, { key: 'sahaPrint', reducer });

        const content = require('./containers/PrintHtmlContainer').default;
        next(null, content);
      },
      'printHtmlContainer'
    );
  }
});
