import { createAction as ca, createReducer } from 'utils';
import { CALL_API } from 'store/middleware/api';

// ------------------------------------
// Constants & FSA actions
// ------------------------------------
const SEARCH_REQUEST = 'SEARCH_REQUEST';
const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
const SEARCH_FAILURE = 'SEARCH_FAILURE';
const SEARCH_SET_TERM = 'SEARCH_SET_TERM';
const SEARCH_CLEAR_TERM = 'SEARCH_CLEAR_TERM';

export const setSearchTerm = ca(SEARCH_SET_TERM);
export const clearSearchTerm = ca(SEARCH_CLEAR_TERM);

// ------------------------------------
// Async actions
// ------------------------------------
export function requestAsyncSearch(q, lang, size) {
  return {
    [CALL_API]: {
      types: [SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILURE],
      endpoint: `search?lang=${lang}&q=${q}&size=${size}`,
      meta: q
    }
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SEARCH_REQUEST]: (state, { payload }) => ({
    ...state,
    searchTerm: payload,
    isFetching: true
  }),
  [SEARCH_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    currentMax: payload.hits.length,
    results: payload
  }),
  [SEARCH_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [SEARCH_SET_TERM]: (state, { payload }) => ({
    ...state,
    searchTerm: payload
  }),
  [SEARCH_CLEAR_TERM]: state => ({
    ...state,
    searchTerm: '',
    results: {}
  })
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = {
  searchTerm: '',
  results: {},
  isFetching: false,
  isBlurred: false,
  currentMax: 10
};
export default createReducer(ACTION_HANDLERS, initialState);
