import React from 'react';
import PropTypes from 'prop-types';
import { IndexLink } from 'react-router';
import InlineSVG from 'svg-inline-react';
import cx from 'classnames';
import styles from './Logo.scss';
import logoSymbolSvg from '!!url-loader?limit=10!./logo_symbol_1x.svg';

import logoTextSvg from '!!svg-inline-loader!./logo_text_fi.svg';
import logoTextSvgEn from '!!svg-inline-loader!./logo_text_en.svg';
import logoTextSvgSv from '!!svg-inline-loader!./logo_text_sv.svg';

const logoTexts = {
  fi: logoTextSvg,
  sv: logoTextSvgSv,
  en: logoTextSvgEn
};

export const Logo = ({ light, registered, locale }) => (
  <div className={styles.logo}>
    <IndexLink to={registered ? '/dashboard' : '/'} tabIndex={-1} id="logo">
      <img src={logoSymbolSvg} alt="Suomi.fi" />
      <InlineSVG
        className={cx(
          'hidden-sm-down',
          { [styles.light]: light },
          styles.iconText
        )}
        src={logoTexts[locale] || logoTextSvg}
      />
    </IndexLink>
  </div>
);

Logo.propTypes = {
  light: PropTypes.bool,
  registered: PropTypes.bool,
  locale: PropTypes.string
};

export default Logo;
