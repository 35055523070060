import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { RadioButton, RadioButtonGroup } from 'suomifi-ui-components';

export const FollowInformationManagementAct = ({
  i18n,
  followInformationManagementAct,
  formik
}) => {
  return (
    <RadioButtonGroup
      labelText={i18n(
        'intermediary.dataProtection.followInformationManagementActLabel'
      )}
      name="formData.statementOnInformationSecurity.followInformationManagementAct"
      value={followInformationManagementAct}
      onChange={value =>
        formik.setFieldValue(
          'formData.statementOnInformationSecurity.followInformationManagementAct',
          value === 'true'
        )
      }
    >
      <RadioButton value="true">
        <FormattedMessage id="intermediary.common.yes" />
      </RadioButton>
      <RadioButton value="false">
        <FormattedMessage id="intermediary.common.no" />
      </RadioButton>
    </RadioButtonGroup>
  );
};

FollowInformationManagementAct.propTypes = {
  i18n: PropTypes.func,
  followInformationManagementAct: PropTypes.string,
  formik: PropTypes.object
};
