/* eslint-disable max-len */
export const NOTIFICATION_LIST_OPEN = 'NOTIFICATION_LIST_OPEN';
export const NOTIFICATION_LIST_CLOSE = 'NOTIFICATION_LIST_CLOSE';
export const NOTIFICATION_NOTIFY = 'NOTIFICATION_NOTIFY';
export const NOTIFICATION_MARK_AS_SEEN = 'NOTIFICATION_MARK_AS_SEEN';
export const NOTIFICATION_MARK_ALL_AS_SEEN = 'NOTIFICATION_MARK_ALL_AS_SEEN';
export const NOTIFICATION_REQUESTING_NOTIFICATIONS =
  'NOTIFICATION_REQUESTING_NOTIFICATIONS';
export const NOTIFICATION_RECEIVE_NOTIFICATIONS =
  'NOTIFICATION_RECEIVE_NOTIFICATIONS';

/**
 * Notify the user with a notification
 * @param notification
 */
export function notify(notification) {
  const payload = Object.assign({}, notification);

  return { type: NOTIFICATION_NOTIFY, payload };
}

export function openNotificationList() {
  return { type: NOTIFICATION_LIST_OPEN };
}

export function closeNotificationList() {
  return { type: NOTIFICATION_LIST_CLOSE };
}

export function notifyRequestingNotifications() {
  return { type: NOTIFICATION_REQUESTING_NOTIFICATIONS };
}

export function receiveNotifications(notifications) {
  return { type: NOTIFICATION_RECEIVE_NOTIFICATIONS, payload: notifications };
}

export function markNotificationAsSeen(notificationId) {
  return { type: NOTIFICATION_MARK_AS_SEEN, payload: notificationId };
}

export function markAllNotificationsAsSeen() {
  return { type: NOTIFICATION_MARK_ALL_AS_SEEN };
}

export function requestNotifications() {
  return async dispatch => {
    dispatch(notifyRequestingNotifications());
    // const results = await getJSON('/api/notifications');
    // const results = await getJSON('/api/articles/topical');

    dispatch(receiveNotifications([]));
  };
}
