import React from 'react';
import { FormattedMessage } from 'react-intl';
import { redirect } from '../../utils';
import { injectReducer } from '../../store/reducers';
import { scrollTo } from 'utils/ScrollToTop';

export const basePath = 'ajankohtaista';

export default (store, browserLang) => ({
  async getComponents(nextState, next) {
    await require.ensure(
      ['./containers/TopicalViewContainer', './modules'],
      require => {
        const content = require('./containers/TopicalViewContainer').default;
        next(null, { content });
      },
      'topical'
    );
  },
  getChildRoutes: (location, cb) => {
    cb(null, [
      redirect(basePath, `/${location.params.lang}/${basePath}/uutiset`),
      {
        path: basePath,
        name: <FormattedMessage id="topical.title" />,
        childRoutes: [
          {
            path: 'uutiset',
            name: <FormattedMessage id="topical.title" />,
            indexRoute: {
              name: <FormattedMessage id="topical.title" />,
              getComponents(nextState, next) {
                require.ensure(
                  [],
                  require => {
                    const content = require('./containers/NewsListContainer')
                      .default;
                    next(null, content);
                  },
                  'topical'
                );
              }
            },
            childRoutes: [
              {
                path: ':id',
                name: <FormattedMessage id="topical.news.title" />,
                onEnter: () =>
                  setTimeout(() => {
                    scrollTo(document, 0, 0);
                  }, 100),
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/NewsViewContainer')
                        .default;
                      next(null, content);
                    },
                    'topical'
                  );
                }
              }
            ]
          },
          {
            path: 'tapahtumat',
            name: <FormattedMessage id="topical.title" />,
            indexRoute: {
              name: <FormattedMessage id="topical.title" />,
              getComponents(nextState, next) {
                require.ensure(
                  [],
                  require => {
                    const content = require('./containers/EventListViewContainer')
                      .default;
                    next(null, content);
                  },
                  'topical'
                );
              }
            },
            childRoutes: [
              {
                path: ':id',
                name: <FormattedMessage id="topical.events.title" />,
                onEnter: () =>
                  setTimeout(() => {
                    scrollTo(document, 0, 0);
                  }, 100),
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/EventViewContainer')
                        .default;
                      next(null, content);
                    },
                    'topical'
                  );
                }
              }
            ]
          },
          {
            path: 'hairiotiedotteet',
            name: <FormattedMessage id="topical.malfunction_title" />,
            indexRoute: {
              name: <FormattedMessage id="topical.malfunction_title" />,
              getComponents(nextState, next) {
                require.ensure(
                  [],
                  require => {
                    const content = require('./containers/MalfunctionListViewContainer')
                      .default;
                    next(null, content);
                  },
                  'topical'
                );
              }
            },
            childRoutes: [
              {
                path: ':id',
                name: <FormattedMessage id="topical.malfunctions.title" />,
                onEnter: () =>
                  setTimeout(() => {
                    scrollTo(document, 0, 0);
                  }, 100),
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/MalfunctionViewContainer')
                        .default;
                      next(null, content);
                    },
                    'topical'
                  );
                }
              }
            ]
          },
          {
            path: 'julkaisutiedotteet',
            name: <FormattedMessage id="topical.releases.title" />,
            indexRoute: {
              name: <FormattedMessage id="topical.releases.title" />,
              getComponents(nextState, next) {
                require.ensure(
                  [],
                  require => {
                    const content = require('./containers/ReleaseListViewContainer')
                      .default;
                    next(null, content);
                  },
                  'topical'
                );
              }
            },
            childRoutes: [
              {
                path: ':id',
                name: <FormattedMessage id="topical.releases.title" />,
                onEnter: () =>
                  setTimeout(() => {
                    scrollTo(document, 0, 0);
                  }, 100),
                getComponents(nextState, next) {
                  require.ensure(
                    [],
                    require => {
                      const content = require('./containers/ReleaseViewContainer')
                        .default;
                      next(null, content);
                    },
                    'topical'
                  );
                }
              }
            ]
          }
        ]
      }
    ]);
  }
});
