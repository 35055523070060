import { createAction as ca, createReducer } from 'utils';
import { CALL_API } from 'store/middleware/api';

// ------------------------------------
// Constants & FSA actions
// ------------------------------------
const GET_MANAGED_NOTIFS_REQUEST = 'GET_MANAGED_NOTIFS_REQUEST';
const GET_MANAGED_NOTIFS_SUCCESS = 'GET_MANAGED_NOTIFS_SUCCESS';
const GET_MANAGED_NOTIFS_FAILURE = 'GET_MANAGED_NOTIFS_FAILURE';
const GET_DEFS_REQUEST = 'GET_DEFS_REQUEST';
const GET_DEFS_SUCCESS = 'GET_DEFS_SUCCESS';
const GET_DEFS_FAILURE = 'GET_DEFS_FAILURE';
const GET_NOTIF_REQUEST = 'GET_NOTIF_REQUEST';
const GET_NOTIF_SUCCESS = 'GET_NOTIF_SUCCESS';
const GET_NOTIF_FAILURE = 'GET_NOTIF_FAILURE';
const DELETE_NOTIF_REQUEST = 'DELETE_NOTIF_REQUEST';
const DELETE_NOTIF_SUCCESS = 'DELETE_NOTIF_SUCCESS';
const DELETE_NOTIF_FAILURE = 'DELETE_NOTIF_FAILURE';
const SAVE_NOTIF_REQUEST = 'SAVE_NOTIF_REQUEST';
const SAVE_NOTIF_SUCCESS = 'SAVE_NOTIF_SUCCESS';
const SAVE_NOTIF_FAILURE = 'SAVE_NOTIF_FAILURE';
const SEARCH_ORGS_REQUEST = 'SEARCH_ORGS_REQUEST';
const SEARCH_ORGS_SUCCESS = 'SEARCH_ORGS_SUCCESS';
const SEARCH_ORGS_FAILURE = 'SEARCH_ORGS_FAILURE';
const SEARCH_NAMES_REQUEST = 'SEARCH_NAMES_REQUEST';
const SEARCH_NAMES_SUCCESS = 'SEARCH_NAMES_SUCCESS';
const SEARCH_NAMES_FAILURE = 'SEARCH_NAMES_FAILURE';
const UPDATE_NOTIF_VALUE = 'UPDATE_NOTIF_VALUE';

export function updateForm(notification) {
  return dispatch => dispatch(ca(GET_NOTIF_SUCCESS)(notification));
}

export function updateValue(name, value) {
  const valueMap = {};
  valueMap[name] = value;
  return dispatch => dispatch(ca(UPDATE_NOTIF_VALUE)(valueMap));
}

// ------------------------------------
// Async actions
// ------------------------------------
export function asyncFetchNotifs(page, count) {
  let params = '';
  if (page !== undefined && count !== undefined) {
    params = '?page=' + page + '&count=' + count;
  }

  return {
    [CALL_API]: {
      types: [
        GET_MANAGED_NOTIFS_REQUEST,
        GET_MANAGED_NOTIFS_SUCCESS,
        GET_MANAGED_NOTIFS_FAILURE
      ],
      endpoint: `admin/notifications${params}`
    }
  };
}

export function asyncFetchDefs(params) {
  return {
    [CALL_API]: {
      types: [GET_DEFS_REQUEST, GET_DEFS_SUCCESS, GET_DEFS_FAILURE],
      endpoint: `notifications/definitions`
    }
  };
}

export function asyncGetNotif(id) {
  if (id === 'uusi') {
    return dispatch =>
      dispatch(
        ca(GET_NOTIF_SUCCESS)({
          language: 'fi',
          notificationText: [
            { language: 'fi' },
            { language: 'sv' },
            { language: 'en' }
          ],
          userIds: [],
          accountIds: [],
          selectedPahaRoles: [],
          selectedServices: [],
          state: 'initial',
          startTime: null,
          endTime: null
        })
      );
  }

  return {
    [CALL_API]: {
      types: [GET_NOTIF_REQUEST, GET_NOTIF_SUCCESS, GET_NOTIF_FAILURE],
      endpoint: `notifications/${id}`
    }
  };
}

export function asyncSaveNotif(notification) {
  return {
    [CALL_API]: {
      method: 'post',
      data: notification,
      types: [SAVE_NOTIF_REQUEST, SAVE_NOTIF_SUCCESS, SAVE_NOTIF_FAILURE],
      endpoint: `admin/notifications`
    }
  };
}

export function asyncDeleteNotif(id) {
  return {
    [CALL_API]: {
      method: 'delete',
      types: [DELETE_NOTIF_REQUEST, DELETE_NOTIF_SUCCESS, DELETE_NOTIF_FAILURE],
      endpoint: `admin/notifications/${id}`
    }
  };
}

export function asyncSearchOrgs(searchTerm) {
  return {
    [CALL_API]: {
      types: [SEARCH_ORGS_REQUEST, SEARCH_ORGS_SUCCESS, SEARCH_ORGS_FAILURE],
      endpoint: `admin/saha/organizations?q=${searchTerm}`
    }
  };
}

export function asyncSearchNames(orgIds, searchTerm) {
  return {
    [CALL_API]: {
      types: [SEARCH_NAMES_REQUEST, SEARCH_NAMES_SUCCESS, SEARCH_NAMES_FAILURE],
      endpoint: `admin/saha/names?orgs=${orgIds.join(',')}&q=${searchTerm}`
    }
  };
}

export function attachDropdownListener(id, action) {
  /*
  document.getElementById(id).onclick = function(e) {

    // Make sure the event doesn't bubble from your element
    if (e) { e.stopPropagation(); }

    else { window.event.cancelBubble = true; }

    alert('this was a click inside');

  };
  */

  // For clicks elsewhere on the page
  document.onclick = function() {
    alert('this was a click outside');
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GET_MANAGED_NOTIFS_REQUEST]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [GET_MANAGED_NOTIFS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    notifications: payload
  }),
  [GET_MANAGED_NOTIFS_FAILURE]: (state, { payload }) => {
    const resultState = {
      ...state,
      isFetching: false,
      error: payload
    };
    return resultState;
  },
  [SEARCH_ORGS_REQUEST]: (state, { payload }) => ({
    ...state,
    isSearchingOrgs: true
  }),
  [SEARCH_ORGS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isSearchingOrgs: false,
      organizations: payload,
      showOrgsResults: true
    };
  },
  [SEARCH_ORGS_FAILURE]: (state, { payload }) => ({
    ...state,
    isSearchingOrgs: false,
    error: payload
  }),
  [SEARCH_NAMES_REQUEST]: (state, { payload }) => ({
    ...state,
    isSearchingNames: true
  }),
  [SEARCH_NAMES_SUCCESS]: (state, { payload }) => ({
    ...state,
    isSearchingNames: false,
    names: payload,
    showNamesResults: true
  }),
  [SEARCH_NAMES_FAILURE]: (state, { payload }) => ({
    ...state,
    isSearchingNames: false,
    error: payload
  }),
  [GET_DEFS_REQUEST]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [GET_DEFS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    definitions: payload
  }),
  [GET_DEFS_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [GET_NOTIF_REQUEST]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [GET_NOTIF_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    editNotification: payload,
    savedNotification: null
  }),
  [GET_NOTIF_FAILURE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload
  }),
  [SAVE_NOTIF_REQUEST]: (state, { payload }) => ({
    ...state,
    isSaving: true
  }),
  [SAVE_NOTIF_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isSaving: false,
      savedNotification: payload
    };
  },
  [SAVE_NOTIF_FAILURE]: (state, { payload }) => ({
    ...state,
    isSaving: false,
    error: payload
  }),
  [DELETE_NOTIF_REQUEST]: (state, { payload }) => ({
    ...state,
    isDeleting: true
  }),
  [DELETE_NOTIF_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isDeleting: false,
      deletedNotification: payload
    };
  },
  [DELETE_NOTIF_FAILURE]: (state, { payload }) => ({
    ...state,
    isDeleting: false,
    error: payload
  }),
  [UPDATE_NOTIF_VALUE]: (state, { payload }) => {
    return {
      ...state,
      ...payload
    };
  }
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = {
  notifications: { notifications: [] },
  organizations: [],
  names: [],
  definitions: [],
  editNotification: {
    language: 'fi',
    notificationText: [
      { language: 'fi' },
      { language: 'sv' },
      { language: 'en' }
    ]
  },
  userIds: [],
  accountIds: [],
  savedNotification: null,
  deletedNotification: null,
  isFetching: false,
  isSaving: false,
  isSearchingOrgs: false,
  isSearchingNames: false,
  showOrgsResults: false,
  showNamesResults: false
};

export default createReducer(ACTION_HANDLERS, initialState);
