import React from 'react';
import { initialSteps } from './initialSteps';
import FormContainer from '../../components/Form/FormContainer';
import { formTypes } from '../../constants';

const ServiceCatalogueForm = props => (
  <FormContainer
    initialSteps={initialSteps}
    serviceType={formTypes.SERVICECATALOGUE}
    {...props}
  />
);

export default ServiceCatalogueForm;
