import { injectReducers } from '../../store/reducers';
import { requireStrongAuth } from 'modules/auth/utils';

const authenticate = store => requireStrongAuth(store.getState().auth);

export const tunnistusAccessForm = store => ({
  path: 'tunnistus-liittymisen-edellytykset',
  onEnter: authenticate(store),
  getComponents(nextState, next) {
    require.ensure(
      ['./Forms/TunnistusAccess/TunnistusAccessContainer'],
      require => {
        const formsReducer = require('./modules/module_Forms').default;

        injectReducers(store, [{ key: 'formsNew', reducer: formsReducer }]);

        const content = require('./Forms/TunnistusAccess/TunnistusAccessContainer')
          .default;
        next(null, { content });
      }
    );
  }
});

// Sähköinen lomake
export const serviceBusCertificateForm = (store, browserLang) => ({
  path: 'palveluvayla-liityntapalvelimen-sertifikaatti/:id',
  onEnter: authenticate(store),
  getComponents(nextState, next) {
    require.ensure(
      ['./Forms/ServiceBusCertificate/ServiceBusCertificateFormContainer'],
      require => {
        const formsReducer = require('./modules/module_Forms').default;

        injectReducers(store, [{ key: 'formsNew', reducer: formsReducer }]);

        const content = require('./Forms/ServiceBusCertificate/ServiceBusCertificateFormContainer')
          .default;
        next(null, { content });
      },
      'Forms'
    );
  }
});

export const serviceCatalogueAccessForm = store => ({
  path: 'palvelutietovaranto-liittymisen-edellytykset',
  onEnter: authenticate(store),
  getComponents(nextState, next) {
    require.ensure(
      ['./Forms/ServiceCatalogueAccess/ServiceCatalogueAccessContainer'],
      require => {
        const formsReducer = require('./modules/module_Forms').default;

        injectReducers(store, [{ key: 'formsNew', reducer: formsReducer }]);

        const content = require('./Forms/ServiceCatalogueAccess/ServiceCatalogueAccessContainer')
          .default;
        next(null, { content });
      }
    );
  }
});
