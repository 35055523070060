import { CALL_API } from 'store/middleware/api';
import { createReducer, createAction as ca } from '../../../utils';
import {
  bootstrapUserKVHCall,
  createUserAndOrgProfile,
  createOrgProfile,
  createUserProfile,
  createOrg,
  createOrgProfileInSAHA,
  fetchUserWithEmail,
  userExists
} from './api';
import { postSemaJSON as post } from 'utils/http';
import {
  changeOrg,
  signOut,
  updateUserToStore,
  waitForUserAndChangeOrg,
  storeUser
} from 'modules/auth';
import { actions as notificationActions } from 'redux-notifications';

import { generateInvitationRequestEmail } from './invitationRequestEmailTemplate';

const PX = 'USER_REGISTRATION:';
export const REGISTER_USER_AND_ORG_REQUEST = `${PX}_REGISTER_USER_AND_ORG_REQUEST`;
export const REGISTER_USER_AND_ORG_SUCCESS = `${PX}_REGISTER_USER_AND_ORG_SUCCESS`;
export const REGISTER_USER_AND_ORG_ERROR = `${PX}_REGISTER_USER_AND_ORG_ERROR`;

export const CREATE_USER_AND_ORG_REQUEST = `${PX}_CREATE_USER_AND_ORG_REQUEST`;
export const CREATE_USER_AND_ORG_SUCCESS = `${PX}_CREATE_USER_AND_ORG_SUCCESS`;
export const CREATE_USER_AND_ORG_ERROR = `${PX}_CREATE_USER_AND_ORG_ERROR`;

export const BOOTSTRAP_REQUEST = `${PX}_BOOTSTRAP_REQUEST`;
export const BOOTSTRAP_SUCCESS = `${PX}_BOOTSTRAP_SUCCESS`;
export const BOOTSTRAP_ERROR = `${PX}_BOOTSTRAP_ERROR`;

export const REGISTER_INVITED_USER_REQUEST = `${PX}_REGISTER_INVITED_USER_REQUEST`;
export const REGISTER_INVITED_USER_SUCCESS = `${PX}_REGISTER_INVITED_USER_SUCCESS`;
export const REGISTER_INVITED_USER_FAILURE = `${PX}_REGISTER_INVITED_USER_FAILURE`;

export const CHECK_ORGANIZATION_REQUEST = `${PX}_CHECK_ORGANIZATION_REQUEST`;
export const CHECK_ORGANIZATION_SUCCESS = `${PX}_CHECK_ORGANIZATION_SUCCESS`;
export const CHECK_ORGANIZATION_FAILURE = `${PX}_CHECK_ORGANIZATION_FAILURE`;

export const CHECK_USER_REQUEST = `${PX}_CHECK_USER_REQUEST`;
export const CHECK_USER_SUCCESS = `${PX}_CHECK_USER_SUCCESS`;
export const CHECK_USER_FAILURE = `${PX}_CHECK_USER_FAILURE`;

export const SEND_INVITATION_REQUEST_EMAILS_REQUEST = `${PX}_SEND_INVITATION_REQUEST_EMAILS_REQUEST`;
export const SEND_INVITATION_REQUEST_EMAILS_SUCCESS = `${PX}_SEND_INVITATION_REQUEST_EMAILS_SUCCESS`;
export const SEND_INVITATION_REQUEST_EMAILS_FAILURE = `${PX}_SEND_INVITATION_REQUEST_EMAILS_FAILURE`;

export const SEND_REQUEST_INVITATION_REQUEST = `${PX}_SEND_REQUEST_INVITATION_REQUEST`;
export const SEND_REQUEST_INVITATION_SUCCESS = `${PX}_SEND_REQUEST_INVITATION_SUCCESS`;
export const SEND_REQUEST_INVITATION_FAILURE = `${PX}_SEND_REQUEST_INVITATION_FAILURE`;

export const REQUEST_INVITATION_SUCCESS = `${PX}_REQUEST_INVITATION_SUCCESS`;

export const UPDATE_BUSINESS_ID = `${PX}_UPDATE_BUSINESS_ID`;
export const UPDATE_INV_EMAIL = `${PX}_UPDATE_INV_EMAIL`;
export const UPDATE_ORG_SEARCH = `${PX}_UPDATE_ORG_SEARCH`;

export const LEAVE_MODAL_VISIBILITY_CHANGE = `${PX}_LEAVE_MODAL_VISIBILITY_CHANGE`;

export const SET_LISTENER_ACTIVE = `${PX}_SET_LISTENER_ACTIVE`;

export const CHANGE_TARGET_ORGANIZATION = `${PX}_CHANGE_TARGET_ORGANIZATION`;

export const getUserWithEmailXXX = emailAddress => ({
  [CALL_API]: {
    method: 'get',
    types: [CHECK_USER_REQUEST, CHECK_USER_SUCCESS, CHECK_USER_FAILURE],
    endpoint: `organisationservice/users/${emailAddress}`,
    onSuccess: payload => payload,
    onError: payload => payload
  }
});

export const getUserWithEmail = emailAddress => {
  return async dispatch => {
    try {
      dispatch(ca(CHECK_USER_REQUEST)());
      const response = fetchUserWithEmail(emailAddress);
      if (response.status === 404) {
        dispatch(ca(CHECK_USER_SUCCESS)(false));
      } else {
        dispatch(ca(CHECK_USER_SUCCESS)(true));
      }
      return response;
    } catch (e) {
      dispatch(ca(CHECK_USER_FAILURE)(e));
    }
  };
};

export const registerUserAndOrg = (orgData, formData) => {
  let orgId = '';
  const userAndOrg = {
    userId: orgData.userId,
    nameFi: orgData.nameFi,
    unitFi: orgData.unitFi,
    address: orgData.address,
    postalCode: orgData.postalCode,
    postOffice: orgData.postOffice,
    country: orgData.country,
    businessId: orgData.businessId,
    firstName: orgData.firstName,
    lastName: orgData.lastName,
    phoneNumber: orgData.phoneNumber,
    email: formData.profile.email,
    password: formData.password
  };

  return async dispatch => {
    try {
      dispatch(ca(CREATE_USER_AND_ORG_REQUEST)());

      const response = await createOrg(userAndOrg);
      if (response.error) {
        throw response;
      } else {
        orgId = response.activeOrganizationId;
        dispatch(updateUserToStore(response));
      }

      /*
        org.data.businessId = data.businessId;
        org.data.name = data.organizationName;
        orgId = data.id;
      } else {
        const isEmailUsed = (await userExists(regData.email)).userFound;

        if (isEmailUsed) {
          throw 'EMAIL_USED';
        }

        const result = await createUserAndOrgProfile(regData);

        data = result.data[0];

        org.data.businessId = data.businessId;
        org.data.name = data.organizationName;
        orgId = data.accountId;
      }

      await createOrgProfile(orgId, org);

      if (!userId) {
        const user = {
          phoneNumber: data.phoneNumber,
          email: data.email
        };
        await createUserProfile(orgId, data.id, user);
      } */

      dispatch(ca(CREATE_USER_AND_ORG_SUCCESS)(orgId));
    } catch (e) {
      dispatch(ca(CREATE_USER_AND_ORG_ERROR)(e));
      throw e;
    }
  };
};

export const bootstrapUser = (userId, orgId) => {
  return async dispatch => {
    dispatch(ca(BOOTSTRAP_REQUEST)());
    try {
      const user = await bootstrapUserKVHCall(userId, orgId);
      dispatch(updateUserToStore(user));
      dispatch(storeUser(user));
      dispatch(ca(BOOTSTRAP_SUCCESS)());
    } catch (e) {
      dispatch(ca(BOOTSTRAP_ERROR)());
    }
  };
};

export function sendErrorNotification(title, description, nId) {
  return dispatch => {
    const notificationAction = notificationActions.notifSend({
      id: nId,
      type: 'fail',
      title,
      description,
      kind: 'danger'
    });
    dispatch(notificationAction);
  };
}

export const getOrganizationWithBusinessId = businessId => ({
  [CALL_API]: {
    method: 'get',
    types: [
      CHECK_ORGANIZATION_REQUEST,
      CHECK_ORGANIZATION_SUCCESS,
      CHECK_ORGANIZATION_FAILURE
    ],
    endpoint: `organisationservice/organisations?businessId=${businessId}`
  }
});

const invitationRequest = data => ({
  [CALL_API]: {
    method: 'post',
    types: [
      SEND_INVITATION_REQUEST_EMAILS_REQUEST,
      SEND_INVITATION_REQUEST_EMAILS_SUCCESS,
      SEND_INVITATION_REQUEST_EMAILS_FAILURE
    ],
    endpoint: 'email/requestinvitation',
    data: data
  }
});

export const sendRequestInvitationRequest = (guidId, invitationEmail) => ({
  [CALL_API]: {
    method: 'post',
    types: [
      SEND_REQUEST_INVITATION_REQUEST,
      SEND_REQUEST_INVITATION_SUCCESS,
      SEND_REQUEST_INVITATION_FAILURE
    ],
    endpoint: `organisationservice/requestinvitation/${guidId}`,
    data: { email: invitationEmail }
  }
});

export const sendInvitationRequests = (
  user,
  emails,
  intl,
  guidId
) => async dispatch => {
  const emailData = {
    guidId: guidId,
    sendToHtml: true,
    senderAddress: emails
  };
  return await dispatch(invitationRequest(emailData));
};

export const requestInvitation = () => async dispatch => {
  dispatch({ type: REQUEST_INVITATION_SUCCESS });
};

export const doChangeOrg = org => async dispatch => {
  dispatch(changeOrg(org));
};

export const doSignOut = () => async dispatch => {
  dispatch(signOut());
};

export const updateBusinessIdValue = value => async dispatch => {
  dispatch(ca(UPDATE_BUSINESS_ID)(value));
};

export const updateInvEmail = value => async dispatch => {
  dispatch(ca(UPDATE_INV_EMAIL)(value));
};

export const updateOrgSearch = value => async dispatch => {
  dispatch(ca(UPDATE_ORG_SEARCH)(value));
};

export const changeTargetOrganization = target => async dispatch => {
  dispatch(ca(CHANGE_TARGET_ORGANIZATION)(target));
};

export const doWaitForUserAndChangeOrg = (
  userId,
  orgId,
  email
) => async dispatch => {
  dispatch(ca(REGISTER_INVITED_USER_REQUEST)());
  try {
    await dispatch(waitForUserAndChangeOrg(userId, orgId, true, email));
    dispatch(ca(REGISTER_INVITED_USER_SUCCESS)());
    return { error: undefined };
  } catch (e) {
    dispatch(ca(REGISTER_INVITED_USER_FAILURE)(e));
    return { error: e || 'Unknown' };
  }
};

export const updateLeaveModalVisibility = (
  leaveModalOpen,
  nextPath,
  listenerActive,
  target
) => async dispatch => {
  dispatch(
    ca(LEAVE_MODAL_VISIBILITY_CHANGE)({
      leaveModalOpen,
      nextPath,
      listenerActive,
      target
    })
  );
};

export const setListenerActive = (listenerActive, target) => async dispatch => {
  dispatch(ca(SET_LISTENER_ACTIVE)({ listenerActive, target }));
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [REGISTER_USER_AND_ORG_REQUEST]: state => ({ ...state, isRegistering: true }),
  [REGISTER_USER_AND_ORG_SUCCESS]: state => ({
    ...state,
    isRegistering: false
  }),
  [REGISTER_USER_AND_ORG_ERROR]: state => ({
    ...state,
    isRegistering: false
  }),
  [BOOTSTRAP_REQUEST]: state => ({
    ...state,
    isBootstrapping: true
  }),
  [BOOTSTRAP_SUCCESS]: state => ({
    ...state,
    isBootstrapping: false,
    error: null
  }),
  [BOOTSTRAP_ERROR]: state => ({
    ...state,
    isBootstrapping: false,
    error: { message: 'bootstrapError' }
  }),
  [CHECK_USER_REQUEST]: state => ({
    ...state,
    isRegistering: true
  }),
  [CHECK_USER_SUCCESS]: (state, { payload }) => ({
    ...state,
    emailInUse: payload
    // in the middle of registration process, do not finish the process
  }),
  [CHECK_USER_FAILURE]: state => ({
    ...state,
    isRegistering: false,
    error: true
  }),
  [CREATE_USER_AND_ORG_REQUEST]: state => ({
    ...state,
    isRegistering: true
  }),
  [CREATE_USER_AND_ORG_SUCCESS]: (state, { payload }) => ({
    ...state,
    createdOrgId: payload,
    isRegistering: false,
    error: null
  }),
  [CREATE_USER_AND_ORG_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isRegistering: false,
      error: payload
    };
  },
  [CHECK_ORGANIZATION_REQUEST]: state => {
    const listenerActive = { ...state.listenerActive, confirm: true };

    return {
      ...state,
      fetching: true,
      listenerActive: listenerActive
    };
  },
  [CHECK_ORGANIZATION_SUCCESS]: (state, { payload }) => {
    const listenerActive = { ...state.listenerActive, confirm: true };
    return {
      ...state,
      fetching: false,
      listenerActive: listenerActive,
      businessIdRetrieved: true,
      businessIdFound: payload.length > 0,
      targetOrganization: payload.length != 1 ? null : payload[0],
      organizations: payload
    };
  },
  [CHECK_ORGANIZATION_FAILURE]: (state, { payload }) => {
    const listenerActive = { ...state.listenerActive, confirm: true };

    return {
      ...state,
      fetching: false,
      listenerActive: listenerActive,
      targetOrganization: null,
      businessIdRetrieved: true,
      businessIdFound: false,
      error: payload
    };
  },
  [REQUEST_INVITATION_SUCCESS]: state => {
    const listenerActive = { ...state.listenerActive, invite: true };

    return {
      ...state,
      listenerActive: listenerActive,
      invitationRequested: true
    };
  },
  [UPDATE_BUSINESS_ID]: (state, { payload }) => {
    const listenerActive = { ...state.listenerActive, confirm: true };

    return {
      ...state,
      listenerActive: listenerActive,
      currentBusinessId: payload,
      businessIdRetrieved: false
    };
  },
  [UPDATE_INV_EMAIL]: (state, { payload }) => {
    const listenerActive = { ...state.listenerActive, invite: true };

    return {
      ...state,
      listenerActive: listenerActive,
      invitationEmail: payload
    };
  },
  [UPDATE_ORG_SEARCH]: (state, { payload }) => {
    const listenerActive = { ...state.listenerActive, info: true };

    return {
      ...state,
      listenerActive: listenerActive,
      orgSearchValue: payload
    };
  },
  [REGISTER_INVITED_USER_REQUEST]: state => ({
    ...state,
    isRegisteringInvitedUser: true
  }),
  [REGISTER_INVITED_USER_SUCCESS]: state => ({
    ...state,
    isRegisteringInvitedUser: false
  }),
  [REGISTER_INVITED_USER_FAILURE]: state => ({
    ...state,
    isRegisteringInvitedUser: false
  }),
  [SEND_INVITATION_REQUEST_EMAILS_REQUEST]: state => ({
    ...state,
    isSendingEmailsToAdmins: true
  }),
  [SEND_INVITATION_REQUEST_EMAILS_SUCCESS]: state => ({
    ...state,
    isSendingEmailsToAdmins: false
  }),
  [SEND_INVITATION_REQUEST_EMAILS_FAILURE]: state => ({
    ...state,
    isSendingEmailsToAdmins: false
  }),
  [LEAVE_MODAL_VISIBILITY_CHANGE]: (state, { payload }) => {
    const leaveModalOpen = state.leaveModalOpen;
    leaveModalOpen[payload.target] = payload.leaveModalOpen;

    const listenerActive = state.listenerActive;
    listenerActive[payload.target] = payload.listenerActive;

    return {
      ...state,
      leaveModalOpen: leaveModalOpen,
      nextPath: payload.nextPath,
      listenerActive: listenerActive
    };
  },
  [SET_LISTENER_ACTIVE]: (state, { payload }) => {
    const listenerActive = state.listenerActive;
    listenerActive[payload.target] = payload.listenerActive;

    return {
      ...state,
      listenerActive: listenerActive
    };
  },
  [CHANGE_TARGET_ORGANIZATION]: (state, { payload }) => {
    return {
      ...state,
      targetOrganization: state.organizations[payload]
    };
  }
};

// ------------------------------------
// Async create reducer
// ------------------------------------
const initialState = {
  createdOrgId: '',
  currentBusinessId: '',
  error: null,
  emailInUse: false,
  fetching: false,
  form: {},
  invitationEmail: '',
  invitationRequested: false,
  isRegisteringInvitedUser: false,
  isBootstrapping: false,
  isRegistering: false,
  isSendingEmailsToAdmins: false,
  leaveModalOpen: {
    info: false,
    confirm: false,
    invite: false,
    register: false
  },
  listenerActive: {
    info: false,
    confirm: false,
    invite: false,
    register: false
  },
  nextPath: '',
  businessIdFound: false,
  businessIdRetrieved: false,
  orgSearchValue: '',
  targetOrganization: null,
  organizations: []
};
export default createReducer(ACTION_HANDLERS, initialState);
