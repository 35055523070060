import React from 'react';
import { FormattedMessage } from 'react-intl';

const myFormsLoc = `forms.myForms.state.`;

const ID_DRAFT = 'draft';
const ID_WAITING_FOR_APPROVAL = 'waitingForApproval';
const ID_SENT = 'sent';
const ID_PROCESSING = 'processing';
const ID_REQUEST_MORE_INFO = 'requestMoreInfo';
const ID_DECIDED = 'decided';
const ID_APPROVED = 'approved';
const ID_REJECTED = 'rejected';
const ID_ENDED = 'ended';

const getRenderMethods = () => {
  return {
    renderText: state => {
      return <FormattedMessage id={`${myFormsLoc}${state}`} />;
    }
  };
};

export const states = {
  [ID_DRAFT]: {
    name: ID_DRAFT,
    allowedTransitions: [ID_WAITING_FOR_APPROVAL, ID_SENT],
    local: `${myFormsLoc}draft`,
    ...getRenderMethods()
  },
  [ID_WAITING_FOR_APPROVAL]: {
    name: ID_WAITING_FOR_APPROVAL,
    allowedTransitions: [ID_DRAFT, ID_SENT],
    local: `${myFormsLoc}waitingForValidation`,
    ...getRenderMethods()
  },
  [ID_SENT]: {
    name: ID_SENT,
    allowedTransitions: [ID_PROCESSING, ID_REQUEST_MORE_INFO, ID_DECIDED],
    local: `${myFormsLoc}sent`,
    ...getRenderMethods()
  },
  [ID_REQUEST_MORE_INFO]: {
    name: ID_REQUEST_MORE_INFO,
    allowedTransitions: [ID_SENT],
    local: `${myFormsLoc}requestMoreInfo`,
    ...getRenderMethods()
  },
  [ID_DECIDED]: {
    name: ID_DECIDED,
    allowedTransitions: [],
    local: `${myFormsLoc}decided`,
    ...getRenderMethods()
  },
  [ID_APPROVED]: {
    name: ID_APPROVED,
    allowedTransitions: [],
    local: `${myFormsLoc}approved`,
    ...getRenderMethods()
  },
  [ID_REJECTED]: {
    name: ID_REJECTED,
    allowedTransitions: [],
    local: `${myFormsLoc}rejected`,
    ...getRenderMethods()
  },
  [ID_ENDED]: {
    name: ID_ENDED,
    allowedTransitions: [],
    local: `${myFormsLoc}ended`,
    ...getRenderMethods()
  }
};
