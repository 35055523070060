import 'isomorphic-fetch';

import { setSessionExpired, updateUserToStore } from 'modules/auth';
import { setSessionExpiredInCookie } from 'utils/sessionUtil';
import { camelizeKeys } from 'humps';

// TODO eventually move methods from this util to CALL_API and get rid of ugly hacks
export const uglyQuickFix = {
  store: {}
};

export function getKVHJSON(url, guid) {
  const headers = new Headers();
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');
  headers.append('X-SahaGuid', guid);

  return genericFetch(url, { credentials: 'same-origin', headers });
}

export function postKVHJSON(url, body, guid) {
  const headers = new Headers();
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');
  headers.append('X-SahaGuid', guid);

  return genericFetch(url, {
    method: 'post',
    body,
    credentials: 'same-origin',
    headers
  });
}

export function putKVHJSON(url, body, guid) {
  const headers = new Headers();
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');
  headers.append('X-SahaGuid', guid);
  headers.append('Content-Type', 'application/json;charset=utf-8');

  return genericFetch(url, {
    method: 'put',
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers
  });
}

export function getJSON(url) {
  const headers = new Headers();
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');

  return genericFetch(url, { credentials: 'same-origin', headers });
}

export function postJSON(url, body) {
  const headers = new Headers();
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');

  return genericFetch(url, {
    method: 'post',
    body,
    credentials: 'same-origin',
    headers
  });
}

export function putJSON(url, body) {
  const headers = new Headers();
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');
  headers.append('Content-Type', 'application/json;charset=utf-8');
  return genericFetch(url, {
    method: 'put',
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers
  });
}

export function postXML(url, body) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/xml');
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');

  return genericFetch(url, {
    method: 'post',
    body,
    credentials: 'same-origin',
    headers
  });
}

export function deleteGeneric(url) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json;charset=utf-8');
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');
  return genericFetch(url, {
    method: 'delete',
    credentials: 'same-origin',
    headers
  });
}

export function postSemaJSON(url, body) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json;charset=utf-8');
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');
  return genericFetch(url, {
    method: 'post',
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers
  });
}

export function postSemaPureJSON(url, body) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json;charset=utf-8');
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');
  return genericFetch(url, {
    method: 'post',
    body: body,
    credentials: 'same-origin',
    headers
  });
}

export function putSemaJSON(url, body) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json;charset=utf-8');
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');
  return genericFetch(url, {
    method: 'put',
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers
  });
}

export function getSemaJSON(url) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json;charset=utf-8');
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('X-Requested-With', 'SEMAFetch');
  return genericFetch(url, { credentials: 'same-origin', headers });
}

export function postSemaFile(url, data) {
  const headers = new Headers();
  headers.append('X-Requested-With', 'SEMAFetch');
  headers.append('Access-Control-Allow-Origin', '*');

  return fetch(url, {
    method: 'post',
    body: data,
    credentials: 'same-origin',
    headers
  })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject({
          status: response.status,
          payload: json
        });
      }
      return camelizeKeys(json);
    });
}

export function deleteSemaFile(url, data) {
  // TODO: Data might be agaisnt specs/standard of http DELETE
  const headers = new Headers();
  headers.append('X-Requested-With', 'SEMAFetch');
  headers.append('Access-Control-Allow-Origin', '*');

  return fetch(url, {
    method: 'delete',
    body: data, // Likely to be ignored.
    credentials: 'same-origin',
    headers
  })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject({
          status: response.status,
          payload: json
        });
      }
      return camelizeKeys(json);
    });
}

export function genericFetch(url, options) {
  return fetch(url, options)
    .then(response => {
      if (response.status === 204) {
        return { json: {}, response: response };
      }

      const json = response.json().catch(err => {
        console.log(`Parsing JSON failed with: ${err}`);
      });
      return json.then(json => ({ json, response }));
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        if (response.status === 401) {
          setSessionExpiredInCookie(true);
          uglyQuickFix.store.dispatch(setSessionExpired(true));
          uglyQuickFix.store.dispatch(updateUserToStore(null));
        }
        return Promise.reject({ status: response.status, json });
      }
      return json;
    });
}

export const loadResource = (params, onChange) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', params.url);
  xhr.setRequestHeader('X-Requested-With', 'SEMAFetch');
  xhr.responseType = 'blob';

  xhr.onreadystatechange = function() {
    if (xhr.readyState === 3) {
      onChange('loading');
    }
  };

  xhr.onload = function() {
    if (this.status === 200) {
      const type = (params.type || 'application/pdf').toLowerCase();

      if (xhr.response.size < 1000) {
        onChange('error', 'invalid_content');
        return;
      }

      const blob = new Blob([xhr.response], { type });

      onChange('loaded', blob);
    } else {
      onChange('error', this.status);
    }
  };

  xhr.send();
  onChange('open');
};
