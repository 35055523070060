import cx from 'classnames';
import React from 'react';
import formDetailsStyles from '../styles/FormDetails.scss';
import styles from '../styles/MyForms.scss';

export const createDraftPath = (formId, service, forms) => {
  const serviceKey = Object.keys(forms).find(
    k =>
      (forms[k].serviceEN === service || forms[k].service === service) &&
      forms[k].formType !== 'access' //TODO: Is checking needed = Which form types are editable and which aren't?
  );
  const pathWithoutId = serviceKey ? forms[serviceKey].path.split('/')[0] : '';
  return serviceKey ? pathWithoutId + '/' + formId : '';
};

export const renderDate = (date, doNotShowTime) => (
  <div>
    <div className={styles.dateField}>
      <p className={styles.date}>{`${date.getDate()}.${date.getMonth() +
        1}.${date.getFullYear()}`}</p>
      {!doNotShowTime && (
        <p className={styles.time}>{`(${date.getHours()}:${(date.getMinutes() <
          10 && '0') + date.getMinutes()})`}</p>
      )}
    </div>
  </div>
);

export const renderCheckboxRow = (i18n, label, value, styles) => (
  <p className={styles.valueLine}>
    <span className={styles.titleField}>{label}</span>
    <span className={styles.valueField}>
      {value ? i18n('forms.general.yes') : i18n('forms.general.no')}
    </span>
  </p>
);

export const renderValueLine = (title, value, indented) => {
  if (Array.isArray(value)) {
    value = value.join(', ');
  }
  return (
    <section
      className={cx(formDetailsStyles.valueLine, {
        [formDetailsStyles.indented]: indented
      })}
    >
      <div className={formDetailsStyles.titleField}>{title}</div>
      <div className={formDetailsStyles.valueField}>{value || '-'}</div>
    </section>
  );
};

export const renderRadioButtonAnswer = (
  i18n,
  answerDescription,
  answer,
  infoDescription,
  info
) => {
  return (
    <section>
      {renderValueLine(
        answerDescription,
        answer ? i18n('forms.general.yes') : i18n('forms.general.no')
      )}
      {info && renderValueLine(infoDescription, info, true)}
    </section>
  );
};

export const renderContactInfo = (name, data, i18n, loc, styles) => {
  return (
    data && (
      <React.Fragment>
        <h3 className="mt-l mb-m">{i18n(`${loc}.${name}`)}</h3>
        {data.organizationName && (
          <div className={styles.valueLine}>
            <span className={styles.titleField}>
              {i18n(`general.form.organization_name`)}
            </span>
            <span className={styles.valueField}>{data.organizationName}</span>
          </div>
        )}
        {data.businessId && (
          <div className={styles.valueLine}>
            <span className={styles.titleField}>
              {i18n(`general.form.organization_business_id`)}
            </span>
            <span className={styles.valueField}>{data.businessId}</span>
          </div>
        )}
        {(data.firstName || data.lastName) && (
          <div className={styles.valueLine}>
            <span className={styles.titleField}>
              {i18n(`general.form.name`)}
            </span>
            <span
              className={styles.valueField}
            >{`${data.firstName} ${data.lastName}`}</span>
          </div>
        )}
        {data.title && (
          <div className={styles.valueLine}>
            <span className={styles.titleField}>
              {i18n(`general.form.job_title`)}
            </span>
            <span className={styles.valueField}>{data.title}</span>
          </div>
        )}
        {data.phoneNumber && (
          <div className={styles.valueLine}>
            <span className={styles.titleField}>
              {i18n(`general.form.phone`)}
            </span>
            <span className={styles.valueField}>{data.phoneNumber}</span>
          </div>
        )}
        {data.address && (
          <div className={styles.valueLine}>
            <span className={styles.titleField}>
              {i18n(`general.form.general_address`)}
            </span>
            <span className={styles.valueField}>{data.address}</span>
          </div>
        )}
        {data.email && (
          <div className={styles.valueLine}>
            <span className={styles.titleField}>
              {i18n(`general.form.email`)}
            </span>
            <span className={styles.valueField}>{data.email}</span>
          </div>
        )}
      </React.Fragment>
    )
  );
};

export const joinTrueValuesAsTranslation = (i18n, obj, translationLoc) => {
  return Object.keys(obj)
    .filter(key => typeof obj[key] === 'boolean' && obj[key])
    .map(key => i18n(`${translationLoc}.${key}`))
    .join(', ');
};

export const filterForms = (
  forms,
  showIntermediaryApplications,
  organisation
) => {
  // Not intermediary, show all
  if (!organisation.intermediary) {
    return forms;
  }
  // Intermediary
  if (showIntermediaryApplications) {
    // Show forms where organisation is intermediary
    return forms.filter(
      form => form.intermediaryOrganizationGuid === organisation.id
    );
  } else {
    // Show forms where organisation is customer
    return forms.filter(
      form => form.customerOrganizationGuid === organisation.id
    );
  }
};
