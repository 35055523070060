import React from 'react';
import { withRouter } from 'react-router';
import { setClientAcceptCookies } from '../../../../utils/clientAcceptUtil';
import styles from './ExternalSignInView.scss';
import {
  Button,
  Heading,
  InlineAlert,
  Link,
  Paragraph,
  Text
} from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import { convertParamsToObjFromBase64 } from './';

export const ExternalSignInView = () => {
  const params =
    '/fi/omat-tiedot/tiedot?' +
    window.atob(new URLSearchParams(location.search).get('values'));

  const {
    orgGuidId,
    formGuidId,
    formType,
    orgName,
    userMail
  } = convertParamsToObjFromBase64(
    new URLSearchParams(location.search).get('values')
  );

  setClientAcceptCookies(orgGuidId, formGuidId, formType, userMail);

  const organisationName = decodeURIComponent(orgName);
  const redirectUrl =
    '/api/auth/customerlogin?redirectUrl=/fi/valitoimija/hyvaksy-ehdot/' +
    formGuidId +
    '?values=' +
    window.btoa(params);

  return (
    <section className={'col-sm-21'}>
      <div className={styles.introduction}>
        <Heading variant={'h1'}>
          <FormattedMessage id="accept_forms_view.welcome" />
        </Heading>
        <div className={styles.blockHighlightBase}></div>
        <Paragraph marginBottomSpacing={'m'}>
          <FormattedMessage id="intermediary.applications.intermediaryOrganisation" />
          <Text variant={'bold'}>&nbsp;{organisationName}&nbsp;</Text>
          <FormattedMessage id="intermediary.external_login.welcome_text_1" />
          <Text variant={'bold'}>
            &nbsp;
            <FormattedMessage id={`general.form.service.${formType}`} />
          </Text>
          <FormattedMessage id="intermediary.external_login.welcome_text_2" />
        </Paragraph>
        <Paragraph marginBottomSpacing={'m'}>
          <FormattedMessage id="intermediary.external_login.term_acceptance_info" />
        </Paragraph>
        <Paragraph marginBottomSpacing={'m'}>
          <InlineAlert>
            <Paragraph marginBottomSpacing={'m'}>
              <FormattedMessage id="intermediary.external_login.account_requirements" />
            </Paragraph>
            <Link href="https://palveluhallinta.suomi.fi/fi/tuki/artikkelit/5b38d620a0d5ad6f941bd6d3">
              <FormattedMessage id="intermediary.external_login.read_more" />
            </Link>
          </InlineAlert>
        </Paragraph>
        <Link href={redirectUrl}>
          <Button>
            <FormattedMessage id="intermediary.external_login.redirect_to_authentication" />
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default withRouter(ExternalSignInView);
