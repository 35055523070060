import React from 'react';
import { injectReducers } from '../../store/reducers';
import { requireAuth } from 'modules/auth/utils';

import { FormattedMessage } from 'react-intl';

const authenticate = store => requireAuth(store.getState().auth);

export default (store, browserLang) => ({
  getComponents(nextState, next) {
    require.ensure(
      ['./containers/MainContainer'],
      require => {
        const ownApplicationsReducer = require('../OwnApplications/modules/module_OwnApplications')
          .default;
        injectReducers(store, [
          { key: 'ownApplicationsView', reducer: ownApplicationsReducer }
        ]);

        const content = require('./containers/MainContainer').default;
        next(null, { content });
      },
      'ownApplications'
    );
  },
  getChildRoutes: (location, cb) => {
    cb(null, [
      {
        path: 'own-applications',
        onEnter: authenticate(store),
        indexRoute: {
          onEnter: (nextState, replace) =>
            replace(`/${browserLang}/own-applications/list`)
        },
        childRoutes: [
          {
            path: 'list',
            name: <FormattedMessage id="my_forms_view.title" />,
            onEnter: authenticate(store),
            getComponents(nextState, next) {
              const content = require('./containers/ApplicationsListContainer')
                .default;
              next(null, content);
            }
          },
          {
            path: 'application/sent/:id/:serviceType',
            onEnter: authenticate(store),
            getComponents(nextState, next) {
              const content = require('./containers/SingleApplicationViewContainer')
                .default;
              next(null, content);
            }
          }
        ]
      }
    ]);
  }
});
