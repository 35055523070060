import getCookie from 'utils/getCookie';

const SESSION_COOKIE = 'sessionExpired';
const VALUE_EXPIRED = 'true';
const VALUE_NOT_EXPIRED = 'false';

export const isSessionExpiredInCookie = () => getSessionCookie();
export const setSessionExpiredInCookie = value => {
  const cookieValue = value ? VALUE_EXPIRED : VALUE_NOT_EXPIRED;
  document.cookie = `${SESSION_COOKIE}=${cookieValue};path=/`;
};

const getSessionCookie = () => {
  const cookies = document.cookie.split(';');

  return cookies
    .map(cookie => {
      const cookieLength = cookie.length;
      const separatorIndex = cookie.indexOf('=');
      const dividingPosition =
        separatorIndex < 0 ? cookieLength : separatorIndex;

      const cookieName = decodeURIComponent(
        cookie.substring(0, dividingPosition).replace(/^\s+/, '')
      );

      const cookieValue = decodeURIComponent(
        cookie.substring(dividingPosition + 1, cookieLength)
      );

      return {
        name: cookieName,
        value: cookieValue
      };
    })
    .filter(({ name }) => name === SESSION_COOKIE)
    .some(({ value }) => value === VALUE_EXPIRED);
};
