import {createAction as ca, createReducer} from '../../utils';
import { CALL_API } from '../../store/middleware/api';

export const FEATURES_REQUEST_START = 'FEATURES_REQUEST_START';
export const FEATURES_REQUEST_SUCCESS = 'FEATURES_REQUEST_SUCCESS';
export const FEATURES_REQUEST_FAILURE = 'FEATURES_REQUEST_FAILURE';
export const FEATURES_UPDATE = 'FEATURES_UPDATE';

const handleFeatureSuccess = (payload, dispatch) => {
  const features = payload.reduce((acc, feature) => Object.assign(acc, {[feature.id]: feature.enabled}),
				  {});
  dispatch(ca(FEATURES_UPDATE)(features));
}

export function requestFeatures() {
  return {
    [CALL_API]: {
      types: [FEATURES_REQUEST_START, FEATURES_REQUEST_SUCCESS, FEATURES_REQUEST_FAILURE],
      endpoint: 'features',
      onSuccess: handleFeatureSuccess
    }
  };
}

const ACTION_HANDLERS = {
  [FEATURES_UPDATE]: (state, { payload }) => payload,

  // Request start and success are a no-op for state.
  [FEATURES_REQUEST_START]: state => state,

  // Request failure is deliberately ignored.
  // It just results in features not being turned on.
  [FEATURES_REQUEST_FAILURE]: state => state,
};

const initialState = {};

export const featureReducer = createReducer(ACTION_HANDLERS, initialState);
