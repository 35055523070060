import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

import {
  Block,
  Heading,
  Paragraph,
  Tooltip,
  Checkbox,
  CheckboxGroup,
  RadioButtonGroup,
  RadioButton
} from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import { formTitle } from './schema';
import {
  organizationClass,
  dataExchangeLayerOwnershipType,
  dataExchangeLayerPurposeOfUse,
  environmentType
} from 'constants/index';

import { IntermediaryTextField } from '../../components';
import { IPAddresses } from './IPAddresses';

export const Form = ({ formik }, { intl }) => {
  const i18n = id => intl.formatMessage({ id });
  const { formData } = formik?.values || {};
  const { serviceData } = formData || {};

  return (
    <>
      <Block>
        <Heading variant="h2" className="borderless mb-2">
          {i18n(formTitle)}
        </Heading>
        <Paragraph>
          {i18n('intermediary.dataexchangelayer.description')}
        </Paragraph>
      </Block>
      <Block className="pb-4 pt-4">
        <RadioButtonGroup
          labelText={i18n('intermediary.dataexchangelayer.environmentType')}
          name={'formData.serviceData.environmentType'}
          value={`${formData?.serviceData?.environmentType}`}
          onChange={value =>
            formik.setFieldValue(
              `formData.serviceData.environmentType`,
              parseInt(value, 10)
            )
          }
        >
          <RadioButton value={environmentType.TEST.toString()}>
            <FormattedMessage id="intermediary.dataexchangelayer.test" />
          </RadioButton>
          <RadioButton value={environmentType.PROD.toString()}>
            <FormattedMessage id="intermediary.dataexchangelayer.prod" />
          </RadioButton>
        </RadioButtonGroup>
      </Block>
      <hr className={styles.spacer} />

      <Block className="pb-4 pt-4">
        <RadioButtonGroup
          labelText={i18n('intermediary.dataexchangelayer.organizationClass')}
          name={`formData.serviceData.dataExchangeLayerInformation.organizationClass`}
          value={`${serviceData?.dataExchangeLayerInformation?.organizationClass}`}
          onChange={value =>
            formik.setFieldValue(
              `formData.serviceData.dataExchangeLayerInformation.organizationClass`,
              parseInt(value, 10)
            )
          }
        >
          <RadioButton value={organizationClass.GOV.toString()}>
            <FormattedMessage id="intermediary.dataexchangelayer.gov" />
          </RadioButton>
          <RadioButton value={organizationClass.COM.toString()}>
            <FormattedMessage id="intermediary.dataexchangelayer.com" />
          </RadioButton>
          <RadioButton value={organizationClass.MUN.toString()}>
            <FormattedMessage id="intermediary.dataexchangelayer.mun" />
          </RadioButton>
          <RadioButton value={organizationClass.EDU.toString()}>
            <FormattedMessage id="intermediary.dataexchangelayer.edu" />
          </RadioButton>
          <RadioButton value={organizationClass.ORG.toString()}>
            <FormattedMessage id="intermediary.dataexchangelayer.org" />
          </RadioButton>
        </RadioButtonGroup>
      </Block>

      <hr className={styles.spacer} />

      <Block className="pb-4 pt-4">
        <RadioButtonGroup
          variant="large"
          labelText={i18n('intermediary.dataexchangelayer.purposeOfUse')}
          name={`formData.serviceData.dataExchangeLayerInformation.purposeOfUse`}
          value={`${formik.values?.formData?.serviceData?.dataExchangeLayerInformation?.purposeOfUse}`}
          onChange={value =>
            formik.setFieldValue(
              'formData.serviceData.dataExchangeLayerInformation.purposeOfUse',
              parseInt(value, 10)
            )
          }
        >
          <RadioButton value={dataExchangeLayerPurposeOfUse.SELF.toString()}>
            <FormattedMessage id="intermediary.dataexchangelayer.self" />
          </RadioButton>
          <RadioButton
            value={dataExchangeLayerPurposeOfUse.PROVIDER.toString()}
          >
            <FormattedMessage id="intermediary.dataexchangelayer.provider" />
          </RadioButton>
        </RadioButtonGroup>
      </Block>

      <hr className={styles.spacer} />

      <Block className="pb-4 pt-4">
        <RadioButtonGroup
          variant="large"
          labelText={i18n('intermediary.dataexchangelayer.ownershipType')}
          name={`formData.serviceData.dataExchangeLayerInformation.serverInformation.ownershipType`}
          value={`${formik.values?.formData?.serviceData?.dataExchangeLayerInformation?.serverInformation?.ownershipType}`}
          onChange={value =>
            formik.setFieldValue(
              'formData.serviceData.dataExchangeLayerInformation.serverInformation.ownershipType',
              parseInt(value, 10)
            )
          }
        >
          <RadioButton
            value={dataExchangeLayerOwnershipType.BUILDOWNSERVER.toString()}
          >
            <FormattedMessage id="intermediary.dataexchangelayer.buildown" />
          </RadioButton>
          <RadioButton
            value={dataExchangeLayerOwnershipType.USEEXISTINGSERVER.toString()}
          >
            <FormattedMessage id="intermediary.dataexchangelayer.useExisting" />
          </RadioButton>
          <RadioButton
            value={dataExchangeLayerOwnershipType.USEEXISTINGEXTERNALSERVER.toString()}
          >
            <FormattedMessage id="intermediary.dataexchangelayer.useExistingExternal" />
          </RadioButton>
        </RadioButtonGroup>

        {// USEEXISTINGEXTERNALSERVER
        formik.values.formData?.serviceData?.dataExchangeLayerInformation
          ?.serverInformation.ownershipType ===
          dataExchangeLayerOwnershipType.USEEXISTINGEXTERNALSERVER && (
          <IntermediaryTextField
            name={
              'formData.serviceData.dataExchangeLayerInformation.serverInformation.ownerName'
            }
            label={'intermediary.dataexchangelayer.ownerName'}
            width={'66.66%'}
          />
        )}

        <IntermediaryTextField
          name={
            'formData.serviceData.dataExchangeLayerInformation.serverInformation.fqdn'
          }
          label={'intermediary.dataexchangelayer.fqdn'}
          width={'66.66%'}
          tooltip={
            <FormattedMessage id="intermediary.dataexchangelayer.fqdnTooltip" />
          }
        />

        <CheckboxGroup
          className="mt-4"
          labelText={
            <FormattedMessage id="intermediary.dataexchangelayer.isAuthorizationForCertificateTitle" />
          }
          tooltipComponent={
            <Tooltip
              ariaToggleButtonLabelText={i18n('general.show_more')}
              ariaCloseButtonLabelText={i18n('general.go_back')}
            >
              <FormattedMessage id="intermediary.dataexchangelayer.authTooltip" />
            </Tooltip>
          }
        >
          <Checkbox
            checked={
              serviceData?.dataExchangeLayerInformation?.serverInformation
                ?.isAuthorizationForCertificate
            }
            onClick={({ checkboxState }) =>
              formik.setFieldValue(
                `formData.serviceData.dataExchangeLayerInformation.serverInformation.isAuthorizationForCertificate`,
                checkboxState
              )
            }
          >
            <FormattedMessage id="intermediary.dataexchangelayer.isAuthorizationForCertificate" />
          </Checkbox>
        </CheckboxGroup>

        <IPAddresses formik={formik} i18n={i18n} serviceData={serviceData} />
      </Block>

      <hr className={styles.spacer} />
    </>
  );
};

Form.propTypes = {
  formik: PropTypes.object.isRequired
};

Form.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default Form;
