import { connect } from 'react-redux';
import ReduxModal from '../components/ReduxModal';
import { openModal, closeModal } from '../modules';

const mapActionCreators = {
  openModal,
  closeModal
};

const mapStateToProps = state => {
  const { isOpen, content } = state.modal;
  return { isOpen, content };
};

export default connect(mapStateToProps, mapActionCreators)(ReduxModal);
