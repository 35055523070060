import React from 'react';
import PropTypes from 'prop-types';
import {
  Block,
  Heading,
  Paragraph,
  RadioButton,
  RadioButtonGroup
} from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';

import styles from './styles.scss';
import { formTitle } from './schema';
import { IntermediaryTextarea } from '../../components';
import { choiceValues } from './constants';

export const choices = [
  {
    value: choiceValues.COMPRESS,
    label: 'intermediary.extentOfData.compress',
    hint: 'intermediary.extentOfData.compressInfo'
  },
  {
    value: choiceValues.MEDIUM,
    label: 'intermediary.extentOfData.medium',
    hint: 'intermediary.extentOfData.mediumInfo'
  },
  {
    value: choiceValues.BROAD,
    label: 'intermediary.extentOfData.broad',
    hint: 'intermediary.extentOfData.broadInfo'
  }
];

export const Form = ({ formik }, { intl }) => {
  const i18n = id => intl.formatMessage({ id });
  const { extentOfPersonalInformation } = formik.values.formData;
  const isSelected = value => value === extentOfPersonalInformation;
  const showTextarea = [choiceValues.MEDIUM, choiceValues.BROAD].includes(
    extentOfPersonalInformation
  );
  const getCurrentChoiceLabel = () => {
    const selected = choices.find(c => c.value === extentOfPersonalInformation);
    return selected ? selected.label + '_tlc' : '';
  };

  return (
    <div className={styles.container}>
      <Block>
        <Heading variant="h2" className="borderless mb-2">
          <FormattedMessage id={formTitle} />
        </Heading>
        <Paragraph>
          <FormattedMessage id="intermediary.extentOfData.description" />
        </Paragraph>
      </Block>
      <Block>
        <RadioButtonGroup
          name="formData.extentOfPersonalInformation"
          labelText={i18n('intermediary.extentOfData.dataExtent')}
          value={`${formik.values.formData.extentOfPersonalInformation}`}
          onChange={value =>
            formik.setFieldValue(
              'formData.extentOfPersonalInformation',
              parseInt(value)
            )
          }
        >
          {choices.map(({ value, label, hint }) => (
            <RadioButton
              key={value}
              value={value.toString()}
              hintText={isSelected(value) ? i18n(hint) : undefined}
            >
              <FormattedMessage id={label} />
            </RadioButton>
          ))}
        </RadioButtonGroup>
      </Block>
      {showTextarea && (
        <Block>
          <IntermediaryTextarea
            name="formData.extentOfPersonalInformationDescription"
            label={[
              'intermediary.extentOfData.reasons',
              getCurrentChoiceLabel()
            ]}
            tooltip={i18n(
              'forms.tunnistus_registration.page_identificationInfo.dataProtectionReguirements'
            )}
          />
        </Block>
      )}
    </div>
  );
};
Form.propTypes = {
  formik: PropTypes.object
};

Form.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default Form;
