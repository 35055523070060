import React from 'react';
import styles from '../styles/MyForms.scss';

export const renderDate = (date, doNotShowTime) => (
  <div>
    <div className={styles.dateField}>
      <p className={styles.date}>{`${date.getDate()}.${date.getMonth() +
        1}.${date.getFullYear()}`}</p>
      {!doNotShowTime && (
        <p className={styles.time}>{`(${date.getHours()}:${(date.getMinutes() <
          10 && '0') + date.getMinutes()})`}</p>
      )}
    </div>
  </div>
);
