import React from 'react';
import { FormattedMessage } from 'react-intl';
import NewApiUserContainer from './containers/NewApiUserContainer';

const routes = [
  {
    path: 'api-kayttaja',
    name: <FormattedMessage id="forgotten_password_title" />,
    components: { content: NewApiUserContainer }
  }
];

export default {
  getChildRoutes: (location, cb) => {
    cb(null, routes);
  }
};
