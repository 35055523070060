import React, { useState } from 'react';
import styles from '../styles/DecisionDetails.scss';
import PropTypes from 'prop-types';

export const TruncatedText = ({ textValue, maxCharacters }) => {
  const [truncated, toggleTruncate] = useState(true);
  const needsTruncation = textValue && textValue.length > maxCharacters;

  const possiblyTruncatedText =
    needsTruncation && truncated
      ? textValue.substring(0, maxCharacters)
      : textValue;

  const truncateToken = '...';

  return (
    <span
      className={styles.truncatedText}
      onClick={() => toggleTruncate(!truncated)}
    >
      {possiblyTruncatedText}
      {needsTruncation && truncated && <span>{truncateToken}</span>}
    </span>
  );
};

TruncatedText.propTypes = {
  textValue: PropTypes.string.isRequired,
  maxCharacters: PropTypes.number.isRequired
};
