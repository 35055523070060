import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import { FormattedMessage } from 'react-intl';
import { Button, IconErrorFilled, IconRemove } from 'suomifi-ui-components';

// Possible error codes: 'file-invalid-type', 'file-too-large', 'file-too-small', 'too-many-files'

export const RejectedFiles = ({ files, removeRejectedFile }, { intl }) => {
  const t = id => intl.formatMessage({ id });

  return (
    <ul>
      {(files || []).map((rejection, i) => (
        <li className={styles.fileRow} key={rejection.file.path + i}>
          <div>
            <IconErrorFilled className={styles.error} />
            <div className={styles.rowContent}>
              <div>{rejection.file.path}</div>
              <div className={styles.errorMessages}>
                {rejection.errors.map((error, i) => (
                  <span key={error.code}>
                    {t(`intermediary.error.${error.code}`)}
                    {i < rejection.errors.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <Button
            variant="secondary"
            icon={<IconRemove />}
            className={styles.removeFile}
            onClick={() => removeRejectedFile(rejection?.file?.name)}
          >
            <FormattedMessage id={'intermediary.common.remove'} />
          </Button>
        </li>
      ))}
    </ul>
  );
};
RejectedFiles.propTypes = {
  files: PropTypes.array,
  removeRejectedFile: PropTypes.func
};

RejectedFiles.contextTypes = {
  intl: PropTypes.object.isRequired
};
