import * as yup from 'yup';

export const formTitle = 'intermediary.inapi.title';

const getTranslationPath = path => `intermediary.validation.${path}`;

const radioSchema = yup
  .boolean()
  .nullable()
  .default(null)
  .when('inApiUsed', {
    is: true,
    then: yup
      .boolean()
      .required()
      .default(null)
      .nullable()
      .label(formTitle)
  });

const stringSchema = path =>
  yup
    .string()
    .default('')
    .max(300, 'intermediary.validation.max300')
    .required(getTranslationPath(path))
    .label(formTitle)
    .when('inApiUsed', {
      is: true,
      otherwise: yup
        .string()
        .default('')
        .label(formTitle)
    });

export const schema = yup.object({
  serviceData: yup.object({
    inApiUsed: yup
      .boolean()
      .required()
      .default(null)
      .nullable()
      .label(formTitle),
    inApiContentUpdatedRegularly: radioSchema,
    inApiMaintainedProperly: radioSchema,
    twoAdminsInvited: radioSchema,
    systemName: yup
      .string()
      .max(300, 'intermediary.validation.max300')
      .notRequired()
      .when('inApiUsed', {
        is: true,
        then: stringSchema('nameIsRequired')
      }),
    usageDate: yup
      .string()
      .default(new Date().toISOString())
      .notRequired()
      .when('inApiUsed', {
        is: true,
        then: yup
          .string()
          .default(new Date().toISOString())
          .required()
          .label(formTitle)
      }),
    organizationUsesSubcontractor: radioSchema,
    apiUserName: yup.string().when('organizationUsesSubcontractor', {
      is: true,
      then: stringSchema('usernameIsRequired'),
      otherwise: yup.string().default('')
    }),
    apiAccessContactInformation: yup
      .object()
      .when('organizationUsesSubcontractor', {
        is: true,
        then: yup.object({
          apiAccessContactPerson: yup.object({
            organizationName: stringSchema('organizationNameIsRequired'),
            businessId: stringSchema('vatIdIsRequired'),
            firstName: stringSchema('firstNameIsRequired'),
            lastName: stringSchema('lastNameIsRequired'),
            email: yup
              .string()
              .max(300, 'intermediary.validation.max300')
              .email(getTranslationPath('emailIsInvalid'))
              .required(getTranslationPath('emailIsRequired'))
              .default('')
              .label(formTitle)
          })
        }),
        otherwise: yup.object({
          apiAccessContactPerson: yup.object({
            organizationName: yup.string().default(''),
            businessId: yup.string().default(''),
            firstName: yup.string().default(''),
            lastName: yup.string().default(''),
            email: yup.string().default('')
          })
        })
      })
  })
});
