import React from 'react';
import { FormattedMessage } from 'react-intl';
import { requireAuth } from '../../modules/auth/utils';
import { injectReducer } from '../../store/reducers';

export const basePath = 'omat-tiedot';

export default function(store) {
  return {
    getComponents(nextState, next) {
      require.ensure(
        [],
        require => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'userProfile', reducer });

          const content = require('./components/UserProfileMainView').default;
          next(null, { content });
        },
        'userProfile'
      );
    },
    getChildRoutes(location, cb) {
      cb(null, {
        path: basePath,
        onEnter: requireAuth(store.getState().auth),
        indexRoute: {
          onEnter: (nextState, replace) =>
            replace(`/${location.params.lang}/${basePath}/tiedot`)
        },
        childRoutes: [
          {
            path: 'tiedot',
            name: <FormattedMessage id="organization.tab_info_title" />,
            getComponents(nextState, next) {
              require.ensure(
                [],
                require => {
                  const content = require('./containers/UserProfileViewContainer')
                    .default;
                  next(null, content);
                },
                'userProfile'
              );
            }
          }
        ]
      });
    }
  };
}
