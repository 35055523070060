// base64 expects 8-bit characters, but UTF-8 uses 1-4 bytes for a character, so some special characters break
// solution is to convert the string into multibyte Unicode character values and decode them into utf-8
const b64DecodeUnicode = str => {
  return decodeURIComponent(
    window
      .atob(str)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

// this separates the objects in the value string
export const convertParamsToObjFromBase64 = params => {
  let obj = {};
  b64DecodeUnicode(params)
    .split('&')
    .map(x => {
      const keyVal = x.split('=');
      obj[keyVal[0]] = keyVal[1];
    });
  return obj;
};
