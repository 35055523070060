import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { LanguageSwitcher } from '../components';
import PropTypes from 'prop-types';

export const TempUser = ({
  user,
  locale,
  styles,
  inverse,
  changeLang,
  signOut
}) => {
  return (
    <div className={styles.authRow}>
      <LanguageSwitcher
        locale={locale}
        styles={styles}
        inverse={inverse}
        changeLang={changeLang}
      />
      <div className={styles.dropdownReplacement}>
        <span>{`${user.firstName} ${user.lastName}`}</span>
        <Link className={styles.logoutTemporaryUserLink} onClick={signOut}>
          <FormattedMessage id="general.sign_out" />
        </Link>
      </div>
    </div>
  );
};
TempUser.propTypes = {
  user: PropTypes.object,
  locale: PropTypes.string,
  styles: PropTypes.object,
  inverse: PropTypes.bool,
  changeLang: PropTypes.func,
  signOut: PropTypes.func
};
